import { message } from "@caisy/league";
import produce from "immer";
import { createProject } from "../../graphql/mutations/createProject.gql";
import { UpdateProject as UpdateProjectGQL } from "../../graphql/mutations/updateProject.gql";
import { getManyProjectsByUserID } from "../../graphql/queries/getManyProjectsByUserID.gql";
import { client } from "../../utils/client";
import {
  ICreateProjectRequestInput,
  IGetManyProjectsByUserIdRequestFilterInput,
  IGetProjectByIdWithInheritanceRequestInput,
  IGetProjectByIdWithInheritanceResponse,
  IPaginationArgumentsInput,
  IProjectInputInput,
  IProjectResponse,
} from "../../interfaces/generated";
import { query_GetProjectByIDWithInheritance } from "../../graphql/queries/getProjectByIDWithInheritance.gql";
import { deleteProject } from "../../graphql/mutations/deleteProject.gql";
import unset from "lodash/unset";
import { moveProject } from "../../graphql/mutations/moveProject.gql";
import { duplicateToProject } from "../../graphql/mutations/duplicateToProject.gql";
import { getProjectPort } from "../../graphql/queries/getProjectPort.gql";
import { getPermissionGrant } from "../../graphql/queries/getPermissionGrant.gql";

interface IDuplicationProgressSelection {
  view?: boolean;
  tag?: boolean;
  preview?: boolean;
  blueprint?: boolean;
  member?: boolean;
  webhook?: boolean;
  document?: boolean;
  documentHistory?: boolean;
  documentFieldLocale?: boolean;
}

interface IDuplicationProgress {
  finished: boolean;
  progress: number;
  blueprint: boolean;
  document: boolean;
  documentFieldLocale: boolean;
  webhook: boolean;
  view: boolean;
  tag: boolean;
  preview: boolean;
  member: boolean;
}

export interface IUseProjectMembership {
  isLoadingProjects: boolean;
  totalProjectCount: number;
  projects: { [projectId: string]: IProjectResponse };
  projectWithInheritance: IGetProjectByIdWithInheritanceResponse;
  loadingInheritance: boolean;
  initializedProjects: boolean;
  duplicationProgress: IDuplicationProgress;
  duplicationProgressSlection: IDuplicationProgressSelection;
  showProjectLimitModal: string | null;
  setShowProjectLimitModal(organizationId: string | null): void;
  getProjectByIdWithInheritance: ({ projectId }: IGetProjectByIdWithInheritanceRequestInput) => Promise<void>;
  createProject: ({
    project: { name, groupId },
    userId,
    organizationId,
  }: ICreateProjectRequestInput) => Promise<IProjectResponse>;
  updateProject: ({
    projectId,
    input,
  }: {
    projectId: string;
    input: IProjectInputInput;
  }) => Promise<IProjectInputInput>;
  deleteProject: ({ projectId }: { projectId: string }) => Promise<boolean>;
  loadProjects: ({
    userId,
  }: {
    userId: string;
    filter?: IGetManyProjectsByUserIdRequestFilterInput;
    paginationArguments?: IPaginationArgumentsInput;
  }) => Promise<void>;
  deleteManyProjectByIds: ({ projectIds }: { projectIds: string[] }) => Promise<void>;
  moveProject: (input: { projectId: string; targetGroupId?: string; targetOrganizationId?: string }) => Promise<void>;
  checkPermissionsForMovingProject: (input: {
    projectId: string;
    currentGroupId: string;
    currentOrganizationId: string;
    targetGroupId?: string;
    targetOrganizationId: string;
    userId: string;
  }) => Promise<boolean>;
  duplicateToProject: (input: {
    projectId: string;
    selection: {
      view: boolean;
      tag: boolean;
      preview: boolean;
      blueprint: boolean;
      member: boolean;
      webhook: boolean;
      document: boolean;
      documentHistory: boolean;
      documentFieldLocale: boolean;
    };
    source: { projectId: string };
  }) => Promise<string>;
  getProjectPort: (input: { portId: string }) => Promise<void>;
  resetDuplicationProgress: () => void;
  cleanUpProjectsByOrganizationId: (organizationId: string) => void;
  cleanUpProjectsByGroupId: (groupId: string) => void;
}

export interface IProjectMembershipState {
  project: IUseProjectMembership;
}

export const createProjectMembershipSlice = (
  set: (cb: (state: IProjectMembershipState) => IProjectMembershipState, boolean, string) => void,
  get: () => IProjectMembershipState,
) => ({
  project: {
    projects: {},
    duplicationProgressSlection: {} as IDuplicationProgressSelection,
    totalProjectCount: 0,
    isLoadingProjects: false,
    projectWithInheritance: {} as IGetProjectByIdWithInheritanceResponse,
    loadingInheritance: false,
    initializedProjects: false,
    duplicationProgress: {} as IDuplicationProgress,
    showProjectLimitModal: null,
    getProjectByIdWithInheritance: async ({ projectId }: IGetProjectByIdWithInheritanceRequestInput) => {
      const { data } = await client.query({
        query: query_GetProjectByIDWithInheritance,
        variables: {
          input: {
            projectId,
          },
        },
      });
      const res = data?.GetProjectByIDWithInheritance as IGetProjectByIdWithInheritanceResponse;
      // console.log(` projectWithInheritance res`, res);

      if (res) {
        set(
          produce((prev: IProjectMembershipState) => {
            if (prev.project.totalProjectCount === 0 && res) {
              prev.project.totalProjectCount = 1;
            }
            prev.project.projectWithInheritance = res;
            if (!prev.project.projects[projectId]) {
              prev.project.projects = {
                ...prev.project.projects,
                [projectId]: res.project,
              };
            }
          }),
          false,
          "project/GetProjectByIdWithInheritance",
        );
      }
    },
    updateProject: async ({ projectId, input }: { projectId: string; input: IProjectInputInput }) => {
      try {
        const res = await client.mutate({
          mutation: UpdateProjectGQL,
          variables: {
            input: {
              input: {
                groupId: input.groupId ? input.groupId : get().project.projects[projectId].groupId,
                ...input,
              },
              projectId,
            },
          },
        });
        if (res?.data?.UpdateProject?.project) {
          set(
            produce((prev) => {
              prev.project.projects = {
                ...prev.project.projects,
                [projectId]: res.data.UpdateProject.project,
              };
              prev.project.projectWithInheritance = {
                ...prev.project.projectWithInheritance,
                project: res.data.UpdateProject.project,
              };
            }),
            false,
            "project/updateProject",
          );

          return res?.data?.UpdateProject?.project;
        }
        res?.data?.UpdateProject?.project;
      } catch (err) {
        console.error(err);
        message.error(`Update project failed: ${err.message ?? ""}`);
      }
    },
    loadProjects: async ({
      userId,
      filter,
      paginationArguments,
    }: {
      userId: string;
      filter?: IGetManyProjectsByUserIdRequestFilterInput;
      paginationArguments?: IPaginationArgumentsInput;
    }) => {
      const { data } = await client.query({
        query: getManyProjectsByUserID,
        variables: { userId, filter, paginationArguments },
        fetchPolicy: "no-cache",
      });

      if (data?.GetManyProjectsByUserID?.connection.edges) {
        const projects = { ...get().project.projects };
        data?.GetManyProjectsByUserID?.connection.edges.forEach((edge) => {
          projects[edge.node.projectId] = edge.node;
        });

        set(
          produce<IProjectMembershipState>((state) => {
            state.project.projects = projects;

            state.project.initializedProjects = true;
            state.project.totalProjectCount = data.GetManyProjectsByUserID.connection.totalCount;
          }),
          false,
          "project/loadProjects/onUpdate",
        );
      }

      if (
        data.GetManyProjectsByUserID?.connection?.pageInfo?.hasNextPage &&
        data.GetManyProjectsByUserID?.connection?.pageInfo?.endCursor
      ) {
        get().project.loadProjects({
          userId,
          filter,
          paginationArguments: {
            first: 1000,
            after: data.GetManyProjectsByUserID.connection.pageInfo.endCursor,
          },
        });
      }

      set(
        produce((state) => {
          state.project.isLoadingProjects = false;
        }),
        false,
        "project/loadProjects",
      );
    },
    createProject: async ({ project: { name, groupId }, userId, organizationId }: ICreateProjectRequestInput) => {
      try {
        const res = await client.mutate({
          mutation: createProject,
          variables: {
            name,
            userId,
            groupId,
            organizationId,
          },
        });
        if (res?.data?.CreateProject?.project) {
          set(
            produce((prev) => {
              prev.project.projects = {
                ...prev.project.projects,
                [res?.data?.CreateProject?.project.projectId]: {
                  ...res.data.CreateProject.project,
                  roleByUser: { title: "owner" },
                },
              };
            }),
            false,
            "project/createProject",
          );

          return res?.data?.CreateProject?.project;
        }
        res?.data?.CreateProject?.project;
      } catch (err) {
        console.error(`err: `, err);
        if (err?.message?.includes("ResourceExhausted")) {
          set(
            produce((state) => {
              state.project.showProjectLimitModal = organizationId;
            }),
            false,
            "project/createProject/limitReached",
          );
        } else {
          message.error(`add project failed: ${err.message ?? ""}`);
        }
      }
    },
    setShowProjectLimitModal: (organizationId: string) => {
      set(
        produce((state) => {
          state.project.showProjectLimitModal = organizationId;
        }),
        false,
        "project/setShowProjectLimitModal",
      );
    },
    deleteProject: async ({ projectId }) => {
      const { data } = await client.mutate({
        mutation: deleteProject,
        variables: { projectId },
      });

      const { deleted } = data?.DeleteProject;

      if (deleted) {
        set(
          produce<IProjectMembershipState>((state) => {
            unset(state.project.projects, projectId);
            unset(state.project.projectWithInheritance, projectId);
          }),
          false,
          "project/deleteProject",
        );
      }

      return deleted;
    },

    deleteManyProjectByIds: async ({ projectIds }) => {
      await Promise.all(projectIds.map((projectId) => get().project.deleteProject({ projectId })));
    },

    moveProject: async (input: { projectId: string; targetGroupId?: string; targetOrganizationId?: string }) => {
      try {
        const { data } = await client.mutate({ mutation: moveProject, variables: { input } });

        if (data.MoveProject.project) {
          set(
            produce<IProjectMembershipState>((state) => {
              state.project.projects[input.projectId] = data.MoveProject.project;
            }),
            false,
            "project/moveProject",
          );
        }

        return data.MoveProject.project;
      } catch (error) {
        console.log(error);
      }
    },

    checkPermissionsForMovingProject: async (input: {
      userId: string;
      projectId?: string;
      currentGroupId?: string;
      currentOrganizationId: string;
      targetGroupId?: string;
      targetOrganizationId: string;
    }) => {
      const permissionPromises = [
        async () => {
          try {
            const { data } = await client.query({
              query: getPermissionGrant,
              variables: {
                input: {
                  userId: input.userId,
                  action: "create",
                  object: "project",
                  projectId: input.projectId,
                },
              },
            });

            return data.GetPermissionGrant.success;
          } catch (error) {
            return false;
          }
        },

        async () => {
          try {
            const { data } = await client.query({
              query: getPermissionGrant,
              variables: {
                input: {
                  userId: input.userId,
                  action: "create",
                  object: "organization",
                  organizationId: input.targetOrganizationId,
                },
              },
            });
            return data.GetPermissionGrant.success;
          } catch (error) {
            return false;
          }
        },

        async () => {
          try {
            const { data } = await client.query({
              query: getPermissionGrant,
              variables: {
                input: {
                  userId: input.userId,
                  action: "create",
                  object: "organization",
                  organizationId: input.currentOrganizationId,
                },
              },
            });
            return data.GetPermissionGrant.success;
          } catch (error) {
            return false;
          }
        },
      ];

      if (input.currentGroupId) {
        permissionPromises.push(async () => {
          try {
            const { data } = await client.query({
              query: getPermissionGrant,
              variables: {
                input: {
                  userId: input.userId,
                  action: "create",
                  object: "group",
                  groupId: input.currentGroupId,
                },
              },
            });

            return data.GetPermissionGrant.success;
          } catch (error) {
            return false;
          }
        });
      }

      if (input.targetGroupId) {
        permissionPromises.push(async () => {
          try {
            const { data } = await client.query({
              query: getPermissionGrant,
              variables: {
                input: {
                  userId: input.userId,
                  action: "create",
                  object: "group",
                  groupId: input.targetGroupId,
                },
              },
            });

            return data.GetPermissionGrant.success;
          } catch (error) {
            return false;
          }
        });
      }

      const permissions = await Promise.all(permissionPromises.map((promise) => promise()));

      return !permissions.some((permission) => !permission);
    },

    duplicateToProject: async (input: {
      projectId: string;
      selection: IDuplicationProgressSelection;
      source: { projectId: string };
    }) => {
      set(
        produce<IProjectMembershipState>((state) => {
          state.project.duplicationProgressSlection = input.selection;
        }),
        false,
        "project/setDuplicationProgressSlection",
      );

      const { data } = await client.mutate({ mutation: duplicateToProject, variables: { input } });

      return data.DuplicateToProject.portId;
    },

    getProjectPort: async (input: { portId: string }) => {
      const { data } = await client.query({
        query: getProjectPort,
        variables: { input },
        fetchPolicy: "no-cache",
      });

      let progress = 0;
      const slection = get().project.duplicationProgressSlection;
      const selectionProgress = data.GetProjectPort.selectionProgress;

      const sections = [
        { name: "blueprint", weight: 1 },
        { name: "document", weight: 10 },
        { name: "documentFieldLocale", weight: 1 },
        { name: "webhook", weight: 1 },
        { name: "preview", weight: 1 },
        { name: "view", weight: 1 },
        { name: "member", weight: 1 },
      ];

      let totalWeights = 0;
      sections.forEach((section) => {
        if (slection[section.name]) {
          totalWeights += section.weight;
          const total = selectionProgress[`${section.name}Total`];
          const completed = selectionProgress[`${section.name}Completed`];
          progress += total == 0 || completed == 0 ? 0 : Math.floor((completed * 100) / total) * section.weight;
        }
      });

      const newDuplicationProgressObject = {
        finished: data.GetProjectPort.finished,
        progress: data.GetProjectPort.finished ? 100 : totalWeights == 0 ? 0 : Math.floor(progress / totalWeights),
        blueprint: selectionProgress.blueprintCompleted === selectionProgress.blueprintTotal,
        document: selectionProgress.documentCompleted === selectionProgress.documentTotal,
        documentFieldLocale:
          selectionProgress.documentFieldLocaleCompleted === selectionProgress.documentFieldLocaleTotal,
        webhook: selectionProgress.webhookCompleted === selectionProgress.webhookTotal,
        preview: selectionProgress.previewCompleted === selectionProgress.previewTotal,
        view: selectionProgress.viewCompleted === selectionProgress.viewTotal,
        tag: selectionProgress.tagCompleted === selectionProgress.tagTotal,
        member: selectionProgress.memberCompleted === selectionProgress.memberTotal,
      };

      set(
        produce<IProjectMembershipState>((state) => {
          state.project.duplicationProgress = newDuplicationProgressObject;
        }),
        false,
        "project/getProjectPort",
      );
    },

    resetDuplicationProgress: () => {
      set(
        produce<IProjectMembershipState>((state) => {
          state.project.duplicationProgressSlection = {};
          state.project.duplicationProgress = {} as IDuplicationProgress;
        }),
        false,
        "project/resetDuplicationProgress",
      );
    },

    cleanUpProjectsByOrganizationId: (organizationId: string) => {
      set(
        produce<IProjectMembershipState>((state) => {
          Object.keys(state.project.projects).forEach((projectId) => {
            if (state.project.projects[projectId].organizationId === organizationId) {
              delete state.project.projects[projectId];
            }
          });
        }),
        false,
        "project/cleanUpProjectsByOrganizationId",
      );
    },

    cleanUpProjectsByGroupId: (groupId: string) => {
      set(
        produce<IProjectMembershipState>((state) => {
          Object.keys(state.project.projects).forEach((projectId) => {
            if (state.project.projects[projectId].groupId === groupId) {
              delete state.project.projects[projectId];
            }
          });
        }),
        false,
        "project/cleanUpProjectsByGroupId",
      );
    },
  },
});
