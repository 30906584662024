import gql from "graphql-tag";
import { GetProjectQuotaPlanResponse } from "../fragments/projectQuotaPlanResponse.gql";

export const getProjectQuotaPlan = gql`
  ${GetProjectQuotaPlanResponse}
  query GetProjectQuotaPlan($input: GetProjectQuotaPlanRequestInput) {
    GetProjectQuotaPlan(input: $input) {
      ...GetProjectQuotaPlanResponse
    }
  }
`;
