import styled from "styled-components";

export const SOnboardingTourCardCloseIcon = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text-03);
`;
