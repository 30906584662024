import { gql } from "@apollo/client";

export const createOrganization = gql`
  mutation CreateOrganization(
    $userId: String
    $name: String!
    $logoAssetUrl: String
    $description: String
    $alias: String
  ) {
    CreateOrganization(
      input: {
        userId: $userId
        organization: { name: $name, logoAssetUrl: $logoAssetUrl, description: $description, alias: $alias }
      }
    ) {
      organization {
        alias
        createdAt
        description
        logoAssetUrl
        name
        organizationId
        updatedAt
      }
    }
  }
`;
