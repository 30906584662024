import { gql } from "@apollo/client";
import { fragment_blueprintResponse } from "../fragments/blueprintResponse.gql";

export const getBlueprintByName = gql`
  ${fragment_blueprintResponse}
  query GetBlueprintByName($input: GetBlueprintByNameRequest) {
    GetBlueprintByName(input: $input) {
      blueprint {
        ...fragment_blueprintResponse
      }
    }
  }
`;
