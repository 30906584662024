export const PERMISSION = {
  DOCUMENTS: "document",
  ASSETS: "asset",
  BLUEPRINT: "blueprint",
  PLAYGROUND: "playground",
  USERROLES: "userRole",
  USERMANAGEMENT: "userManagement",
  SELFUSERMANAGEMENT: "selfUserManagement",
  WEBHOOKS: "webhook",
  PREVIEWS: "preview",
  LOCALES: "locale",
  API: "API",
  BILLING: "billing",
  PROJECT: "project",
  ORGANIZATION: "organization",
  GROUP: "group",
  PRIVATEVIEWS: "privateView",
  SHAREDVIEWS: "sharedView",
  SHAREDVIEWSPROTECTION: "sharedViewProtection",
  RELEASE: "release",
  TAG: "tag",
  AUDIT: "audit",
  MEMBER: "member",
};

export const PERMISSION_ACTION = {
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
  CREATE: "create",
};
