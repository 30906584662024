import { gql } from "@apollo/client";

export const getProjectMembers = gql`
  query GetProjectMembers($projectId: String, $paginationArguments: PaginationArgumentsInput) {
    GetProjectMembers(input: { projectId: $projectId, paginationArguments: $paginationArguments }) {
      connection {
        edges {
          cursor
          node {
            role {
              description
              groupId
              organizationId
              projectId
              roleId
              systemRole
              title
            }
            user {
              displayName
              email
              photoUrl
              userId
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;
