import styled, { css } from "styled-components";

const Default = css`
  display: flex;
  justify-content: start;
  gap: 9px;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  > img {
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
  }
  ${(props: any) => (props.filterColor ? `filter: hue-rotate(var(--value, ${props.filterColor}))` : "")}
`;

const SLogoSquare = styled.div<{ filterColor: string }>`
  ${Default}
`;
export default SLogoSquare;
