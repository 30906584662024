import { Button, IconDelete, IconEditImageAsset } from "@caisy/league";
import { FC } from "react";
import { I18n } from "../../../../../../provider/i18n";
import IconImageUpload from "./IconImageUpload";
import { SOnboardingAssetField } from "./styles/SOnboardingAssetField";
import { SOnboardingAssetFieldButtons } from "./styles/SOnboardingAssetFieldButtons";
import { SOnboardingAssetFieldDescription } from "./styles/SOnboardingAssetFieldDescription";
import { SOnboardingAssetFieldImageContainer } from "./styles/SOnboardingAssetFieldImageContainer";
import { SOnboardingAssetFieldImageWrapper } from "./styles/SOnboardingAssetFieldImageWrapper";
import { SOnboardingAssetFieldLabel } from "./styles/SOnboardingAssetFieldLabel";

interface IOnboardingAssetField {
  src?: string;
  label?: React.ReactNode;
  description?: React.ReactNode;
  onUploadClick?: (e: Event) => void;
  onCancel?: () => void;
}

export const OnboardingAssetField: FC<IOnboardingAssetField> = ({
  src,
  label,
  description,
  onUploadClick,
  onCancel,
}) => {
  return (
    <SOnboardingAssetField>
      <div>
        <SOnboardingAssetFieldLabel>{label}</SOnboardingAssetFieldLabel>
        <SOnboardingAssetFieldDescription>{description}</SOnboardingAssetFieldDescription>
      </div>
      <SOnboardingAssetFieldImageContainer>
        <SOnboardingAssetFieldImageWrapper>
          {src ? <img src={src} /> : <IconImageUpload />}
        </SOnboardingAssetFieldImageWrapper>
        <SOnboardingAssetFieldButtons>
          {!src ? (
            <Button type="primary" onClick={onUploadClick}>
              <I18n selector="homePage.onboarding_uploadImage" fallback="upload image" />
            </Button>
          ) : (
            <>
              <Button onClick={onUploadClick} type="neutral">
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <IconEditImageAsset size={16} /> <I18n selector="homePage.onboarding_change" fallback="change" />
                </div>
              </Button>
              <Button onClick={onCancel} type="danger">
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <IconDelete size={16} /> <I18n selector="homePage.onboarding_delete" fallback="delete" />
                </div>
              </Button>
            </>
          )}
        </SOnboardingAssetFieldButtons>
      </SOnboardingAssetFieldImageContainer>
    </SOnboardingAssetField>
  );
};
