import styled from "styled-components";

export const SMaintenanceWarningModal = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 448px;
`;
