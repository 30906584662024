import { useRouter } from "next/router";
import { useState } from "react";
import { useAuthentication } from "../../stores/authentication/useAuthentication";
import { useEffect } from "react";
import { getAuthToken } from "../../utils/client";
import dynamic from "next/dynamic";

const AppsumoDowngradeModalBody: any = dynamic(
  () => import("./AppsumoDowngradeModalBody").then((mod) => mod.AppsumoDowngradeModalBody),
  { ssr: false },
);

export const AppsumoDowngradeModal = () => {
  const router = useRouter();
  const { userId } = useAuthentication();
  const [modalData, setModalData] = useState<any>(null);

  const rightPath =
    router.asPath.includes("/project/") ||
    router.asPath.includes("/organization/") ||
    router.asPath.includes("/group/");

  useEffect(() => {
    if (userId && rightPath) {
      (async () => {
        try {
          const fRes = await fetch(`${process.env.CORE_URL}/api/appsumo/v1/manage`, {
            headers: {
              "x-caisy-token": await getAuthToken(),
            },
          });
          if (fRes.status === 200) {
            const res = await fRes.json();
            if (
              res &&
              res.downgradedProjects &&
              res.upgradesLeft &&
              res.downgradedProjects.length > 0 &&
              res.upgradesLeft > 0
            ) {
              setModalData(res);
            }
          }
        } catch {}
      })();
    }
  }, [userId, rightPath]);

  if (userId && rightPath && modalData) {
    return <AppsumoDowngradeModalBody modalData={modalData} onClose={() => setModalData(null)} />;
  }

  return null;
};
