import React, { FC, ReactNode } from "react";
import EmptyGroupsIcon from "./EmptyGroupsIcon";
import EmptyOrganizationIcon from "./EmptyOrganizationIcon";
import EmptyProjectsIcon from "./EmptyProjectsIcon";
import EmptySearchIcon from "./EmptySearchIcon";
import { SOrganizationMenuEmpty } from "./styles/SOrganizationMenuEmpty";
import { SOrganizationMenuEmptyWrapper } from "./styles/SOrganizationMenuEmptyWrapper";
import { SOrganizationMenuEmptyWrapperButton } from "./styles/SOrganizationMenuEmptyWrapperButton";
import { SOrganizationMenuEmptyWrapperContent } from "./styles/SOrganizationMenuEmptyWrapperContent";
import { SOrganizationMenuEmptyWrapperLabel } from "./styles/SOrganizationMenuEmptyWrapperLabel";
import { SOrganizationMenuEmptyWrapperTitle } from "./styles/SOrganizationMenuEmptyWrapperTitle";

export interface IOrganizationMenuEmpty {
  emptyType: "organizations" | "groups" | "projects" | "search" | "text";
  size: "large" | "small" | "medium";
  title: ReactNode;
  label?: ReactNode;
  buttonElement?: ReactNode;
  emptyHeight?: string;
}

const getImage = (type) => {
  switch (type) {
    case "organizations":
      return <EmptyOrganizationIcon />;
    case "groups":
      return <EmptyGroupsIcon />;
    case "projects":
      return <EmptyProjectsIcon />;
    case "search":
      return <EmptySearchIcon />;
    case "text":
      return;
  }
};
export const OrganizationMenuEmpty: FC<IOrganizationMenuEmpty> = ({
  emptyType,
  title,
  label,
  emptyHeight,
  size,
  buttonElement,
}) => {
  return (
    <SOrganizationMenuEmpty emptyHeight={emptyHeight}>
      <SOrganizationMenuEmptyWrapper size={size}>
        {getImage(emptyType)}
        <SOrganizationMenuEmptyWrapperContent>
          <SOrganizationMenuEmptyWrapperTitle emptyType={emptyType} size={size}>
            {title}
          </SOrganizationMenuEmptyWrapperTitle>
          {label && <SOrganizationMenuEmptyWrapperLabel size={size}>{label}</SOrganizationMenuEmptyWrapperLabel>}
        </SOrganizationMenuEmptyWrapperContent>
        {buttonElement && <SOrganizationMenuEmptyWrapperButton>{buttonElement}</SOrganizationMenuEmptyWrapperButton>}
      </SOrganizationMenuEmptyWrapper>
    </SOrganizationMenuEmpty>
  );
};
