import { Button } from "@caisy/league";
import { FC, Fragment } from "react";
import { IGenProjectTemplate } from "../../../../../interfaces/generated/i18n-types";
import { I18n } from "../../../../../provider/i18n";
import { SOnboardingTemplatesTemplate } from "./styles/SOnboardingTemplatesTemplate";
import { SOnboardingTemplatesTemplateContent } from "./styles/SOnboardingTemplatesTemplateContent";
import { SOnboardingTemplatesTemplateDescription } from "./styles/SOnboardingTemplatesTemplateDescription";
import { SOnboardingTemplatesTemplateFooter } from "./styles/SOnboardingTemplatesTemplateFooter";
import { SOnboardingTemplatesTemplateImage } from "./styles/SOnboardingTemplatesTemplateImage";
import { SOnboardingTemplatesTemplateName } from "./styles/SOnboardingTemplatesTemplateName";
import { SOnboardingTemplatesTemplateStack } from "./styles/SOnboardingTemplatesTemplateStack";
import { SOnboardingTemplatesTemplateStackIcon } from "./styles/SOnboardingTemplatesTemplateStackIcon";
import { SOnboardingTemplatesTemplateStackSeparator } from "./styles/SOnboardingTemplatesTemplateStackSeparator";

interface IOnboardingTemplatesTemplate {
  template: IGenProjectTemplate;
  onSelect: (template: IGenProjectTemplate) => void;
  isChangeTemplate?: boolean;
}

export const OnboardingTemplatesTemplate: FC<IOnboardingTemplatesTemplate> = ({
  template,
  onSelect,
  isChangeTemplate,
}) => {
  return (
    <SOnboardingTemplatesTemplate onClick={() => onSelect(template)}>
      <SOnboardingTemplatesTemplateImage src={template.previewImage.src} />
      <SOnboardingTemplatesTemplateContent>
        <SOnboardingTemplatesTemplateName>{template.name}</SOnboardingTemplatesTemplateName>
        <SOnboardingTemplatesTemplateDescription>{template.shortDescription}</SOnboardingTemplatesTemplateDescription>
      </SOnboardingTemplatesTemplateContent>
      <SOnboardingTemplatesTemplateFooter>
        {template.stack.length > 0 ? (
          <SOnboardingTemplatesTemplateStack>
            {template.stack.map(({ icon, id }, index) => (
              <Fragment key={id}>
                <SOnboardingTemplatesTemplateStackIcon>
                  <img src={icon.src} />
                </SOnboardingTemplatesTemplateStackIcon>
                {index !== template.stack.length - 1 ? <SOnboardingTemplatesTemplateStackSeparator /> : null}
              </Fragment>
            ))}
          </SOnboardingTemplatesTemplateStack>
        ) : (
          <div />
        )}
        <Button onClick={(e) => e.preventDefault()} style={{ width: "fit-content" }} size="small" type="tertiary">
          {isChangeTemplate ? (
            <I18n selector="homePage.onboarding_changeTemplate" fallback="change template" />
          ) : (
            <I18n selector="homePage.onboarding_select" fallback="select" />
          )}
        </Button>
      </SOnboardingTemplatesTemplateFooter>
    </SOnboardingTemplatesTemplate>
  );
};
