import { FC } from "react";
import { useStore } from "..";
import { IUseAuthentication } from "./createAuthenticationSlice";
export const useAuthentication = () => useStore(({ authentication }) => authentication);

export const withUseAuthentication = <P extends IUseAuthentication>(Wrapped: any) => {
  const WithUseAuthentication: FC<P & IUseAuthentication> = (props) => {
    const authentication = useAuthentication();

    return <Wrapped {...(props as P)} authentication={authentication} />;
  };

  return WithUseAuthentication;
};
