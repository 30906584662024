import produce from "immer";

export interface IUsePlayground {
  shouldReloadPlayground: boolean;
  reloadPlayground: () => void;
  reloadPlaygroundAction: () => boolean;
}

export interface IUsePlaygroundState {
  playground: IUsePlayground;
}

export const createPlaygroundSlice = (
  set: (cb: (state: IUsePlaygroundState) => IUsePlaygroundState) => void,
  get: () => IUsePlaygroundState,
) => ({
  playground: {
    shouldReloadPlayground: false,

    reloadPlayground: () => {
      set(
        produce((state) => {
          state.playground.shouldReloadPlayground = true;
        }),
      );
    },

    reloadPlaygroundAction: () => {
      if (!get().playground.shouldReloadPlayground) {
        return false;
      }
      const node = document.getElementById("playground");
      if (node) {
        const iframe = node as HTMLIFrameElement;
        try {
          const doc = iframe.contentDocument || (iframe.contentWindow && iframe.contentWindow.document);
          if (doc) {
            const reloadButton = doc.querySelector('[title="Reload Schema"]');
            if (reloadButton) {
              (reloadButton as HTMLButtonElement).click();
            }
          }
        } catch (err) {
          const parentNode = iframe.parentNode as HTMLDivElement;
          parentNode.setAttribute("style", "opacity:0; pointer-events: none; position: fixed; top: 0");
          parentNode.hidden = false;
          iframe.src = iframe.src.replace(/\?.*/g, "") + "?" + Math.random();
          setTimeout(() => {
            if (!parentNode) {
              return;
            }
            parentNode.hidden = true;
            parentNode.setAttribute("style", "");
          }, 100);
        }
      }
      set(
        produce((state) => {
          state.playground.shouldReloadPlayground = false;
        }),
      );
    },
  },
});
