import { Spacer } from "@caisy/league";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useCurrentGroupId } from "../../../hooks/current-group-id/useCurrentGroupId";
import { useCurrentOrganizationId } from "../../../hooks/current-organization-id/useCurrentOrganizationId";
import { useCurrentProjectId } from "../../../hooks/current-project-id/useCurrentProjectId";
import { useAuthentication } from "../../../stores/authentication/useAuthentication";
import { useMemberShip } from "../../../stores/membership/useMembership";
import { usePermission } from "../../../stores/permission/usePermission";
import LogoSquare from "../../atom/logoSquare/LogoSquare";
import { FileUpload } from "../file-upload/FileUpload";
import MainMenuItems from "./MainMenuItems";
import MainMenuOrganizationSelect from "./MainMenuOrganizationSelect";
import { SMainMenuSkeleton } from "./MainMenuSkeleton/SMainMenuSkeleton";
import { SMainMenuUpperNav } from "./MainMenuItems/styles/SMainMenuUpperNav";
import { SMainNavigation } from "./styles/SMainNavigation";
import { SMainMenuDivider } from "./styles/SMainNavigationDivider";
import { SMainNavigationFooterNav } from "./MainMenuFooterNav/styles/SMainNavigationFooterNav";
import { SMainNavigationSpacer } from "./styles/SMainNavigationSpacer";
import MainMenuFooterNav from "./MainMenuFooterNav";
import { PERMISSION, PERMISSION_ACTION } from "../../../services/security/constant";
import { useSecurityContext } from "../../../services/security";
import { SExpandButton } from "./styles/SExpandButton";
import { ExpandArrows } from "../../../utils/expandArrows";
import { I18n } from "../../../provider/i18n";
import { ProjectLimitModal } from "./ProjectLimitModal/ProjectLimitModal";
import useExpandableNavigation from "../../../hooks/useExpandableNavigation";
import { OrganizationLimitModal } from "./OrganizationLimitModal/OrganizationLimitModal";

const MainNavigation: FC<{ asPath: any }> = ({ asPath }) => {
  const { allowExpand, expandMainNav, expanded } = useExpandableNavigation();
  const { user, logout } = useAuthentication();
  const {
    initializedProjects,
    projects,
    initializedOrganizations,
    organizations,
    initializedGroups,
    groups,
    projectWithInheritance,
  } = useMemberShip();

  const { initializedPermission } = usePermission();
  const { can } = useSecurityContext();
  const projectId = useCurrentProjectId();
  const groupId = useCurrentGroupId();
  const organizationId = useCurrentOrganizationId();

  const [initialized, setInitialized] = useState(false);

  const organization = useMemo(() => {
    return (
      organizations[organizationId] ||
      organizations[groups[groupId]?.organizationId] ||
      organizations[projects[projectId]?.organizationId] ||
      projectWithInheritance?.organization
    );
  }, [organizations, organizationId, groupId, groups, projectId, projects, projectWithInheritance]);

  const group = useMemo(() => {
    return groups[groupId] || groups[projects[projectId]?.groupId] || projectWithInheritance?.group;
  }, [groups, groupId, projects, projectId, projectWithInheritance]);

  const project = useMemo(() => {
    return projects[projectId];
  }, [projects, projectId]);

  useEffect(() => {
    if (
      initializedGroups &&
      initializedOrganizations &&
      initializedProjects &&
      (initializedPermission || (!projectId && initializedProjects)) &&
      user
    ) {
      setInitialized(true);
    }
  }, [initializedGroups, initializedOrganizations, initializedProjects, initializedPermission, user]);

  return (
    <SMainNavigation expanded={expanded}>
      {initialized ? (
        <>
          <LogoSquare size={40} />
          <SMainNavigationSpacer />
          <MainMenuOrganizationSelect organization={organization} group={group} project={project} />
          <SMainMenuDivider marginTop={20} marginBottom={12} />
          <MainMenuItems asPath={asPath} projectId={projectId} />
          <MainMenuFooterNav user={user} logout={logout} projectId={projectId} />

          {projectId && can(PERMISSION_ACTION.UPDATE, PERMISSION.ASSETS) ? (
            <>
              <Spacer bronze={6} />
              <FileUpload />
            </>
          ) : null}
        </>
      ) : (
        <>
          <LogoSquare size={40} />
          <Spacer bronze={20} />
          <SMainMenuSkeleton width={"38px"} height={"69px"} />
          <SMainMenuDivider marginTop={20} marginBottom={12} />
          <SMainMenuUpperNav>
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
          </SMainMenuUpperNav>
          <SMainNavigationFooterNav>
            {/* <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} /> */}
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
            <SMainMenuDivider marginTop={8} marginBottom={8} />
            <SMainMenuSkeleton width={"20px"} height={"20px"} margin={"6px"} />
          </SMainNavigationFooterNav>
        </>
      )}
      {allowExpand && (
        <>
          <SMainMenuDivider marginTop={20} marginBottom={12} />
          <SExpandButton onClick={expandMainNav} expanded={expanded}>
            <ExpandArrows />
            <div className="text">
              <I18n selector="nav.nav_items_collapse" fallback="Collapse" />
            </div>
          </SExpandButton>
        </>
      )}
      <ProjectLimitModal />
      <OrganizationLimitModal />
    </SMainNavigation>
  );
};

export default MainNavigation;
