import { decodeFieldMessage } from "../encoding";
import { ICollaborationState, IPeer } from "../types";
import { findOwnListeningField } from "./helper";
import * as Y from "yjs";

export const handleSyncUpdate = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  state.mutex.runExclusive(() => {
    // console.log(`START PEER_MESSAGE_TYPE_MESSAGE_SYNC_UPDATE`);

    const field = decodeFieldMessage(data);
    const ownListeningField = findOwnListeningField(state, field);

    if (!ownListeningField?.ydoc) {
      return;
    }

    const peerThinkFieldIsSynced = field.syncChecksum === "2";
    const weThinkTheFieldIsSynced = ownListeningField.peersSynced[peer.clientId];

    // console.log(` peerThinkFieldIsSynced`, peerThinkFieldIsSynced);
    // console.log(` weThinkTheFieldIsSynced`, weThinkTheFieldIsSynced);
    if (peerThinkFieldIsSynced != weThinkTheFieldIsSynced) {
      console.error(`peerThinkFieldIsSynced != weThinkTheFieldIsSynced`);
      return;
    }

    // if (field.isSynced) {
    //   const provider = ownListeningField.provider;
    //   ownListeningField.peersSynced[peer.clientId] = true;
    //   provider.setSynced(true);
    //   provider.emit("synced", [{ synced: true }]);
    // }

    if (ownListeningField.peersSynced[peer.clientId]) {
      const ydoc = ownListeningField.ydoc;

      Y.applyUpdate(ydoc, field.body, { key: "peer_" + peer.clientId });
    } else {
      console.log(`PEER_MESSAGE_TYPE_MESSAGE_SYNC_UPDATE NOT SYNCED ${peer.clientId}`, field);
    }

    // console.log(`END PEER_MESSAGE_TYPE_MESSAGE_SYNC_UPDATE`);
  });
};
