import { Popover, TPlacement } from "@caisy/league";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Z_INDEX } from "../../../../constants/z-index-numbers";
import { IGenUserOnboardingTourPointer, IGenUserOnboardingTourPost } from "../../../../interfaces/generated/i18n-types";
import { IOnboardingTourCardBase, OnboardingTourCard } from "./OnboardingTourCard";
import OnboardingTourCardPointerClickIndicator from "./OnboardingTourCardPointerClickIndicator";
import { OnboardingTourCardPointerWithClick } from "./OnboardingTourCardPointerWithClick";
import { OnboardingTourCardPointerWithoutClick } from "./OnboardingTourCardPointerWithoutClick";
import { SOnboardingTourCardPointerClickIndicator } from "./styles/SOnboardingTourCardPointerClickIndicator";
import { SOnboardingTourCardPointerHighlight } from "./styles/SOnboardingTourCardPointerHighlight";

export const getElementByXpath = (path) => {
  return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
};

export interface IOnboardingTourCardPointer extends IOnboardingTourCardBase {
  step: IGenUserOnboardingTourPointer;
  elementPosition?: DOMRect;
  isFirstPointer: boolean;
  nextStep?: IGenUserOnboardingTourPointer | IGenUserOnboardingTourPost;
}

const FALLBACK_ANIMATION_SPEED = 300;
const ANIMATION_SPEED_MULTIPLIER = 0.75;

const getClickIndicatorInitialPos = ({
  highlightCenter,
  screenCenter,
}: {
  highlightCenter: { x: number; y: number };
  screenCenter: { x: number; y: number };
}) => {
  if (highlightCenter.y < screenCenter.y && highlightCenter.x < screenCenter.x) {
    return "topLeft";
  }

  if (highlightCenter.y < screenCenter.y && highlightCenter.x > screenCenter.x) {
    return "topRight";
  }

  if (highlightCenter.y > screenCenter.y && highlightCenter.x < screenCenter.x) {
    return "bottomRight";
  }

  return "bottomLeft";
};

export const OnboardingTourCardPointer: FC<IOnboardingTourCardPointer> = (props) => {
  const ref = useRef();
  const [cardRef, setCardRef] = useState<HTMLDivElement>(null);
  const nextRef = useRef();
  const [nextCardRef, setNextCardRef] = useState<HTMLDivElement>(null);
  const [distance, setDistance] = useState(null);
  const [element, setElement] = useState(getElementByXpath(props.step.htmlSelector));
  const timeoutRef: any = useRef();

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function updateDimension() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  const nextXpath = (props?.nextStep as IGenUserOnboardingTourPointer)?.htmlSelector;
  const nextElement = nextXpath ? getElementByXpath(nextXpath) : null;
  const xpath = props.step.htmlSelector;
  const isNextStepAPointer = props.nextStep && props.nextStep.__typename === "UserOnboardingTourPointer";

  const elPosition = useMemo(() => {
    if (element) return (element as Element).getBoundingClientRect();

    return null;
  }, [element, dimensions]);

  const nextElPosition = useMemo(() => {
    if (nextElement) return (nextElement as Element).getBoundingClientRect();

    return null;
  }, [nextElement, dimensions]);

  const calculateDistance = () => {
    const cardPos = cardRef?.getBoundingClientRect();
    const nextCardPos = nextCardRef?.getBoundingClientRect();
    const a = nextCardPos?.x - cardPos?.x;
    const b = nextCardPos?.y - cardPos?.y;
    return Math.sqrt(a * a + b * b);
  };

  useEffect(() => {
    let timeout: any = undefined;
    const element = getElementByXpath(xpath);

    if (element) {
      setElement(element);
      return;
    }

    timeout = setTimeout(() => {
      const element = getElementByXpath(xpath);
      if (element) {
        setElement(element);
        return;
      }

      // skip if element is not found
      console.warn(` skip tour step since element is not found:`, xpath);
      props.onClickNext && props.onClickNext();
    }, 333);

    return () => timeout && clearTimeout(timeout);
  }, [xpath]);

  const handleClickElement = useCallback(() => {
    const timeout = setTimeout(() => {
      handleClickNext();
    }, 333);

    return () => timeout && clearTimeout(timeout);
  }, [props.onClickNext]);

  const handleClickNext = async () => {
    await props.onClickNext();

    if (!isNextStepAPointer) return;
    timeoutRef.current = setTimeout(() => {
      if (!element || !nextElement) return setDistance(FALLBACK_ANIMATION_SPEED);
      setDistance(calculateDistance());
    }, 333);
  };

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isNextStepAPointer) return;
    const timeout = setTimeout(() => {
      if (!element || !nextElement) return setDistance(FALLBACK_ANIMATION_SPEED);
      setDistance(calculateDistance());
    }, 333);

    return () => clearTimeout(timeout);
  }, [element, nextElement, cardRef, nextCardRef]);

  useEffect(() => {
    if (!element) {
      handleClickNext();
      return;
    }

    (element as Element).addEventListener("click", handleClickElement);

    return () => {
      element && element.removeEventListener("click", handleClickElement);
    };
  }, [element]);

  if (!elPosition) {
    return null;
  }

  const { height, width, top, left } = elPosition;

  const yCenter = top + height / 2;
  const xCenter = left + width / 2;
  const yScreenCenter = window.innerHeight / 2;
  const xScreenCenter = window.innerWidth / 2;

  console.log(
    getClickIndicatorInitialPos({
      highlightCenter: { x: xCenter, y: yCenter },
      screenCenter: { x: xScreenCenter, y: yScreenCenter },
    }),
  );

  return (
    <div>
      {props.step.clickToContinue ? (
        <OnboardingTourCardPointerWithClick {...props} elementPosition={elPosition} />
      ) : (
        <OnboardingTourCardPointerWithoutClick />
      )}
      {isNextStepAPointer ? (
        <>
          <SOnboardingTourCardPointerHighlight
            ref={nextRef}
            style={{
              height: nextElPosition?.height,
              width: nextElPosition?.width,
              top: nextElPosition?.top,
              left: nextElPosition?.left,
              pointerEvents: "none",
              visibility: "hidden",
            }}
          />
          <Popover
            reference={nextRef}
            display
            placement={((props.nextStep as IGenUserOnboardingTourPointer).displayPreference as TPlacement) || "bottom"}
          >
            <div
              ref={setNextCardRef}
              style={{
                height: cardRef?.offsetHeight,
                width: cardRef?.offsetWidth,
                visibility: "hidden",
              }}
            />
          </Popover>
        </>
      ) : null}
      <Popover
        zIndex={Z_INDEX.ONBOARDING_TOUR_POPOVER}
        reference={ref}
        display
        placement={(props.step.displayPreference as TPlacement) || "bottom"}
        styleOverwrite={
          !props.isFirstPointer && {
            transition: `all ${distance ? distance * ANIMATION_SPEED_MULTIPLIER : FALLBACK_ANIMATION_SPEED}ms`,
          }
        }
      >
        <div ref={setCardRef}>
          <OnboardingTourCard {...props} onClickNext={handleClickNext} />
        </div>
      </Popover>

      <SOnboardingTourCardPointerHighlight
        ref={ref}
        disableBoxShadow={props.step.clickToContinue}
        style={{
          height,
          width,
          top,
          left,
          pointerEvents: props.step.clickToContinue && "none",
        }}
      >
        {props.step.clickToContinue ? (
          <SOnboardingTourCardPointerClickIndicator
            from={getClickIndicatorInitialPos({
              highlightCenter: { x: xCenter, y: yCenter },
              screenCenter: { x: xScreenCenter, y: yScreenCenter },
            })}
          >
            <OnboardingTourCardPointerClickIndicator />
          </SOnboardingTourCardPointerClickIndicator>
        ) : null}
      </SOnboardingTourCardPointerHighlight>
    </div>
  );
};
