import { IconChevronRight, Preview } from "@caisy/league";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { useCurrentGroupId } from "../../../hooks/current-group-id/useCurrentGroupId";
import { useCurrentProjectId } from "../../../hooks/current-project-id/useCurrentProjectId";
import { FCWithChildren } from "../../../interfaces/FCWithChildren";
import { I18n } from "../../../provider/i18n";
import { useMemberShip } from "../../../stores/membership/useMembership";
import { paramsToUrlString } from "../../../utils/getHrefWithParams";
import pageSpecificComponent from "./pageSpecificComponent";
import { SSettingsTopNav } from "./styles/SSettingsTopNav";
import { SSettingsTopNavBody } from "./styles/SSettingsTopNavBody";
import { SSettingsTopNavBreadcrumb } from "./styles/SSettingsTopNavBreadcrumb";
import { SSettingsTopNavChevronRight } from "./styles/SSettingsTopNavChevronRight";
import { SSettingsTopNavName } from "./styles/SSettingsTopNavName";
import { SSettingsTopNavTitle } from "./styles/SSettingsTopNavTitle";
import { SSettingsTopNavType } from "./styles/SSettingsTopNavType";

const BREADCRUMBS_I18N = {
  organization: { selector: "projectManagementNavigation.topnav_organization", fallback: "Organization" },
  group: { selector: "projectManagementNavigation.topnav_group", fallback: "Group" },
  project: { selector: "projectManagementNavigation.topnav_project", fallback: "Project" },
};

const getPlaceholderLettersByName = (name: string) => {
  const singleWords = name.split(" ");
  if (singleWords.length == 1) {
    return singleWords[0].charAt(0).toUpperCase();
  }
  return singleWords[0].charAt(0).toUpperCase() + singleWords[1].charAt(0).toUpperCase();
};

interface ISettingsTopNav {
  styleOverwrite?: React.CSSProperties;
}

const LinksBreadcrumbs = [
  (id) => `/organization/settings/groups${paramsToUrlString({ organizationId: id })}`,
  (id) => `/group/settings/members${paramsToUrlString({ groupId: id })}`,
  (id) => `/project/settings/members${paramsToUrlString({ projectId: id })}`,
];
const SettingsTopNav: FCWithChildren<ISettingsTopNav> = ({ children, styleOverwrite }) => {
  const projectId = useCurrentProjectId();
  const groupId = useCurrentGroupId();
  const { projectWithInheritance, groupWithInheritance, getGroupByIdWithInheritance, groups } = useMemberShip();
  const router = useRouter();

  useEffect(() => {
    if (groupId) {
      getGroupByIdWithInheritance({ groupId });
    }
  }, [groupId]);

  const currentProject = useMemo(() => {
    if (!projectId || projectWithInheritance?.project?.projectId != projectId) return;
    return projectWithInheritance;
  }, [projectWithInheritance, projectId]);

  const currentGroup = useMemo(() => {
    if (!groupId) return;
    return groupWithInheritance;
  }, [groupWithInheritance, groupId, groups]);

  const breadcrumbs = useMemo(() => {
    const newBreadcrumbs = [];
    const breadcrumbsItems = {
      organization: 0,
      group: 1,
      project: 2,
    };

    if (currentGroup) {
      for (const key in currentGroup) {
        if (key !== "__typename" && currentGroup[key].name) {
          newBreadcrumbs[breadcrumbsItems[key]] = {
            name: currentGroup[key].name,
            id: currentGroup[key][`${key}Id`],
            logo: currentGroup[key].logoAssetUrl,
            type: key,
          };
        }
      }
    }
    if (currentProject) {
      for (const key in currentProject) {
        if (key !== "__typename" && currentProject[key]?.name) {
          newBreadcrumbs[breadcrumbsItems[key]] = {
            name: currentProject[key]?.name,
            id: currentProject[key]?.[`${key}Id`],
            logo: currentProject[key]?.logoAssetUrl,
            type: key,
          };
        }
      }
    }

    return newBreadcrumbs;
  }, [currentGroup, currentProject, groups]);

  const currentPage = useMemo(() => {
    if (router.asPath.includes("previews/detail")) return "previewDetails";
    if (
      router.asPath.includes("webhooks/") &&
      (router.asPath.includes("edit") || router.asPath.includes("log")) &&
      !router.query.webhookCallId
    )
      return "webhookEdit";
    if (router.asPath.includes("log") && router.query.webhookCallId) return "webhookCall";
    if (router.asPath.includes("webhooks/create")) return "webhookCreate";
    // Here we can add the conditions for other pages
    // We'd also need to add a component on the `pageSpecificComponent` object
    // under the property name that we assign on the `if` statement
  }, [router.asPath, router.query]);

  const PageSpecificComponent = () => pageSpecificComponent[currentPage] || <></>;

  return (
    <SSettingsTopNav style={styleOverwrite}>
      <div>
        {breadcrumbs.map((breadcrumb, index) => (
          <SSettingsTopNavBreadcrumb
            key={breadcrumb.id}
            onClick={() =>
              LinksBreadcrumbs[index] &&
              router.push(LinksBreadcrumbs[index](breadcrumb.id), undefined, { shallow: true })
            }
            style={{ cursor: "pointer" }}
          >
            {index > 0 && (
              <SSettingsTopNavChevronRight>
                <IconChevronRight size={20} />
              </SSettingsTopNavChevronRight>
            )}
            <div>
              <SSettingsTopNavTitle>
                <Preview
                  size={28}
                  image={
                    breadcrumb.logo && (
                      <img src={breadcrumb.logo} style={{ height: 28, width: 28, objectFit: "cover" }} />
                    )
                  }
                  text={!breadcrumb.logo && getPlaceholderLettersByName(breadcrumb.name)}
                />
                <SSettingsTopNavBody>
                  <SSettingsTopNavType>
                    <I18n {...BREADCRUMBS_I18N[breadcrumb.type]} />
                  </SSettingsTopNavType>
                  <SSettingsTopNavName>{breadcrumb.name}</SSettingsTopNavName>
                </SSettingsTopNavBody>
              </SSettingsTopNavTitle>
            </div>
          </SSettingsTopNavBreadcrumb>
        ))}
        <PageSpecificComponent />
      </div>
      {!!children && <div>{children}</div>}
    </SSettingsTopNav>
  );
};

export default SettingsTopNav;
