import { CSSProgressiveBody03Semibold } from "@caisy/league";
import styled from "styled-components";

export const SOrganizationMenuSubTitleTooltip = styled.div`
  ${CSSProgressiveBody03Semibold};
  background-color: var(--ui-overlay-02);
  color: var(--text-02);
  padding: 16px;
  border-radius: 4px;
  box-shadow: var(--box-shadow-sticked-top-ui);
`;
