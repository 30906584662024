import gql from "graphql-tag";

export const createTag = gql`
  mutation CreateTag($input: CreateTagRequest) {
    __typename
    CreateTag(input: $input) {
      tag {
        color
        name
        projectId
        referenceType
        tagId
      }
    }
  }
`;
