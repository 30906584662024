import gql from "graphql-tag";

export const getProjectOnboarding = gql`
  query GetProjectOnboarding($input: GetProjectOnboardingRequestInput) {
    GetProjectOnboarding(input: $input) {
      onboarding {
        status
        completedEvents
      }
    }
  }
`;
