import { IconCheckmarkSolid, IconCircle } from "@caisy/league";
import { FCWithChildren } from "../../../../interfaces/FCWithChildren";
import { SAddProjectModalSectionItem } from "./styles/SAddProjectModalSectionItem";
import { SAddProjectModalSectionItemCheckmarkWrapper } from "./styles/SAddProjectModalSectionItemCheckmarkWrapper";

interface IAddProjectModalSectionItem {
  active: boolean;
  onChange: (newValue: boolean) => void;
  isSubSection?: boolean;
  disabled?: boolean;
}

export const AddProjectModalSectionItem: FCWithChildren<IAddProjectModalSectionItem> = ({
  children,
  active,
  onChange,
  isSubSection,
  disabled,
}) => {
  return (
    <SAddProjectModalSectionItem
      disabled={disabled}
      isSubSection={isSubSection}
      onClick={() => {
        if (disabled) return;
        onChange(!active);
      }}
    >
      {active ? (
        <SAddProjectModalSectionItemCheckmarkWrapper>
          <IconCheckmarkSolid size={20} />
        </SAddProjectModalSectionItemCheckmarkWrapper>
      ) : (
        <IconCircle size={20} />
      )}
      {children}
    </SAddProjectModalSectionItem>
  );
};
