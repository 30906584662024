import { FC } from "react";
import { IBillingPricingCard } from "../../../../../../../services/billing-plans/getBillingPlans";
import { SPricingCard } from "./styles/SPricingCard";
import { SPricingCardTitle } from "./styles/SPricingCardTitle";
import { Button, Flex, SolidLabel } from "@caisy/league";
import { SPricingCardFeatures } from "./styles/SPricingCardFeatures";
import { PricingCardFeature } from "./PricingCardFeature";
import { I18n } from "../../../../../../../provider/i18n";
import { SPricingCardPrice } from "./styles/SPricingCardPrice";
import { SPricingCardEmptyLabel } from "./styles/SPricingCardEmptyLabel";

interface IPricingCard {
  pricingCard: IBillingPricingCard;
  yearly: boolean;
  isFree: boolean;
  checkmarkType: 1 | 2 | 3;
  onChangePlan: (planName: string) => Promise<void>;
  active: boolean;
  disableButton: boolean;
  selected: boolean;
  onSelectCard: (planName: string) => void;
}

export const PricingCard: FC<IPricingCard> = ({
  pricingCard,
  yearly,
  isFree,
  checkmarkType,
  // onChangePlan,
  active,
  selected,
  onSelectCard,
}) => {
  return (
    <SPricingCard>
      <Flex alignItems="flex-start" direction="column" gap="16px">
        <Flex alignItems="flex-start" direction="column" gap="8px">
          <Flex alignItems="flex-start" direction="column" gap="4px">
            <SPricingCardTitle>{pricingCard.headline}</SPricingCardTitle>

            <SPricingCardPrice>
              {yearly ? pricingCard.pricingYear : pricingCard.pricingMonth} /{" "}
              <I18n fallback="month" selector="organizationManagementBilling.month" />
            </SPricingCardPrice>
          </Flex>

          {yearly || isFree ? (
            <SolidLabel style={{ boxSizing: "border-box" }} type={isFree ? "greyOutline" : "green"}>
              {!isFree ? (
                pricingCard.discountBatch
              ) : (
                <I18n selector="organizationManagementBilling.isAlreadyFree" fallback="It's already Free :)" />
              )}
            </SolidLabel>
          ) : (
            <SPricingCardEmptyLabel isFree={isFree} />
          )}
        </Flex>
        <Button
          // onClick={() => onChangePlan(pricingCard.planName)}
          onClick={() => onSelectCard(pricingCard.planName)}
          type={selected || active ? "primary" : "tertiary"}
          style={{ width: "100%" }}
          disabled={active}
          size="small"
        >
          {active ? (
            <I18n selector="organizationManagementBilling.alreadyScheduled" fallback="already scheduled" />
          ) : selected ? (
            <I18n selector="organizationMasnagementBilling.selected" fallback="selected" />
          ) : (
            <I18n selector="organizationMasnagementBilling.selectPlan" fallback="select plan" />
          )}
        </Button>
      </Flex>
      <SPricingCardFeatures>
        {pricingCard.options.map((option) => (
          <PricingCardFeature key={option.option} checkmarkType={checkmarkType}>
            {option.option}
          </PricingCardFeature>
        ))}
      </SPricingCardFeatures>
    </SPricingCard>
  );
};
