import gql from "graphql-tag";

export const deletePreviewItem = gql`
  mutation DeletePreviewItem($input: DeletePreviewItemRequestInput) {
    __typename
    DeletePreviewItem(input: $input) {
      deleted
    }
  }
`;
