import styled from "styled-components";

export const SSelectProjectStepListsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--ui-01);
  border-radius: 8px;
  border: 1px solid var(--ui-03);
  width: 784px;
  overflow: hidden;
`;
