const NoScript = () => (
  <div className="noscript" style={{ display: "none" }}>
    <h3 className="noscript">For full functionality of this site it is necessary to enable JavaScript.</h3>
    <h3 className="noscript">
      Here are the&nbsp;
      <a className="noscript" href="https://www.enable-javascript.com/">
        instructions how to enable JavaScript in your web browser
      </a>
      .
    </h3>
  </div>
);

export default NoScript;
