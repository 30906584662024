import produce from "immer";
import { getUserProfile } from "../../graphql/queries/getUserProfile.gql";
import { client } from "../../utils/client";
import { IUseProfileState } from "./types";
import { IUserProfile } from "../../interfaces/generated";
import { ApolloQueryResult } from "@apollo/client";

export const createProfileSlice = (
  set: (cb: (state: IUseProfileState) => IUseProfileState, replace: boolean, name: string) => void,
  get: () => IUseProfileState,
) => ({
  profile: {
    profiles: {} as { [userId: string]: IUserProfile },
    loading: false,
    loadUserProfilesByIds: async ({ userIds }: { userIds: string[] }) => {
      set(
        produce((state) => {
          state.profile.loading = true;
        }),
        false,
        "profile/loadUserProfilesByIds/start",
      );
      const usersProfile = {};
      const results = await Promise.allSettled(
        userIds.map((userId) =>
          client.query({
            query: getUserProfile,
            variables: {
              userId,
            },
            fetchPolicy: "no-cache",
          }),
        ),
      );
      results.forEach((res) => {
        if (res.status === "fulfilled") {
          usersProfile[res.value.data.userProfile.id] = res.value.data.userProfile;
        }
      });
      set(
        produce((state) => {
          state.profile.loading = false;
          state.profile.profiles =
            results
              ?.filter((res) => res.status === "fulfilled" && res.value.data.userProfile.id)
              ?.reduce(
                (prev, res: PromiseFulfilledResult<ApolloQueryResult<any>>) => ({
                  ...{ [res.value.data.userProfile.id]: res.value.data.userProfile, ...prev },
                }),
                state.profile.profiles,
              ) || {};
        }),
        false,
        "profile/loadUserProfilesByIds/done",
      );

      if (typeof window === "undefined") return;
      if (!window.c) {
        window.c = {};
      }
      window.c.profiles = get().profile.profiles;
    },
  },
});
