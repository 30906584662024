import { ChangeEvent, FC, Fragment, useRef, useState } from "react";
import { useUserOnboarding } from "../../../../../stores/user-onboarding/useUserOnboarding";
import { useMemberShip } from "../../../../../stores/membership/useMembership";
import { Button, FormFieldWrapper, SimpleInput, useForm } from "@caisy/league";
import { SOnboardingForm } from "../../../../page/onboarding/components/OnboardingForm/styles/SOnboardingForm";
import { SOnboardinbFormInputsAndTemplate } from "../../../../page/onboarding/components/OnboardingForm/styles/SOnboardinbFormInputsAndTemplate";
import { SOnboardingFormInputs } from "../../../../page/onboarding/components/OnboardingForm/styles/SOnboardingFormInputs";
import { OnboardingAssetField } from "../../../../page/onboarding/components/OnboardingForm/OnboardingAssetField/OnboardingAssetField";
import { SOnboardingFormTemplateWrapper } from "../../../../page/onboarding/components/OnboardingForm/styles/SOnboardingFormTemplateWrapper";
import { CreateProjectCard } from "../create-project-card/CreateProjectCard";
import { IGenProjectTemplate } from "../../../../../interfaces/generated/i18n-types";
import { OnboardingTemplatesTemplate } from "../../../../page/onboarding/components/OnboardingTemplates/OnboardingTemplatesTemplate";
import { useI18nString } from "../../../../../provider/i18n/useI18nString";
import { I18n } from "../../../../../provider/i18n";

interface ICreateProjectForm {
  onCancel: () => void;
  fromExistingProject?: boolean;
  selectedOrganization?: string;
  selectedGroup?: string;
  selectedProject?: string;
  onCreateProject: (data: { name: string; logo?: string }) => Promise<void>;
  selectedTemplate?: IGenProjectTemplate;
  onChangeTemplate?: () => void;
}

export const CreateProjectForm: FC<ICreateProjectForm> = ({
  onCancel,
  fromExistingProject,
  selectedOrganization,
  selectedGroup,
  selectedProject,
  onCreateProject,
  selectedTemplate,
  onChangeTemplate,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const { organizations, groups, projects } = useMemberShip();
  const { setLogoBlob } = useUserOnboarding();
  const { control, handleSubmit, setFieldValue, getFormState, validate, getFieldErrors } = useForm();
  const [submitting, setSubmitting] = useState(false);

  const onUploadButtonClick = (e) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef?.current?.click();
    }
  };
  const onFileChange = (event: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const reader = new FileReader();
    reader.onload = function () {
      setFieldValue(fieldName, reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    setLogoBlob(event.target.files[0]);
  };

  const onSubmit = async (data) => {
    if (await validate()) {
      setSubmitting(true);

      await onCreateProject(data);

      setSubmitting(false);
    }
  };

  const placeholderI18n = useI18nString({
    fallback: "Acme company",
    selector: "nav.createProject.projectNamePlaceholder",
  });

  const nameLabelI18n = useI18nString({
    fallback: "Project name",
    selector: "nav.createProject.projectName",
  });

  const logoLabelI18n = useI18nString({
    fallback: "Project Logo",
    selector: "nav.createProject.projectLogo",
  });

  const logoDescriptionI18n = useI18nString({
    fallback: "Supported formats: .jpg, .png, .gif",
    selector: "nav.createProject.projectLogoDescription",
  });

  return (
    <SOnboardingForm style={{ margin: 0 }} onSubmit={handleSubmit(onSubmit)}>
      <SOnboardinbFormInputsAndTemplate>
        <SOnboardingFormInputs>
          <Fragment>
            <FormFieldWrapper
              rules={[
                {
                  name: "required",
                  message: "REQUIRED FIELD",
                  condition: (value) => !!(value && `${value}`.trim() !== ""),
                },
              ]}
              name="name"
              control={control}
            >
              <SimpleInput
                state={getFieldErrors("name")?.length ? "error" : "default"}
                placeholder={placeholderI18n}
                label={nameLabelI18n}
                required
              />
            </FormFieldWrapper>
            <>
              <FormFieldWrapper name="logo" control={control}></FormFieldWrapper>
              <OnboardingAssetField
                label={logoLabelI18n}
                description={logoDescriptionI18n}
                src={getFormState()["logo"]?.value}
                onUploadClick={onUploadButtonClick}
                onCancel={() => {
                  setFieldValue("logo", null);
                  inputRef.current.value = null;
                }}
              />

              <input
                hidden
                ref={inputRef}
                type="file"
                name="file"
                onChange={(event) => {
                  onFileChange(event, "logo");
                }}
              />
            </>
          </Fragment>
        </SOnboardingFormInputs>
        <SOnboardingFormTemplateWrapper>
          {selectedTemplate ? (
            <OnboardingTemplatesTemplate onSelect={onChangeTemplate} isChangeTemplate template={selectedTemplate} />
          ) : (
            <CreateProjectCard
              fromExistingProject={fromExistingProject}
              sourceProject={
                fromExistingProject
                  ? {
                      projectName: projects[selectedProject].name,
                      groupName: selectedGroup !== "ungrouped" ? groups[selectedGroup].name : null,
                      organizationName: organizations[selectedOrganization].name,
                    }
                  : undefined
              }
              onCancel={onCancel}
            />
          )}
        </SOnboardingFormTemplateWrapper>
      </SOnboardinbFormInputsAndTemplate>

      <Button disabled={submitting || !getFormState()?.name?.value?.length} type="primary">
        {submitting ? (
          <img style={{ width: 24, height: 24 }} src={`/app/static-assets/spinner.gif`} />
        ) : (
          <I18n fallback="CREATE PROJECT" selector="nav.createProject.createProject" />
        )}
      </Button>
    </SOnboardingForm>
  );
};
