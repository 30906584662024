import gql from "graphql-tag";

export const deleteLocale = gql`
    mutation DeleteDocumentFieldLocale($input: DeleteDocumentFieldLocaleRequestInput) {  
        DeleteDocumentFieldLocale(input: $input) {    
            ...DeleteDocumentFieldLocaleResponse  
        }
    }
    fragment DeleteDocumentFieldLocaleResponse on DeleteDocumentFieldLocaleResponse {  
        deleted
    }

`;
