import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { IGenMaintenanceWarning } from "../../../interfaces/generated/i18n-types";
import { useI18nDayjs } from "../../../provider/i18n/I18nDayjsProvider";
import { templateFormat } from "../../../utils/stringTemplate";
import { markdownTemplate } from "../../../utils/markdownTemplate";

type IWarningType = "upcoming" | "active";
const localStorageKey = "caisy_app_maintenance_warning_acknowledged";

export const useMaintenanceWarning = () => {
  const router = useRouter();
  const [maintenanceWarning, setMaintenanceWarning] = useState<IGenMaintenanceWarning>(null);
  const [visible, setVisible] = useState(false);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(true);
  const [warningType, setWarningType] = useState<IWarningType>(null);
  const dayjs = useI18nDayjs();

  useEffect(() => {
    setLoading(true);
    fetch(`/app/api/v1/maintenance-warning/${router.locale || "en"}`).then((response) => {
      if (response.status === 200) {
        response
          .json()
          .then((data: IGenMaintenanceWarning) => {
            // if the warning is not enabled, do nothing
            if (!data.enabled) {
              setVisible(false);
              return;
            }

            // if the warning is already over, do nothing
            if (data?.end && new Date(data?.end) < new Date()) {
              setVisible(false);
              return;
            }

            // if the warning is already acknowledged and hasn't changed, do nothing
            if (localStorage.getItem(`${localStorageKey}`)) {
              const start = localStorage.getItem(`${localStorageKey}_start`);
              const end = localStorage.getItem(`${localStorageKey}_end`);
              if (start === new Date(data?.start).toISOString() && end === new Date(data?.end).toISOString()) {
                setVisible(false);
                return;
              }
            }

            setMaintenanceWarning(data);
            const now = new Date();
            const startDate = new Date(data.start);
            const endDate = new Date(data.end);
            setDuration((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60));
            if (now > startDate && now < endDate) {
              setWarningType("active");
            } else if (now < startDate) {
              setWarningType("upcoming");
            }
            const hsBeforeShowing = data.hoursBeforeMaintenanceToShowWarning;

            if (hsBeforeShowing) {
              const startDate = new Date(data.start);
              startDate.setHours(startDate.getHours() - hsBeforeShowing);
              if (now > startDate && now < endDate) {
                setVisible(true);
              }
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  }, [router.locale]);

  const ongoingDescription = useMemo(() => {
    if (!maintenanceWarning) return null;
    const remainingTime = new Date(maintenanceWarning?.end).getTime() - new Date().getTime();
    const translationString = templateFormat(maintenanceWarning?.activeMaintenanceDescription, {
      startDate: dayjs(maintenanceWarning?.start).format("DD MMMM YYYY"),
      startTime: dayjs(maintenanceWarning?.start).format("HH:mm"),
      remainingTime: Math.floor(remainingTime / (1000 * 60 * 60)),
    });
    return markdownTemplate(translationString);
  }, [maintenanceWarning?.activeMaintenanceDescription, maintenanceWarning?.start, duration]);

  const upcomingDescription = useMemo(() => {
    if (!maintenanceWarning) return null;
    const translationString = templateFormat(maintenanceWarning?.preMaintenanceDescription, {
      startDate: dayjs(maintenanceWarning?.start).format("DD MMMM YYYY"),
      startTime: dayjs(maintenanceWarning?.start).format("HH:mm"),
      duration,
    });
    return markdownTemplate(translationString);
  }, [maintenanceWarning?.preMaintenanceDescription, maintenanceWarning?.start, duration]);

  const ongoingTitle = useMemo(() => {
    if (!maintenanceWarning) return null;
    return maintenanceWarning?.activeMaintenanceHeadline;
  }, [maintenanceWarning?.activeMaintenanceHeadline]);

  const upcomingTitle = useMemo(() => {
    if (!maintenanceWarning) return null;
    return maintenanceWarning?.preMaintenanceHeadline;
  }, [maintenanceWarning?.preMaintenanceHeadline]);

  const title = useMemo(() => {
    if (!maintenanceWarning) return null;
    return warningType === "active" ? ongoingTitle : upcomingTitle;
  }, [warningType, ongoingTitle, upcomingTitle]);

  const description = useMemo(() => {
    if (!maintenanceWarning) return null;
    return warningType === "active" ? ongoingDescription : upcomingDescription;
  }, [warningType, ongoingDescription, upcomingDescription]);

  const acknowledgeWarning = () => {
    localStorage.setItem(`${localStorageKey}`, "true");
    localStorage.setItem(`${localStorageKey}_start`, new Date(maintenanceWarning?.start).toISOString());
    localStorage.setItem(`${localStorageKey}_end`, new Date(maintenanceWarning?.end).toISOString());
    setVisible(false);
  };

  return {
    title,
    description,
    visible,
    loading,
    acknowledgeWarning,
    maintenanceWarning,
  };
};
