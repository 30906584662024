import { CSSProgressiveBody03Semibold } from "@caisy/league";
import styled from "styled-components";

export const SAddProjectModalSectionsInfoDescription = styled.div`
  ${CSSProgressiveBody03Semibold};
  color: var(--icon-03);
  display: flex;
  align-items: center;
  gap: 8px;
`;
