import gql from "graphql-tag";
import { fragment_Document } from "../fragments/document.gql";

export const getUnpublishedLinkedDocuments = gql`
  ${fragment_Document}
  query GetUnpublishedLinkedDocuments($input: GetUnpublishedLinkedDocumentsRequestInput) {
    GetUnpublishedLinkedDocuments(input: $input) {
      ...GetUnpublishedLinkedDocumentsResponse
    }
  }
  fragment GetUnpublishedLinkedDocumentsResponse on GetUnpublishedLinkedDocumentsResponse {
    document {
      ...Document
    }
  }
`;
