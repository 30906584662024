import styled, { css } from "styled-components";
import { Z_INDEX } from "../../../../../constants/z-index-numbers";

const CSSWithBoxShadow = css`
  box-shadow: 0 0 0 100000px rgba(0, 0, 0, 0.3);
  z-index: ${Z_INDEX.ONBOARDING_TOUR_BACKGROUND};
`;

export const SOnboardingTourCardPointerHighlight = styled.div`
  position: absolute;
  background-color: transparent;
  z-index: ${Z_INDEX.ONBOARDING_TOUR_HIGHLIGHT};
  border-radius: 4px;
  ${({ disableBoxShadow }) => !disableBoxShadow && CSSWithBoxShadow}
`;
