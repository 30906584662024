import { CSSProgressiveBody02Semibold, CSSProgressiveBody03Semibold } from "@caisy/league";
import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/styles/mediaquerys";

const CSSLarge = css`
  ${CSSProgressiveBody02Semibold}
`;

const CSSSearch = css`
  color: var(--text-04);
`;
const Default = css`
  ${CSSProgressiveBody03Semibold};
  color: var(--text-01);
  width: ${({ emptyType }) => (emptyType === "groups" ? "204px" : "100%")};
  text-align: center;
  ${({ size }) => size === "large" && CSSLarge};
  ${({ emptyType }) => emptyType === "text" && CSSSearch};
  ${({ emptyType }) => emptyType === "search" && "margin-top: 24px"};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuEmptyWrapperTitle = styled.p`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
