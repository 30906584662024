import gql from "graphql-tag";

export const createLocale = gql`
    mutation CreateDocumentFieldLocale($input: CreateDocumentFieldLocaleRequestInput) {  
        CreateDocumentFieldLocale(input: $input) {    
            ...CreateDocumentFieldLocaleResponse  
        }
    }
    fragment CreateDocumentFieldLocaleResponse on CreateDocumentFieldLocaleResponse {  
        documentFieldLocale {    
            ...DocumentFieldLocaleResponse  
        }  
        projectId
    }
    fragment DocumentFieldLocaleResponse on DocumentFieldLocaleResponse {  
        allowEmptyRequired  
        apiName  
        default  
        disableEditing  
        disableInResponse  
        fallbackLocaleId  
        flag  
        id  
        title
    }

`;
