import styled, { css } from "styled-components";
import { Z_INDEX } from "../../../../../constants/z-index-numbers";

const CSSBaseStyling = css`
  position: absolute;
  z-index: ${Z_INDEX.ONBOARDING_TOUR_BACKGROUND};
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: all;
`;

export const SOnboardingTourCardPointerBackground = styled.div`
  position: absolute;
  inset: 0;
  z-index: ${Z_INDEX.ONBOARDING_TOUR_BACKGROUND};
`;

export const SOnboardingTourCardPointerBackgroundTop = styled.div`
  top: 0;
  width: 100%;
  bottom: ${({ bottom }) => `${bottom}`};
  height: ${({ height }) => `${height}`};
  ${CSSBaseStyling};
`;

export const SOnboardingTourCardPointerBackgroundRight = styled.div`
  right: 0;
  height: ${({ height }) => `${height}`};
  width: ${({ width }) => `${width}`};
  top: ${({ top }) => `${top}`};
  ${CSSBaseStyling};
`;

export const SOnboardingTourCardPointerBackgroundBottom = styled.div`
  bottom: 0;
  width: 100%;
  height: ${({ height }) => `${height}`};
  ${CSSBaseStyling};
`;

export const SOnboardingTourCardPointerBackgroundLeft = styled.div`
  left: 0;
  height: ${({ height }) => `${height}`};
  width: ${({ width }) => `${width}`};
  top: ${({ top }) => `${top}`};
  ${CSSBaseStyling};
`;
