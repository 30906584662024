import { useStore } from "../index";
import { IUseGroupMembership } from "./createGroupMembershipSlice";
import { IUseOrganizationMembership } from "./createOrganizationMembershipSlice";
import { IUseProjectMembership } from "./createProjectMembershipSlice";

type MembershipState = IUseProjectMembership & IUseGroupMembership & IUseOrganizationMembership;

type IUseMembership = () => MembershipState;

export const useMemberShip: IUseMembership = () =>
  useStore(({ organization, group, project }) => ({
    ...organization,
    ...group,
    ...project,
  }));
