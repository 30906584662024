import { gql } from "graphql-tag";

export const getManyProjectQuotaOverviewByID = gql`
  query GetManyProjectQuotaOverviewByID($input: GetManyProjectQuotaOverviewByIDRequestInput!) {
    GetManyProjectQuotaOverviewByID(input: $input) {
      quotaOverviews {
        amounts {
          operation
          value
        }
        projectId
        quotaPlan {
          maxApiCallsExternal
          maxApiCallsInteral
          maxAssetBlueprints
          maxAssetDownloadTraffic
          maxAssetStorage
          maxAssetUploadTraffic
          maxComponentBlueprints
          maxDaysAuditlog
          maxDocumentBlueprints
          maxDocuments
          maxFieldsPerBlueprint
          maxLocales
          maxMembers
          maxPreviews
          maxReleases
          maxStoredDocumentVersions
          maxViews
          maxWebhooks
          name
        }
      }
    }
  }
`;
