import React, { FC, createContext, ReactChild, useContext, useCallback, useEffect } from "react";
import { checkFirebaseLogin } from "../firebase";
import { useRouter } from "next/router";
import { useAuthentication } from "../../stores/authentication/useAuthentication";
import { IPermissionSet } from "../../interfaces/generated";
import { usePermission } from "../../stores/permission/usePermission";
import { useMemberShip } from "../../stores/membership/useMembership";

export const SecurityContext = createContext<{
  isLoading: boolean;
  user: any;
  can: (action: string, entity: string) => any;
  ifUserCan: (action: string, entity: string) => any;
  logout: () => Promise<any>;
}>(null);

let timeoutRef = undefined;
// Private Security Context Observer, to update profile context when changed.
const SecurityObserver: FC<{
  children: JSX.Element | JSX.Element[] | ReactChild;
}> = ({ children }) => {
  const { isLoading: isLoadingUser, user, isLoggedIn, checkLogin, logout: authLogout } = useAuthentication();
  const { permissions, isLoading: isLoadingPermission } = usePermission();
  const isLoading = isLoadingPermission || isLoadingUser;
  const router = useRouter();
  // Just for debuggin
  // if (permissions) {
  //   console.table(permissions);
  // }

  const {
    initializedProjects,
    loadProjects,
    initializedOrganizations,
    loadOrganizations,
    initializedGroups,
    loadGroups,
  } = useMemberShip();

  const userId = user?.id;

  useEffect(() => {
    if (userId) {
      !initializedOrganizations && loadOrganizations({ userId });
      !initializedProjects && loadProjects({ userId });
      !initializedGroups && loadGroups({ userId });
    }
  }, [userId]);

  useEffect(() => {
    (async () => {
      if (typeof window !== "undefined" && !isLoading && (!user || !permissions) && !isLoggedIn) {
        await checkFirebaseLogin({ router });
        const didWork = await checkLogin();
        if (!didWork) {
          timeoutRef = setTimeout(() => {
            authLogout();
          }, 1000);
        }
      }
    })();
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [permissions, user, isLoading, isLoggedIn]);

  // Helper to allow to check if current profile CAN do some action overy the given entity
  const can = useCallback(
    (action: string, _object: string) => {
      let allow = false;
      if (!user) {
        return false; // First time the profile is not loaded yet, otherwise it will throw a null pointer
      }

      if (!permissions) {
        return false; // When profile is loaded but organization is changed, permissions are null till refreshed
      }
      permissions?.forEach((rule: IPermissionSet) => {
        if (rule.object == _object) {
          if (rule.action == null || rule.action == action) {
            if (rule.allow) {
              allow = true;
            } else {
              allow = false;
            }
          }
        }
      });
      return allow;
    },
    [permissions, user],
  );

  // Proxy that executes the callback if profile CAN do ACTION over given ENTITY
  const ifUserCan = useCallback(
    (action: string, entity: string) => ({
      then: (callback) => {
        if (can(action, entity)) {
          return callback();
        }
      },
    }),
    [permissions, user],
  );

  const logout = useCallback(() => authLogout(), [user, router]);

  const value = { isLoading, user, can, ifUserCan, logout };
  return <SecurityContext.Provider value={value}>{children}</SecurityContext.Provider>;
};

/**
 * This SecurityProvider compoment will allow children to access the security context.
 *  SecuryProvider is being added at Layout level, so it can be accessed from anywere.
 */
export const SecurityProvider: FC<{ children: JSX.Element | JSX.Element[] | ReactChild }> = ({ children }) => (
  <SecurityObserver>{children}</SecurityObserver>
);

/**
 * Hook to access SecurityContext provided by SecurityProvider.
 * See Secured component for usage reference.
 */
export const useSecurityContext = () => useContext(SecurityContext);
