import { CSSScrollbarStyles } from "@caisy/league";
import styled from "styled-components";

export const SProjectLimitModalContent = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  overflow: auto;
  height: calc(95vh - 64px);
  max-height: calc(720px - 64px);
  box-sizing: border-box;
  ${CSSScrollbarStyles};
`;
