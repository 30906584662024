import styled from "styled-components";

export const SCreateProjectCard = styled.div`
  position: relative;
  display: flex;
  padding: 0 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  background-color: var(--ui-01);
  border: 1px solid var(--ui-03);
  border-radius: 8px;
  height: 100%;
`;
