import gql from "graphql-tag";
import { fragment_Document } from "../fragments/document.gql";

export const updateDocument = gql`
  ${fragment_Document}
  mutation UpdateDocument($input: UpdateDocumentRequestInput) {
    UpdateDocument(input: $input) {
      ...UpdateDocumentResponse
    }
  }
  fragment UpdateDocumentResponse on UpdateDocumentResponse {
    document {
      ...Document
    }
  }
`;
