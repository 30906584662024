import { CSSProgressiveHeading02 } from "@caisy/league";
import styled from "styled-components";

export const SDeleteMemberModalHeader = styled.div`
  ${CSSProgressiveHeading02};
  color: var(--text-04);
  height: 64px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--ui-03);

  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
`;
