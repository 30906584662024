import gql from "graphql-tag";

export const getAllAmounts = gql`
  query GetAllAmounts($input: GetAllAmountsRequestInput) {
    GetAllAmounts(input: $input) {
      amounts {
        value
        operation
      }
    }
  }
`;
