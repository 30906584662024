import { Button, IconCheckmarkSolid, IconCircle } from "@caisy/league";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { I18n } from "../../../../../provider/i18n";
import { useProjectOnboarding } from "../../../../../stores/project-onbarding/useProjectOnboarding";
import { paramsToUrlString } from "../../../../../utils/getHrefWithParams";
import { SDuplicateProjectProgressModalItem } from "../../../management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalItem";
import { SDuplicateProjectProgressModalItemIcon } from "../../../management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalItemIcon";
import { SDuplicateProjectProgressModalProgress } from "../../../management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalProgress";
import { SDuplicateProjectProgressModalProgressBar } from "../../../management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalProgressBar";
import { SDuplicateProjectProgressModalProgressBarContainer } from "../../../management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalProgressBarContainer";
import { SOnboardingImportingTemplate } from "./styles/SOnboardingImportingTemplate";
import { SOnboardingImportingTemplateSections } from "./styles/SOnboardingImportingTemplateSections";

export interface IProjectSection {
  label: React.ReactNode;
  key: string;
  subsections?: IProjectSection[];
  hidden?: boolean;
}

export enum ALL_PROJECT_SECTION_KEYS {
  blueprint = `blueprint`,
  document = `document`,
  preview = `preview`,
  tag = `tag`,
  view = `view`,
  webhook = `webhook`,
}

export const PROJECT_SECTIONS: IProjectSection[] = [
  {
    label: <I18n selector="nav.createProject.blueprints" fallback="Blueprints" />,
    key: ALL_PROJECT_SECTION_KEYS.blueprint,
    subsections: [
      {
        label: <I18n selector="nav.createProject.content" fallback="Content (Documents, Components, Assets)" />,
        key: ALL_PROJECT_SECTION_KEYS.document,
      },
      {
        label: <I18n selector="nav.createProject.tags" fallback="Tags" />,
        key: ALL_PROJECT_SECTION_KEYS.tag,
      },
      {
        label: <I18n selector="nav.createProject.views" fallback="Views" />,
        key: ALL_PROJECT_SECTION_KEYS.view,
      },
      {
        label: <I18n selector="nav.createProject.previews" fallback="Previews" />,
        key: ALL_PROJECT_SECTION_KEYS.preview,
      },
    ],
  },
  {
    label: <I18n selector="nav.createProject.webhooks" fallback="Webhooks" />,
    key: ALL_PROJECT_SECTION_KEYS.webhook,
  },
];

export const OnboardingImportingTemplate = () => {
  const { portId, getProjectPort, importingProgress, resetImportingProgress, currentProjectId } =
    useProjectOnboarding();
  const router = useRouter();

  const [progressInterval, setProgressInterval] = useState(null);

  useEffect(() => {
    if (!portId && !router.query?.portId) {
      router.push(
        `/project/home${paramsToUrlString({
          projectId: currentProjectId || (router.query?.currentProjectId as string),
        })}`,
        undefined,
        { shallow: true },
      );
      return;
    }

    getProjectPort({ portId: portId || (router.query.portId as string) }).then(({ finished }) => {
      if (finished) {
        router.push(
          `/project/home${paramsToUrlString({
            projectId: currentProjectId || (router.query?.currentProjectId as string),
          })}`,
          undefined,
          { shallow: true },
        );
        return;
      }

      const interval = setInterval(() => {
        if (importingProgress.finished) return;
        getProjectPort({ portId });
      }, 1000);

      setProgressInterval(interval);

      return () => {
        if (interval) clearInterval(interval);
        resetImportingProgress();
      };
    });
  }, [portId]);

  useEffect(() => {
    if (importingProgress.finished) {
      const interval = clearInterval(progressInterval);
      setProgressInterval(interval);
    }
  }, [importingProgress?.finished]);

  const ProgressTitleDuplicating = (
    <I18n selector="homePage.onboarding_importingTitle" fallback="Creating in progress..." />
  );
  const ProgressTitleFinished = (
    <I18n selector="homePage.onboarding_importingFinishedTitle" fallback="Project has been successfully created" />
  );

  const handleProceedToProject = () => {
    router.push(
      `/project/home${paramsToUrlString({
        projectId: currentProjectId,
      })}`,
      undefined,
      { shallow: true },
    );
  };

  return (
    <SOnboardingImportingTemplate>
      <SOnboardingImportingTemplateSections>
        <SDuplicateProjectProgressModalProgress finished={importingProgress.finished}>
          {importingProgress.finished ? ProgressTitleFinished : ProgressTitleDuplicating}
          <SDuplicateProjectProgressModalProgressBarContainer finished={importingProgress.finished}>
            {importingProgress.finished ? "100" : importingProgress.progress || "0"} %
            <SDuplicateProjectProgressModalProgressBar
              progress={importingProgress.finished ? 100 : importingProgress.progress}
              finished={importingProgress.finished}
            />
          </SDuplicateProjectProgressModalProgressBarContainer>
        </SDuplicateProjectProgressModalProgress>
        {PROJECT_SECTIONS.map((section) => (
          <Fragment key={section.key}>
            <SDuplicateProjectProgressModalItem>
              <SDuplicateProjectProgressModalItemIcon
                allFinished={importingProgress.finished}
                finished={importingProgress[section.key] || importingProgress.finished}
              >
                {importingProgress[section.key] || importingProgress.finished ? (
                  <IconCheckmarkSolid size={20} />
                ) : (
                  <IconCircle size={20} />
                )}
              </SDuplicateProjectProgressModalItemIcon>
              {section.label}
            </SDuplicateProjectProgressModalItem>

            {section.subsections &&
              section.subsections.map((subSection) => (
                <Fragment key={subSection.key}>
                  <SDuplicateProjectProgressModalItem isSubsection>
                    <SDuplicateProjectProgressModalItemIcon
                      allFinished={importingProgress.finished}
                      finished={importingProgress[subSection.key] || importingProgress.finished}
                    >
                      {importingProgress[subSection.key] || importingProgress.finished ? (
                        <IconCheckmarkSolid size={20} />
                      ) : (
                        <IconCircle size={20} />
                      )}
                    </SDuplicateProjectProgressModalItemIcon>
                    {subSection.label}
                  </SDuplicateProjectProgressModalItem>
                </Fragment>
              ))}
          </Fragment>
        ))}
      </SOnboardingImportingTemplateSections>

      {importingProgress.finished ? (
        <Button onClick={handleProceedToProject} style={{ display: "block" }} type="primary">
          <I18n selector="nav.createProject.proceedToTheProject" fallback="Proceed to the project" />
        </Button>
      ) : null}
    </SOnboardingImportingTemplate>
  );
};
