import {
  PEER_MESSAGE_TYPE_FIELD_DISSMISS,
  PEER_MESSAGE_TYPE_MESSAGE_SYNC_REQUEST,
  PEER_MESSAGE_TYPE_AWARENESS,
  PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON,
} from "../constants";
import { decodeFieldMessage, encodeFieldMessage } from "../encoding";
import { getFieldKey } from "../keys";
import { ICollaborationState, IPeer } from "../types";
import { findOwnListeningField } from "./helper";
import * as Y from "yjs";
import * as awarenessProtocol from "y-protocols/awareness";

export const handleFieldSubscribeOn = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  state.mutex.runExclusive(async () => {
    const field = decodeFieldMessage(data);
    const ownListeningField = findOwnListeningField(state, field);
    if (!ownListeningField) {
      peer.send(
        encodeFieldMessage({
          messageType: PEER_MESSAGE_TYPE_FIELD_DISSMISS,
          ...field,
        }),
      );
      return;
    }

    addFieldListener({ state, clientId: peer.clientId, ...field });

    if (!ownListeningField.peersSynced[peer.clientId]) {
      ownListeningField.peersSynced[peer.clientId] = false;
    }

    if (ownListeningField.peersDismissed[peer.clientId]) {
      delete ownListeningField.peersDismissed[peer.clientId];
    }

    if (!ownListeningField?.ydoc) {
      return;
    }

    const ydoc1 = ownListeningField.ydoc;
    const stateVector1 = Y.encodeStateAsUpdate(ydoc1);

    peer.send(
      encodeFieldMessage({
        messageType: PEER_MESSAGE_TYPE_MESSAGE_SYNC_REQUEST,
        ...field,
        body: stateVector1,
      }),
    );

    if (!ownListeningField.provider.awareness) {
      return;
    }

    const awarenessStates = ownListeningField.provider.awareness.getStates();
    if (awarenessStates.size > 0) {
      // https://docs.yjs.dev/api/about-awareness
      const encodedAwState = awarenessProtocol.encodeAwarenessUpdate(
        ownListeningField.provider.awareness,
        Array.from(ownListeningField.provider.awareness.getStates().keys()),
      );

      peer.send(
        encodeFieldMessage({
          messageType: PEER_MESSAGE_TYPE_AWARENESS,
          ...field,
          body: encodedAwState,
        }),
      );
    }
  });
};

const addFieldListener = ({
  state,
  documentFieldLocaleId,
  blueprintFieldId,
  documentId,
  clientId,
}: {
  state: ICollaborationState;
  documentFieldLocaleId?: string;
  blueprintFieldId: string;
  documentId: string;
  clientId: string;
}) => {
  const fieldKey = getFieldKey({
    type: PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON,
    blueprintFieldId,
    documentId,
    documentFieldLocaleId,
  });
  if (state.fieldListener[fieldKey]) {
    state.fieldListener[fieldKey] = [...state.fieldListener[fieldKey].filter((c) => c !== clientId), clientId];
  } else {
    state.fieldListener[fieldKey] = [clientId];
  }
  state.pubsub.emit(fieldKey, [state.fieldListener[fieldKey]]);
};
