import gql from "graphql-tag";
import { fragment_blueprintGroup } from "./blueprintGroups.gql";

export const fragment_blueprintResponse = gql`
  ${fragment_blueprintGroup}
  fragment fragment_blueprintResponse on BlueprintResponse {
    updatedBy
    updatedAt
    variant
    title
    single
    system
    projectId
    previewImageUrl
    name
    groups {
      ...fragment_blueprintGroup
    }
    description
    exposeMutations
    createdBy
    createdAt
    blueprintId
    tagIds
  }
`;
