import styled, { css } from "styled-components";
import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "../../../../constants/styles/mediaquerys";

const Default = css<{ organizationOpen: boolean }>`
  position: absolute;
  top: 73px;
  left: 0;
  width: 100%;
  height: calc(100% - 73px);
  z-index: 5;
  box-sizing: border-box;
  background: var(--ui-01);
  transition: height 400ms;
  overflow: hidden;
  border-radius: 8px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuSubOrganizations = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
