import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/styles/mediaquerys";

const Default = css`
  width: 717px;
  height: 520px;
  background-color: var(--ui-01);
  box-shadow: var(--box-shadow-light-pop-elevation);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  left: 18px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuMainWrapper = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
