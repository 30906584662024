import { SLayoutSiderContent, SidebarTag, SLayoutSiderWrapper } from "@caisy/league";
import { useCallback, useState } from "react";
import { SManagementSidebarWrapper } from "./styles/SManagementSidebarWrapper";
import { CSSLayoutSiderContentOverwrite } from "./styles/CSSLayoutSiderContentOverwrite";
import { FCWithChildren } from "../../../../interfaces/FCWithChildren";

export const ManagementSidebarNavigation: FCWithChildren<{ overlapped?: boolean; onToggleOpen?: (booelan) => void }> = ({
  children,
  overlapped = false,
  onToggleOpen = () => null,
}) => {
  const [leftSiderOpened, setLeftSiderOpened] = useState(true);

  const handleOpen = useCallback(
    (open: boolean) => {
      setLeftSiderOpened(open);
      onToggleOpen(open);
    },
    [setLeftSiderOpened, onToggleOpen],
  );
  return (
    <SLayoutSiderWrapper left>
      {leftSiderOpened && (
        <SLayoutSiderContent styleOverwrite={CSSLayoutSiderContentOverwrite}>
          <SManagementSidebarWrapper>{children}</SManagementSidebarWrapper>
        </SLayoutSiderContent>
      )}

      {!overlapped && <SidebarTag left onOpenChanged={handleOpen} />}
    </SLayoutSiderWrapper>
  );
};
