import { distance } from "fastest-levenshtein";

export interface IMembershipItem {
  role?: string;
  name?: string;
  alias?: string;
  description?: string;
  projectId?: string;
  groupId?: string;
  organizationId?: string;
}

export interface ISortableMembership {
  name?: string;
  alias?: string;
  description?: string;
}

const sortMembershipItems = <T extends ISortableMembership>(newItems: T[], inputString: string): T[] => {
  newItems.sort((a, b) => {
    const getScore = (item) => {
      const findMinInArr: number[] = [distance(inputString, item.name.toLowerCase())];

      if (item.alias) {
        findMinInArr.push(distance(inputString, item.alias.toLowerCase()) + 1); // + 1 to score name higher
      }

      if (item.description) {
        findMinInArr.push(distance(inputString, item.description.toLowerCase()) + 1); // + 2 to score name and alias higher
      }

      return Math.min(...findMinInArr);
    };

    const scoreA = getScore(a);
    const scoreB = getScore(b);

    if (scoreA == scoreB) {
      return a.name.localeCompare(b.name);
    }

    return scoreA - scoreB > 0 ? 1 : -1;
  });

  return newItems;
};

export default sortMembershipItems;
