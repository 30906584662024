import { decodeFieldMessage } from "../encoding";
import { clientIsEditorRole } from "../helper";
import { ICollaborationState, IPeer } from "../types";
import { findOwnListeningField } from "./helper";

export const handleFieldDissmiss = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  const field = decodeFieldMessage(data);
  const ownListeningField = findOwnListeningField(state, field);
  if (!ownListeningField) {
    return;
  }

  const editorPerrs = state.peers.filter((p) => clientIsEditorRole(p.clientId));

  ownListeningField.peersDismissed[peer.clientId] = true;

  if (editorPerrs.length && editorPerrs.length <= Object.keys(ownListeningField.peersDismissed).length) {
    if (window.c.debug) {
      console.log(
        `i have ${editorPerrs.length} editor peers but no one is intrested in this field so i assingen myself to leader `,
      );
    }
    ownListeningField.provider.setLeaderClientId(state.ownClientId, true);
    ownListeningField.provider.setSynced(true);
    ownListeningField.provider.emit("synced", [{ synced: true }]);
  } else if (window.c.debug) {
    console.log(
      ` editorPerrs`,
      editorPerrs.length,
      `<= Object.keys(ownListeningField.peersDismissed).length`,
      Object.keys(ownListeningField.peersDismissed).length,
    );
  }
};
