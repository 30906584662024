import { CSSProgressiveCaption01 } from '@caisy/league';
import styled from 'styled-components';

export const SUserListItemDescription = styled.p`
  ${CSSProgressiveCaption01};
  color: var(--text-03);
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  width: 100%;
  white-space: nowrap;
`;

