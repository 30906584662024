import gql from "graphql-tag";

export const getOrganizationQuotaPlan = gql`
  query GetOrganizationQuotaPlan($input: GetOrganizationQuotaPlanRequestInput) {
    GetOrganizationQuotaPlan(input: $input) {
      organizationId
      quotaPlan {
        availableSystemRoles
        maxMembers
      }
    }
  }
`;
