import React from "react";
import { useStore } from "../index";

export const useEntrypoint = () => useStore(({ entrypoint }) => entrypoint);

export const ListenerEntrypoint = () => {
  const { setDomain, load } = useEntrypoint();

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setDomain(window.location.host);
      load();
    }
  }, [typeof window]);

  return null;
};
