import { CSSProgressiveHighlightSmallBadges } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSCompleted = css`
  color: var(--active-text-04-1);
  background-color: #35aa17;
`;

const CSSCurrent = css`
  color: var(--active-text-04-1);
  background-color: var(--active-ui-03-2);
`;

export const SCreateProjectModalNavStepsStepBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--ui-03);
  ${CSSProgressiveHighlightSmallBadges};
  color: var(--text-04);

  ${({ current }) => current && CSSCurrent}
  ${({ completed }) => completed && CSSCompleted}
`;
