import { gql } from "@apollo/client/core";

export const getPermissionSet = gql`
  query GetPermissionSet($projectId: String, $organizationId: String, $groupId: String) {
    GetPermissionSet(input: { projectId: $projectId, organizationId: $organizationId, groupId: $groupId }) {
      permissionSet {
        action
        allow
        domain
        object
        target
      }
      appliedRole {
        title
        systemRole
        roleId
        organizationId
        groupId
        description
        projectId
      }
    }
  }
`;
