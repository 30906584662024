import { FC } from "react";
import { IOrganizationResponse } from "../../../../../../interfaces/generated";
import { SSelectOrganizationStepListItem } from "./styles/SSelectOrganizationStepListItem";
import { IconCheckmarkCircle, IconCircle, Preview } from "@caisy/league";
import { SSelectOrganizationStepListItemIcon } from "./styles/SSelectOrganizationStepListItemIcon";
import { SSelectOrganizationStepListItemContent } from "./styles/SSelectOrganizationStepListItemContent";
import { SSelectOrganizationStepListItemBody } from "./styles/SSelectOrganizationStepListItemBody";
import { SSelectOrganizationStepListItemName } from "./styles/SSelectOrganizationStepListItemName";
import { SSelectOrganizationStepListItemAlias } from "./styles/SSelectOrganizationStepListItemAlias";
import { getPlaceholderLettersByName } from "../../../getPlaceholderLettersByName";
import getMemberRoleI18N from "../../../../../../utils/getMemberRoleI18N";
import { IMemberRole } from "../../../../../../utils/getMemberRoleI18N";
import { SSelectOrganizationStepListItemBadge } from "./styles/SSelectOrganizationStepListItemBadge";

interface ISelectOrganizationStepListItem {
  organization: IOrganizationResponse;
  selected: boolean;
  onSelect: () => void;
}

export const SelectOrganizationStepListItem: FC<ISelectOrganizationStepListItem> = ({
  organization,
  selected,
  onSelect,
}) => {
  return (
    <SSelectOrganizationStepListItem onMouseDown={onSelect}>
      <SSelectOrganizationStepListItemIcon selected={selected}>
        {selected ? <IconCheckmarkCircle solid size={16} /> : <IconCircle size={16} />}
      </SSelectOrganizationStepListItemIcon>
      <SSelectOrganizationStepListItemContent>
        <Preview
          size={48}
          {...(organization.logoAssetUrl
            ? {
                image: <img src={organization.logoAssetUrl!} style={{ objectFit: "cover" }} />,
              }
            : {
                text: getPlaceholderLettersByName(`${organization.name ? organization.name : ""}`),
              })}
        />

        <SSelectOrganizationStepListItemBody>
          <SSelectOrganizationStepListItemName>{organization.name}</SSelectOrganizationStepListItemName>
          <SSelectOrganizationStepListItemAlias>{organization.alias}</SSelectOrganizationStepListItemAlias>
        </SSelectOrganizationStepListItemBody>
        <SSelectOrganizationStepListItemBadge>
          {getMemberRoleI18N((organization?.roleByUser?.title.toLowerCase() as IMemberRole) || "viewer")}
        </SSelectOrganizationStepListItemBadge>
      </SSelectOrganizationStepListItemContent>
    </SSelectOrganizationStepListItem>
  );
};
