import gql from "graphql-tag";

export const ProjectQuotaPlan = gql`
  fragment ProjectQuotaPlan on ProjectQuotaPlan {
    name
    maxDocumentBlueprints
    maxComponentBlueprints
    maxAssetBlueprints
    maxFieldsPerBlueprint
    maxMembers
    maxDocuments
    maxWebhooks
    maxPreviews
    maxViews
    maxLocales
    maxStoredDocumentVersions
    maxReleases
    maxAssetDownloadTraffic
    maxAssetUploadTraffic
    maxAssetStorage
    maxApiCallsExternal
    maxApiCallsInteral
    maxDaysAuditlog
    availableSystemRoles
  }
`;
