import { useEffect } from "react";
import { useAuthentication } from "src/stores/authentication/useAuthentication";
import { usePermission } from "src/stores/permission/usePermission";
import { useCurrentOrganizationId } from "src/hooks/current-organization-id/useCurrentOrganizationId";
import { useOrganizationQuota } from "../../stores/organization-quota/useOrganizationQuota";

export const ListenerCurrentOrganization = () => {
  const { userId } = useAuthentication();
  const { loadPermissions } = usePermission();
  const organizationId = useCurrentOrganizationId();
  const { getOrganizationQuotaPlan, reset: resetOrganizationQuota } = useOrganizationQuota();

  useEffect(() => {
    (async () => {
      resetOrganizationQuota();
      if (organizationId && organizationId != "undefined") {
        getOrganizationQuotaPlan({ organizationId });
      }
      if (organizationId && organizationId != "undefined" && userId) {
        await loadPermissions({ organizationId, userId });
      }
    })();
  }, [organizationId, userId]);

  return null;
};
