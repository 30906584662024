import gql from "graphql-tag";

export const updateLocale = gql`
    mutation UpdateDocumentFieldLocale($input: UpdateDocumentFieldLocaleRequestInput) {  
        UpdateDocumentFieldLocale(input: $input) {    
            ...UpdateDocumentFieldLocaleResponse  
        }
    }
    fragment UpdateDocumentFieldLocaleResponse on UpdateDocumentFieldLocaleResponse {  
        documentFieldLocale {    
            ...DocumentFieldLocaleResponse  
        }  
        projectId
    }
    fragment DocumentFieldLocaleResponse on DocumentFieldLocaleResponse {  
        allowEmptyRequired  
        apiName  
        default  
        disableEditing  
        disableInResponse  
        fallbackLocaleId  
        flag  
        id  
        title
    }
`;
