import { useMemo } from "react";
import { useI18nQuotaPlans } from "../../provider/i18n/I18nProvider";
import { useBilling } from "../../stores/billing/useBilling";
import { useProjectQuota } from "../../stores/project-quota/useProjectQuota";
import { useI18nDayjs } from "../../provider/i18n/I18nDayjsProvider";

export const MONTHS = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const useCurrentBillingPlan = () => {
  const plans = useI18nQuotaPlans();
  const { projectBillingPlan, projectBillingStatus: status } = useBilling();
  const { plan } = useProjectQuota();
  const dayjs = useI18nDayjs();

  const quotaPlans = plans?.quotaPlans;

  const currentPlanNameWithSuffix = status?.billingPlanName || "v1_free_plan_monthly";
  const currentPlanPeriod = currentPlanNameWithSuffix?.split("_")?.[currentPlanNameWithSuffix?.split("_")?.length - 1];
  const currentPlanName = currentPlanNameWithSuffix?.split(`_${currentPlanPeriod}`)?.[0];
  const currentPlanPublicName = quotaPlans?.find((quota) => quota.systemName === currentPlanName)?.publicName;
  const today = dayjs();
  const currentPlanEndDate =
    currentPlanPeriod === "monthly"
      ? dayjs().date(1).add(1, "month")
      : status.cancelAt ||
        dayjs()
          .month(MONTHS[status?.endMonth] || today.month())
          .date(1)
          .add(1, "year");

  const currentPlanType = currentPlanNameWithSuffix?.includes("free") ? "free" : "paid";

  const nextPlanNameWithSuffix = status?.nextPlan;
  const nextPlanPeriod = nextPlanNameWithSuffix?.split("_")?.[nextPlanNameWithSuffix?.split("_")?.length - 1];
  const nextPlanName = nextPlanNameWithSuffix?.split(`_${nextPlanPeriod}`)?.[0];
  const nextPlanPublicName = quotaPlans?.find((quota) => quota.systemName === nextPlanName)?.publicName;
  const nextPlanType = nextPlanNameWithSuffix ? (nextPlanNameWithSuffix?.includes("free") ? "free" : "paid") : null;

  const scheduledPlan = useMemo(() => {
    if (!status?.nextPlan || !quotaPlans) return;
    const nextPlanNameWithSuffix = status?.nextPlan;
    const suffix = nextPlanNameWithSuffix.split("_")[nextPlanNameWithSuffix.split("_").length - 1];
    const nextPlanName = nextPlanNameWithSuffix.split(`_${suffix}`)[0];
    return quotaPlans?.find(({ systemName }) => nextPlanName === systemName).publicName;
  }, [status, quotaPlans]);

  const currentPlan = useMemo(() => {
    if (!plan?.name || !quotaPlans) return;

    return quotaPlans?.find(({ systemName }) => plan.name === systemName);
  }, [quotaPlans, plan]);

  const projectBillingStatus = useMemo(() => {
    if (!projectBillingPlan) return currentPlan;

    const suffix = projectBillingPlan.split("_")[projectBillingPlan.split("_").length - 1];
    const billingPlan = projectBillingPlan.split(`_${suffix}`)[0];

    return quotaPlans?.find(({ systemName }) => billingPlan === systemName);
  }, [projectBillingPlan, quotaPlans, currentPlan]);

  const currentPeriod = useMemo(() => {
    if (!projectBillingPlan) return;

    if (projectBillingPlan.includes("free")) return "free";
    const suffix = projectBillingPlan.split("_")[projectBillingPlan.split("_").length - 1];
    return suffix;
  }, [projectBillingPlan]);

  return {
    projectBillingStatus,
    currentPlan,
    currentPeriod,
    scheduledPlan,
    currentPlanPeriod,
    currentPlanName,
    currentPlanPublicName,
    currentPlanType,
    currentPlanEndDate,
    nextPlanPublicName,
    nextPlanPeriod,
    nextPlanName,
    nextPlanType,
  };
};
