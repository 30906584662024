import {
  Button,
  Form,
  FormFieldWrapper,
  IconAvatar,
  IconCheckmarkOutlined,
  IconCross,
  Modal,
  PrimaryModalHeader,
  SimpleInput,
  useForm,
} from "@caisy/league";
import { FC } from "react";
import { I18n } from "../../../../provider/i18n";
import { useI18nString } from "../../../../provider/i18n/useI18nString";
import { mailRegex } from "../../../../utils/mailRegex";
import { SAddMemberModal } from "./styles/SAddMemberModal";
import { SAddMemberModalCloseIconWrapper } from "./styles/SAddMemberModalCloseIconWrapper";
import { SAddMemberModalContent } from "./styles/SAddMemberModalContent";
import { SAddMemberModalFooter } from "./styles/SAddMemberModalFooter";
import { SAddMemberModalSection } from "./styles/SAddMemberModalSection";
import { SAddMemberModalSectionTitlte } from "./styles/SAddMemberModalSectionTitle";
import { SInputContainer } from "./styles/SInputContainer";

interface IAddMemberModal {
  visible: boolean;

  onCancel(): void;

  onSubmit(data: any): void;

  title: any;
}

export const AddMemberModal: FC<IAddMemberModal> = ({ visible, onSubmit, onCancel, title }) => {
  const { control, handleSubmit, validate, getFieldErrors } = useForm();

  const onFormSubmit = async (formData) => {
    if (await validate()) {
      return onSubmit(formData);
    }
    return null;
  };

  const headerBreadcrumbs = [{ title, icon: <IconAvatar /> }];

  const i18nEmailPlaceholder = useI18nString({
    fallback: "example@example.com",
    selector: "addNewMember.emailPlaceholder",
  });

  const i18nInvalidEmail = useI18nString({
    fallback: "Invalid Email",
    selector: "addNewMember.invalidEmail",
  });

  return (
    <Modal visible={visible}>
      <SAddMemberModal>
        <PrimaryModalHeader breadcrumbs={headerBreadcrumbs}>
          <SAddMemberModalCloseIconWrapper onClick={onCancel}>
            <IconCross size={24} />
          </SAddMemberModalCloseIconWrapper>
        </PrimaryModalHeader>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <SAddMemberModalContent>
            <SAddMemberModalSection>
              <SAddMemberModalSectionTitlte>
                <I18n selector="addNewMember.user" fallback="User" />
              </SAddMemberModalSectionTitlte>
              <SInputContainer>
                <FormFieldWrapper
                  name="email"
                  control={control}
                  rules={[
                    {
                      name: "valid email",
                      condition: (value) => value && value !== "" && value.match(mailRegex),
                      message: i18nInvalidEmail,
                    },
                  ]}
                >
                  <SimpleInput
                    label={<I18n selector="addNewMember.email" fallback="Email" />}
                    errors={getFieldErrors("email")?.map((e) => e.message) ?? []}
                    autoFocus
                    autoComplete="off"
                    placeholder={i18nEmailPlaceholder}
                    required
                  />
                </FormFieldWrapper>
              </SInputContainer>
            </SAddMemberModalSection>
          </SAddMemberModalContent>
          <SAddMemberModalFooter>
            <Button style={{ width: "calc(100% - 32px)" }} type="primary" htmlType="submit">
              <span style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <IconCheckmarkOutlined size={16} />
                <I18n selector="addNewMember.addMember" fallback="Add Member" />
              </span>
            </Button>
          </SAddMemberModalFooter>
        </Form>
      </SAddMemberModal>
    </Modal>
  );
};
