import React, { useEffect, useState } from "react";

import { useRouter } from "next/router";
import { FCWithChildren } from "../../interfaces/FCWithChildren";

export const useHistory = () => {
  return React.useContext(HistoryProviderContext);
};

export const HistoryProviderContext = React.createContext<IHistoryProvider>({});

interface IHistoryProvider {
  previousRoute?: string;
}

export const HistoryProvider: FCWithChildren<IHistoryProvider> = ({ children }) => {
  const [previousRoute, setPreviousRouter] = useState<string | undefined>(undefined);
  const router = useRouter();

  const handleBeforeHistoryChange = () => {
    setPreviousRouter(router.asPath);
  };

  useEffect(() => {
    router.events.on("beforeHistoryChange", handleBeforeHistoryChange);

    return () => {
      router.events.off("beforeHistoryChange", handleBeforeHistoryChange);
    };
  }, []);

  return <HistoryProviderContext.Provider value={{ previousRoute }}>{children}</HistoryProviderContext.Provider>;
};
