import { CSSScrollbarStyles } from "@caisy/league";
import styled from "styled-components";

export const SOnboardingTemplatesGrid = styled.div`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  overflow: auto;
  max-height: calc(100vh - 176px - 40px - 49px - 20px);
  padding-bottom: 20px;
  box-sizing: border-box;
  ${CSSScrollbarStyles};
`;
