import gql from "graphql-tag";
import { fragment_GroupResponse } from "../fragments/groupResponse.gql";
import { fragment_OrganizationResponse } from "../fragments/organizationResponse.gql";
import { fragment_ProjectResponse } from "../fragments/projectResponse.gql";

export const query_GetProjectByIDWithInheritance = gql`
  ${fragment_GroupResponse}
  ${fragment_OrganizationResponse}
  ${fragment_ProjectResponse}
  query GetProjectByIDWithInheritance($input: GetProjectByIDWithInheritanceRequestInput) {
    GetProjectByIDWithInheritance(input: $input) {
      ...GetProjectByIDWithInheritanceResponse
    }
  }
  fragment GetProjectByIDWithInheritanceResponse on GetProjectByIDWithInheritanceResponse {
    group {
      ...fragment_GroupResponse
    }
    organization {
      ...fragment_OrganizationResponse
    }
    project {
      ...fragment_ProjectResponse
    }
  }
`;
