import gql from "graphql-tag";

export const moveProject = gql`
  mutation MoveProject($input: MoveProjectRequestInput) {
    MoveProject(input: $input) {
      project {
        alias
        createdAt
        description
        groupId
        logoAssetUrl
        name
        organizationId
        projectId
        roleByUser {
          title
        }
        updatedAt
      }
    }
  }
`;
