import { gql } from "graphql-tag";

export const getPaymentInfo = gql`
  query GetPaymentInfo($input: GetPaymentInfoRequestInput) {
    GetPaymentInfo(input: $input) {
      createdAt
      email
      last4CardDigits
      valid
    }
  }
`;
