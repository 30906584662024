import gql from "graphql-tag";

export const getPreviewById = gql`
  query GetPreviewById($input: GetPreviewByIdRequestInput) {
    __typename
    GetPreviewById(input: $input) {
      preview {
        previewId
        previewItems {
          blueprintId
          enableLivePreview
          previewItemId
          previewId
          previewUrl
        }
        name
      }
    }
  }
`;
