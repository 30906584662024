import { CLIENT_EVENTS } from "../../../constants/clientEvents";
import { decodeAnyJSONMessage } from "../encoding";
import { clientIsEditorRole } from "../helper";
import { IPreviewFieldActiveBody } from "../live-preview/types";
import { ICollaborationState, IPeer } from "../types";

export const handlePreviewFieldActive = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  const body: IPreviewFieldActiveBody = decodeAnyJSONMessage(data);

  // only handle messages from peers of the same user -> own preview clients
  if (state.ownClientId.slice(0, 38) !== peer.clientId.slice(0, 38) || clientIsEditorRole(peer.clientId)) {
    return;
  }

  if (!body.projectId || !body.documentId) {
    return;
  }

  const e = new CustomEvent(CLIENT_EVENTS.previewEdit, {
    bubbles: true,
    detail: body,
  });
  window.dispatchEvent(e);
};
