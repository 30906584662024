import { HttpLink, ApolloLink, ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import firebase from "firebase/app";
import "firebase/auth";
import { refreshFirebaseToken } from "../services/firebase";
import { message } from "@caisy/league";
import { getSubClient } from "./subclient";

let globalApolloClient: any = null;

const config = {
  authDomain: process.env.NEXT_PUBLIC_FIRE_AUTHDOMAIN,
  apiKey: process.env.NEXT_PUBLIC_FIRE_APIKEY,
};

if (!firebase?.apps?.length) {
  firebase.initializeApp(config);
}

if (typeof window !== "undefined") {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
}

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 3000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => {
      console.log(` error`, error);
      console.log(` !!error`, !!error);
      console.log(` _operation`, _operation);
      return true;
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((graphQLError) => {
      if (graphQLError.extensions && graphQLError.extensions.code === "FORBIDDEN") {
        message.error("Forbidden: " + graphQLError.message, { duration: 5000 });
        return;
      }
      if (graphQLError.extensions && graphQLError.extensions.code === "UNAUTHENTICATED") {
        console.error("Unauthenticated: " + graphQLError.message);
        return;
      }
      if (graphQLError.extensions && graphQLError.extensions.code === "GRAPHQL_VALIDATION_FAILED") {
        if (typeof window !== "undefined") {
          message.error(`${graphQLError.message}`, { duration: 5000 });
        }
        return;
      }
      if (typeof window !== "undefined") {
        message.error(`${graphQLError.message}`, { duration: 5000 });
      }
      console.error(`[GraphQL error]: Message: ${graphQLError.message}, %o`, graphQLError); // tslint:disable-line no-console
    });
  } else if (networkError) {
    console.log(`networkError`, networkError);
    console.error("[Network error]: %o", networkError); // tslint:disable-line no-console
    message.error("Network error");
  }
});

export const getAuthToken = async () => {
  let token = localStorage.getItem("accounts:accessToken");
  const time = localStorage.getItem("accounts:time");
  if (~~(Date.now() / 1000) - parseInt(time) > 60) {
    const newToken = await refreshFirebaseToken();
    if (newToken) {
      token = newToken;
    }
  }
  return token;
};

const authLink = setContext(async (_, { headers }) => {
  if (!headers) {
    headers = {};
  }
  const token = await getAuthToken();
  if (token) {
    headers["x-caisy-token"] = token;
  }
  return {
    headers: {
      ...headers,
    },
  };
});

const httpLink = new HttpLink({
  uri: `${process.env.CORE_URL}/caisy/graphql`,
});

export const client = getSubClient();

export function createApolloClient() {
  return getSubClient();
}

// export const client = initApolloClient();

export function createApolloClientOld(initialState = {}) {
  const ssrMode = typeof window === "undefined";
  const cache = new InMemoryCache().restore(initialState);

  const serverLink = ApolloLink.from([authLink, retryLink, errorLink, httpLink]);

  const link = ssrMode ? serverLink : serverLink;
  // : (process as any).browser
  // ? split(
  //     ({ query }: any) => {
  //       const { kind, operation }: OperationVariables = getMainDefinition(query);
  //       return kind === "OperationDefinition" && operation === "subscription";
  //     },
  //     //@ts-ignore
  //     () => {
  //       // const wsClient = new SubscriptionClient(`${process.env.SUB_URL || process.env.CORE_URL}/hotdocument`.replace('http', 'ws').replace('https', 'wss'), {
  //       const wsClient = new SubscriptionClient(
  //         `${process.env.CORE_URL}/caisy/graphql`.replace("http", "ws").replace("https", "wss"),
  //         {
  //           reconnect: true,
  //           connectionParams: () => {
  //             const token = getAuthToken();
  //             return { token };
  //           },
  //         },
  //       );
  //       // return wsClient;
  //       //@ts-ignore
  //       return new WebSocketLink(wsClient);
  //     },
  //     serverLink,
  //   )
  // : serverLink

  const apc = new ApolloClient({
    ssrMode,
    link,
    cache,
  });

  apc.defaultOptions = {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  return apc;
}

export function initApolloClient() {
  if (typeof window === "undefined") {
    return createApolloClient();
  }

  if (!globalApolloClient) {
    globalApolloClient = createApolloClient();
  }

  return globalApolloClient;
}
