import gql from "graphql-tag";
import { fragment_GetAmountResponse } from "../fragments/amountResponse.gql";

export const getAmount = gql`
  ${fragment_GetAmountResponse}
  query GetAmount($input: GetAmountRequestInput) {
    GetAmount(input: $input) {
      ...GetAmountResponse
    }
  }
`;
