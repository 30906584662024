import gql from "graphql-tag";

export const query_validateProjectDiscountCode = gql`
  query ValidateProjectDiscountCode($input: ValidateProjectDiscountCodeRequestInput) {
    ValidateProjectDiscountCode(input: $input) {
      valid
      discountPercentage
      error
      errorCode
    }
  }
`;
