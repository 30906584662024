import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/styles/mediaquerys";

const Default = css<{ expanded?: boolean }>`
  background-color: var(--ui-01);
  padding: 12px;
  border-right: 1px solid var(--ui-03);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: var(--box-shadow-left-sidebar);
  width: ${(props) => (props?.expanded ? "172px" : "60px")};
  align-items: flex-start;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SMainNavigation = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
