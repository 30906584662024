import gql from "graphql-tag";

export const getPreviewItemsByBlueprintId = gql`
  query GetPreviewItemsByBlueprintId($input: GetPreviewItemsByBlueprintIdRequestInput) {
    __typename
    GetPreviewItemsByBlueprintId(input: $input) {
      previewItems {
        blueprintId
        enableLivePreview
        previewItemId
        previewId
        previewUrl
      }
    }
  }
`;
