import { Button, IconChevronRight } from "@caisy/league";
import { useState } from "react";
import { useCurrentProjectId } from "../../../hooks/current-project-id/useCurrentProjectId";
import { I18n } from "../../../provider/i18n";
import { useWebhook } from "../../../stores/webhook/useWebhook";
import { SSettingsTopNavBreadcrumb } from "./styles/SSettingsTopNavBreadcrumb";
import { SSettingsTopNavChevronRight } from "./styles/SSettingsTopNavChevronRight";
import { SWebhookEditButtonWrapper } from "./styles/SWebhookEditButtonWrapper";

const WebhookEditNavComponent = () => {
  const projectId = useCurrentProjectId();
  const { currentWebhook, saveCurrentWebhook } = useWebhook();
  const [loading, setLoading] = useState(false);

  const onSaveWebhook = async () => {
    setLoading(true);
    await saveCurrentWebhook({ projectId });
    setLoading(false);
  };

  return (
    <>
      {currentWebhook?.name && (
        <>
          <SSettingsTopNavChevronRight>
            <IconChevronRight size={20} />
          </SSettingsTopNavChevronRight>

          <SSettingsTopNavBreadcrumb>{currentWebhook?.name}</SSettingsTopNavBreadcrumb>
        </>
      )}
      <SWebhookEditButtonWrapper>
        <Button disabled={loading} size="default" type="primary" onClick={onSaveWebhook}>
          <I18n selector="projectManagementWebhooks.header_saveChanges" fallback="Save changes" />
        </Button>
      </SWebhookEditButtonWrapper>
    </>
  );
};

export default WebhookEditNavComponent;
