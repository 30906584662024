import gql from "graphql-tag";

export const getPermissionGrant = gql`
  query GetPermissionGrant($input: GetPermissionGrantRequestInput) {
    __typename
    GetPermissionGrant(input: $input) {
      success
    }
  }
`;
