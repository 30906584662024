import { SAssetListItemIconWrapper } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSError = css`
 svg {
   color: var(--activated-icon-02-1);
  }
`;

const SStyledIconWrapper = styled(SAssetListItemIconWrapper)`
  svg{
    color: var(--icon-08);
  }
  ${(props) => props.error && CSSError}
  svg:last-of-type{
    color: var(--icon-03);
  }
`;

export default SStyledIconWrapper;
