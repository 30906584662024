import styled from "styled-components";
import { CSSRichtext } from "../../../../../../constants/styles/css-richtext";

export const SOnboardingTemplateModalRichtext = styled.div`
  padding-bottom: 40px;
  ul {
    li {
      margin-bottom: 20px;
      > p {
        padding: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
      }
    }
  }

  & > .richtext-code-block,
  & > h1,
  & > section > h1,
  & > h2,
  & > section > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6,
  & > p,
  & > pre,
  & > li,
  & > ol,
  & > ul,
  & > iframe,
  & > blockquote,
  & > table {
    margin-left: auto;
    margin-right: auto;
  }

  ${CSSRichtext}
  p {
    margin-bottom: 24px;
  }
  ul {
    li {
      /* margin-bottom: 25px; */
      > p {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 0;
      }
    }
  }

  h1 {
    margin-bottom: 24px;
  }
`;
