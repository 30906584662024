import { Button } from "@caisy/league";
import { useState } from "react";
import { useCurrentGroupId } from "../../../../../hooks/current-project-id/useCurrentGroupId";
import { I18n } from "../../../../../provider/i18n";
import { useGroupMember } from "../../../../../stores/membership/useGroupMember";
import { AddMemberModal } from "../../add-member-modal/AddMemberModal";

export const GroupMembersNavChildren = () => {
  const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
  const { createGroupMember, loadGroupMembers } = useGroupMember();
  const groupId = useCurrentGroupId();

  const onCancelAddMemberModal = () => {
    setAddMemberModalVisible(false);
  };

  const loadData = async () => {
    await loadGroupMembers({ groupId });
  };

  const onAddMemberModalSubmit = async (data) => {
    await createGroupMember({
      emailAddress: data.email,
      groupId,
      systemRole: "viewer",
    });
    await loadData();
    setAddMemberModalVisible(false);
  };

  return (
    <>
      <Button
        size="medium"
        type="primary"
        onClick={() => {
          setAddMemberModalVisible(true);
        }}
      >
        <I18n selector="groupManagementMembers.newMember" fallback="new member" />
      </Button>
      <AddMemberModal
        title={<I18n selector="settings.add_member_group_title" fallback="Add Member To Group" />}
        visible={addMemberModalVisible}
        onCancel={onCancelAddMemberModal}
        onSubmit={onAddMemberModalSubmit}
      />
    </>
  );
};
