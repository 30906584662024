import { CSSProgressiveBody01Semibold } from "@caisy/league";
import styled from "styled-components";

export const SOnboardingTourCardTitle = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${CSSProgressiveBody01Semibold}
`;
