import { SOnboardingTemplatesModalSiderItem } from "../../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalSiderItem";
import styled from "styled-components";

export const SSelectFromTemplateStepTemplateDetails = styled.div`
  display: flex;
  gap: 80px;
  padding: 40px 120px;

  ${SOnboardingTemplatesModalSiderItem} {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  background-color: var(--ui-01);
`;
