import { useEffect } from "react";
import { CLIENT_EVENTS } from "../../constants/clientEvents";
import { useDocument } from "../../stores/document/useDocument";
import { IStoreEvent } from "./broadcasts/broadcastMutation";
import { useCurrentProjectId } from "../../hooks/current-project-id/useCurrentProjectId";

export const ListenerPeerMutation = () => {
  const {
    applyPeerUpdateDocument,
    applyPeerDocumentFieldUpdate,
    applyPeerFieldValidation,
    applyPeerDeleteDocument,
    applyPeerDuplicateDocument,
  } = useDocument();
  const projectId = useCurrentProjectId();

  useEffect(() => {
    const handler = (e: any) => {
      const {
        storeEvent,
        request,
        response,
      }: {
        storeEvent: IStoreEvent;
        request: any;
        response: any;
      } = e.detail;

      switch (storeEvent) {
        case "document/updateDocument":
          if (projectId !== response.document.projectId) return;
          applyPeerUpdateDocument(response.document);
          break;
        case "document/updateField":
          // if (projectId !== response.document.projectId) return;
          applyPeerDocumentFieldUpdate({ response, request });
          break;
        case "document/validateField":
          // if (projectId !== response.document.projectId) return;
          applyPeerFieldValidation({ response, request });
          break;
        case "document/deleteDocumentById":
          applyPeerDeleteDocument({ response, request });
          break;
        case "document/duplicateDocumentById":
          applyPeerDuplicateDocument({ response, request });
          break;
        default:
          console.log(`handler is not handling ${storeEvent}`, request, response);
      }
    };

    if (typeof window !== "undefined") {
      // @ts-ignore
      window.addEventListener(CLIENT_EVENTS.peerMutation, handler);
    }

    return () => {
      // @ts-ignore
      window.removeEventListener("message", handler);
    };
  }, [projectId]);

  return null;
};
