import gql from "graphql-tag";

export const UpdateWebhook = gql`
  mutation UpdateWebhook($input: UpdateWebhookRequestInput) {
    UpdateWebhook(input: $input) {
      ...UpdateWebhookResponse
    }
  }
  fragment UpdateWebhookResponse on UpdateWebhookResponse {
    webhook {
      ...WebhookResponse
    }
  }
  fragment WebhookResponse on WebhookResponse {
    createdAt
    description
    headers {
      ...WebhookHeader
    }
    method
    name
    trigger
    updatedAt
    url
    webhookId
  }
  fragment WebhookHeader on WebhookHeader {
    name
    value
  }
`;
