import gql from "graphql-tag";

export const DeleteWebhook = gql`
  mutation DeleteWebhook($input: DeleteWebhookRequestInput) {
    DeleteWebhook(input: $input) {
      ...DeleteWebhookResponse
    }
  }
  fragment DeleteWebhookResponse on DeleteWebhookResponse {
    deleted
  }
`;
