import styled, { css } from "styled-components";

const CSSOpened = css`
  display: block;
`;

export const SMainMenuUserPopup = styled.div`
  width: 256px;
  border-radius: 8px;
  box-shadow: var(--box-shadow-strong-pop-elavation);
  background-color: #fff;
  overflow: hidden;
  position: relative;
  display: none;

  max-height: calc(100vh - 300px);
  overflow-y: auto;
  ${({ opened }) => opened && CSSOpened}
`;
