import { IconChevronRight } from "@caisy/league";
import { useRouter } from "next/router";
import { useCurrentProjectId } from "../../../hooks/current-project-id/useCurrentProjectId";
import { I18n } from "../../../provider/i18n";
import { useWebhook } from "../../../stores/webhook/useWebhook";
import { paramsToUrlString } from "../../../utils/getHrefWithParams";
import { SSettingsTopNavBreadcrumb } from "./styles/SSettingsTopNavBreadcrumb";
import { SSettingsTopNavChevronRight } from "./styles/SSettingsTopNavChevronRight";

const WebhookCallNavComponent = () => {
  const { currentWebhook } = useWebhook();
  const router = useRouter();
  const projectId = useCurrentProjectId();

  return (
    <>
      {currentWebhook?.name && (
        <>
          <SSettingsTopNavChevronRight>
            <IconChevronRight size={20} />
          </SSettingsTopNavChevronRight>

          <SSettingsTopNavBreadcrumb
            onClick={() =>
              router.push(
                `/project/settings/development/webhooks/edit${paramsToUrlString({
                  webhookId: currentWebhook.webhookId,
                  projectId,
                })}`,
                undefined,
                { shallow: true },
              )
            }
          >
            {currentWebhook?.name}
          </SSettingsTopNavBreadcrumb>
          <SSettingsTopNavChevronRight>
            <IconChevronRight size={20} />
          </SSettingsTopNavChevronRight>

          <SSettingsTopNavBreadcrumb>
            <I18n selector="projectManagementWebhooks.header_logDetails" fallback="Log details" />
          </SSettingsTopNavBreadcrumb>
        </>
      )}
    </>
  );
};

export default WebhookCallNavComponent;
