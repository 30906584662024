import React, { useState } from "react";

import { FCWithChildren } from "../../interfaces/FCWithChildren";

interface INavbarBehavior {
  expanded?: boolean;
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  allowExpand?: boolean;
  setAllowExpand?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavbarBehaviorContext = React.createContext<INavbarBehavior>({} as INavbarBehavior);

export const NavbarBehaviorProvider: FCWithChildren = ({ children }) => {
  const [allowExpand, setAllowExpand] = useState(true);
  const [expanded, setExpanded] = useState(localStorage?.getItem("expandedMainNav") === "true" ? true : false);

  return (
    <NavbarBehaviorContext.Provider value={{ expanded, setExpanded, allowExpand, setAllowExpand }}>
      {children}
    </NavbarBehaviorContext.Provider>
  );
};
