import { Button, Modal } from "@caisy/league";
import { FC } from "react";
import { SMaintenanceWarningModal } from "./styles/SMaintenanceWarningModal";
import { SMaintenanceWarningModalIcon } from "./styles/SMaintenanceWarningModalIcon";
import MaintenanceWarningIcon from "./MaintenanceWarningIcon";
import { SMaintenanceWarningModalContent } from "./styles/SMaintenanceWarningModalContent";
import { SMaintenanceWarningModalTitle } from "./styles/SMaintenanceWarningModalTitle";
import { SMaintenanceWarningModalDescription } from "./styles/SMaintenanceWarningModalDescription";
import { useMaintenanceWarning } from "../useMaintenanceWarning";

export const MaintenanceWarningModal: FC = () => {
  const { title, description, maintenanceWarning, visible, acknowledgeWarning } = useMaintenanceWarning();

  if (!visible) return null;

  return (
    <Modal visible>
      <SMaintenanceWarningModal>
        <SMaintenanceWarningModalIcon>
          <MaintenanceWarningIcon />
        </SMaintenanceWarningModalIcon>
        <SMaintenanceWarningModalContent>
          <SMaintenanceWarningModalTitle>{title}</SMaintenanceWarningModalTitle>

          <SMaintenanceWarningModalDescription>{description}</SMaintenanceWarningModalDescription>
        </SMaintenanceWarningModalContent>
        <Button onClick={acknowledgeWarning} style={{ minWidth: 240 }} type="danger">
          {maintenanceWarning?.buttonContent || "acknowledge"}
        </Button>
      </SMaintenanceWarningModal>
    </Modal>
  );
};
