import styled from "styled-components";

export const SProjectLimitModalPricingCardsHeaderIcon = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ui-02);
  border-radius: 4px;
  color: var(--icon-09);
`;
