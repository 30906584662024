import { Button, IconExternalLink, Modal, ModalHeader, ModalHeaderTitle, RichTextRenderer } from "@caisy/league";
import { FC } from "react";
import { IGenProjectTemplate } from "../../../../../interfaces/generated/i18n-types";
import { I18n } from "../../../../../provider/i18n";
import LogoFull from "../../../../atom/logoFull/LogoFull";
import IconGithub from "./IconGithub";
import { SOnboardingTemplateModalRichtext } from "./styles/SOnboardingTemplateModalRichtext";
import { SOnboardingTemplatesModal } from "./styles/SOnboardingTemplatesModal";
import { SOnboardingTemplatesModalArticle } from "./styles/SOnboardingTemplatesModalArticle";
import { SOnboardingTemplatesModalContent } from "./styles/SOnboardingTemplatesModalContent";
import { SOnboardingTemplatesModalImage } from "./styles/SOnboardingTemplatesModalImage";
import { SOnboardingTemplatesModalName } from "./styles/SOnboardingTemplatesModalName";
import { SOnboardingTemplatesModalSider } from "./styles/SOnboardingTemplatesModalSider";
import { SOnboardingTemplatesModalSiderItem } from "./styles/SOnboardingTemplatesModalSiderItem";
import { SOnboardingTemplatesModalSiderSection } from "./styles/SOnboardingTemplatesModalSiderSection";
import { SOnboardingTemplatesModalSiderTitle } from "./styles/SOnboardingTemplatesModalSiderTitle";

interface IOnboardingTemplateModal {
  template: IGenProjectTemplate;
  onClose: () => void;
  visible: boolean;
  onSelectTemplate: (template: IGenProjectTemplate) => void;
}

export const OnboardingTemplateModal: FC<IOnboardingTemplateModal> = ({
  visible,
  onClose,
  template,
  onSelectTemplate,
}) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <SOnboardingTemplatesModal>
        <ModalHeader onClose={onClose}>
          <ModalHeaderTitle>{template?.name}</ModalHeaderTitle>
        </ModalHeader>
        <SOnboardingTemplatesModalContent>
          <SOnboardingTemplatesModalArticle>
            <SOnboardingTemplatesModalName>{template?.name}</SOnboardingTemplatesModalName>
            <SOnboardingTemplatesModalImage src={template?.previewImage?.src} />
            <SOnboardingTemplateModalRichtext>
              <RichTextRenderer node={template?.fullDescription?.json} />
            </SOnboardingTemplateModalRichtext>
          </SOnboardingTemplatesModalArticle>
          <SOnboardingTemplatesModalSider>
            <Button onClick={() => onSelectTemplate(template)} style={{ display: "block" }} type="primary">
              <I18n selector="homePage.onboarding_addToProject" fallback="Add to project" />
            </Button>
            <SOnboardingTemplatesModalSiderSection>
              <SOnboardingTemplatesModalSiderTitle>Resources</SOnboardingTemplatesModalSiderTitle>
              <SOnboardingTemplatesModalSiderItem isLink>
                <IconExternalLink size={16} />
                <a rel="noreferrer" href={template?.demoLink} target="_blank">
                  <I18n selector="homePage.onboarding_liveDemo" fallback="Live demo" />
                </a>
              </SOnboardingTemplatesModalSiderItem>
              <SOnboardingTemplatesModalSiderItem isLink>
                <IconGithub />
                <a rel="noreferrer" href={template?.githubLink} target="_blank">
                  <I18n selector="homePage.onboarding_githubRepo" fallback="Github repo" />
                </a>
              </SOnboardingTemplatesModalSiderItem>
            </SOnboardingTemplatesModalSiderSection>
            <SOnboardingTemplatesModalSiderSection>
              <SOnboardingTemplatesModalSiderTitle>
                <I18n selector="homePage.onboarding_stack" fallback="Stack" />
              </SOnboardingTemplatesModalSiderTitle>
              {template?.stack?.map(({ id, icon, label }) => (
                <SOnboardingTemplatesModalSiderItem key={id}>
                  <img src={icon.src} /> {label}
                </SOnboardingTemplatesModalSiderItem>
              ))}
            </SOnboardingTemplatesModalSiderSection>
            <SOnboardingTemplatesModalSiderSection>
              <SOnboardingTemplatesModalSiderTitle>
                <I18n selector="homePage.onboarding_publisher" fallback="Publisher" />
              </SOnboardingTemplatesModalSiderTitle>
              <div style={{ width: "fit-content" }}>
                <LogoFull />
              </div>
            </SOnboardingTemplatesModalSiderSection>
          </SOnboardingTemplatesModalSider>
        </SOnboardingTemplatesModalContent>
      </SOnboardingTemplatesModal>
    </Modal>
  );
};
