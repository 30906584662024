import gql from "graphql-tag";

export const getDocumentReferences = gql`
  query GetDocumentReferences($input: GetDocumentReferencesRequestInput) {
    GetDocumentReferences(input: $input) {
      connection {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            documentId
            statusId
            title
            previewImageUrl
            blueprintId
          }
        }
      }
    }
  }
`;
