import React, { useEffect, useState } from "react";
import SLogoSquare from "./Styles/SLogoSquare";
import { Img } from "@caisy/league";
import { useEntrypoint } from "../../../stores/entrypoint/useEntrypoint";
import { SMainNavigationLogoText } from "../../organism/main-navigation/styles/SMainNavigationLogoText";
import { SMainNavigationLogoContainer } from "../../organism/main-navigation/styles/SMainNavigationLogoContainer";
import useExpandableNavigation from "../../../hooks/useExpandableNavigation";

const LogoSquare: React.FC<{ size?: number }> = ({ size = 54 }) => {
  const [filterColor, setFilterColor] = useState<string>(undefined);
  const { logoSquare, domain, foundDomain } = useEntrypoint();
  const { expanded } = useExpandableNavigation();
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.href.includes("localhost")) {
        setFilterColor("247deg");
      }
      if (window.location.href.includes("staging.caisy") || process.env.CORE_UR?.includes("staging.caisy")) {
        setFilterColor("87deg");
      }
      if (window.location.href.includes("dev.caisy") || process.env.CORE_UR?.includes("dev.caisy")) {
        setFilterColor("247deg");
      }
    }
  });

  return (
    <SMainNavigationLogoContainer>
      {!domain || !foundDomain || domain.includes("caisy.io") ? (
        <>
          <SLogoSquare size={size} filterColor={filterColor}>
            {logoSquare && (
              <Img
                style={{ height: size, width: size }}
                resolution={logoSquare && logoSquare.endsWith(".svg") ? 0 : 800}
                src={logoSquare}
                contain
                lazyload={false}
              />
            )}
          </SLogoSquare>
          {expanded && (
            <SMainNavigationLogoText filterColor={filterColor}>
              <svg xmlns="http://www.w3.org/2000/svg" width="64" height="24" fill="none" viewBox="0 0 64 24">
                <path
                  fill="rgb(26, 49, 64)"
                  d="M35.629 1.922c0 1.07-.679 1.993-1.968 1.993-1.337 0-1.993-.922-1.993-1.993 0-.972.656-1.92 1.993-1.92a1.872 1.872 0 011.968 1.92zM10.945 14.417a4.033 4.033 0 01-3.493 1.94 3.963 3.963 0 01-2.972-1.212 4.37 4.37 0 010-5.998 3.961 3.961 0 012.972-1.212c1.61 0 2.819.892 3.588 2.05l2.677-1.735c-1.212-1.88-3.446-3.295-6.318-3.295-4.261 0-7.38 3.075-7.38 7.159s3.193 7.21 7.38 7.21c2.48 0 5.032-1.066 6.441-3.446l-2.895-1.46zM28.9 5.352a2.775 2.775 0 00-2.529 1.63c-1.115-1.384-2.947-2.03-4.756-2.03-3.86 0-6.859 3.15-6.859 7.208 0 4.059 2.947 7.174 6.785 7.174 2.08 0 4.012-.94 4.983-2.398v2.078h3.019V5.352H28.9zm-3.785 9.785a3.842 3.842 0 01-2.923 1.22 3.957 3.957 0 01-2.975-1.212 4.162 4.162 0 01-1.189-3.026 4.055 4.055 0 011.19-2.972 3.958 3.958 0 012.974-1.212 3.842 3.842 0 012.923 1.212 4.119 4.119 0 011.163 2.972 4.228 4.228 0 01-1.163 3.018zm6.903-9.785v13.672h3.223V5.352H32.02zm15.606 6.61c-.914-.614-2.275-1.088-4.133-1.408-2.749-.472-2.818-1.166-2.818-1.56 0-.672.966-1.067 2.152-1.067 1.187 0 2.726.446 3.641 1.36l2.106-2.103c-1.089-1.186-3.046-2.231-5.624-2.231-2.846 0-5.547 1.34-5.547 3.94 0 2.401 1.76 3.913 5.696 4.612 1.12.197 1.857.395 2.183.572a.824.824 0 01.512.789c0 .768-.891 1.488-2.35 1.488a5.789 5.789 0 01-4.434-1.832l-2.178 2.03a7.106 7.106 0 002.398 1.932c1.066.571 2.429.842 4.1.842 3.24 0 5.695-1.857 5.695-4.46-.01-1.315-.484-2.28-1.399-2.903zm12.903-6.61v7.628c0 1.732-1.76 3.075-3.49 3.075-1.96 0-3.171-1.243-3.171-3.15V5.353h-3.224v8.77c0 2.596 2.53 4.902 5.924 4.902a5.577 5.577 0 004.1-1.832v.344c0 2.05-1.612 3.494-3.967 3.494a8.696 8.696 0 01-2.741-.469l-.969 2.804c1.21.414 2.48.629 3.76.635 3.991 0 7.01-2.798 7.01-6.364V5.351h-3.232z"
                ></path>
              </svg>
            </SMainNavigationLogoText>
          )}
        </>
      ) : (
        <>
          {
            <SLogoSquare size={size} filterColor={filterColor}>
              {logoSquare && (
                <Img
                  style={{ height: size, width: size }}
                  resolution={logoSquare && logoSquare.endsWith(".svg") ? 0 : 800}
                  src={logoSquare}
                  contain
                  lazyload={false}
                />
              )}
            </SLogoSquare>
          }
        </>
      )}
    </SMainNavigationLogoContainer>
  );
};

export default LogoSquare;
