import gql from "graphql-tag";

export const updatePreview = gql`
  mutation UpdatePreview($input: UpdatePreviewRequestInput) {
    __typename
    UpdatePreview(input: $input) {
      preview {
        previewId
        name
      }
    }
  }
`;
