import produce from "immer";
import { m_CreateCheckoutSession } from "../../graphql/mutations/createCheckoutSession.gql";
import { m_CreatePortalSession } from "../../graphql/mutations/createPortalSession.gql";
import { m_SetProjectBillingPlan } from "../../graphql/mutations/setProjectBillingPlan.gql";
import { q_GetPaymentStatus } from "../../graphql/queries/getPaymentStatus.gql";
import {
  ICreateCheckoutSessionResponse,
  ICreatePortalSessionResponse,
  IGetManyProjectQuotaOverviewByIdResponse,
  IGetPaymentInfoResponse,
  ISetProjectBillingPlanResponse,
  IValidateProjectDiscountCodeRequestInput,
} from "../../interfaces/generated";
import { client } from "../../utils/client";
import { IBillingState } from "./types";
import { getPaymentInfo } from "../../graphql/queries/getPaymentInfo.gql";
import { getManyProjectQuotaOverviewByID } from "../../graphql/queries/getManyProjectQuotaOverviewByID.gql";
import { q_GetProjectBillingStatus } from "../../graphql/queries/getProjectBillingStatus.gql";
import { query_validateProjectDiscountCode } from "../../graphql/queries/validateProjectDiscountCode.gql";

export const createBillingSlice = (
  set: (cb: (state: IBillingState) => IBillingState, replace: boolean, name: string) => void,
  get: () => IBillingState,
) => ({
  billing: {
    loading: false,
    paymentInfoLoaded: {},
    initalizedOrganization: undefined,
    initalizedProject: undefined,
    organizationBillingStatus: undefined,
    paymentInfo: {},
    quotaOverviews: [],
    projectBillingPlan: null,
    projectBillingStatus: null,
    promoCodeStatus: {
      valid: null,
      discountPercentage: null,
    },
    getQuotaOverviews: async ({ projectIds, organizationId }) => {
      const { data } = await client.query({
        query: getManyProjectQuotaOverviewByID,
        variables: {
          input: {
            projectIds,
            organizationId,
          },
        },
        fetchPolicy: "no-cache",
      });

      const response = data?.GetManyProjectQuotaOverviewByID as IGetManyProjectQuotaOverviewByIdResponse;

      if (response) {
        set(
          produce((state: IBillingState) => {
            state.billing.quotaOverviews = [...state.billing.quotaOverviews, ...response.quotaOverviews];
          }),
          false,
          "billing/getQuotaOverviews",
        );
      }

      return response.quotaOverviews.length;
    },
    getPaymentInfo: async ({ organizationId }) => {
      if (get().billing.paymentInfoLoaded[organizationId]) return;

      try {
        const { data } = await client.query({
          query: getPaymentInfo,
          variables: {
            input: {
              organizationId,
            },
          },
          fetchPolicy: "no-cache",
        });

        const paymentInfo = data?.GetPaymentInfo as IGetPaymentInfoResponse;
        if (paymentInfo) {
          set(
            produce((state: IBillingState) => {
              state.billing.paymentInfoLoaded[organizationId] = true;
              state.billing.paymentInfo[organizationId] = paymentInfo;
            }),
            false,
            "billing/getPaymentInfo",
          );
        }
      } catch {
        set(
          produce((state: IBillingState) => {
            state.billing.paymentInfo[organizationId] = { valid: false, __typename: "GetPaymentInfoResponse" };
            state.billing.paymentInfoLoaded[organizationId] = true;
          }),
          false,
          "billing/getPaymentInfo/catch",
        );
      }
    },
    loadOrganizationBillingStatus: async ({ organizationId }) => {
      try {
        if (get().billing.loading == true) {
          return;
        }

        set(
          produce((state: IBillingState) => {
            state.billing.loading = true;
          }),
          false,
          "billing/loadOrganizationBillingStatus/start",
        );
        const { data } = await client.mutate({
          mutation: q_GetPaymentStatus,
          variables: {
            input: {
              organizationId,
            },
          },
        });

        set(
          produce((state: IBillingState) => {
            state.billing.initalizedOrganization = organizationId;
            state.billing.organizationBillingStatus = data?.GetPaymentStatus;
            state.billing.loading = false;
          }),
          false,
          "billing/loadOrganizationBillingStatus/update",
        );
      } catch (err) {
        console.log(`err`, err);
        set(
          produce((state: IBillingState) => {
            state.billing.initalizedOrganization = organizationId;
            state.billing.loading = false;
          }),
          false,
          "billing/loadOrganizationBillingStatus/failed",
        );
      }
    },
    createPortalSession: async ({ organizationId }) => {
      const { data } = await client.mutate({
        mutation: m_CreatePortalSession,
        variables: {
          input: {
            organizationId,
          },
        },
      });
      return data?.CreatePortalSession as ICreatePortalSessionResponse;
    },
    getProjectBillingStatus: async (input) => {
      const { data } = await client.query({
        query: q_GetProjectBillingStatus,
        variables: { input },
        fetchPolicy: "no-cache",
      });

      if (data?.GetProjectBillingStatus) {
        set(
          produce((state: IBillingState) => {
            state.billing.projectBillingPlan = data.GetProjectBillingStatus.nextPlan;
            state.billing.projectBillingStatus = data.GetProjectBillingStatus;
          }),
          false,
          "billing/getProjectBillingStatus",
        );
      }
    },
    setProjectBillingPlan: async ({ organizationId, projectId, billingPlanName, discountCode = null }) => {
      const { data } = await client.mutate({
        mutation: m_SetProjectBillingPlan,
        variables: {
          input: {
            organizationId,
            projectId,
            billingPlanName,
            discountCode,
          },
        },
      });

      if (data?.SetProjectBillingPlan?.success) {
        set(
          produce((state: IBillingState) => {
            state.billing.projectBillingPlan = billingPlanName;
          }),
          false,
          "billing/setProjectBillingPlan/success",
        );
      }

      return data?.SetProjectBillingPlan as ISetProjectBillingPlanResponse;
    },
    setupOrganizationBilling: async ({ organizationId }) => {
      const { data } = await client.mutate({
        mutation: m_CreateCheckoutSession,
        variables: {
          input: {
            organizationId,
          },
        },
      });
      return data?.CreateCheckoutSession as ICreateCheckoutSessionResponse;
    },
    resetQuotaOverviews: () => {
      set(
        produce((state: IBillingState) => {
          state.billing.quotaOverviews = [];
        }),
        false,
        "billing/resetQuotaOverviews",
      );
    },
    validateProjectDiscountCode: async ({
      discountCode,
      billingPlanName,
      projectId,
    }: IValidateProjectDiscountCodeRequestInput) => {
      set(
        produce((state) => {
          state.billing.promoCodeStatus.valid = null;
          state.billing.promoCodeStatus.discountPercentage = null;
        }),
        false,
        "billing/validateProjectDiscountCode/start",
      );
      if (discountCode == "") {
        console.log(`discountCode == "" return`);
        return;
      }

      const { data } = await client.query({
        query: query_validateProjectDiscountCode,
        variables: {
          input: {
            discountCode,
            ...(billingPlanName ? { billingPlanName } : {}),
            projectId,
          },
        },
        fetchPolicy: "no-cache",
      });

      if (data.ValidateProjectDiscountCode) {
        set(
          produce((state) => {
            state.billing.promoCodeStatus = data.ValidateProjectDiscountCode;
          }),
          false,
          "billing/validateProjectDiscountCode/end",
        );
      }
      return data?.ValidateProjectDiscountCode ?? { valid: false, discountPercentage: null };
    },
    resetProjectDiscountCode: () => {
      set(
        produce((state) => {
          state.billing.promoCodeStatus.valid = null;
          state.billing.promoCodeStatus.discountPercentage = null;
        }),
        false,
        "billing/resetProjectDiscountCode",
      );
    },
  },
});
