import { CSSProgressiveBody03Medium } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSSubSection = css`
  padding-left: 48px;
`;

const CSSDisabled = css`
  color: var(--disabled-text);
`;

export const SAddProjectModalSectionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border-top: 1px solid var(--ui-03);
  cursor: pointer;

  ${CSSProgressiveBody03Medium};
  color: var(--text-01);

  ${({ isSubSection }) => isSubSection && CSSSubSection}
  ${({ disabled }) => disabled && CSSDisabled}
`;
