import { useEffect } from "react";
import { useCurrentProjectId } from "../../hooks/current-project-id/useCurrentProjectId";
import * as Sentry from "@sentry/browser";
import { useRouter } from "next/router";
import { useAuthentication } from "../../stores/authentication/useAuthentication";
import { usePermission } from "../../stores/permission/usePermission";
import { useBlueprint } from "../../stores/blueprint/useBlueprint";
import { useTag } from "../../stores/tag/useTag";
import { useDocumentLocale } from "../../stores/document-locale/useDocumentLocale";
import { useMemberShip } from "../../stores/membership/useMembership";
import { useDocument } from "../../stores/document/useDocument";
import { useProjectOnboarding } from "../../stores/project-onbarding/useProjectOnboarding";
import { useBilling } from "../../stores/billing/useBilling";
import { useProjectQuota } from "../../stores/project-quota/useProjectQuota";
import { useSecurityContext } from "../../services/security";
import { PERMISSION, PERMISSION_ACTION } from "../../services/security/constant";

export const ListenerCurrentProject = () => {
  const { userId } = useAuthentication();
  const { projects } = useMemberShip();
  const { can } = useSecurityContext();
  const { loadPermissions, isLoading: loadingPermissions } = usePermission();
  const projectId = useCurrentProjectId();
  const router = useRouter();
  const { loadAllBlueprints, resetBlueprint } = useBlueprint();
  const { getProjectByIdWithInheritance } = useMemberShip();
  const { loadAllTags, resetTag } = useTag();
  const { loadLocales, resetDocumentLocale } = useDocumentLocale();
  const { getProjectOnboarding, resetProjectOnboardingLocal } = useProjectOnboarding();
  const { getProjectBillingStatus } = useBilling();
  const organizationId = projects?.[projectId]?.organizationId;
  // const { loadStatuses, resetStatus } = useDocumentStatus();
  const { resetDocument } = useDocument();
  const { reset: resetQuota, getProjectQuotaPlan, getAllAmounts } = useProjectQuota();

  useEffect(() => {
    (async () => {
      if (projectId && projectId != "undefined" && userId) {
        await loadPermissions({ projectId, userId });
      }
    })();
  }, [projectId, userId]);

  useEffect(() => {
    if (!organizationId || !projectId || loadingPermissions) return;

    if (can(PERMISSION_ACTION.READ, PERMISSION.BILLING)) {
      getProjectBillingStatus({ organizationId, projectId });
    }
  }, [organizationId, projectId, loadingPermissions]);

  useEffect(() => {
    if (projectId) {
      if (projectId == "undefined") {
        router.push(`/profile/membership`);
      }

      if (!globalThis?._caisyClientState) {
        globalThis._caisyClientState = {};
      }

      globalThis._caisyClientState.projectId = projectId;
      // console.log(` projectId refetch`, projectId);
      try {
        // const organizationRes = await client.query({
        //   query: organizationById,
        //   variables: {
        //     projectId,
        //   },
        // });
        // setActiveProject(organizationRes?.data?.Organization ?? {});
        resetQuota();
        resetBlueprint();
        resetTag();
        // resetStatus();
        resetDocument();
        resetProjectOnboardingLocal();
        resetDocumentLocale();
        getProjectByIdWithInheritance({ projectId });
        getProjectQuotaPlan({ projectId });
        getAllAmounts({ projectId });
        loadAllBlueprints({ projectId });
        loadAllTags({ projectId });
        loadLocales({ projectId });
        getProjectOnboarding({ projectId, locale: router.locale });
        getProjectQuotaPlan({ projectId });
        // loadStatuses({ projectId });
        // setCurrentOrganizationMembers(organizationRes?.data?.Organization.members ?? []);
        // useOrganizations.getState().setActiveOrganization(projectId);
      } catch (err) {
        if (`${err.toString()}`.includes("User is not permitted") && typeof window !== "undefined") {
          router.push(`/profile/membership`);
          return;
        }

        console.error(` err`, err);
        Sentry.captureException(err);
      }
    }
  }, [projectId]);

  return null;
};
