import gql from "graphql-tag";

export const fragment_GroupResponse = gql`
  fragment fragment_GroupResponse on GroupResponse {
    alias
    createdAt
    description
    groupId
    logoAssetUrl
    name
    organizationId
    updatedAt
    roleByUser {
      title
    }
  }
`;
