import React from "react";
import { useSecurityContext } from "./SecurityProvider";
import { Spinner } from "../../components/atom/Spinner";
import { SpinnerWrapperFullWidthCenter } from "../../components/atom/Spinner/wrapper/fullWidthCenter";
import { FCWithChildren } from "../../interfaces/FCWithChildren";

export const Secured: FCWithChildren<{
  action: string;
  entity: string;
  pageLoader?: boolean;
}> = ({ action, entity, pageLoader, children }) => {
  const { user, isLoading, can } = useSecurityContext();
  if (pageLoader && (!user || isLoading)) {
    // style={{ padding: `20% 50%` }}
    return (
      <SpinnerWrapperFullWidthCenter>
        <Spinner>Loading...</Spinner>
      </SpinnerWrapperFullWidthCenter>
    );
  }

  return <>{can(action, entity) ? <>{children}</> : null}</>;
};
