export const templateFormatErr = new Error("templateFormat: input or params is missing");

export const templateFormat = (input: string, params: string[] | any): string => {
  if (!input || !params) throw templateFormatErr;

  if (Array.isArray(params)) {
    return input.replace(/{(\d+)}/g, function (match, number) {
      return typeof params[number] != "undefined" ? params[number] : match;
    });
  }

  // legacy support of ${var} format, we will deprecate this in the future
  if (input.includes("${")) {
    return input.replace(/\${(\w+)}/g, function (match, key) {
      return typeof params[key] != "undefined" ? params[key] : match;
    });
  }

  return input.replace(/{{(\w+)}}/g, function (match, key) {
    return typeof params[key] != "undefined" ? params[key] : match;
  });
};
