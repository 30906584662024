import { Button, IconDocuments } from "@caisy/league";
import { SCreateProjectCard } from "./styles/SCreateProjectCard";
import { SCreateProjectCardIcon } from "./styles/SCreateProjectCardIcon";
import { SCreateProjectCardBody } from "./styles/SCreateProjectCardBody";
import { SCreateProjectCardTitle } from "./styles/SCreateProjectCardTitle";
import { SCreateProjectCardDescription } from "./styles/SCreateProjectCardDescription";
import { FC } from "react";
import { SCreateProjectCardIndicator } from "./styles/SCreateProjectCardIndicator";
import { I18n } from "../../../../../provider/i18n";

interface ICreateProjectCardBase {
  onCancel: () => void;
}

interface ICreateProjectCardWithSource extends ICreateProjectCardBase {
  fromExistingProject?: boolean;
  sourceProject?: { projectName: string; groupName?: string; organizationName: string };
}

interface ICreateProjectCardBlank extends ICreateProjectCardBase {
  fromExistingProject?: never;
  sourceProject?: never;
}

type ICreateProjectCard = ICreateProjectCardWithSource | ICreateProjectCardBlank;

export const CreateProjectCard: FC<ICreateProjectCard> = ({ onCancel, fromExistingProject, sourceProject }) => {
  return (
    <SCreateProjectCard>
      {fromExistingProject ? (
        <SCreateProjectCardIndicator>
          <I18n selector="nav.createProject.sourceProject" fallback="SOURCE PROJECT" />
        </SCreateProjectCardIndicator>
      ) : null}
      <SCreateProjectCardIcon>
        <IconDocuments size={24} />
      </SCreateProjectCardIcon>
      <SCreateProjectCardBody>
        <SCreateProjectCardTitle>
          {fromExistingProject ? (
            sourceProject.projectName
          ) : (
            <I18n fallback="New empty project" selector="nav.createProject.newEmptyProject" />
          )}
        </SCreateProjectCardTitle>
        <SCreateProjectCardDescription>
          {fromExistingProject ? (
            sourceProject.groupName ? (
              `${sourceProject.organizationName} / ${sourceProject.groupName}`
            ) : (
              `${sourceProject.organizationName}`
            )
          ) : (
            <I18n
              fallback="A new empty project will be created"
              selector="nav.createProject.newEmptyProjectDescription"
            />
          )}
        </SCreateProjectCardDescription>
      </SCreateProjectCardBody>
      <Button onClick={onCancel} type="tertiary">
        {fromExistingProject ? (
          <I18n fallback="CHANGE SOURCE PROJECT" selector="nav.createProject.changeSourceProject" />
        ) : (
          <I18n fallback="CHANGE" selector="nav.createProject.change" />
        )}
      </Button>
    </SCreateProjectCard>
  );
};
