import { CSSProgressiveBody02Medium } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSFinished = css`
  color: var(--text-01);
`;

export const SDuplicateProjectProgressModalProgress = styled.div`
  ${CSSProgressiveBody02Medium};
  margin: auto;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  color: var(--text-03);

  ${({ finished }) => finished && CSSFinished}
`;
