import request from "graphql-request";
import produce from "immer";

export interface IUseEntrypoint {
  logoFull: string | null;
  logoSquare: string | null;
  domain: string | null;
  foundDomain: boolean | null;
  authenticationService: string | null;
  setDomain: (domain: string) => void;
  load: () => Promise<void>;
}

export interface IEntrypointState {
  entrypoint: IUseEntrypoint;
}

export const createEntrypointSlice = (
  set: (cb: (state: IEntrypointState) => IEntrypointState, boolean, string) => void,
  get: () => IEntrypointState,
) => ({
  entrypoint: {
    logoFull: null,
    logoSquare: null,
    domain: null,
    foundDomain: false,
    authenticationService: null,
    setDomain: (domain: string) =>
      set(
        produce((state) => {
          state.entrypoint.domain = domain;
        }),
        false,
        "entrypoint/setDomain",
      ),
    load: async () => {
      const query = `
        query GetEntrypointByDomain($domain: String!) {
          GetEntrypointByDomain(input: {domain: $domain}){
            entrypoint{
              data
            }
          }
        }
      `;
      let data: any = {};
      try {
        const r: any = await request(`${process.env.CORE_URL || ""}/caisy/graphql`, query, {
          domain: get().entrypoint?.domain || "caisy.io",
        });
        data = {
          entrypoint: r?.GetEntrypointByDomain.entrypoint.data,
        };
      } catch (e) {
        console.info("no entrypoint found");
      }

      const logoFull =
        data?.entrypoint?.logoFull ||
        "https://assets.caisy.io/asset/1bcdc513-e692-4fe8-abaa-21ca6335036c/e5cb239884df56d2897e9bb37d16625d/CAISY_FULL.svg";
      const logoSquare =
        data?.entrypoint?.logoSquare ||
        "https://caisy.io/assets/e8a6853a-1d3f-4e33-941f-2b47d8d5e886/dslVTAqVZk/7e9b617a-5eaa-4e3e-9eda-d4a02e1c87eaLogo.svg";
      const authenticationService = data?.entrypoint?.authenticationService || null;
      // const theme = data?.entrypoint?.theme || null ;
      return set(
        produce((state) => {
          state.entrypoint.logoFull = logoFull;
          state.entrypoint.logoSquare = logoSquare;
          state.entrypoint.authenticationService = authenticationService;
          state.entrypoint.foundDomain = !!data?.entrypoint;
        }),
        false,
        "entrypoint/load",
      );
    },
  },
});
