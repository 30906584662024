import { FC, Fragment, useMemo } from "react";
import {
  IconAssetsImage,
  IconCalendar,
  IconCog,
  IconDocumentsComponents,
  IconSchemas,
  IconTerminal,
  MainMenuItem,
  Tooltip,
} from "@caisy/league";
import { I18n } from "../../../../provider/i18n";
import { useSecurityContext } from "../../../../services/security";
import { SMainMenuUpperNav } from "./styles/SMainMenuUpperNav";
import { PERMISSION, PERMISSION_ACTION } from "../../../../services/security/constant";
import IconHome from "./IconHome";
import { CLink } from "../../../molecule/c-link/CLink";
import { SMainMenuItemText } from "./styles/SMainMenuItemText";
import { SMainMenuItemIconTextHolder } from "./styles/SMainMenuItemIconTextHolder";
import useExpandableNavigation from "../../../../hooks/useExpandableNavigation";

const MENU = [
  {
    path: "documents/document",
    permission: [PERMISSION_ACTION.READ, PERMISSION.DOCUMENTS],
    tooltip: <I18n selector="nav.nav_items_content" fallback="Content" />,
    checkNameActive: "documents",
    icon: IconDocumentsComponents,
    className: "ob-21c",
  },
  {
    path: "assets/masonry",
    permission: [PERMISSION_ACTION.READ, PERMISSION.ASSETS],
    tooltip: <I18n selector="nav.nav_items_assets" fallback="Assets" />,
    checkNameActive: "assets",
    icon: IconAssetsImage,
    className: "ob-22c",
  },
  {
    path: "releases",
    permission: [PERMISSION_ACTION.READ, PERMISSION.RELEASE],
    tooltip: <I18n selector="nav.nav_items_releases" fallback="Releases" />,
    checkNameActive: "release",
    icon: IconCalendar,
    className: "ob-23c",
  },
  {
    path: "blueprints/document",
    permission: [PERMISSION_ACTION.UPDATE, PERMISSION.BLUEPRINT],
    tooltip: <I18n selector="nav.nav_items_blueprints" fallback="Blueprints" />,
    checkNameActive: "blueprint",
    icon: IconSchemas,
    className: "ob-24c",
  },
  {
    path: "playground",
    permission: [PERMISSION_ACTION.READ, PERMISSION.PLAYGROUND],
    tooltip: <I18n selector="nav.nav_items_playground" fallback="Playground" />,
    checkNameActive: "playground",
    icon: IconTerminal,
    className: "ob-25c",
  },
  {
    path: "settings/members",
    permission: [PERMISSION_ACTION.UPDATE, PERMISSION.PROJECT],
    tooltip: <I18n selector="nav.nav_items_setting" fallback="Setting" />,
    checkNameActive: "settings/",
    icon: IconCog,
    className: "ob-26c",
  },
  {
    path: "home",
    permission: [PERMISSION_ACTION.READ, PERMISSION.BILLING],
    tooltip: <I18n selector="nav.nav_items_home" fallback="Home" />,
    checkNameActive: "home",
    icon: IconHome,
    className: "ob-34c",
  },
];

const MainMenuItems: FC<{
  projectId: string;
  asPath: string;
}> = ({ projectId, asPath }) => {
  const { can } = useSecurityContext();
  const { expanded } = useExpandableNavigation();

  const menu = useMemo(() => {
    return MENU.filter((item) => can(item.permission[0], item.permission[1])).map(
      ({ path, icon: Icon, checkNameActive, tooltip, className }, index) => {
        const active = !!asPath?.includes(checkNameActive);
        return (
          <Fragment key={"main_menu_item_" + index}>
            <Tooltip disableTriangle color="black" content={tooltip} placement="right" delay={0} disabled={expanded}>
              <div className={className}>
                <CLink path={`/project/${path}`} params={{ projectId }}>
                  <SMainMenuItemIconTextHolder activated={active}>
                    <MainMenuItem activated={active}>
                      <Icon size={20} />
                    </MainMenuItem>

                    <SMainMenuItemText expanded={expanded} activated={active}>
                      {tooltip}
                    </SMainMenuItemText>
                  </SMainMenuItemIconTextHolder>
                </CLink>
              </div>
            </Tooltip>
          </Fragment>
        );
      },
    );
  }, [can, projectId, asPath, expanded]);

  return <SMainMenuUpperNav>{projectId ? <>{menu}</> : null}</SMainMenuUpperNav>;
};

export default MainMenuItems;
