import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCurrentProjectId } from "../../hooks/current-project-id/useCurrentProjectId";
import { useProjectOnboarding } from "../../stores/project-onbarding/useProjectOnboarding";
import { OnboardingProjectPostCard } from "../../components/organism/onboarding-tour/OnboardingTourCard/OnboardingProjectPostCard";
import { SOnboardingTourCardPointerHighlight } from "../../components/organism/onboarding-tour/OnboardingTourCard/styles/SOnboardingTourCardPointerHighlight";
import { Z_INDEX } from "../../constants/z-index-numbers";
import { Popover } from "@caisy/league";
import { getElementByXpath } from "../../components/organism/onboarding-tour/OnboardingTourCard/OnboardingTourCardPointer";
import { OnboardingTourCardPointerWithClick } from "../../components/organism/onboarding-tour/OnboardingTourCard/OnboardingTourCardPointerWithClick";
import { useUserOnboarding } from "../../stores/user-onboarding/useUserOnboarding";
import { IOnboardingStatus } from "../../interfaces/generated";

export const BACK_HOME_MODAL_KEY = "onboarding_back_home_modal";

const SKIP_BACK_HOME_MODAL = 100001;

export const ListenerProjectOnboarding = () => {
  const { projectOnboarding } = useProjectOnboarding();
  const {
    completedEvents: userOnboardingCompletedEvents,
    status: userOnboardingStatus,
    setUserOnboardingEventCompleted,
  } = useUserOnboarding();
  const currentProjectId = useCurrentProjectId();
  const [modal, setModal] = useState<any>(null);

  const xpath = `//*[contains(@class, 'ob-34c')]`;

  const ref = useRef();
  const [element, setElement] = useState(getElementByXpath(xpath));

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function updateDimension() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  const elPosition = useMemo(() => {
    if (element) return (element as Element).getBoundingClientRect();

    return null;
  }, [element, dimensions]);

  const cleanup = useCallback(() => {
    setModal(null);
    sessionStorage.removeItem(BACK_HOME_MODAL_KEY);
  }, [setModal]);

  useEffect(() => {
    if (!element) {
      return;
    }

    (element as Element).addEventListener("click", cleanup);

    return () => {
      element && element.removeEventListener("click", cleanup);
    };
  }, [element]);

  useEffect(() => {
    const element = getElementByXpath(xpath);

    if (element) {
      setElement(element);
      return;
    }
  }, [xpath, modal]);

  useEffect(() => {
    if (
      userOnboardingCompletedEvents?.includes(SKIP_BACK_HOME_MODAL) ||
      userOnboardingStatus === IOnboardingStatus.Completed
    ) {
      return;
    }
    const text = sessionStorage.getItem(BACK_HOME_MODAL_KEY);
    if (text) {
      const { projectId, modal, identifier } = JSON.parse(text);
      if (projectId === currentProjectId && projectOnboarding.completedEvents.includes(identifier)) {
        setModal(modal);
      }
    }
  }, [projectOnboarding, currentProjectId, userOnboardingStatus, userOnboardingCompletedEvents]);

  if (!modal || !elPosition) return null;

  const { height, width, top, left } = elPosition;

  return (
    <div>
      <OnboardingTourCardPointerWithClick elementPosition={elPosition} />
      <Popover zIndex={Z_INDEX.ONBOARDING_TOUR_POPOVER} reference={ref} display placement={"right"}>
        <div>
          <OnboardingProjectPostCard
            title={modal.title || ""}
            onClose={cleanup}
            buttonText={modal.buttonText || ""}
            onButtonClick={() => {
              setUserOnboardingEventCompleted({ eventCode: SKIP_BACK_HOME_MODAL });
              cleanup();
            }}
            text={modal.body}
          />
        </div>
      </Popover>
      <SOnboardingTourCardPointerHighlight
        ref={ref}
        disableBoxShadow={true}
        style={{
          height,
          width,
          top,
          left,
          pointerEvents: "none",
        }}
      />
    </div>
  );
};
