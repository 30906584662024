import { CSSProgressiveHeading02 } from "@caisy/league";
import styled from "styled-components";

export const SSettingsTopNavBreadcrumb = styled.div`
  ${CSSProgressiveHeading02};
  color: var(--text-01);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
