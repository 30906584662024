import { CSSProgressiveBody03Medium } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSSubsection = css`
  padding-left: 32px;
`;

export const SDuplicateProjectProgressModalItem = styled.div`
  height: 40px;
  ${CSSProgressiveBody03Medium};
  color: var(--text-01);

  display: flex;
  align-items: center;
  gap: 12px;

  ${({ isSubsection }) => isSubsection && CSSSubsection}
`;
