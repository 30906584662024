import produce from "immer";
import isObject from "lodash/isObject";
import cloneDeepWith from "lodash/cloneDeepWith";
import cloneDeep from "lodash/cloneDeep";
import { createView } from "../../graphql/mutations/createView.gql";
import { deleteView } from "../../graphql/mutations/deleteView.gql";
import { createPinView, deletePinView } from "../../graphql/mutations/pinView.gql";
import { updateView } from "../../graphql/mutations/updateView.gql";
import { getManyViews } from "../../graphql/queries/getManyViews.gql";
import {
  ICreateViewPinRequestInput,
  ICreateViewRequestInput,
  IDeleteViewRequestInput,
  IGetManyViewsRequestInput,
  IUpdateViewRequestInput,
  IViewResponse,
} from "../../interfaces/generated";
import { client } from "../../utils/client";
import { IUseViewState } from "./types";
import { message } from "@caisy/league";
import { I18n } from "../../provider/i18n";

const omitSchema = (collection) => {
  const clonedCollection = JSON.parse(JSON.stringify(collection));
  return cloneDeepWith(clonedCollection, (value: { hasChildren?: boolean }) => {
    if (isObject(value)) {
      if (value.hasChildren || value.hasChildren === false) {
        delete value.hasChildren;
      }
    }
  });
};

export const createViewSlice = (
  set: (cb: (state: IUseViewState) => IUseViewState, replace: boolean, name: string) => void,
  get: () => IUseViewState,
) => ({
  view: {
    views: [] as IViewResponse[],
    loading: true,
    newView: {} as IViewResponse,
    getManyViews: async (input: IGetManyViewsRequestInput) => {
      set(
        produce((state) => {
          state.view.loading = true;
        }),
        false,
        "view/getManyViews/start",
      );

      const [resGetMany] = await Promise.all([
        client.query({
          query: getManyViews,
          variables: {
            input,
          },
          fetchPolicy: "no-cache",
        }),
      ]);

      set(
        produce((state) => {
          const views = resGetMany?.data?.GetManyViews?.views;
          state.view.loading = false;
          state.view.views = views;
          state.view.newView = views;
        }),
        false,
        "view/getManyViews/done",
      );
    },
    createView: async (input: ICreateViewRequestInput, inputPin?: ICreateViewPinRequestInput) => {
      const isSection = !input?.view?.query;
      try {
        if (input.view) {
          const { parentId = null, userId, target } = input.view;

          const privat = userId != null;

          const views = get().view.views;

          let position = views.filter(
            (view) => view.parentId === parentId && view.privat == !!privat && view.target === target,
          )?.length;

          // this ensures that the view has a unique position
          while (
            views.find(
              (view) =>
                view.position === position &&
                view.parentId === parentId &&
                view.privat == !!privat &&
                view.target === target,
            )
          ) {
            position++;
          }

          input.view.position = position;
        }

        const { data } = await client.mutate({
          mutation: createView,
          variables: {
            input,
          },
        });
        const view = data?.CreateView?.view;
        if (view) {
          set(
            produce((state) => {
              // const isSection = !!input.view.iconUrl;
              // hasChildren: isSection, isExpanded: isSection
              state.view.views = [...state.view.views, { ...data.CreateView.view }];
              state.view.newView = {
                ...data.CreateView.view,
              };
            }),
            false,
            "view/createView",
          );
          if (!!inputPin) {
            get().view.updatePinView({ ...inputPin, viewId: view.id }, view);
          }
        }

        message.success(
          isSection ? (
            <I18n
              selector="documentOverviewView.view_msg_section_created_successful"
              fallback="Section created successfully."
            />
          ) : (
            <I18n
              selector="documentOverviewView.view_msg_view_created_successful"
              fallback="View created successfully."
            />
          ),
        );
      } catch (err) {
        if (err.toString().includes("ResourceExhausted")) {
          message.error(
            <I18n
              selector="viewErrorMessages.viewQuota"
              fallback="Quota breached: max views the are allowed in your plan exhauseted."
            />,
            {
              duration: 5000,
            },
          );
          return;
        }
        message.error(
          isSection ? (
            <I18n selector="documentOverviewView.view_msg_section_create_failed" fallback="Section create failed." />
          ) : (
            <I18n selector="documentOverviewView.view_msg_view_create_failed" fallback="View create failed." />
          ),
          {
            duration: 3000,
          },
        );
      }
    },
    updateView: async (input: IUpdateViewRequestInput) => {
      const viewBeforeUpdate = cloneDeep(get().view.views.find((v) => v.id === input.input.id));

      const { data } = await client.mutate({
        mutation: updateView,
        variables: {
          input: omitSchema(input),
        },
      });
      const viewFromServer = data?.UpdateView?.view;

      if (viewFromServer) {
        set(
          produce((state) => {
            for (let i = 0; i < state.view.views.length; i++) {
              const view = state.view.views[i];
              // if its the new view, update it
              if (view.id === viewFromServer.id) {
                state.view.views[i] = viewFromServer;
              }
              // for all the views that where in the same parent before and have a position greater than the old view, decrease their position by 1
              if (
                view.parentId === viewBeforeUpdate.parentId &&
                view.privat == !!viewBeforeUpdate.privat &&
                view.target === viewBeforeUpdate.target &&
                view.position > viewBeforeUpdate.position
              ) {
                state.view.views[i].position--;
              }
              // for all the views that where in the same parent after and have a position greater than the new view, increase their position by 1
              if (
                view.parentId === viewFromServer.parentId &&
                view.privat == !!viewFromServer.privat &&
                view.target === viewFromServer.target &&
                view.position >= viewFromServer.position
              ) {
                state.view.views[i].position++;
              }
            }
            state.view.newView = viewFromServer;
          }),
          false,
          "view/updateView",
        );
      }
    },
    updatePinView: async (input: ICreateViewPinRequestInput, view: IViewResponse) => {
      const { data } = await client.mutate({
        mutation: view.pinned ? deletePinView : createPinView,
        variables: {
          input,
        },
      });
      const success = data?.CreateViewPin?.success;
      const deleted = data?.DeleteViewPin?.deleted;
      if (success || deleted) {
        const viewPined = { ...view, pinned: success ? true : false };
        set(
          produce((state) => {
            state.view.views = [...state.view.views.filter((v) => v.id !== input.viewId), viewPined];
            state.view.newView = viewPined;
          }),
          false,
          "view/updatePinView",
        );
      }
    },
    deleteView: async (input: IDeleteViewRequestInput) => {
      const { data } = await client.mutate({
        mutation: deleteView,
        variables: {
          input,
        },
      });
      const deleted = data?.DeleteView?.deleted;
      if (deleted) {
        set(
          produce((state) => {
            state.view.views = state.view.views.filter((v) => v.id !== input.id);
          }),
          false,
          "view/deleteView",
        );
      }
    },
  },
});
