import { IPreview, OrganizationSelectListItem } from "@caisy/league";
import { IListItemSize } from "@caisy/league/src/components/organization-select-list-item/OrganizationSelectListItem";
import { FC, ReactNode } from "react";

interface IOrganizationSelectListItemWrapper {
  title?: React.ReactNode | undefined;
  label?: string | undefined;
  imageUrl?: string | undefined;
  badgeText?: string | undefined;
  itemSize?: IListItemSize;
  previewProps?: IPreview;
  onClick?: any;
  onClickSettingButton?: () => void;
  onClickSwitchButton?: () => void;
  disabledSwitchButton?: boolean;
  switchText?: string | ReactNode;
  active: boolean;
  settingTooltip?: string | ReactNode;
}

export const OrganizationSelectListItemWrapper: FC<IOrganizationSelectListItemWrapper> = ({
  title,
  label,
  itemSize,
  previewProps,
  badgeText,
  onClick,
  onClickSettingButton,
  onClickSwitchButton,
  disabledSwitchButton,
  switchText,
  settingTooltip,
  active,
}) => {
  return (
    <div>
      <OrganizationSelectListItem
        onClick={onClick}
        previewProps={previewProps}
        switchText={switchText}
        label={label}
        title={title}
        itemSize={itemSize}
        badgeText={badgeText}
        active={active}
        disabledSwitchButton={disabledSwitchButton}
        onClickSettingButton={onClickSettingButton}
        onClickSwitchButton={onClickSwitchButton}
        settingTooltip={settingTooltip}
      />
    </div>
  );
};
