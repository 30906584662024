import { IImageMetadata } from "../../../stores/upload/types";
import { getAuthToken } from "../../../utils/client";
import { encode } from "blurhash";

export function steppedResize(src, width, step, cb: (data: any, meta: IImageMetadata) => any) {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    steppedScale(img, width, step, (data, color, blurHash) => {
      // console.log(` blurHash`, blurHash);
      cb(data, { dominantColor: color, width: img.width, height: img.height, blurHash });
    });
  };
  img.src = src;
}

export function steppedScale(img, width, step, cb: (data: any, color: string, blurHash: string) => any) {
  const canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d"),
    oc = document.createElement("canvas"),
    octx = oc.getContext("2d");

  canvas.width = width; // destination canvas size
  canvas.height = (canvas.width * img.height) / img.width;

  if (img.width * step > width) {
    // For performance avoid unnecessary drawing
    const mul = 1 / step;
    let cur = {
      width: Math.floor(img.width * step),
      height: Math.floor(img.height * step),
    };

    oc.width = cur.width;
    oc.height = cur.height;

    octx.drawImage(img, 0, 0, cur.width, cur.height);

    while (cur.width * step > width) {
      cur = {
        width: Math.floor(cur.width * step),
        height: Math.floor(cur.height * step),
      };
      octx.drawImage(oc, 0, 0, cur.width * mul, cur.height * mul, 0, 0, cur.width, cur.height);
    }

    ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
  } else {
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  }

  let blurHash = "";
  try {
    let count = 0;
    let i = 0;
    const rgb = {
      r: 0,
      g: 0,
      b: 0,
    };
    const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
    console.time("blurhash");
    blurHash = encode(data.data, data.width, data.height, 4, 4);
    console.timeEnd("blurhash");
    const length = data.data.length;

    while ((i += 5 * 4) < length) {
      count++;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    cb(canvas.toDataURL(), rgbToHex(rgb), blurHash);
  } catch (e) {
    cb(canvas.toDataURL(), "", blurHash);
  }
}

export function downloadCORS(url, cb: (data: any) => any) {
  getAuthToken()
    .then((token) => {
      // console.log(` token`, token);

      const headers = {
        "x-caisy-token": token,
        "x-caisy-organization-id": globalThis?._caisyClientState?.projectId,
      };

      // console.log(` headers`, headers);

      return fetch(url, {
        headers,
      });
    })
    .then((response) => {
      // console.log(` response`, response);

      return response.blob();
    })
    .then((blob) => {
      // Create a new FileReader innstance
      const reader = new FileReader();

      // Add a listener to handle successful reading of the blob
      reader.addEventListener("load", () => {
        // const image = new Image;

        // Set the src attribute of the image to be the resulting data URL
        // obtained after reading the content of the blob
        // (image as any).src = reader.result;
        cb(reader.result);
        // document.body.appendChild(image);
      });

      // Start reading the content of the blob
      // The result should be a base64 data URL
      reader.readAsDataURL(blob);
    });

  // let img = new Image();
  // img.crossOrigin = "Anonymous";

  // img.onload = function () {
  //   let canvas = document.createElement('canvas');
  //   let ctx = canvas.getContext("2d");

  //   canvas.width = img.width; // destination canvas size
  //   canvas.height = img.height;

  //   ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  //   cb(canvas.toDataURL())
  // }

  // img.src = url;
}

export function dataURLtoFile(dataurl, filename) {
  // eslint-disable-next-line no-var
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex({ r, g, b }) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
