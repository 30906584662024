import gql from "graphql-tag";

export const createGroup = gql`
  mutation Mutation(
    $organizationId: String
    $group: GroupInputInput
    $userId: String
  ) {
    CreateGroup(
      input: {
        organizationId: $organizationId
        group: $group
        userId: $userId
      }
    ) {
      group {
        alias
        createdAt
        description
        groupId
        logoAssetUrl
        name
        organizationId
        roleByUser {
          title
          __typename
        }
        updatedAt
        __typename
      }
    }
  }
`;
