import { client } from "../../utils/client";
import hash from "object-hash";

// const getDataHash = (lookUpKey: string) => {
//   return localStorage.getItem(lookUpKey);
// };
// const setDataHash = ({ lookUpKey, dataHash }: { lookUpKey: string; dataHash: string }) => {
//   return localStorage.setItem(lookUpKey, dataHash);
// };

interface IQueryWithStale {
  uniquePrefix: string;
  query: any;
  variables: any;
  onUpdate: (update: { data: any; dataHash: string; stale: boolean }) => void;
  onCacheMiss: () => void;
}

/*
 * we check if the query + variables are in the cache first
 * if they are some we trigger the onUpdate callback saying that we got stale data
 * so onUpdate will be called twice if we find something in the cache and we get different data afterwards from the server query
 */
export const queryWithStaleCache = async ({
  // uniquePrefix,
  query,
  variables,
  onUpdate,
  onCacheMiss,
}: IQueryWithStale) => {
  /*
   *   we could also use the query here instead of a custom uniquePrefix to ensure uniqueness but that would make debugging harder
   *   this way can give the key a speaking name and can find it in the local storage to debug if needed
   */
  // const lookUpKey = uniquePrefix + hash(variables);
  // const existingDataHash = getDataHash(lookUpKey);
  // let hit = false;

  // if (existingDataHash) {
  //   const staleDataDataString = localStorage.getItem(existingDataHash);
  //   if (staleDataDataString) {
  //     await onUpdate({ data: JSON.parse(staleDataDataString), stale: true, dataHash: existingDataHash });
  //     hit = true;
  //   }
  // }

  // if (!hit) {
  onCacheMiss();
  // }

  const { data } = await client.query({
    query,
    variables,
  });

  const dataHash = hash(data);

  // if (existingDataHash !== dataHash) {
  await onUpdate({ data, stale: false, dataHash });
  // localStorage.setItem(dataHash, JSON.stringify(data));
  // setDataHash({ lookUpKey, dataHash });
  // }
};
