import gql from "graphql-tag";

export const getDocumentSnapshot = gql`
  query getDocumentSnapshot($input: GetDocumentSnapshotRequestInput) {
    __typename
    GetDocumentSnapshot(input: $input) {
      document {
        archivedAt
        blueprintBranch
        createdAt
        blueprintId
        documentId
        createdByUserId
        environmentId
        fields {
          blueprintFieldId
          createdAt
          lastUpdatedByUserId
          data
          documentFieldLocaleId
          type
          updatedAt
        }
        lastUpdatedByUserId
        previewImageUrl
        projectId
        publishedAt
        statusId
        title
        updatedAt
      }
    }
  }
`;
