import { HttpLink, ApolloLink, ApolloClient, InMemoryCache } from "@apollo/client";
// import { SubscriptionClient } from "subscriptions-transport-ws";
// import { WebSocketLink } from "@apollo/client/link/ws";
import { getAuthToken } from "./client";
import { setContext } from "@apollo/client/link/context";

let subClientCache: any;
export const getSubClient = () => {
  if (typeof window !== "undefined") {
    if (subClientCache) {
      return subClientCache as ApolloClient<any>;
    } else {
      const url =
        process.env.CORE_URL && process.env.CORE_URL != ""
          ? process.env.CORE_URL
          : `https://${window.location.hostname}`;

      // const wsEndpoint = `${url}/caisy/graphql`.replace("http", "ws").replace("https", "wss");
      const httpEndPoint = `${url}/caisy/graphql`;
      //
      // const subClient = new SubscriptionClient(wsEndpoint, {
      //   timeout: 10000,
      //   reconnect: true,
      //   connectionCallback: (err) => {
      //     if (err) {
      //       console.log(`Error connecting to Websocket ${JSON.stringify(err)}`);
      //     }
      //   },
      // });
      //
      // const wsLink = new WebSocketLink(subClient);

      const createApolloV2 = () => {
        const httpLink = new HttpLink({
          uri: httpEndPoint,
          // @ts-ignore
          headers: async () => {
            return {
              "x-caisy-token": (await getAuthToken()) as string,
            };
          },
        });

        const authLink = setContext(async (_, { headers }) => {
          if (!headers) {
            headers = {};
          }
          const token = await getAuthToken();
          if (token) {
            headers["x-caisy-token"] = token;
          }
          return {
            headers: {
              ...headers,
            },
          };
        });

        const httpComb = ApolloLink.from([authLink, httpLink]);

        // const link = split(
        //   ({ query }) => {
        //     const def = getMainDefinition(query);
        //     return def.kind === "OperationDefinition" && def.operation == "subscription";
        //   },
        //   wsLink,
        //   httpComb,
        // );
        const link = httpComb;
        // const AuthLink = new ApolloLink((operation, forward) => {
        //   wsLink.request(operation);
        //   operation.setContext(async (context) => {
        //     return {
        //       headers: {
        //         ...context.headers,
        //       },
        //     };
        //   });
        //   const subscription = forward(operation);
        //   return subscription;
        // });

        const client = new ApolloClient({
          cache: new InMemoryCache(),
          link,
          // link: AuthLink.concat(link),
        });

        return client;
      };
      subClientCache = createApolloV2();
      return subClientCache as ApolloClient<any>;
    }
  }
};
