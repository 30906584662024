import styled from "styled-components";

export const SCreateProjectModalNavSteps = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--ui-01);
  padding: 2px;
`;
