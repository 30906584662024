import {
  AssetListItem,
  AssetListItemTextWrapper,
  IconCheckmarkOutlined,
  IconCross,
  IconDocuments,
  IconWarning,
  Preview,
  SAssetListItemDescription,
  SAssetListItemTitle,
  SFlexListItem,
  SImagePlaceholder,
} from "@caisy/league";
import { useMemo } from "react";
import { EFileStatus } from "../../../stores/upload/types";
import { FileUploadProgressBar } from "./FileUploadProgressBar";
import SStyledIconWrapper from "./Styles/SStyledIconWrapper";

export const FileUploadAssetListItem = ({ upload, iconWidth, handleRemoveUpload, calculatedRef }) => {
  const fileType = useMemo(() => {
    if (!upload.url) return;

    const splitURL = upload.url.split(".");

    const type = splitURL[splitURL.length - 1];

    return type.toUpperCase();
  }, [upload.url]);

  return (
    <AssetListItem error={upload.status === EFileStatus.ERROR}>
      <SFlexListItem error={upload.status === EFileStatus.ERROR} iconWidth={iconWidth}>
        {
          <>
            {upload.dataURL ? (
              <Preview
                styleOverwrite={{ overflow: "hidden" }}
                size={40}
                image={
                  <img src={upload.dataURL} className={upload.status === EFileStatus.SUCCESS ? "with-pointer" : ""} />
                }
              />
            ) : (
              <SImagePlaceholder error={upload.status === EFileStatus.ERROR}>
                <IconDocuments></IconDocuments>
              </SImagePlaceholder>
            )}
          </>
        }
        <AssetListItemTextWrapper progressValue={undefined}>
          <SAssetListItemTitle style={{ maxWidth: 249 }} error={upload.status === EFileStatus.ERROR}>
            {upload.name}
          </SAssetListItemTitle>
          {!(upload.status === EFileStatus.UPLOADING) ? (
            <SAssetListItemDescription progressValue={undefined} error={upload.status === EFileStatus.ERROR}>
              {fileType}
            </SAssetListItemDescription>
          ) : (
            <FileUploadProgressBar progress={upload.progress} />
          )}
        </AssetListItemTextWrapper>
      </SFlexListItem>
      <SStyledIconWrapper error={upload.status === EFileStatus.ERROR} ref={calculatedRef}>
        {upload.status === EFileStatus.SUCCESS && <IconCheckmarkOutlined size={20}></IconCheckmarkOutlined>}
        {upload.status === EFileStatus.ERROR && <IconWarning size={20}></IconWarning>}
        <div style={{ cursor: "pointer" }} onClick={() => handleRemoveUpload(upload.url)}>
          <IconCross variant={"close"} size={20}></IconCross>
        </div>
      </SStyledIconWrapper>
    </AssetListItem>
  );
};
