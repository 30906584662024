import gql from "graphql-tag";

export const getManyTags = gql`
  query GetManyTags($input: GetManyTagsRequest) {
    GetManyTags(input: $input) {
      connection {
        edges {
          cursor
          node {
            color
            name
            referenceType
            tagId
            projectId
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;
