import React, { useState, useEffect, createContext } from "react";
import { Spinner } from "../../atom/Spinner";
import { SpinnerWrapperFullWidthCenter } from "../../atom/Spinner/wrapper/fullWidthCenter";
import firebase from "firebase/app";
import "firebase/auth";
import { useAuthentication } from "../../../stores/authentication/useAuthentication";
import { FCWithChildren } from "../../../interfaces/FCWithChildren";

const config = {
  authDomain: process.env.NEXT_PUBLIC_FIRE_AUTHDOMAIN,
  apiKey: process.env.NEXT_PUBLIC_FIRE_APIKEY,
};

if (!firebase?.apps?.length) {
  firebase.initializeApp(config);
}

if (typeof window !== "undefined") {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
}

const AuthContext = createContext<{ loading: boolean }>({
  loading: null,
});

export const AuthProvider: FCWithChildren = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { checkLogin } = useAuthentication();

  useEffect(() => {
    firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        if (token) {
          if (typeof window !== undefined) {
            localStorage.setItem("accounts:accessToken", token);
            localStorage.setItem("accounts:time", `${~~(Date.now() / 1000)}`);
          }
          setLoading(false);
          checkLogin().then((isLoggedIn) => {
            if (isLoggedIn) {
              setLoading(false);
            }
          });
        }
      }
    });
    checkLogin().then((isLoggedIn) => {
      if (isLoggedIn) {
        setLoading(false);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ loading }}>
      {!loading ? (
        <>{children}</>
      ) : (
        <SpinnerWrapperFullWidthCenter>
          <Spinner></Spinner>
        </SpinnerWrapperFullWidthCenter>
      )}
    </AuthContext.Provider>
  );
};
