import gql from "graphql-tag";

export const getManyReleasesByProjectId = gql`
  query GetManyReleasesByProjectId($input: GetManyReleasesByProjectIdRequestInput) {
    __typename
    GetManyReleasesByProjectId(input: $input) {
      node {
        createdAt
        documentIds
        id
        name
        projectId
        scheduledAt
        status
        type
        updatedAt
        validationErrors {
          documentId
          errors {
            blueprintFieldId
            errorCodes
            documentFieldLocaleId
          }
        }
      }
    }
  }
`;
