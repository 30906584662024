import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IGenUserOnboardingTour, IGenUserOnboardingTourPointer } from "../../../interfaces/generated/i18n-types";
import { usePermission } from "../../../stores/permission/usePermission";
import { useUserOnboarding } from "../../../stores/user-onboarding/useUserOnboarding";
import { hasPermissions } from "../../../utils/hasPermissions";
import { OnboardingTourCardPointer } from "./OnboardingTourCard/OnboardingTourCardPointer";
import { OnboardingTourCardPost } from "./OnboardingTourCard/OnboardingTourCardPost";

const preventAllEvents = (e: MouseEvent, onClickOutside: () => void) => {
  e.preventDefault();
  e.stopImmediatePropagation();
  e.stopPropagation();
  onClickOutside();
};

interface IOnboardingTour {
  tour: IGenUserOnboardingTour;
}

export const INDEX_NUMBER_MULTIPLIER = 100;
export const COMPLETED_CODE_SUM = 99;

export const OnboardingTour: FC<IOnboardingTour> = ({ tour }) => {
  const { setUserOnboardingEventCompleted, completedEvents, userOnboardingTours, setUserOnboardingCompleted } =
    useUserOnboarding();
  const { permissions, isLoading: loadingPermissions } = usePermission();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isFirstPointer, setIsFirstPointer] = useState(true);
  const [showSkipPopover, setShowSkipPopover] = useState(false);
  const skipButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // new tour just startet, resetting local state
    setCurrentStepIndex(0);
    setIsFirstPointer(true);
  }, [tour]);

  const filteredTourSteps = useMemo(() => {
    if (loadingPermissions) return [];

    return tour.steps.filter((step) =>
      hasPermissions({
        permissionSet: permissions ?? [],
        permission: (step as IGenUserOnboardingTourPointer).permission,
      }),
    );
  }, [loadingPermissions, permissions, tour.steps]);

  const tourEventCode = tour.indexNumber * INDEX_NUMBER_MULTIPLIER;

  const completedTourEventCode = tourEventCode + COMPLETED_CODE_SUM;

  const stepsAmount = useMemo(() => {
    return filteredTourSteps.length;
  }, [filteredTourSteps]);

  const currentStepNumber = useMemo(() => {
    return currentStepIndex + 1;
  }, [currentStepIndex]);

  const step = useMemo(() => {
    return filteredTourSteps[currentStepIndex];
  }, [currentStepIndex, filteredTourSteps]);

  const onClickOutside = useCallback(
    (e) => {
      preventAllEvents(e, () => {
        if (skipButtonRef.current && !skipButtonRef.current.contains(e.target)) setShowSkipPopover(false);
      });
    },
    [preventAllEvents],
  );

  useEffect(() => {
    if (!completedEvents.includes(completedTourEventCode)) return document.addEventListener("mouseup", onClickOutside);

    document.removeEventListener("mouseup", onClickOutside);
  }, [completedEvents, completedTourEventCode]);

  useEffect(() => {
    if (!completedEvents.includes(tourEventCode + currentStepNumber)) return;

    let stepNumber = currentStepNumber + 1;

    while (completedEvents.includes(tourEventCode + stepNumber)) {
      stepNumber++;
    }
    setCurrentStepIndex(stepNumber - 1);
  }, [completedEvents]);

  useEffect(() => {
    if (!filteredTourSteps[currentStepIndex]) return;

    const isFirstPointer =
      filteredTourSteps[currentStepIndex].__typename === "UserOnboardingTourPointer" &&
      (!filteredTourSteps[currentStepIndex - 1] ||
        filteredTourSteps[currentStepIndex - 1].__typename === "UserOnboardingTourPost");
    setIsFirstPointer(isFirstPointer);
  }, [currentStepIndex]);

  const handleFinish = async () => {
    await setUserOnboardingEventCompleted({ eventCode: completedTourEventCode });

    const allCompletedEvents = [...completedEvents, completedTourEventCode];
    const completedAllTours = userOnboardingTours.every((tour) =>
      allCompletedEvents.includes(tour.indexNumber * INDEX_NUMBER_MULTIPLIER + COMPLETED_CODE_SUM),
    );

    if (completedAllTours) {
      setUserOnboardingCompleted();
    }
  };

  const handleSkipAll = async () => {
    await Promise.all(
      userOnboardingTours.map((tour) =>
        setUserOnboardingEventCompleted({ eventCode: tour.indexNumber * INDEX_NUMBER_MULTIPLIER + COMPLETED_CODE_SUM }),
      ),
    );

    await setUserOnboardingCompleted();
  };

  const handleNext = async () => {
    if (currentStepNumber === filteredTourSteps.length) {
      await handleFinish();
    } else {
      setUserOnboardingEventCompleted({ eventCode: currentStepNumber + tourEventCode });
      setCurrentStepIndex((prev) => prev + 1);
    }
  };

  if (!step || !step.__typename || completedEvents?.includes(completedTourEventCode) || loadingPermissions) {
    return null;
  }

  if (step.__typename === "UserOnboardingTourPost") {
    return (
      <OnboardingTourCardPost
        skipButtonRef={skipButtonRef}
        showSkipPopover={showSkipPopover}
        setShowSkipPopover={setShowSkipPopover}
        onSkipAll={handleSkipAll}
        onSkipCurrent={handleFinish}
        key={step.id}
        stepsAmount={stepsAmount}
        currentStepNumber={currentStepNumber}
        onClickNext={handleNext}
        step={step}
        guide={tour.tourGuide}
      />
    );
  }

  if (step.__typename === "UserOnboardingTourPointer") {
    return (
      <OnboardingTourCardPointer
        nextStep={tour.steps[currentStepIndex + 1]}
        skipButtonRef={skipButtonRef}
        showSkipPopover={showSkipPopover}
        setShowSkipPopover={setShowSkipPopover}
        isFirstPointer={isFirstPointer}
        onSkipAll={handleSkipAll}
        onSkipCurrent={handleFinish}
        stepsAmount={stepsAmount}
        currentStepNumber={currentStepNumber}
        onClickNext={handleNext}
        step={step}
        guide={tour.tourGuide}
      />
    );
  }

  return;
};
