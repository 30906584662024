import styled, { css } from "styled-components";

interface ISIntercomButton {
  active?: boolean;
}

const Inactive = css<ISIntercomButton>`
  opacity: 0;
  pointer-events: none;
`;

export const SIntercomButton = styled.div<ISIntercomButton>`
  ${({ active }) => (active ? "" : Inactive)};
`;
