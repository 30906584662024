import { CSSProgressiveBody02Medium } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSHover = css`
  background-color: var(--hover-ui-01);
`;

const CSSSelected = css`
  background-color: var(--active-ui-01-1);
`;

export const SSelectProjectStepListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  ${CSSProgressiveBody02Medium};
  color: var(--text-01);
  height: 48px;
  border-bottom: 1px solid var(--ui-03);
  padding: 0 16px;
  transition: background-color 150ms;
  cursor: pointer;

  :hover {
    ${CSSHover}
  }

  ${({ selected }) => selected && CSSSelected}
`;
