import React from "react";

export function IconBug() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 5.833L14.169 7.5l-1.111.764M4.166 10h2.592M13.242 10h2.592M5 2.5h-.833c-.921 0-1.667.746-1.667 1.667V5M15 17.5h.833c.921 0 1.667-.746 1.667-1.667V15M2.5 15v.833c0 .921.746 1.667 1.667 1.667H5M17.5 5v-.833c0-.921-.746-1.667-1.667-1.667H15M5 5.833L5.833 7.5l1.111.764"
      ></path>
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.37 14.167h-.741a2.5 2.5 0 01-2.5-2.5v-2.5c0-.921.746-1.667 1.667-1.667h2.407c.92 0 1.667.746 1.667 1.667v2.5a2.5 2.5 0 01-2.5 2.5z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5 14.167l.833-1.667 1.111-.764M15 14.167l-.832-1.667-1.111-.764"
      ></path>
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.667 7.5H8.334V5.833c0-.46.373-.833.833-.833h1.667c.46 0 .833.373.833.833V7.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
