import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/styles/mediaquerys";

const Bronze = css<{ expanded?: boolean }>`
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  .text {
    color: var(--text-04);
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    ${(props) => (props?.expanded ? "opacity:1;" : "opacity:0")};
  }

  svg {
    opacity: 1;
    display: block;
    transform: rotate(${(props) => (props?.expanded ? "-180deg" : "0deg")});
  }
  svg {
    color: var(--text-04);
  }
  &:hover {
    .text {
      color: var(--hover-icon-04);
    }
    svg {
      color: var(--hover-icon-04);
    }
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SExpandButton = styled.button<any>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
