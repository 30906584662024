import produce from "immer";
import { resetUserOnboarding } from "../../graphql/mutations/resetUserOnboarding.gql";
import { setUserOnboardingCompleted } from "../../graphql/mutations/setUserOnboardingCompleted.gql";
import { setUserOnboardingEventCompleted } from "../../graphql/mutations/setUserOnboardingEventCompleted.gql";
import { getUserOnboarding } from "../../graphql/queries/getUserOnboarding.gql";
import { IOnboardingStatus } from "../../interfaces/generated";
import { client } from "../../utils/client";
import { IUserOnboardingState } from "./types";

export const createUserOnboardingSlice = (
  set: (cb: (state: IUserOnboardingState) => IUserOnboardingState, replace: boolean, name: string) => void,
  get: () => IUserOnboardingState,
): IUserOnboardingState => ({
  userOnboarding: {
    userOnboardingTours: [],
    status: null,
    completedEvents: [],
    logoBlob: null,
    setLogoBlob: (newLogo) => {
      set(
        produce<IUserOnboardingState>((state) => {
          state.userOnboarding.logoBlob = newLogo;
        }),
        false,
        "userOnboarding/setLogoBlob",
      );
    },
    getUserOnboardingTour: async ({ locale }) => {
      const res = await fetch(`/app/api/v1/user-onboarding-tours/${locale}`);
      const { tours } = await res.json();

      if (!tours) return;

      set(
        produce<IUserOnboardingState>((state) => {
          state.userOnboarding.userOnboardingTours = tours.userOnboardingTours;
        }),
        false,
        "userOnboarding/getUserOnboardingTour",
      );
    },
    setUserOnboardingEventCompleted: async ({ eventCode }) => {
      if (get().userOnboarding.completedEvents.includes(eventCode)) return;

      const { data } = await client.mutate({
        mutation: setUserOnboardingEventCompleted,
        variables: {
          input: {
            eventCode,
          },
        },
      });

      if (data?.SetUserOnboardingEventCompleted.success && !get().userOnboarding.completedEvents.includes(eventCode)) {
        set(
          produce<IUserOnboardingState>((state) => {
            state.userOnboarding.completedEvents.push(eventCode);
          }),
          false,
          "userOnboarding/etUserOnboardingEventCompleted",
        );
      }
    },
    resetUserOnboardingProgress: async () => {
      await client.mutate({
        mutation: resetUserOnboarding,
      });

      set(
        produce<IUserOnboardingState>((state) => {
          state.userOnboarding.completedEvents = [];
          state.userOnboarding.status = IOnboardingStatus.NotStarted;
        }),
        false,
        "userOnboarding/resetUserOnboardingProgress",
      );
    },
    getUserOnboarding: async () => {
      const { data } = await client.query({
        query: getUserOnboarding,
        fetchPolicy: "no-cache",
      });

      if (data.GetUserOnboarding) {
        set(
          produce<IUserOnboardingState>((state) => {
            state.userOnboarding.status = data.GetUserOnboarding.onboarding.status;
            state.userOnboarding.completedEvents = data.GetUserOnboarding.onboarding.completedEvents;
          }),
          false,
          "userOnboarding/getUserOnboarding",
        );
      }
    },
    setUserOnboardingCompleted: async () => {
      await client.mutate({
        mutation: setUserOnboardingCompleted,
      });
    },
  },
});
