import { useEffect } from "react";
import { useAuthentication } from "src/stores/authentication/useAuthentication";
import { usePermission } from "src/stores/permission/usePermission";
import { useCurrentGroupId } from "src/hooks/current-group-id/useCurrentGroupId";
import { useMemberShip } from "../../stores/membership/useMembership";
import { useGroupQuota } from "../../stores/group-quota/useGroupQuota";

export const ListenerCurrentGroup = () => {
  const { userId } = useAuthentication();
  const { loadPermissions } = usePermission();
  const groupId = useCurrentGroupId();
  const { getGroupByIdWithInheritance } = useMemberShip();
  const { getGroupQuotaPlan, reset: resetGroupQuota } = useGroupQuota();

  useEffect(() => {
    (async () => {
      resetGroupQuota();
      if (groupId && groupId != "undefined") {
        getGroupQuotaPlan({ groupId });
      }
      if (groupId) {
        getGroupByIdWithInheritance({ groupId });
      }
      if (groupId && groupId != "undefined" && userId) {
        await loadPermissions({ groupId, userId });
      }
    })();
  }, [groupId, userId]);

  return null;
};
