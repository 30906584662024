import gql from "graphql-tag";

export const fragment_caisyUserProfile = gql`
  fragment fragment_caisyUserProfile on CaisyUserProfile {
    displayName
    email
    id
    photoURL
  }
`;
