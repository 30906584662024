import styled from "styled-components";

export const SCreateProjectModalContentContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    max-width: 784px;
    max-width: 784px;
    margin: auto;
    padding: 38px 0;
    /* gap: 24px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
  }
`;
