import { CSSProgressiveBody02Medium } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSWithDiscount = css`
  color: var(--text-03);
  p:first-of-type {
    text-decoration: line-through;
  }

  p:last-of-type {
    color: var(--text-07);
  }
`;

export const SPricingCardPrice = styled.div`
  ${CSSProgressiveBody02Medium};
  color: var(--text-01);
  display: flex;
  align-items: center;
  gap: 6px;

  ${({ withDiscount }) => withDiscount && CSSWithDiscount}
`;
