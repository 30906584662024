import gql from "graphql-tag";

export const updatePreviewItem = gql`
  mutation UpdatePreviewItem($input: UpdatePreviewItemRequestInput) {
    __typename
    UpdatePreviewItem(input: $input) {
      previewItem {
        blueprintId
        enableLivePreview
        previewItemId
        previewUrl
        previewId
      }
    }
  }
`;
