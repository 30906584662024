import { decodeFieldMessage } from "../encoding";
import { ICollaborationState, IPeer } from "../types";
import { findOwnListeningField } from "./helper";
import * as awarenessProtocol from "y-protocols/awareness";

export const handleAwareness = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  const field = decodeFieldMessage(data);
  const ownListeningField = findOwnListeningField(state, field);

  if (!ownListeningField?.provider?.awareness) {
    return;
  }

  awarenessProtocol.modifyAwarenessUpdate(field.body, (awarenessUpdate) => {
    // this is preveting other peers to fake there name
    const profile = window.c.profiles && window.c.profiles[peer.clientId.split("_")[1]];
    if (
      awarenessUpdate &&
      awarenessUpdate.user &&
      awarenessUpdate.user.name &&
      profile &&
      profile?.displayName !== awarenessUpdate.user.name
    ) {
      awarenessUpdate.user.name = profile?.displayName || "Unknown";
    }
    return awarenessUpdate;
  });

  awarenessProtocol.applyAwarenessUpdate(ownListeningField.provider.awareness, field.body, "peer");
};
