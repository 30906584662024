import styled from "styled-components";

export const SCreateProjectModalCloseIconWrapper = styled.div`
  color: var(--icon-03);
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
