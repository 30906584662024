import { LineTab } from "@caisy/league";
import { useRouter } from "next/router";
import { FC, useMemo, useState } from "react";
import { IGenProjectTemplate } from "../../../../../interfaces/generated/i18n-types";
import { useI18nOnboardingProjectTemplates } from "../../../../../provider/i18n/I18nProvider";
import { useProjectOnboarding } from "../../../../../stores/project-onbarding/useProjectOnboarding";
import { OnboardingTemplateModal } from "./OnboardingTemplateModal";
import { OnboardingTemplatesTemplate } from "./OnboardingTemplatesTemplate";
import { SOnboardingLineTabContent } from "./styles/SOnboardingLineTabContent";
import { SOnboardingTemplates } from "./styles/SOnboardingTemplates";
import { SOnboardingTemplatesGrid } from "./styles/SOnboardingTemplatesGrid";
import { SOnboardingTemplatesNav } from "./styles/SOnboardingTemplatesNav";

export const BLANK_TEMPLATE_ID = "blank-template";

interface IOnboardingTemplates {
  onSelectTemplate?: () => void;
}

export const OnboardingTemplates: FC<IOnboardingTemplates> = ({ onSelectTemplate }) => {
  const router = useRouter();
  const { setCurrentTemplate } = useProjectOnboarding();
  const { projectTemplates, tabs } = useI18nOnboardingProjectTemplates();
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);
  const [selectedTemplate, setSelectedTemplate] = useState<IGenProjectTemplate>(null);
  const [showModal, setShowModal] = useState(false);

  const filteredTemplates = useMemo(() => {
    return projectTemplates.filter(
      (template) => template.stack.some(({ id }) => id === selectedTab) || template.templateId === BLANK_TEMPLATE_ID,
    );
  }, [projectTemplates, selectedTab]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelect = (template: IGenProjectTemplate) => {
    if (template.templateId === BLANK_TEMPLATE_ID) {
      router.replace({ query: { ...router.query, templateId: template.templateId } });
      setCurrentTemplate(template);
      onSelectTemplate?.();
      return;
    }
    setShowModal(true);
    setSelectedTemplate(template);
  };

  const handleSelectTemplateOnModal = (template: IGenProjectTemplate) => {
    router.replace({ query: { ...router.query, templateId: template.templateId } });
    setCurrentTemplate(template);
    setShowModal(false);
    onSelectTemplate?.();
  };

  return (
    <SOnboardingTemplates>
      <SOnboardingTemplatesNav>
        {tabs.map((tab) => (
          <LineTab onClick={() => setSelectedTab(tab.id)} activated={tab.id === selectedTab} key={tab.id}>
            <SOnboardingLineTabContent active={tab.id === selectedTab}>
              {tab.label} <img src={tab.icon.src} />
            </SOnboardingLineTabContent>
          </LineTab>
        ))}
      </SOnboardingTemplatesNav>
      <SOnboardingTemplatesGrid>
        {filteredTemplates.map((template) => (
          <OnboardingTemplatesTemplate onSelect={handleSelect} key={template.id} template={template} />
        ))}
      </SOnboardingTemplatesGrid>
      <OnboardingTemplateModal
        onSelectTemplate={handleSelectTemplateOnModal}
        template={selectedTemplate}
        visible={showModal}
        onClose={handleCloseModal}
      />
    </SOnboardingTemplates>
  );
};
