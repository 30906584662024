import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useProjectOnboarding } from "../../../../../../stores/project-onbarding/useProjectOnboarding";
import { useRouter } from "next/router";
import { paramsToUrlString } from "../../../../../../utils/getHrefWithParams";
import { I18n } from "../../../../../../provider/i18n";
import { SOnboardingImportingTemplate } from "../../../../../page/onboarding/components/OnboardingImportingTemplate/styles/SOnboardingImportingTemplate";
import { SOnboardingImportingTemplateSections } from "../../../../../page/onboarding/components/OnboardingImportingTemplate/styles/SOnboardingImportingTemplateSections";
import { SDuplicateProjectProgressModalProgress } from "../../../../../page/management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalProgress";
import { SDuplicateProjectProgressModalProgressBarContainer } from "../../../../../page/management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalProgressBarContainer";
import { SDuplicateProjectProgressModalProgressBar } from "../../../../../page/management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalProgressBar";
import { SDuplicateProjectProgressModalItem } from "../../../../../page/management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalItem";
import { SDuplicateProjectProgressModalItemIcon } from "../../../../../page/management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalItemIcon";
import { Button, IconCheckmarkSolid, IconCircle } from "@caisy/league";
import { PROJECT_SECTIONS } from "../../../../../page/onboarding/components/OnboardingImportingTemplate/OnboardingImportingTemplate";
import { fireAll } from "../../../../onboarding-tour/OnboardingTourCard/OnboardingTourCard";

interface IImportingTemplate {
  onFinish: () => void;
  newProjectId: string;
}

export const ImportingTemplate: FC<IImportingTemplate> = ({ onFinish, newProjectId }) => {
  const buttonRef = useRef();
  const { portId, getProjectPort, importingProgress, resetImportingProgress } = useProjectOnboarding();
  const router = useRouter();

  const [progressInterval, setProgressInterval] = useState(null);

  useEffect(() => {
    getProjectPort({ portId: portId || (router.query.portId as string) }).then(() => {
      const interval = setInterval(() => {
        if (importingProgress.finished) return;
        getProjectPort({ portId });
      }, 1000);

      setProgressInterval(interval);

      return () => {
        if (interval) clearInterval(interval);
        resetImportingProgress();
      };
    });
  }, [portId]);

  useEffect(() => {
    if (importingProgress.finished) {
      const interval = clearInterval(progressInterval);
      setProgressInterval(interval);
    }
  }, [importingProgress?.finished]);

  const ProgressTitleDuplicating = (
    <I18n selector="nav.createProject.importingTitle" fallback="Creating in progress..." />
  );
  const ProgressTitleFinished = (
    <I18n selector="nav.createProject.importingFinishedTitle" fallback="Project has been successfully created" />
  );

  const handleProceedToProject = async () => {
    await router.push(
      `/project/documents/document${paramsToUrlString({
        projectId: newProjectId,
      })}`,
      undefined,
      { shallow: true },
    );
    const buttonPosition = (buttonRef?.current as HTMLDivElement)?.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const buttonTop = buttonPosition.top;
    const buttonLeft = buttonPosition.left;
    const buttonWidth = buttonPosition.width;
    const buttonHeight = buttonPosition.height;

    const x = (buttonLeft + buttonWidth / 2) / windowWidth;
    const y = (buttonTop + buttonHeight / 2) / windowHeight;

    fireAll({ x, y }, 1250);
    onFinish();
  };

  return (
    <SOnboardingImportingTemplate style={{ width: 784, marginTop: "auto" }}>
      <SOnboardingImportingTemplateSections>
        <SDuplicateProjectProgressModalProgress finished={importingProgress.finished}>
          {importingProgress.finished ? ProgressTitleFinished : ProgressTitleDuplicating}
          <SDuplicateProjectProgressModalProgressBarContainer finished={importingProgress.finished}>
            {importingProgress.finished ? "100" : importingProgress.progress || "0"} %
            <SDuplicateProjectProgressModalProgressBar
              progress={importingProgress.finished ? 100 : importingProgress.progress}
              finished={importingProgress.finished}
            />
          </SDuplicateProjectProgressModalProgressBarContainer>
        </SDuplicateProjectProgressModalProgress>
        {PROJECT_SECTIONS.map((section) => (
          <Fragment key={section.key}>
            <SDuplicateProjectProgressModalItem>
              <SDuplicateProjectProgressModalItemIcon
                allFinished={importingProgress.finished}
                finished={importingProgress[section.key] || importingProgress.finished}
              >
                {importingProgress[section.key] || importingProgress.finished ? (
                  <IconCheckmarkSolid size={20} />
                ) : (
                  <IconCircle size={20} />
                )}
              </SDuplicateProjectProgressModalItemIcon>
              {section.label}
            </SDuplicateProjectProgressModalItem>

            {section.subsections &&
              section.subsections.map((subSection) => (
                <Fragment key={subSection.key}>
                  <SDuplicateProjectProgressModalItem isSubsection>
                    <SDuplicateProjectProgressModalItemIcon
                      allFinished={importingProgress.finished}
                      finished={importingProgress[subSection.key] || importingProgress.finished}
                    >
                      {importingProgress[subSection.key] || importingProgress.finished ? (
                        <IconCheckmarkSolid size={20} />
                      ) : (
                        <IconCircle size={20} />
                      )}
                    </SDuplicateProjectProgressModalItemIcon>
                    {subSection.label}
                  </SDuplicateProjectProgressModalItem>
                </Fragment>
              ))}
          </Fragment>
        ))}
      </SOnboardingImportingTemplateSections>

      <Button
        ref={buttonRef}
        disabled={!importingProgress.finished}
        onClick={handleProceedToProject}
        style={{ display: "block" }}
        type="primary"
      >
        <I18n selector="nav.createProject.proceedToTheProject" fallback="Proceed to the project" />
      </Button>
    </SOnboardingImportingTemplate>
  );
};
