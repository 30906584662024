import produce from "immer";
import { importFromTemplate } from "../../graphql/mutations/importFromTemplate.gql";
import { resetProjectOnboarding } from "../../graphql/mutations/resetProjectOnboarding.gql";
import { setProjectOnboardingEventCompleted } from "../../graphql/mutations/setProjectOnboardingEventCompleted.gql";
import { getProjectOnboarding } from "../../graphql/queries/getProjectOnboarding.gql";
import { getProjectPort } from "../../graphql/queries/getProjectPort.gql";
import { getProjectTemplateUsage } from "../../graphql/queries/getProjectTemplateUsage.gql";
import { client } from "../../utils/client";
import { IImportingProgress, IProjectOnboardingState } from "./types";

export const createProjectOnboardingSlice = (
  set: (cb: (state: IProjectOnboardingState) => IProjectOnboardingState, replace: boolean, name: string) => void,
  get: () => IProjectOnboardingState,
): IProjectOnboardingState => ({
  projectOnboarding: {
    loadingProjectOnboarding: true,
    projectOnboarding: { completedEvents: [], status: "NOT_STARTED" },
    currentTemplate: null,
    importingTemplate: false,
    portId: null,
    projectTemplate: null,
    usedTemplateReference: null,
    importingProgress: {} as IImportingProgress,
    currentProjectId: null,
    setCurrentTemplate: (template) => {
      set(
        produce<IProjectOnboardingState>((state) => {
          state.projectOnboarding.currentTemplate = template;
        }),
        false,
        "projectOnboarding/setSelectedTemplateId",
      );
    },
    getProjectOnboarding: async ({ projectId, locale }) => {
      const [{ data }, resProjectTemplateUsage] = await Promise.all([
        client.query({
          query: getProjectOnboarding,
          variables: {
            input: {
              projectId,
            },
          },
          fetchPolicy: "no-cache",
        }),
        client.query({
          query: getProjectTemplateUsage,
          variables: {
            input: {
              projectId,
            },
          },
          fetchPolicy: "no-cache",
        }),
      ]);

      if (resProjectTemplateUsage?.data?.GetProjectTemplateUsage?.templates?.[0]) {
        const usedTemplateReference = resProjectTemplateUsage.data.GetProjectTemplateUsage.templates[0];
        set(
          produce<IProjectOnboardingState>((state) => {
            state.projectOnboarding.usedTemplateReference = usedTemplateReference;
          }),
          false,
          "projectOnboarding/setUsedTemplateReference",
        );
        if (usedTemplateReference.documentationId) {
          const res = await fetch(
            `/app/api/v1/project-onboarding-steps/${usedTemplateReference.documentationId}/${locale}`,
          );
          const projectTemplate = await res.json();
          if (projectTemplate?.id) {
            set(
              produce<IProjectOnboardingState>((state) => {
                state.projectOnboarding.projectTemplate = projectTemplate;
              }),
              false,
              "projectOnboarding/setProjectTemplate",
            );
          }
        }
      }
      if (!data?.GetProjectOnboarding) return;

      set(
        produce<IProjectOnboardingState>((state) => {
          state.projectOnboarding.loadingProjectOnboarding = false;
          state.projectOnboarding.projectOnboarding = data.GetProjectOnboarding.onboarding;
        }),
        false,
        "projectOnboarding/getProjectOnboarding",
      );
    },
    setProjectOnboardingEventCompleted: async ({ projectId, eventCode }) => {
      if (get().projectOnboarding.projectOnboarding.completedEvents.includes(eventCode)) return;

      const { data } = await client.mutate({
        mutation: setProjectOnboardingEventCompleted,
        variables: {
          input: {
            projectId,
            eventCode,
          },
        },
      });

      if (
        data?.SetProjectOnboardingEventCompleted.success &&
        !get().projectOnboarding.projectOnboarding.completedEvents.includes(eventCode)
      ) {
        set(
          produce<IProjectOnboardingState>((state) => {
            state.projectOnboarding.projectOnboarding.completedEvents.push(eventCode);
          }),
          false,
          "projectOnboarding/SetProjectOnboardingEventCompleted",
        );
      }
    },
    resetProjectOnboardingLocal: async () => {
      set(
        produce<IProjectOnboardingState>((state) => {
          state.projectOnboarding.loadingProjectOnboarding = true;
          state.projectOnboarding.projectOnboarding = { completedEvents: [], status: "NOT_STARTED" };
        }),
        false,
        "projectOnboarding/resetProjectOnboardingLocal",
      );
    },
    resetProjectOnboarding: async ({ projectId }) => {
      const { data } = await client.mutate({
        mutation: resetProjectOnboarding,
        variables: { projectId },
      });

      if (data?.ResetProjectOnboarding?.success) {
        set(
          produce<IProjectOnboardingState>((state) => {
            state.projectOnboarding.loadingProjectOnboarding = false;
            state.projectOnboarding.projectOnboarding = { completedEvents: [], status: "NOT_STARTED" };
          }),
          false,
          "projectOnboarding/resetProjectOnboarding",
        );
      }
    },

    importFromTemplate: async (input) => {
      set(
        produce<IProjectOnboardingState>((state) => {
          state.projectOnboarding.currentProjectId = input.projectId;
        }),
        false,
        "projectOnboarding/importFromTemplate/setCurrentProjectId",
      );

      const { data } = await client.mutate({
        mutation: importFromTemplate,
        variables: {
          input: {
            ...input,
            selection: {
              view: true,
              tag: true,
              preview: true,
              blueprint: true,
              webhook: true,
              document: true,
              documentHistory: true,
              documentFieldLocale: true,
            },
          },
        },
      });

      set(
        produce<IProjectOnboardingState>((state) => {
          state.projectOnboarding.importingTemplate = true;
          state.projectOnboarding.portId = data.ImportFromTemplate.portId;
        }),
        false,
        "projectOnboarding/importFromTemplate",
      );
    },

    getProjectPort: async (input: { portId: string }) => {
      try {
        const { data } = await client.query({
          query: getProjectPort,
          variables: { input },
          fetchPolicy: "no-cache",
        });

        let progress = 0;
        const selectionProgress = data.GetProjectPort.selectionProgress;

        const sections = [
          { name: "blueprint", weight: 1 },
          { name: "document", weight: 10 },
          { name: "documentFieldLocale", weight: 1 },
          { name: "webhook", weight: 1 },
          { name: "preview", weight: 1 },
          { name: "view", weight: 1 },
        ];

        let totalWeights = 0;
        sections.forEach((section) => {
          totalWeights += section.weight;
          const total = selectionProgress[`${section.name}Total`];
          const completed = selectionProgress[`${section.name}Completed`];
          progress += total == 0 || completed == 0 ? 0 : Math.floor((completed * 100) / total) * section.weight;
        });

        const newDuplicationProgressObject = {
          finished: data.GetProjectPort.finished,
          progress: data.GetProjectPort.finished ? 100 : totalWeights == 0 ? 0 : Math.floor(progress / totalWeights),
          blueprint: selectionProgress.blueprintCompleted === selectionProgress.blueprintTotal,
          document:
            selectionProgress.documentCompleted === 0
              ? false
              : selectionProgress.documentCompleted === selectionProgress.documentTotal,
          documentFieldLocale:
            selectionProgress.documentFieldLocaleCompleted === selectionProgress.documentFieldLocaleTotal,
          webhook: selectionProgress.webhookCompleted === selectionProgress.webhookTotal,
          preview: selectionProgress.previewCompleted === selectionProgress.previewTotal,
          view: selectionProgress.viewCompleted === selectionProgress.viewTotal,
          tag: selectionProgress.tagCompleted === selectionProgress.tagTotal,
          member: selectionProgress.memberCompleted === selectionProgress.memberTotal,
        };

        set(
          produce<IProjectOnboardingState>((state) => {
            state.projectOnboarding.importingProgress = newDuplicationProgressObject;
          }),
          false,
          "projectOnboarding/getProjectPort",
        );

        return newDuplicationProgressObject;
      } catch {
        return { finished: true } as IImportingProgress;
      }
    },

    resetImportingProgress: () => {
      set(
        produce<IProjectOnboardingState>((state) => {
          // state.projectOnboarding.duplicationProgressSlection = {};
          state.projectOnboarding.importingProgress = {} as IImportingProgress;
        }),
        false,
        "projectOnboarding/resetImportingProgress",
      );
    },
  },
});
