import React, { useContext } from "react";
import { useRouter } from "next/router";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";

import "dayjs/locale/es";
import "dayjs/locale/de";
import { FCWithChildren } from "../../interfaces/FCWithChildren";

dayjs.extend(relativeTime);
dayjs.extend(isToday);

const I18nDayjsProviderContext = React.createContext<II18nDayjsProviderValue>({
  localeString: "en",
});

interface II18nDayjsProviderValue {
  localeString: string;
}

interface II18nDayjsProvider {
  localeString?: string;
}

export const useI18nDayjs = () => {
  const c = useContext(I18nDayjsProviderContext);
  dayjs.locale(c.localeString);
  return dayjs;
};

export const setDateTo = (date: string) => dayjs().to(dayjs(date));

export const isTodayDate = (date?: string) => dayjs(date).isToday();

export const dateFormat = (date?: string, formatDate?: string) => dayjs(date).format(formatDate);

export const I18nDayjsProvider: FCWithChildren<II18nDayjsProvider> = ({ children }) => {
  const { locale } = useRouter();
  dayjs.locale(locale);
  const value = { localeString: locale };
  return <I18nDayjsProviderContext.Provider value={value}>{children}</I18nDayjsProviderContext.Provider>;
};
