import gql from "graphql-tag";
import { fragment_ViewResponse } from "../fragments/viewResponse.gql";

export const updateView = gql`
  ${fragment_ViewResponse}
  fragment UpdateViewResponse on UpdateViewResponse {
    view {
      ...fragment_ViewResponse
    }
  }

  mutation updateView($input: UpdateViewRequestInput!) {
    UpdateView(input: $input) {
      ...UpdateViewResponse
    }
  }
`;
