import { FCWithChildren } from "../../../../interfaces/FCWithChildren";
import { SMainMenuUserPopup } from "./styles/SMainMenuUserPopup";

interface IMainMenuUserPopup {
  opened: boolean;
}

export const MainMenuUserPopup: FCWithChildren<IMainMenuUserPopup> = ({ children, opened }) => {
  return (
    <SMainMenuUserPopup opened={opened} onClick={(e) => e.stopPropagation()}>
      {children}
    </SMainMenuUserPopup>
  );
};
