import { message } from "@caisy/league";
import { getManyGroupsByUserID } from "../../graphql/queries/getManyGroupsByUserID.gql";
import {
  ICreateGroupRequestInput,
  IGetGroupByIdWithInheritanceRequestInput,
  IGetGroupByIdWithInheritanceResponse,
  IGroupInputInput,
  IGroupResponse,
} from "../../interfaces/generated";
import { updateGroup as UpdateGroupGQL } from "../../graphql/mutations/updateGroup.gql";
import { client } from "../../utils/client";
import { createGroup } from "../../graphql/mutations/createGroup.gql";
import produce from "immer";
import { query_GetGroupByIDWithInheritance } from "../../graphql/queries/getGroupByIDWithInheritance.gql";
import { deleteGroup } from "../../graphql/mutations/deleteGroup.gql";

export interface IUseGroupMembership {
  isLoadingGroups: boolean;
  totalGroupCount: number;
  initializedGroups: boolean;
  groups: { [groupId: string]: IGroupResponse };
  loadGroups: ({ userId }: { userId: string }) => Promise<void>;
  createGroup: ({ group, organizationId, userId }: ICreateGroupRequestInput) => Promise<IGroupResponse>;
  updateGroup: ({ groupId, input }: { groupId: string; input: IGroupInputInput }) => Promise<IGroupInputInput>;
  deleteGroup: ({ groupId }: { groupId: string }) => Promise<boolean>;
  groupWithInheritance: IGetGroupByIdWithInheritanceResponse;
  loadingInheritance: boolean;
  getGroupByIdWithInheritance: ({ groupId }: IGetGroupByIdWithInheritanceRequestInput) => Promise<void>;
  cleanUpGroupsByOrganizationId: (organizationId: string) => void;
}

export interface IGroupMembershipState {
  group: IUseGroupMembership;
}

export const createGroupMembershipSlice = (
  set: (cb: (state: IGroupMembershipState) => IGroupMembershipState, boolean, string) => void,
  // get: () => IGroupMembershipState,
) => ({
  group: {
    groups: {},
    totalGroupCount: 0,
    isLoadingGroups: false,
    groupWithInheritance: {} as IGetGroupByIdWithInheritanceResponse,
    loadingInheritance: false,
    initializedGroups: false,
    getGroupByIdWithInheritance: async ({ groupId }: IGetGroupByIdWithInheritanceRequestInput) => {
      const { data } = await client.query({
        query: query_GetGroupByIDWithInheritance,
        variables: {
          input: {
            groupId,
          },
        },
        fetchPolicy: "no-cache",
      });
      const res = data?.GetGroupByIDWithInheritance as IGetGroupByIdWithInheritanceResponse;
      if (res) {
        set(
          produce((prev: IGroupMembershipState) => {
            prev.group.groupWithInheritance = res;
            prev.group.groups = { ...prev.group.groups, [groupId]: res.group };
          }),
          false,
          "project/GetProjectByIdWithInheritance",
        );
      }
    },
    updateGroup: async ({ groupId, input }: { groupId: string; input: IGroupInputInput }) => {
      try {
        const res = await client.mutate({
          mutation: UpdateGroupGQL,
          variables: {
            input: {
              input: {
                ...input,
              },
              groupId,
            },
          },
        });
        if (res?.data?.UpdateGroup?.group) {
          set(
            produce<IGroupMembershipState>((prev) => {
              prev.group.groups[groupId] = res.data.UpdateGroup.group;
              prev.group.groupWithInheritance.group = res.data.UpdateGroup.group;
            }),
            false,
            "group/updateGroup",
          );

          return res?.data?.UpdateGroup?.group;
        }
      } catch (err) {
        console.error(err);
        message.error(`Update group failed: ${err.message ?? ""}`);
      }
    },
    loadGroups: async ({ userId }) => {
      const { data } = await client.query({
        query: getManyGroupsByUserID,
        variables: { userId },
        fetchPolicy: "no-cache",
      });

      if (data?.GetManyGroupsByUserID.connection.edges) {
        set(
          produce<IGroupMembershipState>((state) => {
            state.group.groups =
              data?.GetManyGroupsByUserID?.connection.edges
                ?.map((edge) => edge?.node)
                ?.reduce(
                  (prev, group: IGroupResponse) => ({
                    ...{
                      [group.groupId]: { ...group },
                      ...prev,
                    },
                  }),
                  {},
                ) || {};
            state.group.initializedGroups = true;
            state.group.totalGroupCount = data.GetManyGroupsByUserID.connection.totalCount;
          }),
          false,
          "project/loadGroups/onUpdate",
        );
      }

      set(
        produce((state) => {
          state.group.isLoadingGroups = false;
        }),
        false,
        "group/loadGroups",
      );
    },
    createGroup: async ({ group, organizationId, userId }: ICreateGroupRequestInput) => {
      try {
        const res = await client.mutate({
          mutation: createGroup,
          variables: {
            organizationId,
            group,
            userId,
          },
        });
        if (res?.data?.CreateGroup?.group) {
          set(
            produce((prev) => {
              prev.group.groups = {
                ...prev.group.groups,
                [res?.data?.CreateGroup?.group.groupId]: {
                  ...res.data.CreateGroup.group,
                  roleByUser: { title: "owner" },
                },
              };
            }),
            false,
            "group/createGroup",
          );

          return res?.data?.CreateGroup?.group;
        }
        res?.data?.CreateGroup?.group;
      } catch (err) {
        console.error(err);
        message.error(`add group failed: ${err.message ?? ""}`);
      }
    },
    deleteGroup: async ({ groupId }) => {
      const { data } = await client.mutate({
        mutation: deleteGroup,
        variables: { groupId },
      });

      const { deleted } = data?.DeleteGroup;

      if (deleted) {
        set(
          produce<IGroupMembershipState>((state) => {
            delete state.group.groups[groupId];
          }),
          false,
          "group/deleteGroup",
        );
      }

      return deleted;
    },
    cleanUpGroupsByOrganizationId: (organizationId: string) => {
      set(
        produce<IGroupMembershipState>((state) => {
          state.group.groups = Object.entries(state.group.groups)
            .filter(([, value]) => value.organizationId !== organizationId)
            .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {});
        }),
        false,
        "group/cleanUpGroupsByOrganizationId",
      );
    },
  },
});
