import React from "react";

function MaintenanceWarningIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64">
      <path
        fillRule="evenodd"
        stroke="#F7F9FA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.906"
        d="M17.217 24.365c-6.351.867-11.26 6.255-11.26 12.844 0 7.19 5.83 13.02 13.02 13.02h28.647c5.752 0 10.416-4.663 10.416-10.416s-4.664-10.417-10.416-10.417c0-8.63-6.995-15.625-15.625-15.625-6.867 0-12.685 4.435-14.782 10.594z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#F7F9FA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.906"
        d="M32 35.464v-8.672M31.997 41.551a.432.432 0 00-.433.435.435.435 0 00.87-.003.434.434 0 00-.437-.432"
      ></path>
    </svg>
  );
}

export default MaintenanceWarningIcon;
