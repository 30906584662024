import { gql } from "@apollo/client";
import { fragment_blueprintResponse } from "../fragments/blueprintResponse.gql";

export const updateBlueprint = gql`
  ${fragment_blueprintResponse}
  mutation UpdateBlueprint($input: UpdateBlueprintRequest) {
    UpdateBlueprint(input: $input) {
      blueprint {
        ...fragment_blueprintResponse
      }
    }
  }
`;
