import styled, { css } from "styled-components";

const CSSSelected = css`
  color: var(--active-icon-04);
`;

export const SSelectOrganizationStepListItemIcon = styled.div`
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-04);

  ${({ selected }) => selected && CSSSelected}
`;
