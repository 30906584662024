import { Button, Flex, IconClose, Modal, Tab } from "@caisy/league";
import { useMemberShip } from "../../../../stores/membership/useMembership";
import { SProjectLimitModal } from "./styles/SProjectLimitModal";
import { SProjectLimitModalHeader } from "./styles/SProjectLimitModalHeader";
import { SProjectLimitModalCloseIcon } from "./styles/SProjectLimitModalCloseIcon";
import { SProjectLimitModalContent } from "./styles/SProjectLimitModalContent";
import { PricingCards } from "../../../page/management/project/plan-and-billing/components/pricing-cards/PricingCards";
import { SProjectLimitModalTitle } from "./styles/SProjectLimitModalTitle";
import { SProjectLimitModalDescription } from "./styles/SProjectLimitModalDescription";
import { SProjectLimitModalPricingCardsContainer } from "./styles/SProjectLimitModalPricingCardsContainer";
import { SProjectLimitModalPricingCardsWrapper } from "./styles/SProjectLimitModalPricingCardsWrapper";
import { SProjectLimitModalPricingCardsHeader } from "./styles/SProjectLimitModalPricingCardsHeader";
import { SProjectLimitModalPricingCardsHeaderIcon } from "./styles/SProjectLimitModalPricingCardsHeaderIcon";
import IconCards from "./IconCards";
import { I18n } from "../../../../provider/i18n";
import { useEffect, useState } from "react";
import { useSecurityContext } from "../../../../services/security";
import { PERMISSION, PERMISSION_ACTION } from "../../../../services/security/constant";
import { useBilling } from "../../../../stores/billing/useBilling";
import { InformationModal } from "../../information-modal/InformationModal";
import { SInformationModalTitle } from "../../information-modal/styles/SInformationModalTitle";
import { SInformationModalText } from "../../information-modal/styles/SInformationModalText";
import { useRouter } from "next/router";
import { SInformationModalButtons } from "../../information-modal/styles/SInformationModalButtons";

const MODAL_STATES = [
  {
    id: 1,
    canUpdateBilling: false,
    validPaymentInfo: false,
    title: <I18n selector="nav.createProject.projectCreationLimitReached" fallback="Project Creation Limit Reached" />,
    description: (
      <I18n
        selector="nav.createProject.projectCreationLimitReachedDescription"
        fallback="You've reached the maximum limit of 3 free projects for your organisation and no credit card is on file. As you're not the billing admin, you cannot create a paid project. Please reach out to your billing admin to extend your project limit."
      />
    ),
  },
  {
    id: 2,
    canUpdateBilling: true,
    validPaymentInfo: false,
    title: <I18n selector="nav.createProject.paymentInformationRequired" fallback="Payment Information Required" />,
    description: (
      <I18n
        selector="nav.createProject.paymentInformationRequiredDescription"
        fallback="You've exceeded the limit of 3 free projects for your organisation. To continue creating new projects, please add a credit card to your billing information."
      />
    ),
  },
  {
    id: 3,
    canUpdateBilling: true,
    validPaymentInfo: true,
  },
];

export const ProjectLimitModal = () => {
  const { showProjectLimitModal, setShowProjectLimitModal } = useMemberShip();
  const { paymentInfo, paymentInfoLoaded, getPaymentInfo } = useBilling();
  const { can } = useSecurityContext();
  const router = useRouter();
  const [currentTab, setCurrentTab] = useState<"yearly" | "monthly">("monthly");

  useEffect(() => {
    if (!showProjectLimitModal) return;
    getPaymentInfo({ organizationId: showProjectLimitModal });
  }, [showProjectLimitModal]);

  const validPaymentInfo = paymentInfoLoaded[showProjectLimitModal] && !!paymentInfo?.[showProjectLimitModal]?.valid;
  const canUpdateBilling = can(PERMISSION_ACTION.UPDATE, PERMISSION.BILLING);
  const showModal = !!showProjectLimitModal && paymentInfoLoaded[showProjectLimitModal];

  const modalState = MODAL_STATES.find(
    (state) => state.canUpdateBilling === canUpdateBilling && state.validPaymentInfo === validPaymentInfo,
  );

  const handleAddBillingMethod = () => {
    router.push(`/organization/settings/billing?organization_id=${showProjectLimitModal}`, undefined, {
      shallow: true,
    });
    setShowProjectLimitModal(null);
  };

  return (
    <>
      {modalState?.id === 3 && (
        <Modal onClose={() => setShowProjectLimitModal(null)} visible={showModal}>
          <SProjectLimitModal>
            <SProjectLimitModalHeader>
              <I18n selector="nav.createProject.newProject" fallback="New Project" />
              <SProjectLimitModalCloseIcon onClick={() => setShowProjectLimitModal(null)}>
                <IconClose size={24} />
              </SProjectLimitModalCloseIcon>
            </SProjectLimitModalHeader>
            <SProjectLimitModalContent>
              <Flex direction="column" gap="12px">
                <SProjectLimitModalTitle>
                  <I18n
                    selector="nav.createProject.limitModalTitle"
                    fallback="You already reached the limit of your subscription plan"
                  />
                </SProjectLimitModalTitle>
                <SProjectLimitModalDescription>
                  <I18n
                    selector="nav.createProject.limitModalDescription"
                    fallback="Upgrade your plan to create more than 3 projects"
                  />
                </SProjectLimitModalDescription>
              </Flex>
              <SProjectLimitModalPricingCardsContainer>
                <SProjectLimitModalPricingCardsHeader>
                  <Flex alignItems="center" gap="4px">
                    <SProjectLimitModalPricingCardsHeaderIcon>
                      <IconCards />
                    </SProjectLimitModalPricingCardsHeaderIcon>
                    <I18n selector="nav.createProject.availablePlans" fallback="available plans" />
                  </Flex>
                  <Flex gap="4px">
                    <Tab
                      onClick={() => setCurrentTab("yearly")}
                      activated={currentTab === "yearly"}
                      value="yearly"
                      size="micro"
                    >
                      <I18n selector="nav.createProject.yearly" fallback="Yearly" />
                    </Tab>
                    <Tab
                      onClick={() => setCurrentTab("monthly")}
                      activated={currentTab === "monthly"}
                      value="monthly"
                      size="micro"
                    >
                      <I18n selector="nav.createProject.monthly" fallback="Monthly" />
                    </Tab>
                  </Flex>
                </SProjectLimitModalPricingCardsHeader>
                <SProjectLimitModalPricingCardsWrapper>
                  <PricingCards
                    organizationId={showProjectLimitModal}
                    submitOnSelect
                    onSubmit={() => {
                      setShowProjectLimitModal(null);
                    }}
                    currentTab={currentTab}
                  />
                </SProjectLimitModalPricingCardsWrapper>
              </SProjectLimitModalPricingCardsContainer>
            </SProjectLimitModalContent>
          </SProjectLimitModal>
        </Modal>
      )}
      {(modalState?.id === 1 || modalState?.id === 2) && (
        <InformationModal visible={showModal}>
          <Flex direction="column" gap="24px">
            <Flex direction="column" gap="12px">
              <SInformationModalTitle>{modalState.title}</SInformationModalTitle>
              <SInformationModalText>{modalState.description}</SInformationModalText>
            </Flex>
            <SInformationModalButtons>
              {modalState.id === 1 ? (
                <Button onClick={() => setShowProjectLimitModal(null)} type="primary" style={{ width: "100%" }}>
                  <I18n selector="nav.createProject.close" fallback="close" />
                </Button>
              ) : (
                <>
                  <Button onClick={handleAddBillingMethod} type="primary" style={{ width: "100%" }}>
                    <I18n selector="nav.createProject.addBillingMethod" fallback="add billing method" />
                  </Button>
                  <Button onClick={() => setShowProjectLimitModal(null)} type="tertiary" style={{ width: "100%" }}>
                    <I18n selector="nav.createProject.cancel" fallback="cancel" />
                  </Button>
                </>
              )}
            </SInformationModalButtons>
          </Flex>
        </InformationModal>
      )}
    </>
  );
};
