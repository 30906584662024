import { FC, useMemo, useState } from "react";
import { SOnboardingTemplates } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplates";
import { SOnboardingTemplatesNav } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesNav";
import { IGenProjectTemplate } from "../../../../../../interfaces/generated/i18n-types";
import { useI18nNav } from "../../../../../../provider/i18n/I18nProvider";
import { LineTab } from "@caisy/league";
import { SOnboardingLineTabContent } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingLineTabContent";
import { SOnboardingTemplatesGrid } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesGrid";
import { BLANK_TEMPLATE_ID } from "../../../../../page/onboarding/components/OnboardingTemplates/OnboardingTemplates";
import { OnboardingTemplatesTemplate } from "../../../../../page/onboarding/components/OnboardingTemplates/OnboardingTemplatesTemplate";
import { SSelectFromTemplateStepTemplatesGrid } from "./styles/SSelectFromTemplateStepTemplatesGrid";

interface ISelectFromTemplateStepTemplatesGrid {
  onSelectTemplate: (template: IGenProjectTemplate) => void;
}

export const SelectFromTemplateStepTemplatesGrid: FC<ISelectFromTemplateStepTemplatesGrid> = ({ onSelectTemplate }) => {
  const { createProject } = useI18nNav();
  const { projectTemplates, tabs } = createProject.projectTemplates;
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);

  const filteredTemplates = useMemo(() => {
    return projectTemplates.filter(
      (template) => template.stack.some(({ id }) => id === selectedTab) && template.templateId !== BLANK_TEMPLATE_ID,
    );
  }, [projectTemplates, selectedTab]);

  return (
    <SSelectFromTemplateStepTemplatesGrid>
      <SOnboardingTemplates>
        <SOnboardingTemplatesNav>
          {tabs.map((tab) => (
            <LineTab onClick={() => setSelectedTab(tab.id)} activated={tab.id === selectedTab} key={tab.id}>
              <SOnboardingLineTabContent active={tab.id === selectedTab}>
                {tab.label} <img src={tab.icon.src} />
              </SOnboardingLineTabContent>
            </LineTab>
          ))}
        </SOnboardingTemplatesNav>
        <SOnboardingTemplatesGrid>
          {filteredTemplates.map((template) => (
            <OnboardingTemplatesTemplate onSelect={onSelectTemplate} key={template.id} template={template} />
          ))}
        </SOnboardingTemplatesGrid>
      </SOnboardingTemplates>
    </SSelectFromTemplateStepTemplatesGrid>
  );
};
