import styled from "styled-components";
import { SOnboardingTemplatesTemplate } from "../../OnboardingTemplates/styles/SOnboardingTemplatesTemplate";
import { SOnboardingTemplatesTemplateImage } from "../../OnboardingTemplates/styles/SOnboardingTemplatesTemplateImage";

export const SOnboardingFormTemplateWrapper = styled.div`
  width: 328px;
  flex: 1;

  ${SOnboardingTemplatesTemplate} {
    width: 328px;
    flex: 1;
    height: unset;
    min-height: 396px;

    ${SOnboardingTemplatesTemplateImage} {
      width: 100%;
      height: 212px;
    }
  }
`;
