import gql from "graphql-tag";

export const validateDocument = gql`
  mutation validateDocument($input: ValidateDocumentRequestInput) {
    ValidateDocument(input: $input) {
      validation {
        errors {
          blueprintFieldId
          errorCodes
          errorDetails
          documentFieldLocaleId
        }
        valid
      }
    }
  }
`;
