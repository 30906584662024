import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import {
  IGenLogin,
  IGenNav,
  IGenProfile,
  IGenBlueprintOverview,
  IGenTagField,
  IGenBlueprintDetail,
  IGenBlueprintDetailCommon,
  IGenProjectManagementNavigation,
  IGenProjectManagementMembers,
  IGenProjectManagementGeneral,
  IGenProjectManagementApiKeys,
  IGenProjectManagementPreviews,
  IGenProjectManagementLocales,
  IGenProjectManagementWebhooks,
  IGenChangeRoleModal,
  IGenAddNewMember,
  IGenGroupManagementNavigation,
  IGenMemberRoles,
  IGenGroupManagementMembers,
  IGenGroupManagementGeneral,
  IGenGroupManagementProjects,
  IGenOrganizationManagementNavigation,
  IGenOrganizationManagementMembers,
  IGenOrganizationManagementGeneral,
  IGenOrganizationManagementGroups,
  IGenDocumentVersions,
  IGenProjectManagementAuditlogs,
  IGenReleases,
  IGenDocumentValidationErrors,
  IGenProjectUsage,
  IGenProjectManagementUsageHistory,
  IGenBlueprintErrorMessages,
  IGenHomeScreen,
  IGenOnboardingForm,
  IGenSignUpFlow,
  IGenOnboardingProjectTemplates,
  IGenQuotaPlans,
  IGenOrganizationManagementBilling,
  IGenUserOnboardingTourShared,
  IGenErrorPage404,
  IGenErrorPage500,
} from "../../interfaces/generated/i18n-types";
import { II18nResetPassword } from "src/services/i18n/getI18NResetPassword";
import { NextRouter, useRouter } from "next/router";
import { II18nErrorMessages } from "src/services/i18n/getI18NErrorMessages";
import { useAuthentication } from "src/stores/authentication/useAuthentication";
import { FCWithChildren } from "../../interfaces/FCWithChildren";

export const I18nProviderContext = React.createContext<II18nProviderValue>({
  hasData: false,
  nav: {
    __typename: "Nav",
  },
  errorMessages: {},
  login: {
    __typename: "Login",
  },
  resetPassword: {},
  releases: {
    __typename: "Releases",
  },
  profile: {
    __typename: "Profile",
  },
  blueprintOverview: {
    __typename: "BlueprintOverview",
  },
  tagField: {
    __typename: "TagField",
  },
  blueprintDetail: {
    __typename: "BlueprintDetail",
  },
  projectManagementNavigation: {
    __typename: "ProjectManagementNavigation",
  },
  projectManagementMembers: {
    __typename: "ProjectManagementMembers",
  },
  projectManagementGeneral: {
    __typename: "ProjectManagementGeneral",
  },
  projectManagementApiKeys: {
    __typename: "ProjectManagementApiKeys",
  },
  projectManagementPreviews: {
    __typename: "ProjectManagementPreviews",
  },
  projectManagementLocales: {
    __typename: "ProjectManagementLocales",
  },
  projectManagementWebhooks: {
    __typename: "ProjectManagementWebhooks",
  },
  changeRoleModal: {
    __typename: "ChangeRoleModal",
  },
  addNewMember: {
    __typename: "AddNewMember",
  },
  groupManagementNavigation: {
    __typename: "GroupManagementNavigation",
  },
  memberRoles: {
    __typename: "MemberRoles",
  },
  groupManagementMembers: {
    __typename: "GroupManagementMembers",
  },
  groupManagementGeneral: {
    __typename: "GroupManagementGeneral",
  },
  groupManagementProjects: {
    __typename: "GroupManagementProjects",
  },
  organizationManagementNavigation: {
    __typename: "OrganizationManagementNavigation",
  },
  organizationManagementMembers: {
    __typename: "OrganizationManagementMembers",
  },
  organizationManagementGeneral: {
    __typename: "OrganizationManagementGeneral",
  },
  organizationManagementGroups: {
    __typename: "OrganizationManagementGroups",
  },
  documentVersions: {
    __typename: "DocumentVersions",
  },
  projectManagementAuditlogs: {
    __typename: "ProjectManagementAuditlogs",
  },
  documentValidationErrors: {
    __typename: "DocumentValidationErrors",
  },
  signUpFlow: {
    __typename: "SignUpFlow",
  },
  projectUsage: {
    __typename: "ProjectUsage",
  },
  projectUsageHistory: {
    __typename: "ProjectManagementUsageHistory",
  },
  blueprintErrorMessages: {
    __typename: "BlueprintErrorMessages",
  },
  homeScreenContent: {
    __typename: "HomeScreen",
  },
  onboardingForm: {
    __typename: "OnboardingForm",
  },
  onboardingProjectTemplates: {
    __typename: "OnboardingProjectTemplates",
  },
  quotaPlans: {
    __typename: "QuotaPlans",
  },
  organizationManagementBilling: {
    __typename: "OrganizationManagementBilling",
  },
  userOnboardingTourShared: {
    __typename: "UserOnboardingTourShared",
  },
  blueprintDetailCommon: {
    __typename: "BlueprintDetailCommon",
  },
  error404: {
    __typename: "ErrorPage404",
  },
  error500: {
    __typename: "ErrorPage500",
  },
});

export interface II18nProviderValue {
  hasData: boolean;
  nav: IGenNav;
  errorMessages: II18nErrorMessages;
  login: IGenLogin;
  resetPassword: II18nResetPassword;
  releases: IGenReleases;
  profile: IGenProfile;
  blueprintOverview: IGenBlueprintOverview;
  tagField: IGenTagField;
  blueprintDetail: IGenBlueprintDetail;
  blueprintDetailCommon: IGenBlueprintDetailCommon;
  projectManagementNavigation: IGenProjectManagementNavigation;
  projectManagementMembers: IGenProjectManagementMembers;
  projectManagementGeneral: IGenProjectManagementGeneral;
  projectManagementApiKeys: IGenProjectManagementApiKeys;
  projectManagementPreviews: IGenProjectManagementPreviews;
  projectManagementLocales: IGenProjectManagementLocales;
  projectManagementWebhooks: IGenProjectManagementWebhooks;
  changeRoleModal: IGenChangeRoleModal;
  addNewMember: IGenAddNewMember;
  groupManagementNavigation: IGenGroupManagementNavigation;
  memberRoles: IGenMemberRoles;
  groupManagementMembers: IGenGroupManagementMembers;
  groupManagementGeneral: IGenGroupManagementGeneral;
  groupManagementProjects: IGenGroupManagementProjects;
  organizationManagementNavigation: IGenOrganizationManagementNavigation;
  organizationManagementMembers: IGenOrganizationManagementMembers;
  organizationManagementGeneral: IGenOrganizationManagementGeneral;
  organizationManagementGroups: IGenOrganizationManagementGroups;
  documentVersions: IGenDocumentVersions;
  projectManagementAuditlogs: IGenProjectManagementAuditlogs;
  documentValidationErrors: IGenDocumentValidationErrors;
  signUpFlow: IGenSignUpFlow;
  projectUsage: IGenProjectUsage;
  projectUsageHistory: IGenProjectManagementUsageHistory;
  blueprintErrorMessages: IGenBlueprintErrorMessages;
  homeScreenContent: IGenHomeScreen;
  onboardingForm: IGenOnboardingForm;
  onboardingProjectTemplates: IGenOnboardingProjectTemplates;
  quotaPlans: IGenQuotaPlans;
  organizationManagementBilling: IGenOrganizationManagementBilling;
  userOnboardingTourShared: IGenUserOnboardingTourShared;
  error404: IGenErrorPage404;
  error500: IGenErrorPage500;
}

interface II18nProvider {
  i18n: II18nProviderValue;
  unprotectedPage: boolean;
}

export const useI18nBlueprintErrorMessages = () => {
  const c = useContext(I18nProviderContext);
  return c?.blueprintErrorMessages;
};

export const useI18nNav = () => {
  const c = useContext(I18nProviderContext);
  return c?.nav;
};

export const useI18nProfile = () => {
  const c = useContext(I18nProviderContext);
  return c?.profile;
};

export const useI18nBlueprintOverview = () => {
  const c = useContext(I18nProviderContext);
  return c?.blueprintOverview;
};

export const useI18nBlueprintDetail = () => {
  const c = useContext(I18nProviderContext);
  return c?.blueprintDetail;
};

export const useI18nBlueprintDetailCommon = () => {
  const c = useContext(I18nProviderContext);
  return c?.blueprintDetailCommon;
};

export const useI18nErrorMessages = () => {
  const c = useContext(I18nProviderContext);
  return c?.errorMessages;
};

export const useI18nLogin = () => {
  const c = useContext(I18nProviderContext);
  return c?.login;
};

export const useI18nResetPassword = () => {
  const c = useContext(I18nProviderContext);
  return c?.resetPassword;
};

export const useI18nRelease = () => {
  const c = useContext(I18nProviderContext);
  return c?.releases;
};

export const useI18nDocumentVersions = () => {
  const c = useContext(I18nProviderContext);
  return c?.documentVersions;
};

export const userI18nProjectManagementAuditlogs = () => {
  const c = useContext(I18nProviderContext);
  return c?.projectManagementAuditlogs;
};

export const useI18nDocumentValidationErrors = () => {
  const c = useContext(I18nProviderContext);
  return c?.documentValidationErrors;
};

export const useI18nSignUpFlow = () => {
  const c = useContext(I18nProviderContext);
  return c?.signUpFlow || ({} as IGenSignUpFlow);
};

export const useI18nProjectUsage = () => {
  const c = useContext(I18nProviderContext);
  return c?.projectUsage;
};

export const useI18nProjectManagementUsageHistory = () => {
  const c = useContext(I18nProviderContext);
  return c?.projectUsageHistory;
};

export const useI18nHomeScreenContent = () => {
  const c = useContext(I18nProviderContext);
  return c?.homeScreenContent;
};

export const useI18nOnboardingForm = () => {
  const c = useContext(I18nProviderContext);
  return c?.onboardingForm;
};

export const useI18nOnboardingProjectTemplates = () => {
  const c = useContext(I18nProviderContext);
  return c?.onboardingProjectTemplates;
};

export const useI18nQuotaPlans = () => {
  const c = useContext(I18nProviderContext);
  return c?.quotaPlans;
};

export const useI18nOrganizationManagementBilling = () => {
  const c = useContext(I18nProviderContext);
  return c?.organizationManagementBilling;
};

export const useI18nUserOnboardingTourShared = () => {
  const c = useContext(I18nProviderContext);
  return c?.userOnboardingTourShared;
};

export const useI18nErrorPage404 = () => {
  const c = useContext(I18nProviderContext);
  return c?.error404;
};

export const useI18nErrorPage500 = () => {
  const c = useContext(I18nProviderContext);
  return c?.error500;
};

const ensureLocale = (desiredLanguage: string, router: NextRouter) => {
  if (router.locales?.includes(desiredLanguage) && desiredLanguage && desiredLanguage !== router.locale) {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: desiredLanguage });
  }
};

export const I18nProvider: FCWithChildren<II18nProvider> = ({ children, i18n, unprotectedPage }) => {
  const i18nPropsTruty = !isEmpty(i18n);

  if (isEmpty(globalThis["i18n"]) && i18nPropsTruty) {
    globalThis["i18n"] = i18n;
  }

  const router = useRouter();
  const { locale } = router;
  const { isLoggedIn, isLoading, user: currUser } = useAuthentication();

  const langInStore =
    currUser?.preferredUILanguage && currUser?.preferredUILanguage != "" ? currUser?.preferredUILanguage : null;

  useEffect(() => {
    const browserLanguage = navigator.language.substring(0, 2);

    if (process.env.NEXT_PUBLIC_LOCALE_DETECTION !== "false" && unprotectedPage) {
      // handline just browser language
      ensureLocale(browserLanguage, router);
      return;
    }

    // if user not loaded we should wait for it
    if (!isLoggedIn || isLoading) {
      return;
    }

    if (process.env.NEXT_PUBLIC_LOCALE_DETECTION === "false" && !langInStore) {
      return;
    }

    // logged in user maybe has a preferred language set in the backend
    const preferredLanguage = langInStore ?? browserLanguage;
    ensureLocale(preferredLanguage, router);
  }, [langInStore, isLoggedIn, isLoading, locale, unprotectedPage]);

  return (
    <I18nProviderContext.Provider value={{ ...i18n, hasData: i18nPropsTruty }}>{children}</I18nProviderContext.Provider>
  );
};
