import styled from "styled-components";

export const SCreateProjectModalFirstStepItemIcon = styled.div`
  border-radius: 8px;
  background-color: ${({ color }) => `${color}`};
  color: var(--icon-02);
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;
