import { IGetAllDocumentStatusRequestInput } from "../../interfaces/generated";

export enum DocumentStatusEnum {
  DRAFT = 0,
  CHANGED,
  PUBLISHED,
  ARCHIVED,
}
export interface IUseDocumentStatusState {
  documentStatus: IUseDocumentStatus;
}

export interface IStatus {
  statusId: string;
  name: string;
}

export interface IUseDocumentStatus {
  statuses: { [statusId: string]: IStatus };
  loadStatuses: (input: IGetAllDocumentStatusRequestInput) => Promise<void>;
  isLoading: boolean;
  resetStatus: () => void;
}
