import React from "react";

function OnboardingTourCardPointerClickIndicator() {
  return (
    <svg width="41" height="41" style={{ position: "absolute", top: 0, left: 0 }} overflow="visible">
      <defs>
        <filter
          id="pp-gen-12"
          width="165%"
          height="170%"
          x="-33%"
          y="-33%"
          colorInterpolationFilters="sRGB"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="step0"></feOffset>
          <feGaussianBlur in="step0" result="step1" stdDeviation="4.5"></feGaussianBlur>
          <feComposite in="step1" in2="SourceAlpha" operator="out" result="step2"></feComposite>
          <feColorMatrix in="step2" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
        </filter>
      </defs>
      <ellipse cx="20.5" cy="20.5" filter="url(#pp-gen-12)" rx="21.5" ry="21.5"></ellipse>
      <ellipse cx="20.5" cy="20.5" fill="rgba(255,255,255,0.85)" rx="20.5" ry="20.5"></ellipse>
      <ellipse cx="20.5" cy="20.5" fill="none" stroke="rgba(114,114,114,0.6)" rx="21" ry="21"></ellipse>
    </svg>
  );
}

export default OnboardingTourCardPointerClickIndicator;
