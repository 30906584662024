import { Button, IconChevronRight } from "@caisy/league";
import { useRouter } from "next/router";
import { useState } from "react";
import { useCurrentProjectId } from "../../../hooks/current-project-id/useCurrentProjectId";
import { I18n } from "../../../provider/i18n";
import { useWebhook } from "../../../stores/webhook/useWebhook";
import { paramsToUrlString } from "../../../utils/getHrefWithParams";
import { SSettingsTopNavBreadcrumb } from "./styles/SSettingsTopNavBreadcrumb";
import { SSettingsTopNavChevronRight } from "./styles/SSettingsTopNavChevronRight";
import { SWebhookEditButtonWrapper } from "./styles/SWebhookEditButtonWrapper";

const WebhookCreateNavComponent = () => {
  const projectId = useCurrentProjectId();
  const router = useRouter();
  const { currentWebhook, createWebhook } = useWebhook();
  const [loading, setLoading] = useState(false);

  const redirectToEdit = ({ webhookId }) => {
    router.push(
      `/project/settings/development/webhooks/edit${paramsToUrlString({ webhookId, projectId })}`,
      undefined,
      { shallow: true },
    );
  };

  const onSaveWebhook = async () => {
    setLoading(true);
    const createdWebhook = await createWebhook({
      projectId,
      webhook: currentWebhook,
    });
    const webhookId = createdWebhook?.webhookId;
    if (!webhookId) return setLoading(false);
    redirectToEdit({ webhookId });
  };

  return (
    <>
      <>
        <SSettingsTopNavChevronRight>
          <IconChevronRight size={20} />
        </SSettingsTopNavChevronRight>

        <SSettingsTopNavBreadcrumb>
          <I18n selector="projectManagementWebhooks.header_newWebhook" fallback="New webhook" />
        </SSettingsTopNavBreadcrumb>
      </>
      <SWebhookEditButtonWrapper>
        <Button disabled={loading} size="default" type="primary" onClick={onSaveWebhook}>
          <I18n selector="projectManagementWebhooks.header_saveChanges" fallback="Save changes" />
        </Button>
      </SWebhookEditButtonWrapper>
    </>
  );
};

export default WebhookCreateNavComponent;
