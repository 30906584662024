import { FC, useEffect, useState } from "react";
import { SSelectProjectStepList } from "./styles/SSelectProjectStepList";
import { SSelectProjectStepListHeader } from "./styles/SSelectProjectStepListHeader";
import { List, SearchInput } from "@caisy/league";
import { SSelectProjectStepListItem } from "./styles/SSelectProjectStepListItem";
import fuzzysearch from "fuzzysearch";

interface ISelectProjectStepList {
  header: React.ReactNode;
  icon: React.ReactNode;
  searchPlaceholder: string;
  dataSource: any[];
  width: number;
  onSelect: (id: string) => void;
  items: { [itemId: string]: any };
  selectedItem: string;
}

export const SelectProjectStepList: FC<ISelectProjectStepList> = ({
  header,
  icon,
  searchPlaceholder,
  dataSource,
  width,
  onSelect,
  items,
  selectedItem,
}) => {
  useEffect(() => {
    onSelect(dataSource[0]);
  }, [dataSource]);

  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setSearchTerm("");
  }, []);

  const filterdDataSource =
    searchTerm === ""
      ? dataSource
      : dataSource.filter((id) => {
          const project = items[id];

          switch (true) {
            case fuzzysearch(searchTerm, project.name.toLowerCase()):
              return true;
            case project.description && fuzzysearch(searchTerm, project.description.toLowerCase()):
              return true;
            case project.alias && fuzzysearch(searchTerm, project.alias.toLowerCase()):
              return true;
          }

          return false;
        });

  return (
    <SSelectProjectStepList style={{ width }}>
      <SSelectProjectStepListHeader>
        {icon}
        {header}
      </SSelectProjectStepListHeader>
      <SearchInput placeholder={searchPlaceholder} onChange={(e) => setSearchTerm(e.target.value)} />
      <List
        dataSource={filterdDataSource}
        renderItem={(id) => (
          <SSelectProjectStepListItem key={id} selected={selectedItem === id} onMouseDown={() => onSelect(id)}>
            {items[id].name}
          </SSelectProjectStepListItem>
        )}
        height={380 - 56 - 53}
        itemSize={48}
        renderLoadingItem={() => <></>}
      />
    </SSelectProjectStepList>
  );
};
