import { CSSProgressiveHighlight } from "@caisy/league";
import styled from "styled-components";

export const SProjectLimitModalPricingCardsHeader = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  ${CSSProgressiveHighlight};
  color: var(--text-03);
  text-transform: uppercase;
  border-bottom: 1px solid var(--ui-03);
`;
