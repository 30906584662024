import { gql } from "@apollo/client";
import { fragment_blueprintResponse } from "../fragments/blueprintResponse.gql";

export const createBlueprint = gql`
  ${fragment_blueprintResponse}
  mutation CreateBlueprint($input: CreateBlueprintRequest) {
    CreateBlueprint(input: $input) {
      blueprint {
        ...fragment_blueprintResponse
      }
    }
  }
`;
