import produce from "immer";
import debounce from "lodash/debounce";
import { client } from "../../utils/client";
import { query_GetManyDocuments } from "../../graphql/queries/getManyDocuments.gql";
import {
  IBlueprintVariant,
  IDeleteDocumentResponse,
  IDocumentFieldResponse,
  IDuplicateDocumentResponse,
  IGetManyDocumentsRequestInput,
  IGetUnpublishedLinkedDocumentsRequestInput,
  IValidationError,
} from "../../interfaces/generated";
import { getDocumentById } from "../../graphql/queries/getDocumentById";
import { createDocument } from "../../graphql/mutations/createDocument.gql";
import { getUnpublishedLinkedDocuments } from "../../graphql/queries/getUnpublishedLinkedDocuments";
import uniq from "lodash/uniq";

import {
  IManyDocumentsState,
  IDocument,
  IDocumentField,
  IDocumentUsage,
  IDocumentSearchFilter,
  IDocumentFieldState,
} from "./types";
import { getManyDocumentFieldsByDocumentId } from "../../graphql/queries/getManyDocumentFieldsByDocumentId";
import { updateDocumentField } from "../../graphql/mutations/updateDocumentField.gql";
import { validateDocumentField } from "../../graphql/mutations/validateDocumentField.gql";
import { updateDocument } from "../../graphql/mutations/updateDocument.gql";
import { DocumentStatusEnum } from "../document-status/types";
import { deleteDocument } from "../../graphql/mutations/deleteDocument.gql";
import { duplicateDocument } from "../../graphql/mutations/duplicateDocument.gql";
import { deleteManyDocument } from "../../graphql/mutations/deleteManyDocument.gql";
import { validateDocument } from "../../graphql/mutations/validateDocument.gql";
import { message } from "@caisy/league";
import { I18n } from "../../provider/i18n";
import { CLIENT_EVENTS } from "../../constants/clientEvents";
import { getAllDocumentSnapshot } from "../../graphql/queries/getAllDocumentSnapshot.glq";
import { getDocumentSnapshot } from "../../graphql/queries/getDocumentSnapshot.gql";
import { getDocumentReferences } from "../../graphql/queries/getDocumentReferences.gql";
import { query_GetManyDocumentsWithFields } from "../../graphql/queries/getManyDocumentsWithFields.gql";
import { broadcastMutation } from "../../provider/collaboration/broadcasts/broadcastMutation";

const DOCUMENT_BLUEPRINT_VARIANTS = {
  document: IBlueprintVariant.BlueprintVariantDocument,
  asset: IBlueprintVariant.BlueprintVariantAsset,
  component: IBlueprintVariant.BlueprintVariantComponent,
};

export const getOpenedInlineDocumentName = (documentId, parentDocumentId) => {
  return `ih_${documentId}${parentDocumentId}`;
};

const triggerUpdateDocumentFieldEvent = ({ documentId, blueprintFieldId, locale, projectId, data }) => {
  if (typeof window !== "undefined") {
    const e = new CustomEvent(CLIENT_EVENTS.updateDocumentField, {
      bubbles: true,
      detail: { documentId, blueprintFieldId, locale, projectId, data },
    });
    window.dispatchEvent(e);
  }
};

const updateFieldDebounces = [];
const validateFieldDebounces = [];

const getDebouncedFieldUpdate = (key: string) => {
  if (!updateFieldDebounces[key]) {
    updateFieldDebounces[key] = debounce(
      async (set: (cb: (state: IManyDocumentsState) => void, merge: boolean, name: string) => void, input: any) => {
        set(
          produce<IManyDocumentsState>((state) => {
            state.document.updatingDocumentField = true;
          }),
          false,
          "document/updateField/start",
        );

        const prom = await client.mutate({
          mutation: updateDocumentField,
          variables: {
            input,
          },
        });

        triggerUpdateDocumentFieldEvent(input);
        const result = await prom;

        if (result?.data?.UpdateDocumentField?.document) {
          const { documentId, title, previewImageUrl, statusId, lastUpdatedByUserId, updatedAt } =
            result?.data?.UpdateDocumentField?.document;

          broadcastMutation({
            storeEvent: "document/updateField",
            response: result?.data?.UpdateDocumentField,
            request: input,
          });

          // This is to check if the document was previously `Published`, if it was, it needs to be set to `Changed`
          // otherwise, it can stay as it is
          const newStatusId = statusId === 2 ? 1 : statusId;

          set(
            produce((state: IManyDocumentsState) => {
              const currDoc = state.document.documents[documentId];
              const current = {
                ...currDoc,
                title,
                previewImageUrl,
                statusId: newStatusId,
                lastUpdatedByUserId,
                updatedAt,
              };
              state.document.documents[documentId] = current;
              state.document.updatingDocumentField = false;
            }),
            false,
            `document/updateField/merge/${documentId}`,
          );
        }
      },
      600,
      { leading: true, trailing: true },
    );
  }
  return updateFieldDebounces[key];
};
const getDebouncedValidateField = (key: string) => {
  if (!validateFieldDebounces[key]) {
    validateFieldDebounces[key] = debounce(
      async ({
        recipe,
        ...input
      }: {
        recipe: (result: any) => void;
        blueprintFieldId: string;
        documentFieldLocaleId: string;
        documentId: string;
        projectId: string;
      }) => {
        // blueprintBranch?: InputMaybe<Scalars["String"]>;
        // blueprintFieldId?: InputMaybe<Scalars["String"]>;
        // documentId?: InputMaybe<Scalars["String"]>;
        // environmentId?: InputMaybe<Scalars["String"]>;
        // locale?: InputMaybe<Scalars["String"]>;
        // projectId?: InputMaybe<Scalars["String"]>;
        const result = await client.mutate({
          mutation: validateDocumentField,
          variables: {
            input,
          },
        });

        broadcastMutation({
          storeEvent: "document/validateField",
          response: result?.data?.ValidateDocumentField,
          request: input,
        });

        recipe(result?.data?.ValidateDocumentField.validation);
      },
      1200,
    );
  }
  return validateFieldDebounces[key];
};

export const createDocumentSlice: (
  set: (cb: (state: IManyDocumentsState) => IManyDocumentsState, merge: boolean, name: string) => void,
  get: () => IManyDocumentsState,
) => IManyDocumentsState = (set, get) => ({
  document: {
    documents: {} as { [documentId: string]: IDocument },
    documentFields: {} as { [documentId: string]: { [blueprintFieldId: string]: IDocumentFieldState } },
    unpublishedDocuments: {} as { [parentDocumentId: string]: IDocument[] },
    isLoadingLinked: false,
    currentSearchResultIds: [],
    currentSearchTotalCount: 0,
    currentSearchFilter: {} as IDocumentSearchFilter,
    initialLoading: false,
    isNextPageLoading: false,
    isUpdating: false,
    currentPageRootDocumentId: null,
    loadedPages: 0,
    error: undefined,
    hasNextPage: true,
    endCursor: null,
    documentSnapshots: [],
    documentValidities: {},
    googleMapsScriptLoaded: false,
    documentReferences: {},
    updatingDocumentField: false,
    resetDocument: () => {
      set(
        produce((state: IManyDocumentsState) => {
          state.document.loadedPages = 0;
          state.document.currentSearchTotalCount = 0;
          state.document.isNextPageLoading = false;
          state.document.initialLoading = false;
          state.document.error = undefined;
          state.document.hasNextPage = true;
          state.document.isUpdating = false;
          state.document.documents = {};
          state.document.documentFields = {};
          state.document.currentSearchResultIds = [];
          state.document.currentSearchFilter = {};
        }),
        true,
        "document/reset",
      );
    },
    setCurrentPageRootDocumentId: (currentPageRootDocumentId: string) => {
      set(
        produce((state: IManyDocumentsState) => {
          state.document.currentPageRootDocumentId = currentPageRootDocumentId;
        }),
        true,
        "document/setCurrentPageRootDocumentId",
      );
    },
    getManyDocuments: async (
      variables: IGetManyDocumentsRequestInput,
      options: { withFields?: boolean; pageSize?: number },
    ) => {
      // const localCopyOfSearchHash = hash(variables);
      // currentSearchHash = `${localCopyOfSearchHash}`;
      // console.log("loadDocuments");
      const upcomingSearchFilter = {
        fieldFilter: variables.fieldFilter,
        fulltextFilter: variables.fulltextFilter,
        metaFilter: variables.metaFilter,
        projectId: variables.projectId,
        sort: variables.sort,
      };

      const replacingCurrentSearch =
        JSON.stringify(get().document.currentSearchFilter) != JSON.stringify(upcomingSearchFilter);

      set(
        produce((state: IManyDocumentsState) => {
          state.document.loadedPages = 0;
          state.document.initialLoading = true;
          state.document.endCursor = null;
          if (replacingCurrentSearch) {
            state.document.currentSearchFilter = upcomingSearchFilter;
            state.document.currentSearchResultIds = [];
          }
        }),
        false,
        "document/getManyDocuments/start",
      );

      try {
        // console.log("try catch,", variables);
        const { data } = await client.query({
          query: options?.withFields ? query_GetManyDocumentsWithFields : query_GetManyDocuments,
          variables: {
            input: {
              ...variables,
              ...(options?.pageSize && { first: options.pageSize }),
              // projectId: variables.projectId,
            },
          },
          fetchPolicy: "no-cache",
        });
        set(
          produce((state: IManyDocumentsState) => {
            state.document.initialLoading = false;
            state.document.currentSearchTotalCount = data?.GetManyDocuments?.connection?.totalCount || 0;
            state.document.loadedPages = state.document.loadedPages + 1;
            const edges = data?.GetManyDocuments?.connection?.edges || [];
            state.document.currentSearchResultIds = uniq(
              edges.length === 0 ? [] : edges?.map((edge) => edge?.node?.documentId) || [],
            );

            state.document.documents =
              data?.GetManyDocuments?.connection?.edges
                ?.map((edge) => edge?.node)
                ?.reduce((prev, document: IDocument) => {
                  const prevDoc = get().document.documents[document.documentId];
                  return {
                    ...{
                      ...prev,
                      [document.documentId]: {
                        ...document,

                        title: prevDoc?.usage === IDocumentUsage.direct ? prevDoc.title : document.title,
                        previewImageUrl:
                          prevDoc?.usage === IDocumentUsage.direct ? prevDoc.previewImageUrl : document.previewImageUrl,
                        statusId: prevDoc?.usage === IDocumentUsage.direct ? prevDoc.statusId : document.statusId,
                        usage:
                          prevDoc?.usage === IDocumentUsage.direct ? IDocumentUsage.direct : IDocumentUsage.indirect,
                      } as IDocument,
                    },
                  };
                }, state.document.documents) || state.document.documents;

            state.document.endCursor = data?.GetManyDocuments?.connection?.pageInfo?.endCursor;
            state.document.hasNextPage = data?.GetManyDocuments?.connection?.pageInfo?.hasNextPage;
          }),
          false,
          "document/getManyDocuments/response",
        );
        // if they do not match another search with different variables as triggered later which we do not want to overwrite
        // we consider the result as irrelevant if the search variables do not match anymore
        // if (localCopyOfSearchHash == currentSearchHash) {
        //   set((state) => {
        //     state.document.data = data;
        //     state.document.loadedPages = state.document.loadedPages + 1;
        //     state.document.dataSource = data?.GetManyDocuments?.connection?.edges?.map((edge) => edge.node);
        //     state.document.initialLoading = false;
        //   });
        // }
      } catch (err) {
        // if they do not match another search with different variables as triggered later which we do not want to overwrite
        // we consider the error result as irrelevant if the search variables do not match anymore
        console.error("error catched", err);
        // if (localCopyOfSearchHash == currentSearchHash) {
        //   set((state) => {
        //     state.document.error = err;
        //     state.document.initialLoading = false;
        //   });
        // }
      }
    },
    loadDocumentById: async ({ projectId, documentId, fromLinkedDocument }) => {
      let document = get().document.documents[documentId];

      if (!document) {
        try {
          const { data } = await client.query({
            query: getDocumentById,
            fetchPolicy: "no-cache",
            variables: {
              input: {
                projectId,
                documentId,
              },
            },
          });
          if (data?.GetDocumentById?.document?.documentId) {
            const loadedDocument = {
              ...data.GetDocumentById.document,
              usage: IDocumentUsage.direct,
            };
            set(
              produce((state: IManyDocumentsState) => {
                state.document.documents[data.GetDocumentById.document.documentId] = loadedDocument;
              }),
              false,
              "document/loadDocumentById/success",
            );

            document = loadedDocument;
          }
        } catch (error) {
          const is404 = `${error}`.includes("NotFound") || `${error}`.includes("404");
          if (is404) {
            if (!fromLinkedDocument) {
              message.error(<I18n selector="document.error_msg_not_found" fallback="404 - document not found"></I18n>);
            }
          }
          if (!(fromLinkedDocument && is404)) console.error(error);
        }
      }

      if (!document) return;

      const fieldsSate = get().document.documentFields[document.documentId];

      if (!fieldsSate) {
        try {
          // Get document fields
          set(
            produce((state: IManyDocumentsState) => {
              state.document.documentFields[documentId] = { isLoadingFields: true };
            }),
            false,
            `document/loadDocumentById/loadFieldsStart/${documentId}`,
          );

          const { data: fieldsData } = await client.query({
            query: getManyDocumentFieldsByDocumentId,
            fetchPolicy: "no-cache",
            variables: {
              input: {
                projectId,
                documentId,
              },
            },
          });

          if (fieldsData?.GetManyDocumentFieldsByDocumentId?.fields) {
            const { fields } = fieldsData.GetManyDocumentFieldsByDocumentId;

            const updated = fields.reduce((memo, input: IDocumentFieldResponse) => {
              const {
                field: { blueprintFieldId, documentFieldLocaleId, data },
                validation,
              } = input;
              if (!memo[blueprintFieldId]) {
                memo[blueprintFieldId] = {};
              }

              memo[blueprintFieldId][documentFieldLocaleId];
              if (!memo[blueprintFieldId][documentFieldLocaleId]) {
                memo[blueprintFieldId][documentFieldLocaleId] = {};
              }

              memo[blueprintFieldId][documentFieldLocaleId] = {
                error: validation?.errors, // should update error here
                data,
              };

              return memo;
            }, {});

            set(
              produce((state: IManyDocumentsState) => {
                state.document.documentFields[documentId] = updated;
                state.document.documentFields[documentId].isLoadingFields = false;
              }),
              false,
              `document/loadDocumentById/loadFields/${documentId}`,
            );
          }
        } catch (err) {
          console.error(`getManyDocumentFieldsByDocumentId err:`, err);
        }
      }

      return document;
    },
    loadManyDocumentByIds: async (projectId: string, documentIds: string[], fromLinkedDocument) => {
      if (!documentIds) return null;
      try {
        const promises = documentIds.map(async (documentId) => {
          return get().document.loadDocumentById({ projectId, documentId, fromLinkedDocument });
        });

        return Promise.all(promises);
      } catch (err) {}
    },
    loadNextPage: async (
      variables: IGetManyDocumentsRequestInput,
      options: { withFields?: boolean; pageSize?: number },
    ) => {
      const { withFields, pageSize } = options || {};
      if (!get().document.hasNextPage || get().document.isNextPageLoading) {
        return;
      }

      try {
        set(
          produce<IManyDocumentsState>((state) => {
            state.document.isNextPageLoading = true;
          }),
          false,
          "document/loadNextPage/loading",
        );
        // const currentSearchResultIds = get().document.currentSearchResultIds;
        const endCursor = get().document.endCursor;
        const { data } = await client.query({
          query: withFields ? query_GetManyDocumentsWithFields : query_GetManyDocuments,
          variables: {
            input: {
              ...variables,
              paginationArguments: {
                first: pageSize || 100,
                after: endCursor,
              },
            },
          },
          fetchPolicy: "no-cache",
        });
        set(
          produce((state: IManyDocumentsState) => {
            state.document.initialLoading = false;
            state.document.currentSearchTotalCount = data?.GetManyDocuments?.connection?.totalCount || 0;
            state.document.loadedPages = state.document.loadedPages + 1;
            state.document.hasNextPage = data?.GetManyDocuments?.connection?.pageInfo?.hasNextPage;
            const edges = data?.GetManyDocuments?.connection?.edges || [];
            state.document.currentSearchResultIds = [
              ...(state.document.currentSearchResultIds || ([] as string[])),
              ...(uniq(edges.length === 0 ? [] : edges?.map((edge) => edge?.node?.documentId) || []) as string[]),
            ];

            state.document.documents =
              data?.GetManyDocuments?.connection?.edges
                ?.map((edge) => edge?.node)
                ?.reduce((prev, document: IDocument) => {
                  const prevDoc = get().document.documents[document.documentId];
                  return {
                    ...{
                      ...prev,
                      [document.documentId]: {
                        ...document,

                        title: prevDoc?.usage === IDocumentUsage.direct ? prevDoc.title : document.title,
                        previewImageUrl:
                          prevDoc?.usage === IDocumentUsage.direct ? prevDoc.previewImageUrl : document.previewImageUrl,
                        statusId: prevDoc?.usage === IDocumentUsage.direct ? prevDoc.statusId : document.statusId,
                        usage:
                          prevDoc?.usage === IDocumentUsage.direct ? IDocumentUsage.direct : IDocumentUsage.indirect,
                      } as IDocument,
                    },
                  };
                }, state.document.documents) || state.document.documents;
            state.document.endCursor = data?.GetManyDocuments?.connection?.pageInfo?.endCursor;
            state.document.isNextPageLoading = false;
          }),
          false,
          "document/loadNextPage/response",
        );
      } catch (error) {
        console.error(error);
      }
    },
    createDocument: async (input) => {
      try {
        const { data } = await client.mutate({
          mutation: createDocument,
          variables: {
            input,
          },
        });

        if (data?.CreateDocument?.document) {
          set(
            produce((state: IManyDocumentsState) => {
              const documentId = data.CreateDocument.document.documentId;

              state.document.documents[documentId] = {
                ...data.CreateDocument.document,
                usage: IDocumentUsage.direct,
              };

              get()
                .document.isDocumentWithinSearchQuery({
                  documentId,
                  projectId: input.projectId,
                  variant: data.CreateDocument.document.blueprintVariant,
                })
                .then((isWithinSearchQuery) => {
                  if (isWithinSearchQuery) {
                    get().document.addIdToSearchResults(documentId, input.projectId);
                  }
                });

              // produce an initial empty value instead of undefined
              // this will signal that the field is already initialized
              let currentDoc = state.document.documentFields[documentId];
              if (!currentDoc) {
                currentDoc = state.document.documentFields[documentId] = {};
              }
            }),
            false,
            "document/createDocument",
          );
          return data.CreateDocument.document;
        }
      } catch (err) {
        if (err.toString().includes("ResourceExhausted")) {
          message.error(
            <I18n
              selector="documentErrorMessages.documentQuota"
              fallback="Quota breached: max entries the are allowed in your plan exhauseted."
            />,
            {
              duration: 5000,
            },
          );

          return;
        }
        if (err.toString().includes("AlreadyExists")) {
          message.error(
            <I18n
              selector="documentErrorMessages.alreadyExists"
              fallback="Document already exists. There can be only one of this blueprint."
            />,
            {
              duration: 3000,
            },
          );
          return;
        }
        console.error(err);
      }
    },

    updateFieldValue: async ({
      projectId,
      input,
      documentFieldLocaleId,
      documentId,
    }: {
      projectId: string;
      input: IDocumentField;
      documentFieldLocaleId: string;
      documentId: string;
    }) => {
      const { blueprintFieldId, data } = input;
      const key = `${blueprintFieldId}/${documentFieldLocaleId}`;
      set(
        produce((state: IManyDocumentsState) => {
          let currentDoc = state.document.documentFields[documentId];
          if (!currentDoc) {
            currentDoc = state.document.documentFields[documentId] = {};
          }

          if (!currentDoc[blueprintFieldId]) {
            currentDoc[blueprintFieldId] = {};
          }

          currentDoc[blueprintFieldId][documentFieldLocaleId] = {
            ...currentDoc[blueprintFieldId][documentFieldLocaleId],
            error: null,
            data,
          };
        }),
        false,
        `document/updateFieldValue/${key}`,
      );

      // Mutate fire and forget
      const updateField = getDebouncedFieldUpdate(`${input.blueprintFieldId}_${documentFieldLocaleId}`);
      updateField(set, {
        blueprintFieldId: input.blueprintFieldId,
        data,
        documentId,
        documentFieldLocaleId: documentFieldLocaleId,
        projectId,
      });

      const validateField = getDebouncedValidateField(`${input.blueprintFieldId}_${documentFieldLocaleId}`);
      validateField({
        recipe: (validation) => {
          set(
            produce((state: IManyDocumentsState) => {
              const currentDoc = state.document.documentFields[documentId];
              currentDoc[blueprintFieldId][documentFieldLocaleId] = {
                ...currentDoc[blueprintFieldId][documentFieldLocaleId],
                error: validation?.errors,
              };
            }),
            false,
            `document/updateFieldValue/validateField/${key}`,
          );
        },
        blueprintFieldId: input.blueprintFieldId,
        documentId,
        documentFieldLocaleId: documentFieldLocaleId,
        projectId,
      });
    },

    getFieldValueAllLocales: (input: IDocumentField, documentId: string) => {
      const currentDoc = get().document.documentFields[documentId];
      const field = currentDoc?.[input.blueprintFieldId];
      return field;
    },

    getFieldValue: (input: IDocumentField, documentFieldLocaleId, documentId: string) => {
      const documentFields = get().document.documentFields[documentId];
      const field = documentFields?.[input.blueprintFieldId]?.[documentFieldLocaleId];
      return field?.data;
    },

    getFieldState: (input: IDocumentField, documentFieldLocaleId, documentId: string) => {
      if (!documentId) return null;

      const documentFields = get().document.documentFields[documentId];
      const field = documentFields?.[input.blueprintFieldId]?.[documentFieldLocaleId];
      return field || {};
    },

    updateFieldValidation: (
      input: IDocumentField,
      documentFieldLocaleId: string,
      error: IValidationError[],
      documentId: string,
    ) => {
      const { blueprintFieldId } = input;
      const key = `${blueprintFieldId}/${documentFieldLocaleId}`;
      set(
        produce((state: IManyDocumentsState) => {
          let currentDoc = state.document.documentFields[documentId];
          if (!currentDoc) {
            currentDoc = state.document.documentFields[documentId] = {};
          }

          if (!currentDoc[blueprintFieldId]) {
            currentDoc[blueprintFieldId] = {};
          }

          currentDoc[blueprintFieldId][documentFieldLocaleId] = {
            ...currentDoc[blueprintFieldId][documentFieldLocaleId],
            error,
          };
        }),
        false,
        `document/updateFieldValidation/${key}`,
      );
    },

    getDocumentErrors: (documentId: string): IValidationError[] | null => {
      const documentState = get().document.documentFields[documentId];
      if (!documentState) return null;
      const errors = Object.values(documentState).reduce((memo, fieldState) => {
        if (fieldState.error?.length) {
          memo = memo || [];
          memo = [...memo, ...fieldState.error];
        }

        return memo;
      }, null);

      return errors;
    },

    getDocumentFieldLoadingStatus: (documentId: string) => {
      const documentState = get().document.documentFields[documentId];
      if (!documentState) return null;
      return documentState.isLoadingFields;
    },

    publishDocumentById: async ({ projectId, documentId }) => {
      set(
        produce((state) => {
          state.document.isUpdating = true;
        }),
        false,
        `document/publishDocumentById/${documentId}`,
      );
      const doc = get().document.documents[documentId];

      const input = {
        projectId,
        documentId,
        statusId: DocumentStatusEnum.PUBLISHED,
        title: doc?.title,
        previewImageUrl: doc?.previewImageUrl,
      };

      const { data } = await client.mutate({
        mutation: updateDocument,
        variables: {
          input,
        },
      });

      if (data?.UpdateDocument?.document) {
        broadcastMutation({
          storeEvent: "document/updateDocument",
          response: data?.UpdateDocument,
          request: input,
        });

        const { document } = data.UpdateDocument;

        set(
          produce((state) => {
            state.document.documents[document.documentId] = document;
          }),
          false,
          `document/publishDocumentById/${data?.UpdateDocument?.document.documentId}`,
        );
      }
      set(
        produce((state) => {
          state.document.isUpdating = false;
        }),
        false,
        `document/publishDocumentById/done/${documentId}`,
      );
    },

    unpublishDocumentById: async ({ projectId, documentId }) => {
      set(
        produce((state) => {
          state.document.isUpdating = true;
        }),
        false,
        `document/unpublishDocumentById/${documentId}`,
      );

      const doc = get().document.documents[documentId];

      const input = {
        projectId,
        documentId,
        statusId: DocumentStatusEnum.DRAFT,
        title: doc?.title,
        previewImageUrl: doc?.previewImageUrl,
      };

      const { data } = await client.mutate({
        mutation: updateDocument,
        variables: {
          input,
        },
      });

      if (data?.UpdateDocument?.document) {
        broadcastMutation({
          storeEvent: "document/updateDocument",
          response: data?.UpdateDocument,
          request: input,
        });

        const { document } = data.UpdateDocument;

        set(
          produce((state) => {
            state.document.documents[document.documentId] = document;
          }),
          false,
          `document/unpublishDocumentById/${data?.UpdateDocument?.document.documentId}`,
        );
      }
      set(
        produce((state) => {
          state.document.isUpdating = false;
        }),
        false,
        `document/unpublishDocumentById/done/${documentId}`,
      );
    },

    deleteDocumentById: async ({ documentId, projectId, isBulkOperation }) => {
      set(
        produce((state) => {
          state.document.isUpdating = true;
        }),
        false,
        `document/deleteDocumentById/${documentId}`,
      );

      const { data } = await client.mutate({
        mutation: deleteDocument,
        variables: {
          input: { projectId, documentId },
        },
      });

      if ((data?.DeleteDocument as IDeleteDocumentResponse)?.deleted) {
        set(
          produce<IManyDocumentsState>((state) => {
            if (!isBulkOperation) {
              state.document.currentSearchResultIds = state.document.currentSearchResultIds.filter(
                (id) => documentId !== id,
              );
            }
            delete state.document.documents[documentId];
          }),
          false,
          `document/deleteDocumentById/${documentId}`,
        );
      }

      broadcastMutation({
        storeEvent: "document/deleteDocumentById",
        response: data?.DeleteDocument,
        request: { projectId, documentId },
      });

      set(
        produce((state) => {
          state.document.isUpdating = false;
        }),
        false,
        `document/deleteDocumentById/done/${documentId}`,
      );
    },

    publishManyDocuments: async ({ documentIds, projectId }) => {
      await Promise.all(documentIds.map((documentId) => get().document.publishDocumentById({ documentId, projectId })));
    },

    unpublishManyDocuments: async ({ documentIds, projectId }) => {
      await Promise.all(
        documentIds.map((documentId) => get().document.unpublishDocumentById({ documentId, projectId })),
      );
    },

    deleteManyDocuments: async ({ documentIds, projectId }) => {
      const { data } = await client.mutate({
        mutation: deleteManyDocument,
        variables: {
          input: {
            deleteDocumentRequests: documentIds.map((documentId) => ({
              documentId,
              projectId,
            })),
          },
        },
      });

      if (data.DeleteManyDocuments?.batchErrors) {
        data.DeleteManyDocuments?.batchErrors.map((error) => message.error(error));
      }

      broadcastMutation({
        storeEvent: "document/deleteManyDocuments",
        response: data.DeleteManyDocuments,
        request: {
          deleteDocumentRequests: documentIds.map((documentId) => ({
            documentId,
            projectId,
          })),
        },
      });

      set(
        produce<IManyDocumentsState>((state) => {
          let deltedCountFromSearchResults = 0;
          state.document.currentSearchResultIds = state.document.currentSearchResultIds.filter((id) => {
            const match = documentIds.includes(id);
            if (match) {
              deltedCountFromSearchResults++;
            }
            return !match;
          });

          state.document.currentSearchTotalCount -= deltedCountFromSearchResults;

          documentIds.forEach((documentId) => {
            delete state.document.documents[documentId];
          });
        }),
        false,
        `document/deleteManyDocuments/done`,
      );
    },

    duplicateDocumentById: async ({ documentId, projectId, blueprintId }) => {
      try {
        const { data } = await client.mutate({
          mutation: duplicateDocument,
          variables: {
            input: { projectId, documentId, blueprintId },
          },
        });

        if (data?.DuplicateDocument?.response) {
          broadcastMutation({
            storeEvent: "document/duplicateDocumentById",
            response: data.DuplicateDocument,
            request: { projectId, documentId, blueprintId },
          });

          const { response } = data?.DuplicateDocument as IDuplicateDocumentResponse;
          set(
            produce<IManyDocumentsState>((state) => {
              state.document.currentSearchResultIds = [response.documentId, ...state.document.currentSearchResultIds];
              state.document.documents[response.documentId] = { ...response, usage: IDocumentUsage.direct };
            }),
            false,
            `document/duplicateDocumentById/${documentId}`,
          );

          return response;
        }
      } catch (err) {
        if (err.toString().includes("ResourceExhausted")) {
          message.error(
            <I18n
              selector="documentErrorMessages.documentQuota"
              fallback="Quota breached: max entries the are allowed in your plan exhauseted."
            />,
            {
              duration: 5000,
            },
          );

          return;
        }
        if (err.toString().includes("AlreadyExists")) {
          message.error(
            <I18n
              selector="documentErrorMessages.alreadyExists"
              fallback="Document already exists. There can be only one of this blueprint."
            />,
            {
              duration: 3000,
            },
          );
          return;
        }
        console.error(err);
      }
    },

    duplicateManyDocuments: async ({ documents, projectId }) => {
      await Promise.all(
        documents.map((d) =>
          get().document.duplicateDocumentById({ documentId: d.documentId, blueprintId: d.blueprintId, projectId }),
        ),
      );
    },
    publishDocumentList: async (documents: IDocument[]) => {
      return Promise.all(
        documents?.map(async (doc) => {
          const { projectId, documentId } = doc;

          const docInStore = get().document.documents[documentId];

          const input = {
            projectId,
            documentId,
            statusId: DocumentStatusEnum.PUBLISHED,
            title: docInStore?.title,
            previewImageUrl: docInStore?.previewImageUrl,
          };

          const { data } = await client.mutate({
            mutation: updateDocument,
            variables: {
              input,
            },
          });

          if (data?.UpdateDocument?.document) {
            broadcastMutation({
              storeEvent: "document/updateDocument",
              response: data?.UpdateDocument,
              request: input,
            });

            const merged = {
              ...doc,
              ...data?.UpdateDocument?.document,
            };

            set(
              produce((state) => {
                state.document.documents[merged.documentId] = merged;
              }),
              false,
              `document/publishDocumentList/merge/${doc.documentId}`,
            );
            return merged;
          }
        }),
      );
    },
    getUnpublishedLinkedDocuments: async (input: IGetUnpublishedLinkedDocumentsRequestInput) => {
      let result: IDocument[];

      set(
        produce((state) => {
          state.document.isLoadingLinked = true;
          state.document.unpublishedDocuments[input.documentId] = null;
        }),
        false,
        `document/getUnpublishedLinkedDocuments/${input.documentId}`,
      );

      const { data } = await client.query({
        query: getUnpublishedLinkedDocuments,
        variables: {
          input,
        },
        fetchPolicy: "no-cache",
      });

      if (data?.GetUnpublishedLinkedDocuments?.document) {
        result = data?.GetUnpublishedLinkedDocuments?.document;
        set(
          produce((state) => {
            state.document.unpublishedDocuments[input.documentId] = result;
          }),
          false,
          `document/getUnpublishedLinkedDocuments/merge/${input.documentId}`,
        );
      }
      set(
        produce((state) => {
          state.document.isLoadingLinked = false;
        }),
        false,
        `document/getUnpublishedLinkedDocuments/done/${input.documentId}`,
      );

      return result;
    },

    clearUnpublishedLinkedDocuments: (documentId: string) => {
      set(
        produce((state) => {
          state.document.isLoadingLinked = false;
          state.document.unpublishedDocuments[documentId] = null;
        }),
        false,
        `document/clearUnpublishedLinkedDocuments/${documentId}`,
      );
    },
    checkDocumentValidity: async ({ documentId, projectId }) => {
      try {
        const { data } = await client.mutate({
          mutation: validateDocument,
          variables: { input: { projectId, documentId } },
        });

        const validity = data.ValidateDocument.validation.valid;
        const errors = data.ValidateDocument.validation.errors;
        const documentTitle = get().document.documents[documentId]?.title;

        set(
          produce((state) => {
            state.document.documentValidities[documentId] = validity;
          }),
          false,
          `document/checkDocumentValidity/${documentId}`,
        );

        return { validity, documentId, documentTitle, errors };
      } catch (err) {
        console.error(`checkDocumentValidity err: `, err);
        return { validity: false, documentId, documentTitle: "", errors: [err] };
      }
    },
    checkManyDocumentsValidity: async ({ documentIds, projectId }) => {
      const allValidities = await Promise.all(
        documentIds.map((documentId) => get().document.checkDocumentValidity({ documentId, projectId })),
      );
      return allValidities;
    },

    addIdToSearchResults: async (documentId, projectId) => {
      const document = await get().document.loadDocumentById({ projectId, documentId });

      set(
        produce<IManyDocumentsState>((state) => {
          state.document.documents[document.documentId] = { ...document, usage: IDocumentUsage.direct };
          if (!get().document.currentSearchResultIds.includes(document.documentId))
            state.document.currentSearchResultIds = [documentId, ...get().document.currentSearchResultIds];
        }),
        false,
        `document/setCurrentSearchResultIds`,
      );
    },
    validateDocumentAndUpdateFieldsValidity: async ({ documentId, projectId }) => {
      const { validity, errors } = await get().document.checkDocumentValidity({ projectId, documentId });

      if (!validity) {
        const fieldsWithErrors = [];
        errors.forEach((error) =>
          fieldsWithErrors.push({
            blueprintFieldId: error.blueprintFieldId,
            // @ts-ignore
            documentFieldLocaleId: error.documentFieldLocaleId,
            documentId,
            error: error,
          }),
        );

        fieldsWithErrors.forEach(({ blueprintFieldId, documentFieldLocaleId, documentId, error }) => {
          const fieldValue = get().document.getFieldState(
            { blueprintFieldId, __typename: "DocumentField", usage: IDocumentUsage.indirect },
            documentFieldLocaleId,
            documentId,
          );

          get().document.updateFieldValidation(
            {
              blueprintFieldId,
              __typename: "DocumentField",
              usage: IDocumentUsage.indirect,
              data: fieldValue,
            },
            documentFieldLocaleId,
            [error],
            documentId,
          );
        });
      }
    },
    updateLocalDocument: (document) => {
      set(
        produce<IManyDocumentsState>((state) => {
          state.document.documents[document.documentId] = {
            ...get().document.documents[document.documentId],
            ...document,
          };
        }),
        false,
        `document/uploadLocalDocument/${document.documentId}`,
      );
    },
    getAllDocumentSnapshots: async ({ documentId, projectId }) => {
      const { data } = await client.query({
        query: getAllDocumentSnapshot,
        variables: { input: { projectId, documentId } },
        fetchPolicy: "no-cache",
      });

      const { documentSnapshots } = data.GetAllDocumentSnapshot;

      if (documentSnapshots) {
        set(
          produce<IManyDocumentsState>((state) => {
            state.document.documentSnapshots = documentSnapshots;
          }),
          false,
          "document/getAllDocumentSnapshots",
        );
      }
    },
    getDocumentSnapshot: async ({ snapshotId, projectId }) => {
      const { data } = await client.query({
        query: getDocumentSnapshot,
        fetchPolicy: "no-cache",
        variables: { input: { projectId, snapshotId } },
      });

      const { document } = data.GetDocumentSnapshot;

      return document;
    },
    applyVersionChanges: async ({
      fields,
      documentId,
      projectId,
    }: {
      fields: {
        [blueprintFieldId: string]: {
          [documentFieldLocaleId: string]: IDocumentField;
        };
      };
      documentId: string;
      projectId: string;
    }) => {
      const promises: (() => Promise<any>)[] = [];

      Object.values(fields).forEach((value) => {
        if (value)
          Object.values(value).forEach((locale) => {
            promises.push(async () => {
              const input = {
                blueprintFieldId: locale.blueprintFieldId,
                data: locale.data,
                documentId,
                documentFieldLocaleId: locale.documentFieldLocaleId,
                projectId,
              };

              const result = await client.mutate({
                mutation: updateDocumentField,
                variables: {
                  input,
                },
              });

              broadcastMutation({
                storeEvent: "document/updateField",
                response: result?.data?.UpdateDocumentField,
                request: input,
              });
            });
          });
      });

      await Promise.all(promises.map((promise) => promise()));

      Object.values(fields).forEach((value) => {
        if (value)
          Object.values(value).forEach((locale) => {
            set(
              produce((state: IManyDocumentsState) => {
                let currentDoc = state.document.documentFields[documentId];
                if (!currentDoc) {
                  currentDoc = state.document.documentFields[documentId] = {};
                }

                if (!currentDoc[locale.blueprintFieldId]) {
                  currentDoc[locale.blueprintFieldId] = {};
                }

                currentDoc[locale.blueprintFieldId][locale.documentFieldLocaleId] = {
                  ...currentDoc[locale.blueprintFieldId][locale.documentFieldLocaleId],
                  error: null,
                  data: locale.data,
                };
              }),
              false,
              `document/updateFieldValue/${locale.blueprintFieldId}-${locale.documentFieldLocaleId}`,
            );
          });
      });
    },

    isDocumentWithinSearchQuery: async ({ documentId, projectId, variant }) => {
      let isDocumentWithinSearchQuery = true;

      const query = get().document.currentSearchFilter;

      const document = await get().document.loadDocumentById({
        documentId,
        projectId,
      });

      if (!document) {
        return false;
      }

      if (
        query &&
        query.metaFilter &&
        query.metaFilter.blueprint &&
        document.blueprintId !== query.metaFilter.blueprint.blueprintId
      ) {
        isDocumentWithinSearchQuery = false;
      }

      if (DOCUMENT_BLUEPRINT_VARIANTS[variant] !== query.metaFilter?.variant?.variant) {
        isDocumentWithinSearchQuery = false;
      }

      return isDocumentWithinSearchQuery;
    },
    setGoogleMapsScriptLoaded: (value: boolean) => {
      set(
        produce((state) => {
          state.document.googleMapsScriptLoaded = value;
        }),
        false,
        `document/setGoogleMapsScriptLoaded/${value}`,
      );
    },

    openedInlineDocuments: {},
    toggleOpenedInlineDocument: (input: { documentId: string; parentDocumentId: string }) => {
      const documentName = getOpenedInlineDocumentName(input.documentId, input.parentDocumentId);
      set(
        produce((state) => {
          if (!state.document.openedInlineDocuments[documentName]) {
            state.document.openedInlineDocuments[documentName] = true;
          } else {
            state.document.openedInlineDocuments[documentName] = false;
          }
        }),
        false,
        "document/toggleOpenedInlineDocument",
      );
    },
    getDocumentReferences: async (input) => {
      const { data } = await client.query({
        query: getDocumentReferences,
        variables: { input },
      });

      if (!data.GetDocumentReferences) return;

      const { pageInfo, totalCount } = data.GetDocumentReferences.connection;
      const { hasNextPage, endCursor } = pageInfo;

      set(
        produce<IManyDocumentsState>((state) => {
          if (!state.document.documentReferences[input.documentId]) {
            state.document.documentReferences[input.documentId] = {};
          }

          data.GetDocumentReferences.connection.edges.forEach(({ node }) => {
            state.document.documentReferences[input.documentId] = {
              ...state.document.documentReferences[input.documentId],
              [node.documentId]: node,
            };
          });

          // state.document.documentReferences[input.documentId] = [
          //   ...state.document.documentReferences[input.documentId],
          //   ...data.GetDocumentReferences.connection.edges.map(({ node }) => node),
          // ];
        }),
        false,
        "document/getDocumentReferences",
      );

      return { hasNextPage, endCursor, totalCount };
    },

    richtextFieldEditors: {},
    addRichtextFieldEditor: ({ key, editor }) => {
      set(
        produce<IManyDocumentsState>((state) => {
          state.document.richtextFieldEditors[key] = editor;
        }),
        false,
        "document/addRichtextFieldEditor",
      );
    },
    applyPeerUpdateDocument: (document) => {
      const doc = get().document.documents[document.documentId];
      const merged = {
        ...doc,
        ...document,
      };

      set(
        produce((state) => {
          state.document.documents[merged.documentId] = merged;
        }),
        false,
        `document/applyPeerUpdateDocument/${document.documentId}`,
      );
    },
    applyPeerDocumentFieldUpdate: (update) => {
      const { request: input, response } = update;
      const { blueprintFieldId, data, documentFieldLocaleId, documentId } = input;
      const currentFieldDoc = get().document.documentFields[documentId];

      if (currentFieldDoc) {
        set(
          produce((state: IManyDocumentsState) => {
            let currentDoc = state.document.documentFields[documentId];
            if (!currentDoc) {
              currentDoc = state.document.documentFields[documentId] = {};
            }

            if (!currentDoc[blueprintFieldId]) {
              currentDoc[blueprintFieldId] = {};
            }

            currentDoc[blueprintFieldId][documentFieldLocaleId] = {
              ...currentDoc[blueprintFieldId][documentFieldLocaleId],
              error: null,
              data,
            };
          }),
          false,
          `document/applyPeerDocumentFieldUpdate/updateField/${blueprintFieldId}-${documentFieldLocaleId}`,
        );

        triggerUpdateDocumentFieldEvent(input);
      }

      const currentDoc = get().document.documents[documentId];

      if (currentDoc) {
        const { title, previewImageUrl, statusId, lastUpdatedByUserId, updatedAt } = response.document;

        // This is to check if the document was previously `Published`, if it was, it needs to be set to `Changed`
        // otherwise, it can stay as it is
        const newStatusId = statusId === 2 ? 1 : statusId;

        set(
          produce((state: IManyDocumentsState) => {
            const currDoc = state.document.documents[documentId];
            const current = {
              ...currDoc,
              title,
              previewImageUrl,
              statusId: newStatusId,
              lastUpdatedByUserId,
              updatedAt,
            };
            state.document.documents[documentId] = current;
          }),
          false,
          `document/applyPeerDocumentFieldUpdate/document/${documentId}`,
        );
      }
    },
    applyPeerFieldValidation: (update) => {
      const documentId = update.request.documentId;
      const validity = update.response.validation.valid;

      set(
        produce((state) => {
          state.document.documentValidities[documentId] = validity;
        }),
        false,
        `document/applyPeerFieldValidation/${documentId}`,
      );
    },
    applyPeerDeleteDocument: (update) => {
      const documentId = update.request.documentId;

      set(
        produce((state) => {
          delete state.document.documents[documentId];
        }),
        false,
        `document/applyPeerDeleteDocument/${documentId}`,
      );
    },
    applyPeerDuplicateDocument: (update) => {
      const duplicatedDocumentId = update.response.response.documentId;

      set(
        produce((state) => {
          state.document.documents[duplicatedDocumentId] = {
            ...update.response.response,
            usage: IDocumentUsage.direct,
          };
          state.document.currentSearchResultIds = [duplicatedDocumentId, ...state.document.currentSearchResultIds];
        }),
        false,
        `document/applyPeerDuplicateDocument/${duplicatedDocumentId}`,
      );
    },

    updateDocumentTitle: async (update) => {
      const input = {
        projectId: update.projectId,
        documentId: update.documentId,
        title: update.title,
        previewImageUrl: update?.previewImageUrl,
        statusId: update.statusId,
      };

      const { data } = await client.mutate({
        mutation: updateDocument,
        variables: {
          input,
        },
      });

      broadcastMutation({
        storeEvent: "document/updateDocument",
        response: data?.UpdateDocument,
        request: input,
      });

      if (data?.UpdateDocument?.document) {
        set(
          produce<IManyDocumentsState>((state) => {
            state.document.documents[update.documentId] = data.UpdateDocument.document;
          }),
          false,
          "document/updateDocumentTitle",
        );
      }
    },
  },
});
