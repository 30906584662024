import { FC, useRef, useState } from "react";
import { CreateProjectForm } from "../create-project-form/CreateProjectForm";
import { SelectOrganizationStep } from "./select-organizaiton-step/SelectOrganizationStep";
import { SelectProjectStep } from "./select-project-step/SelectProjectStep";
import { useMemberShip } from "../../../../../stores/membership/useMembership";
import { DuplicateProjectProgressModal } from "../../../../page/management/duplicate-project-progress-modal/DuplicateProjectProgressModal";
import {
  ALL_PROJECT_SECTION_KEYS,
  AddProjectModalSections,
} from "../../../../page/management/add-project-modal-sections/AddProjectModalSections";
import { SAddProjectModalSectionsContainer } from "./styles/SAddProjectModalSectionsContainer";
import { SCreateProjectModalContentContainer } from "../styles/SCreateProjectModalContentContainer";
import { Button, Spacer } from "@caisy/league";
import { useUserOnboarding } from "../../../../../stores/user-onboarding/useUserOnboarding";
import { useUpload } from "../../../../../stores/upload/useUpload";
import { I18n } from "../../../../../provider/i18n";
import { SDuplicateProjectProgressModalButton } from "../../../../page/management/duplicate-project-progress-modal/styles/SDuplicateProjectProgressModalButton";
import { paramsToUrlString } from "../../../../../utils/getHrefWithParams";
import { useRouter } from "next/router";
import { fireAll } from "../../../onboarding-tour/OnboardingTourCard/OnboardingTourCard";
import { SCreateProjectModalContentTitle } from "../styles/SCreateProjectModalContentTitle";
import { processImage } from "../utils";

interface ICreateFromExisting {
  onCancel: () => void;
  advanceStep: () => void;
  currentStep: number;
  onClose: () => void;
  backStep: () => void;
  organizationId: string;
  groupId: string;
}

export const CreateFromExisting: FC<ICreateFromExisting> = ({
  onCancel,
  onClose,
  currentStep,
  advanceStep,
  organizationId,
  groupId,
  backStep,
}) => {
  const buttonRef = useRef();
  const router = useRouter();
  const { createProject, duplicateToProject, projects, resetDuplicationProgress, updateProject, duplicationProgress } =
    useMemberShip();
  const { logoBlob } = useUserOnboarding();
  const { handleFile } = useUpload();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [portId, setPortId] = useState(null);
  const [duplicating, setDuplicating] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);
  const [newProjectId, setNewProjectId] = useState(null);

  const handleCancel = () => {
    setSelectedOrganization(null);
    setSelectedGroup(null);
    setSelectedProject(null);
    resetDuplicationProgress();
    onCancel();
  };

  const onCreateProject = async (data: any) => {
    const project: any = { name: data.name };

    if (groupId && groupId !== "ungrouped") {
      project.groupId = groupId;
    }

    const createdProject = await createProject({
      project,
      organizationId,
    });

    if (!createdProject) return;

    setNewProjectId(createdProject.projectId);

    if (data.logo) {
      const projectId = createdProject.projectId;
      const logoAssetUrl = await processImage(logoBlob, projectId, handleFile);
      await updateProject({
        projectId,
        input: { logoAssetUrl: logoAssetUrl as string, name: data.name },
      });
    }

    const portId = await duplicateToProject({
      projectId: createdProject.projectId,
      source: { projectId: selectedProject },
      selection: {
        blueprint: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.blueprint),
        document: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.document),
        documentFieldLocale: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.documentFieldLocale),
        documentHistory: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.documentHistory),
        member: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.member),
        preview: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.preview),
        tag: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.tag),
        view: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.view),
        webhook: selectedSections.includes(ALL_PROJECT_SECTION_KEYS.webhook),
      },
    });
    setPortId(portId);
    setDuplicating(true);
    // onClose();
  };

  return (
    <>
      {currentStep === 2 ? (
        <SelectOrganizationStep
          onSelect={setSelectedOrganization}
          selectedOrganization={selectedOrganization}
          onContinue={advanceStep}
        />
      ) : null}
      {currentStep === 3 ? (
        <SelectProjectStep
          selectedOrganization={selectedOrganization}
          selectedGroup={selectedGroup}
          selectedProject={selectedProject}
          onSelectGroup={setSelectedGroup}
          onSelectProject={setSelectedProject}
          onContinue={advanceStep}
          onChange={() => {
            setSelectedOrganization(null);
            backStep();
          }}
        />
      ) : null}
      {currentStep === 4 ? (
        <SCreateProjectModalContentContainer>
          <div>
            <SCreateProjectModalContentTitle>
              <I18n selector="nav.createProject.duplicateSettings" fallback="Duplicate Settings" />
            </SCreateProjectModalContentTitle>
            <SAddProjectModalSectionsContainer>
              <AddProjectModalSections
                project={projects[selectedProject]}
                selectedSections={selectedSections}
                setSelectedSections={setSelectedSections}
              />
            </SAddProjectModalSectionsContainer>
            <Spacer bronze={24} />
            <Button onClick={advanceStep} style={{ width: "100%" }} type="primary">
              <I18n selector="nav.createProject.continue" fallback="Continue" />
            </Button>
          </div>
        </SCreateProjectModalContentContainer>
      ) : null}
      {currentStep === 5 ? (
        !duplicating ? (
          <SCreateProjectModalContentContainer>
            <div>
              <SCreateProjectModalContentTitle>
                <I18n selector="nav.createProject.newProjectDetails" fallback="New Project details" />
              </SCreateProjectModalContentTitle>
              <CreateProjectForm
                onCreateProject={onCreateProject}
                fromExistingProject
                onCancel={handleCancel}
                selectedOrganization={selectedOrganization}
                selectedGroup={selectedGroup}
                selectedProject={selectedProject}
              />
            </div>
          </SCreateProjectModalContentContainer>
        ) : (
          <SCreateProjectModalContentContainer>
            <div>
              <SCreateProjectModalContentTitle>
                <I18n selector="nav.createProject.duplicatingProject" fallback="Duplicating Project..." />
              </SCreateProjectModalContentTitle>
              <SAddProjectModalSectionsContainer style={{ padding: 24, width: "calc(784px - 48px)" }}>
                <DuplicateProjectProgressModal selectedSections={selectedSections} portId={portId} />
              </SAddProjectModalSectionsContainer>
              <Spacer bronze={24} />
              <SDuplicateProjectProgressModalButton>
                <Button
                  ref={buttonRef}
                  disabled={!duplicationProgress.finished}
                  onClick={() => {
                    router.push(
                      `/project/documents/document${paramsToUrlString({
                        projectId: newProjectId,
                      })}`,
                      undefined,
                      { shallow: true },
                    );
                    const buttonPosition = (buttonRef?.current as HTMLDivElement)?.getBoundingClientRect();
                    const windowWidth = window.innerWidth;
                    const windowHeight = window.innerHeight;
                    const buttonTop = buttonPosition.top;
                    const buttonLeft = buttonPosition.left;
                    const buttonWidth = buttonPosition.width;
                    const buttonHeight = buttonPosition.height;

                    const x = (buttonLeft + buttonWidth / 2) / windowWidth;
                    const y = (buttonTop + buttonHeight / 2) / windowHeight;

                    fireAll({ x, y }, 1250);
                    handleCancel();
                    onClose();
                  }}
                  style={{ display: "block", width: "100%" }}
                  type="primary"
                >
                  <I18n selector="nav.createProject.proceedToTheProject" fallback="Proceed to the project" />
                </Button>
              </SDuplicateProjectProgressModalButton>
            </div>
          </SCreateProjectModalContentContainer>
        )
      ) : null}
    </>
  );
};
