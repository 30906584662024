import styled from "styled-components";

export const SAddNewItemModalFooter = styled.div`
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--ui-03);
  box-shadow: var(--box-shadow-sticked-bottom-ui);
`;
