import gql from "graphql-tag";

export const createPreview = gql`
  mutation CreatePreview($input: CreatePreviewRequestInput) {
    __typename
    CreatePreview(input: $input) {
      preview {
        previewId
        name
      }
    }
  }
`;
