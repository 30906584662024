import styled, { css } from "styled-components";

const CSSActive = css`
  color: var(--text-01);
  img {
    filter: none;
  }
`;

export const SOnboardingLineTabContent = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--text-04);
  img {
    filter: grayscale(100%);
  }

  ${({ active }) => active && CSSActive}
`;
