import { useContext } from "react";
import { I18nProviderContext } from "src/provider/i18n/I18nProvider";
import { templateFormat } from "../../utils/stringTemplate";
import get from "lodash/get";
import { markdownTemplate } from "../../utils/markdownTemplate";
interface IUseI18nString {
  selector: string;
  fallback: string;
  params?: any;
  allowMarkdown?: boolean;
}

export const useI18nString = ({ selector, fallback, params, allowMarkdown }: IUseI18nString) => {
  if (selector.split(".")?.length > 3) {
    throw new Error("Illegal selector: " + selector + ' Only two "." allowd');
  }

  let c = useContext(I18nProviderContext);
  if (c.hasData === false) {
    c = globalThis["i18n"];
  }
  const v = get(c, selector);

  // if (v === undefined) {
  //   console.info(`I18n: selector=${selector} not found in i18n data using fallback=${fallback}`);
  // }

  const translationString = params ? (v ? templateFormat(v, params) : templateFormat(fallback, params)) : v || fallback;

  if (!allowMarkdown) {
    return translationString;
  }

  return markdownTemplate(translationString);
};
