import gql from "graphql-tag";
import { ProjectQuotaPlan } from "./projectQuotaPlan.gql";

export const GetProjectQuotaPlanResponse = gql`
  ${ProjectQuotaPlan}
  fragment GetProjectQuotaPlanResponse on GetProjectQuotaPlanResponse {
    projectId
    quotaPlan {
      ...ProjectQuotaPlan
    }
  }
`;
