import { FC, Fragment } from "react";
import { SCreateProjectModalNavSteps } from "./styles/SCreateProjectModalNavSteps";
import { SCreateProjectModalNavStepsStep } from "./styles/SCreateProjectModalNavStepsStep";
import { SCreateProjectModalNavStepsStepBadge } from "./styles/SCreateProjectModalNavStepsStepBadge";
import { SCreateProjectModalNavStepsSeparator } from "./styles/SCreateProjectModalNavStepsSeparator";
import { I18n } from "../../../../../provider/i18n";

export interface ICreateProjectModalNavStepsStep {
  i18n: {
    selector: string;
    fallback: string;
  };
  stepNumber: number;
}

interface ICreateProjectModalNavSteps {
  steps: ICreateProjectModalNavStepsStep[];
  currentStep: number;
  onClickStep: (stepNumber: number) => void;
}

export const CreateProjectModalNavSteps: FC<ICreateProjectModalNavSteps> = ({ steps, currentStep, onClickStep }) => {
  return (
    <SCreateProjectModalNavSteps>
      {steps.map((step, i) => (
        <Fragment key={i}>
          {step.stepNumber !== 1 ? <SCreateProjectModalNavStepsSeparator /> : null}
          <SCreateProjectModalNavStepsStep
            onClick={() => onClickStep(step.stepNumber)}
            completed={currentStep > step.stepNumber}
            current={currentStep === step.stepNumber}
          >
            <SCreateProjectModalNavStepsStepBadge
              completed={currentStep > step.stepNumber}
              current={currentStep === step.stepNumber}
            >
              {step.stepNumber}
            </SCreateProjectModalNavStepsStepBadge>
            <I18n selector={step.i18n.selector} fallback={step.i18n.fallback} />
          </SCreateProjectModalNavStepsStep>
        </Fragment>
      ))}
    </SCreateProjectModalNavSteps>
  );
};
