import gql from "graphql-tag";

export const getDocumentById = gql`
  query GetDocumentById($input: GetDocumentByIdRequestInput) {
    GetDocumentById(input: $input) {
      ...GetDocumentByIdResponse
    }
  }
  fragment GetDocumentByIdResponse on GetDocumentByIdResponse {
    document {
      ...DocumentWithFieldsResponse
    }
  }
  fragment DocumentWithFieldsResponse on DocumentWithFieldsResponse {
    archivedAt
    blueprintBranch
    blueprintId
    createdAt
    createdByUserId
    documentId
    environmentId
    previewImageUrl
    projectId
    publishedAt
    statusId
    title
    updatedAt
    lastUpdatedByUserId
  }
`;
