import { CSSProgressiveCaption01 } from "@caisy/league";
import styled from "styled-components";

export const SFileUploadProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${CSSProgressiveCaption01};
  color: var(--ui-supportive-06);
`;
