import styled from "styled-components";

export const SOnboardingTemplatesTemplateContent = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 4px;
  margin-bottom: 12px;
`;
