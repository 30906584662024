import { IconCross, RichTextRenderer } from "@caisy/league";
import { FC } from "react";
import { SOnboardingTourCard } from "./styles/SOnboardingTourCard";
import { SOnboardingTourCardCloseIcon } from "./styles/SOnboardingTourCardCloseIcon";
import { SOnboardingTourCardContent } from "./styles/SOnboardingTourCardContent";
import { SOnboardingTourCardGuide } from "./styles/SOnboardingTourCardGuide";

export interface IOnboardingProjectPostCard {
  title: string;
  buttonText: string;
  onButtonClick?: any;
  text: any;
  onClose?: any;
}

export const OnboardingProjectPostCard: FC<IOnboardingProjectPostCard> = ({
  title,
  // buttonText,
  // onButtonClick,
  text,
  onClose,
}) => {
  return (
    <SOnboardingTourCard size={"small"}>
      <SOnboardingTourCardGuide>
        <div>{title}</div>
        <SOnboardingTourCardCloseIcon onClick={onClose}>
          <IconCross size={20} />
        </SOnboardingTourCardCloseIcon>
      </SOnboardingTourCardGuide>
      <SOnboardingTourCardContent showDelayedElements={true}>
        <RichTextRenderer node={text.json} />
      </SOnboardingTourCardContent>
      {/* <SOnboardingTourCardFooter>
        {buttonText && onButtonClick ? (
          <FlatActionButton onClick={onButtonClick} type="default">
            <div>{buttonText}</div>
          </FlatActionButton>
        ) : (
          <div />
        )}
        <p></p>
        <div></div>
      </SOnboardingTourCardFooter> */}
    </SOnboardingTourCard>
  );
};
