import { gql } from "@apollo/client";
import { fragment_APIKeyInitalResponse } from "../fragments/apiKeyReponse.gql";

export const createApiKey = gql`
  ${fragment_APIKeyInitalResponse}
  mutation CreateApiKey($input: CreateAPIKeyRequestInput) {
    CreateApiKey(input: $input) {
      apikey {
        ...fragment_APIKeyInitalResponse
      }
      projectId
    }
  }
`;
