import { queryWithStaleCache } from "../../graphql/middleware/query-with-stale-cache";
import { createOrganization } from "../../graphql/mutations/createOrganization.gql";
import { getManyOrganizationsByUserID } from "../../graphql/queries/getManyOrganizationsByUserID.gql";
import { client } from "../../utils/client";
import { IOrganizationInputInput, IOrganizationResponse } from "../../interfaces/generated";
import { updateOrganization as updateOrganizationGQL } from "../../graphql/mutations/updateOrganization.gql";
import { deleteOrganization as deleteOrganizationGQL } from "../../graphql/mutations/deleteOrganization.gql";
import { message } from "@caisy/league";
import produce from "immer";

export interface IUseOrganizationMembership {
  isLoadingOrganizations: boolean;
  totalOrganizationCount: number;
  initializedOrganizations: boolean;
  organizations: { [organizationId: string]: IOrganizationResponse };
  showOrganizationLimitModal: boolean;
  setShowOrganizationLimitModal: (show: boolean) => void;
  loadOrganizations: ({ userId }: { userId: string }) => Promise<void>;
  deleteOrganization: ({ organizationId }: { organizationId: string }) => Promise<boolean>;
  createOrganization: ({ userId, name }: { userId: string; name: string }) => Promise<IOrganizationResponse>;
  updateOrganization: ({
    organizationId,
    input,
  }: {
    organizationId: string;
    input: IOrganizationInputInput;
  }) => Promise<IOrganizationInputInput>;
}

export interface IOrganizationMembershipState {
  organization: IUseOrganizationMembership;
}

export const createOrganizationMembershipSlice = (
  set: (cb: (state: IOrganizationMembershipState) => IOrganizationMembershipState, boolean, string) => void,
  // get: () => IOrganizationMembershipState,
) => ({
  organization: {
    organizations: {},
    totalOrganizationCount: 0,
    isLoadingOrganizations: false,
    initializedOrganizations: false,
    showOrganizationLimitModal: false,
    setShowOrganizationLimitModal: (show: boolean) => {
      set(
        produce((state) => {
          state.organization.showOrganizationLimitModal = show;
        }),
        false,
        "organization/setShowOrganizationBillingModal",
      );
    },
    createOrganization: async ({ userId, name }) => {
      try {
        const { data } = await client.mutate({
          mutation: createOrganization,
          variables: { userId, name },
        });

        const createdOrganization = data.CreateOrganization.organization as IOrganizationResponse;

        set(
          produce((state) => {
            state.organization.organizations[createdOrganization.organizationId] = {
              ...createdOrganization,
              roleByUser: { title: "owner", __typename: "AnyToRoleConnection" },
            };
            state.organization.totalOrganizationCount = state.organization.totalOrganizationCount + 1;
          }),
          false,
          "organization/createOrganization",
        );

        return createdOrganization;
      } catch (error) {
        if (error?.message?.includes("ResourceExhausted")) {
          set(
            produce<IOrganizationMembershipState>((state) => {
              state.organization.showOrganizationLimitModal = true;
            }),
            false,
            "organization/createOrganization/ResourceExhausted",
          );
          // message.error(
          //   <I18n
          //     fallback="Daily organization limit reached (3/day). Please try again tomorrow."
          //     selector="nav.organization_switcher_dailyOrgLimitReached"
          //   />,
          // );
        } else {
          message.error(`Create organization failed: ${error.message ?? ""}`);
        }
      }
    },
    updateOrganization: async ({
      organizationId,
      input,
    }: {
      organizationId: string;
      input: IOrganizationInputInput;
    }) => {
      try {
        const res = await client.mutate({
          mutation: updateOrganizationGQL,
          variables: {
            input: {
              input: {
                ...input,
              },
              organizationId,
            },
          },
        });
        if (res?.data?.UpdateOrganization?.organization) {
          set(
            produce((prev) => {
              prev.organization.organizations = {
                ...prev.organization.organizations,
                [organizationId]: res.data.UpdateOrganization.organization,
              };
            }),
            false,
            "organization/updateOrganization",
          );

          return res?.data?.UpdateOrganization?.organization;
        }
        res?.data?.UpdateOrganization?.organization;
      } catch (err) {
        console.error(err);
        message.error(`Update organization failed: ${err.message ?? ""}`);
      }
    },
    deleteOrganization: async ({ organizationId }: { organizationId: string }) => {
      try {
        const res = await client.mutate({
          mutation: deleteOrganizationGQL,
          variables: {
            input: {
              organizationId,
            },
          },
        });

        const deleted = res?.data?.DeleteOrganization?.deleted;

        if (deleted) {
          set(
            produce<IOrganizationMembershipState>((state) => {
              delete state.organization.organizations[organizationId];
            }),
            false,
            "organization/deleteOrganization",
          );
        }

        return deleted;
      } catch (err) {
        console.error(err);
        message.error(`Update organization failed: ${err.message ?? ""}`);
      }
    },
    loadOrganizations: async ({ userId }) => {
      await queryWithStaleCache({
        uniquePrefix: "organization-membership",
        query: getManyOrganizationsByUserID,
        variables: { userId },
        onCacheMiss: () => {
          set(
            produce((state) => {
              state.organization.isLoadingOrganizations = true;
            }),
            false,
            "organization/loadOrganizations/onCacheMiss",
          );
        },
        onUpdate: ({ data }) => {
          set(
            produce((state) => {
              for (let i = 0; i < data.GetManyOrganizationsByUserID.connection.edges.length; i++) {
                state.organization.organizations[
                  data.GetManyOrganizationsByUserID.connection.edges[i].node.organizationId
                ] = data.GetManyOrganizationsByUserID.connection.edges[i].node;
              }
              state.organization.totalOrganizationCount = data.GetManyOrganizationsByUserID.connection.totalCount;
              state.organization.initializedOrganizations = true;
            }),
            false,
            "organization/loadOrganizations/onUpdate",
          );
        },
      });
      set(
        produce((state) => {
          state.organization.isLoadingOrganizations = false;
        }),
        false,
        "organization/loadOrganizations/onUpdate",
      );
    },
  },
});
