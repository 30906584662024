import { CSSProgressiveCaption01Medium } from "@caisy/league";
import { CSSProgressiveCaption02Medium } from "@caisy/league/src/constants/styles/design-tokens/fonts/CSSTypographies";
import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/styles/mediaquerys";

const CSSLarge = css`
  ${CSSProgressiveCaption01Medium};
`;

const Default = css`
  ${CSSProgressiveCaption02Medium};
  color: var(--text-04);
  text-align: center;
  ${({ size }) => size === "large" && CSSLarge}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuEmptyWrapperLabel = styled.p`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
