import { Button } from "@caisy/league";
import { useEffect, useRef, useState } from "react";
import { useCurrentGroupId } from "../../../../../hooks/current-project-id/useCurrentGroupId";
import { I18n } from "../../../../../provider/i18n";
import { Secured } from "../../../../../services/security";
import { PERMISSION, PERMISSION_ACTION } from "../../../../../services/security/constant";
import { useAuthentication } from "../../../../../stores/authentication/useAuthentication";
import { useMemberShip } from "../../../../../stores/membership/useMembership";
import { CreateProjectModal } from "../../../../organism/organization-menu/create-project-modal/CreateProjectModal";

export const GroupProjectsNavChildren = () => {
  const [showCreateProjectPopover, setShowCreateProjectPopover] = useState(false);
  const [organizationId, setOrganizationId] = useState("");

  const { user } = useAuthentication();
  const groupId = useCurrentGroupId();
  const { groups, loadGroups, loadProjects } = useMemberShip();

  useEffect(() => {
    const userId = user?.id;
    if (groupId && userId) {
      loadGroups({ userId }).then(() => {
        setOrganizationId(groups[groupId]?.organizationId);
      });
      loadProjects({ userId, filter: { groupId } });
    }
  }, [groupId, user]);

  const createProjectPopoverRef = useRef();

  return (
    <>
      <div ref={createProjectPopoverRef}>
        <Secured action={PERMISSION_ACTION.CREATE} entity={PERMISSION.PROJECT}>
          <Button
            type="primary"
            size="medium"
            onClick={() => {
              setShowCreateProjectPopover((prev) => !prev);
            }}
          >
            <I18n selector="groupManagementProjects.addProject" fallback="Add project" />
          </Button>
        </Secured>
      </div>

      <CreateProjectModal
        visible={showCreateProjectPopover}
        onClose={() => setShowCreateProjectPopover(false)}
        groupId={groupId}
        organizationId={organizationId}
      />
    </>
  );
};
