import { SButton } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSHover = css`
  box-shadow: var(--box-shadow-strong-pop-elavation);

  ${SButton} {
    color: var(--hover-text-04);
    border: solid 1px var(--hover-interactional-tertiary-01);
  }
`;

const CSSActive = css`
  ${SButton} {
    color: var(--text-02);

    &:after {
      background-color: var(--active-interactional-tertiary-01);
    }
  }
`;

export const SOnboardingTemplatesTemplate = styled.div`
  background-color: var(--ui-01);
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid var(--ui-03);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: box-shadow 150ms;
  cursor: pointer;
  height: 375px;

  :hover {
    ${CSSHover}
  }

  :active {
    ${CSSActive}
  }
`;
