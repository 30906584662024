import gql from "graphql-tag";

export const duplicateDocument = gql`
  mutation DuplicateDocument($input: DuplicateDocumentRequestInput) {
    DuplicateDocument(input: $input) {
      response {
        archivedAt
        blueprintBranch
        createdAt
        blueprintId
        createdByUserId
        environmentId
        documentId
        lastUpdatedByUserId
        previewImageUrl
        projectId
        publishedAt
        title
        statusId
        updatedAt
      }
    }
  }
`;
