import gql from "graphql-tag";

export const q_GetProjectBillingStatus = gql`
  query GetProjectBillingStatus($input: GetProjectBillingStatusRequestInput!) {
    GetProjectBillingStatus(input: $input) {
      billingPlanName
      nextPlan
      endMonth
      cancelAt
    }
  }
`;
