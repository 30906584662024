import { CSSProgressiveCaption01 } from "@caisy/league";
import styled from "styled-components";

export const SPricingCardFeature = styled.div`
  ${CSSProgressiveCaption01};
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-01);
`;
