import {
  Button,
  ButtonsBar,
  ClickOutside,
  IconAssetsImage,
  message,
  Popover,
  UploadMenuItem,
  UploadsPopUp,
  UploadsPopUpButtons,
  UploadsPopUpContent,
} from "@caisy/league";
import { useRouter } from "next/router";
import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useCurrentProjectId } from "../../../hooks/current-project-id/useCurrentProjectId";
import { FCWithChildren } from "../../../interfaces/FCWithChildren";
import { I18n } from "../../../provider/i18n";
import { EFileStatus } from "../../../stores/upload/types";
import { useUpload } from "../../../stores/upload/useUpload";
import { paramsToUrlString } from "../../../utils/getHrefWithParams";
import { FileUploadAssetListItem } from "./FileUploadAssetListItem";
import { SFileUploadEmptyContainer } from "./Styles/SFileUploadEmptyContainer";
import { SFileUploadEmptyDescription } from "./Styles/SFileUploadEmptyDescription";
import { SFileUploadEmptyIconContainer } from "./Styles/SFileUploadEmptyIconContainer";
import { SFileUploadEmptyText } from "./Styles/SFileUploadEmptyText";
import { SFileUploadEmptyTitle } from "./Styles/SFileUploadEmptyTitle";
import { SMainMenuItemIconTextHolder } from "../main-navigation/MainMenuItems/styles/SMainMenuItemIconTextHolder";
import { SMainMenuItemText } from "../main-navigation/MainMenuItems/styles/SMainMenuItemText";
import useExpandableNavigation from "../../../hooks/useExpandableNavigation";

export const FileUpload: FCWithChildren = ({ children }) => {
  const { push } = useRouter();
  const { expanded } = useExpandableNavigation();
  const projectId = useCurrentProjectId();
  const inputRef = useRef<HTMLInputElement>();
  const { uploads, currentDroppingFilesCount, setUploads, handleFile, currentUploads, setCurrentUploads } = useUpload();

  const [dropdownActive, setDropdownActive] = useState(false);
  const dropdownAnchorRef = useRef();
  const onNavItemClick = () => {
    setDropdownActive((prev) => !prev);
  };

  const percentageLoaded = useMemo(() => {
    const totalUploads = currentUploads.length * 100;
    let loaded = 0;

    currentUploads.forEach((upload) => (loaded += upload.progress));
    return (loaded * 100) / totalUploads;
  }, [currentUploads]);

  const uploadState = useMemo(() => {
    if (dropdownActive) {
      return "activated";
    }
    if (currentDroppingFilesCount > 0) return "dragging";
    if (uploads?.length > 0) {
      const amountSuccessful = uploads.filter((u) => u.status == EFileStatus.SUCCESS)?.length;

      if (uploads.length == amountSuccessful) {
        setCurrentUploads([]);
        return "success";
      }

      return "loading";
    }
    return "default";
  }, [uploads, dropdownActive, currentDroppingFilesCount]);

  const [iconWidth, setWidth] = React.useState(0);
  const calculatedRef = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const handleClearUploads = () => {
    setCurrentUploads([]);
    setUploads([]);
  };

  const handleRemoveUpload = (url) => {
    setUploads(uploads.filter((upload) => upload.url !== url));
  };

  const handleGoToAssets = () => {
    push(`/project/assets/list${paramsToUrlString({ projectId })}`);
  };

  const handleFileChange = async (e) => {
    const promises = [];
    for (const file of e?.target?.files) {
      promises.push(
        handleFile({
          file: file,
          extraHeaders: {
            "x-caisy-project-id": projectId,
          },
          meta: {
            mode: "document",
          },
          onError: function (error) {
            message.error(`${error}`);
          },
          onSuccess: function () {
            message.success(`${file.name} uploaded`);
          },
        }),
      );
    }
    await Promise.all(promises);
  };

  return (
    <ClickOutside
      onClickOutside={() => {
        setDropdownActive(false);
      }}
    >
      <div>
        <SMainMenuItemIconTextHolder uploadIcon disableHover expanded={expanded}>
          <UploadMenuItem
            onClick={onNavItemClick}
            itemCount={currentDroppingFilesCount || currentUploads.length}
            percentageLoaded={percentageLoaded}
            state={uploadState}
          >
            {(uploadState == "default" || uploadState == "activated") && "Upload"}
            {uploadState == "dragging" && "Upload"}
            {uploadState == "loading" && (percentageLoaded ? `${Math.floor(percentageLoaded)}%` : "Loading...")}
            {uploadState == "success" && "Done"}
          </UploadMenuItem>
          <SMainMenuItemText expanded={expanded} onClick={onNavItemClick}>
            <I18n selector="nav.nav_items_upload" fallback="Upload" />
          </SMainMenuItemText>
        </SMainMenuItemIconTextHolder>
        <Popover reference={dropdownAnchorRef} placement="topRight" display={dropdownActive}>
          {() => (
            <UploadsPopUp
              badgeValue={uploads.length !== 0 && `${uploads.length}`}
              onClear={handleClearUploads}
              poupTitle={<I18n selector="nav.upload_title" fallback="Uploads" />}
              ClearText={<I18n selector="nav.upload_clear" fallback="clear" />}
              onClose={() => setDropdownActive(false)}
            >
              {uploads && uploads.length > 0 ? (
                <UploadsPopUpContent>
                  {uploads?.map((upload, index) => {
                    return (
                      <Fragment key={`upload-${index}`}>
                        <FileUploadAssetListItem
                          calculatedRef={calculatedRef}
                          upload={upload}
                          handleRemoveUpload={handleRemoveUpload}
                          iconWidth={iconWidth}
                        />
                      </Fragment>
                    );
                  })}
                </UploadsPopUpContent>
              ) : (
                <SFileUploadEmptyContainer>
                  <SFileUploadEmptyIconContainer>
                    <IconAssetsImage size={32} />
                  </SFileUploadEmptyIconContainer>
                  <SFileUploadEmptyText>
                    <SFileUploadEmptyTitle>
                      <I18n selector="nav.upload_noUploads" fallback="No uploads yet" />
                    </SFileUploadEmptyTitle>
                    <SFileUploadEmptyDescription>
                      <I18n
                        selector="nav.upload_noUploadsMessage"
                        fallback="To add assets to your project you you can just drag any file from your desktop into caisy and they will be uploaded here"
                      />
                    </SFileUploadEmptyDescription>
                  </SFileUploadEmptyText>
                </SFileUploadEmptyContainer>
              )}
              <UploadsPopUpButtons>
                <ButtonsBar>
                  <Button style={{ margin: 0 }} onClick={handleGoToAssets} type="primary" sticked={true}>
                    <I18n selector="nav.upload_goToAssets" fallback="GO TO ALL ASSETS" />
                  </Button>
                  <Button
                    style={{ margin: 0 }}
                    onClick={() => inputRef?.current?.click()}
                    type="primary"
                    sticked={true}
                  >
                    <I18n selector="nav.upload_selectImage" fallback="SELECT IMAGE" />
                  </Button>
                </ButtonsBar>
              </UploadsPopUpButtons>
              <input hidden ref={inputRef} type="file" multiple={true} name="file" onChange={handleFileChange} />
            </UploadsPopUp>
          )}
        </Popover>
        <div ref={dropdownAnchorRef} />
        {children}
      </div>
    </ClickOutside>
  );
};
