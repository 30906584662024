import { client } from "../../utils/client";
import {
  ICreatePreviewItemRequestInput,
  ICreatePreviewRequestInput,
  IDeletePreviewItemRequestInput,
  IDeletePreviewRequestInput,
  IGetManyPreviewsRequestInput,
  IGetPreviewByIdRequestInput,
  IGetPreviewItemsByBlueprintIdRequestInput,
  IPreviewConnectionEdge,
  IPreviewItemResponse,
  IPreviewWithItemsResponse,
  IUpdatePreviewItemRequestInput,
  IUpdatePreviewRequestInput,
} from "../../interfaces/generated";
import { getManyPreviews } from "../../graphql/queries/getManyPreviews.gql";
import { createPreview } from "../../graphql/mutations/createPreview.gql";
import { updatePreview } from "../../graphql/mutations/updatePreview.gql";
import { deletePreview } from "../../graphql/mutations/deletePreview.gql";
import { getPreviewItemsByBlueprintId } from "../../graphql/queries/getPreviewItemsByBlueprintId.gql";
import { getPreviewById } from "../../graphql/queries/getPreviewById.gql";
import { createPreviewItem } from "../../graphql/mutations/createPreviewItem.gql";
import { updatePreviewItem } from "../../graphql/mutations/updatePreviewItem.gql";
import { deletePreviewItem } from "../../graphql/mutations/deletePreviewItem.gql";
import produce from "immer";
import { message } from "@caisy/league";
import { I18n } from "../../provider/i18n";

export interface IUsePreview {
  // PREVIEWS
  previews: { [previewId: string]: IPreviewWithItemsResponse };
  loadPreviews: (input: IGetManyPreviewsRequestInput) => Promise<void>;
  loadingPreviews: boolean;
  syncingPreview: boolean;
  currentPreviewId: string;
  getCurrentPreview: () => IPreviewWithItemsResponse;
  getAllPreviewsList: () => IPreviewWithItemsResponse[];
  loadPreviewById: (input: IGetPreviewByIdRequestInput) => Promise<IPreviewWithItemsResponse>;
  createPreview: (input: ICreatePreviewRequestInput) => Promise<IPreviewWithItemsResponse>;
  updatePreview: (input: IUpdatePreviewRequestInput) => Promise<IPreviewWithItemsResponse>;
  deletePreview: (input: IDeletePreviewRequestInput) => Promise<void>;

  // PREVIEW ITEMS
  previewItems: { [previewItemId: string]: IPreviewItemResponse };
  loadPreviewItemsByBlueprintId: (input: IGetPreviewItemsByBlueprintIdRequestInput) => Promise<IPreviewItemResponse[]>;
  getPreviewItemsList: () => IPreviewItemResponse[];
  createPreviewItem: (input: ICreatePreviewItemRequestInput) => Promise<IPreviewItemResponse>;
  updatePreviewItem: (input: IUpdatePreviewItemRequestInput) => Promise<IPreviewItemResponse>;
  deletePreviewItem: (input: IDeletePreviewItemRequestInput) => Promise<void>;
}

export interface IPreviewState {
  preview: IUsePreview;
}

export const createPreviewSlice = (
  set: (cb: (state: IPreviewState) => IPreviewState, boolean: boolean, name: string) => void,
  get: () => IPreviewState,
): IPreviewState => ({
  preview: {
    previews: {},
    currentPreviewId: "",
    getCurrentPreview: () => get().preview.previews[get().preview.currentPreviewId],

    loadPreviews: async (input) => {
      try {
        set(
          produce((state) => {
            state.preview.loadingPreviews = true;
            state.preview.previews = {};
          }),
          false,
          "preview/loadPreviews/start",
        );

        const { data } = await client.query({
          query: getManyPreviews,
          fetchPolicy: "no-cache",
          variables: { input },
        });

        const previews: IPreviewConnectionEdge[] = data.GetManyPreviews.connection.edges;

        previews.forEach((edge) => {
          const preview = edge.node;

          set(
            produce((state) => {
              state.preview.previews[preview.previewId] = preview;
            }),
            false,
            "preview/loadPreviews",
          );
        });

        if (get().preview.loadingPreviews)
          set(
            produce((state) => {
              state.preview.loadingPreviews = false;
            }),
            false,
            "preview/loadPreviews/end",
          );
      } catch (error) {
        console.log(error);
      }
    },

    loadingPreviews: false,

    syncingPreview: false,

    getAllPreviewsList: () => Object.values(get().preview.previews),

    loadPreviewById: async (input: IGetPreviewByIdRequestInput) => {
      let preview: IPreviewWithItemsResponse = get().preview.previews[input.previewId];
      if (!preview) {
        try {
          const { data } = await client.query({
            query: getPreviewById,
            variables: { input },
          });

          preview = data.GetPreviewById.preview;
          set(
            produce((state: IPreviewState) => {
              state.preview.currentPreviewId = preview.previewId;
              state.preview.previews[preview.previewId] = preview;
            }),
            false,
            "preview/loadPreviewById",
          );
        } catch (error) {
          console.log(error);
        }
      }

      set(
        produce<IPreviewState>((state) => {
          state.preview.previewItems = {};
          preview.previewItems.forEach((previewItem) => {
            state.preview.previewItems[previewItem.previewItemId] = previewItem;
          });
        }),
        false,
        "preview/loadPreviewById/setPreviewItems",
      );

      return preview;
    },

    createPreview: async (input) => {
      try {
        const { data } = await client.mutate({
          mutation: createPreview,
          variables: {
            input,
          },
        });

        const preview: IPreviewWithItemsResponse = { ...data.CreatePreview.preview, previewItems: [] };

        set(
          produce((state) => {
            state.preview.previews[preview.previewId] = preview;
          }),
          false,
          "preview/createPreviews",
        );

        return preview;
      } catch (err) {
        if (err.toString().includes("ResourceExhausted")) {
          message.error(
            <I18n
              selector="previewErrorMessages.previewQuota"
              fallback="Quota breached: max previews the are allowed in your plan exhauseted."
            />,
            {
              duration: 5000,
            },
          );

          return;
        }

        console.log(err);
      }
    },

    updatePreview: async (input) => {
      try {
        set(
          produce((state) => {
            state.preview.syncingPreview = true;
          }),
          false,
          "preview/updatePreview",
        );
        const { data } = await client.mutate({
          mutation: updatePreview,
          variables: {
            input,
          },
        });

        const preview: IPreviewWithItemsResponse = {
          ...data.UpdatePreview.preview,
          previewItems: get().preview.getPreviewItemsList(),
        };

        set(
          produce((state) => {
            state.preview.previews[preview.previewId] = preview;
            state.preview.syncingPreview = false;
          }),
          false,
          "preview/updatePreview",
        );

        return preview;
      } catch (error) {
        console.log(error);
      }
    },

    deletePreview: async (input) => {
      try {
        const { data } = await client.mutate({
          mutation: deletePreview,
          variables: { input },
        });

        if (!data.DeletePreview.deleted) return;

        set(
          produce((state) => {
            delete state.preview.previews[input.previewId];
          }),
          false,
          "preview/deletePreview",
        );
      } catch (error) {}
    },

    // PREVIEW ITEMS
    previewItems: {} as { [previewItemId: string]: IPreviewItemResponse },

    loadPreviewItemsByBlueprintId: async (input) => {
      try {
        const { data } = await client.query({
          query: getPreviewItemsByBlueprintId,
          fetchPolicy: "no-cache",
          variables: { input },
        });

        const previewItems: IPreviewItemResponse[] = data.GetPreviewItemsByBlueprintId.previewItems;

        previewItems.forEach((previewItem) => {
          set(
            produce((state) => {
              state.preview.previewItems[previewItem.previewItemId] = previewItem;
            }),
            false,
            "preview/loadPreviewItemsByBlueprintId",
          );
        });

        return previewItems;
      } catch (error) {}
    },

    getPreviewItemsList: () => {
      return Object.values(get().preview.previewItems);
    },

    createPreviewItem: async (input) => {
      try {
        set(
          produce((state) => {
            state.preview.syncingPreview = true;
          }),
          false,
          "preview/createPreviewItem/syncingPreview",
        );
        const { data } = await client.mutate({
          mutation: createPreviewItem,
          variables: { input },
        });

        const previewItem: IPreviewItemResponse = data.CreatePreviewItem.previewItem;

        set(
          produce((state) => {
            state.preview.previewItems[previewItem.previewItemId] = previewItem;
            state.preview.syncingPreview = false;
          }),
          false,
          "preview/createPreviewItem/updateState",
        );

        return previewItem;
      } catch (error) {
        console.log(error);
      }
    },
    updatePreviewItem: async (input) => {
      try {
        set(
          produce((state) => {
            state.preview.syncingPreview = true;
          }),
          false,
          "preview/updatePreviewItem/syncingPreview",
        );
        const { data } = await client.mutate({
          mutation: updatePreviewItem,
          variables: { input },
        });

        const previewItem: IPreviewItemResponse = data.UpdatePreviewItem.previewItem;

        set(
          produce((state) => {
            state.preview.previewItems[previewItem.previewItemId] = previewItem;
            state.preview.syncingPreview = false;
          }),
          false,
          "preview/updatePreviewItem/updateState",
        );

        return previewItem;
      } catch (error) {
        console.log(error);
      }
    },
    deletePreviewItem: async (input: IDeletePreviewItemRequestInput) => {
      try {
        set(
          produce((state) => {
            state.preview.syncingPreview = true;
          }),
          false,
          "preview/deletePreviewItem/syncingPreview",
        );

        const { data } = await client.mutate({
          mutation: deletePreviewItem,
          variables: { input },
        });

        if (!data.DeletePreviewItem.deleted) return;

        set(
          produce((state) => {
            delete state.preview.previewItems[input.previewItemId];
            state.preview.syncingPreview = false;
          }),
          false,
          "preview/deletePreviewItem/updateState",
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
});
