import { PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON } from "../constants";
import { decodeFieldMessage, encodeFieldMessage } from "../encoding";
import { ICollaborationState, IPeer } from "../types";
import { findOwnListeningField } from "./helper";
import * as Y from "yjs";

export const handleSyncAcknowledgement = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  state.mutex.runExclusive(() => {
    console.log("START PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT");
    const field = decodeFieldMessage(data);
    const ownListeningField = findOwnListeningField(state, field);

    if (!ownListeningField) {
      // console.log("!ownListeningField PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT");
      return;
    }

    // console.log(
    //   ` PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT field.syncChecksum`,
    //   field.syncChecksum,
    //   ownListeningField.peersSynced[peer.clientId],
    // );
    if (field.syncChecksum === "2" && ownListeningField.peersSynced[peer.clientId] !== false) {
      console.error(new Error("field.syncChecksum === '2' , this should not happen, two peers wants to be follower"));
      // start again
      peer.send(
        encodeFieldMessage({
          messageType: PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON,
          documentId: ownListeningField.documentId,
          blueprintFieldId: ownListeningField.blueprintFieldId,
          documentFieldLocaleId: ownListeningField.documentFieldLocaleId,
        }),
      );
      return;
    }

    const { isAlone, wsAssignment, wsChange } = state.connection;
    if (!isAlone && wsAssignment && wsChange) {
      ownListeningField.provider.setLeaderClientId(peer.clientId, false);
      const ydoc1 = ownListeningField.provider.doc;
      Y.applyUpdate(ydoc1, field.body);
      ownListeningField.provider.setSynced(true);
      ownListeningField.provider.emit("synced", [{ synced: true }]);
      ownListeningField.peersSynced[peer.clientId] = true;
      console.log("PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT APPLIED");
    }
  });
};
