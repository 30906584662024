import gql from "graphql-tag";

export const getUserProfile = gql`
  query  userProfile($userId:ID!){
    userProfile(userId: $userId){
      id
      email
      displayName
      photoURL
    }
  }`;
