import styled from "styled-components";

export const SOnboardingAssetField = styled.div`
  background-color: var(--ui-01);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--ui-03);
`;
