import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useAuthentication } from "../../stores/authentication/useAuthentication";

if (typeof window === "undefined") React.useLayoutEffect = React.useEffect;

export const PosthogIgnorlist = ["localhost", "vercel.app", "app.staging.caisy.io", "dev.staging.caisy.io"];
export const shouldLoadPosthog = () => {
  const shouldLoadPosthog =
    typeof window !== "undefined" && !PosthogIgnorlist.some((domain) => window.location.host.includes(domain));
  return shouldLoadPosthog;
};

export const PosthogWrapper = () => {
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);
  const retryTimeout = 500; // 5 seconds
  const [retryCount, setRetryCount] = useState(0);

  const { user } = useAuthentication();

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    const posthog = (window as any).posthog;

    if (!posthog || !user || !user.id) {
      return;
    }

    if (user.id) {
      posthog.identify(user.id);
    }

    posthog.people.set({
      email: user.email,
      name: user.displayName,
    });
  }, [user, isLoaded]);

  const loadPosthog = async () => {
    const posthog = (window as any).posthog;
    if (posthog) {
      setIsLoaded(true);
      return;
    }

    setIsLoaded(false);
    setRetryCount((prevRetryCount) => prevRetryCount + 1);
  };

  useEffect(() => {
    if (!shouldLoadPosthog()) {
      return;
    }

    const posthog = (window as any).posthog;
    if (posthog) {
      setIsLoaded(true);
      return;
    }

    if (!isLoaded && retryCount < 5) {
      const timeoutId = setTimeout(loadPosthog, retryTimeout * retryCount);
      return () => clearTimeout(timeoutId);
    }
  }, [retryCount, isLoaded]);

  React.useLayoutEffect((): (() => void) => {
    if (!isLoaded) return;
    const posthog = (window as any).posthog;
    if (!posthog) return;
    // posthog.debug();
    // Track page views
    const handleRouteChange = () => {
      posthog.capture("$pageview");
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    const hanldeRouteChangeStart = (url) => {
      posthog.capture("page_change", { url });
    };

    router.events.on("routeChangeStart", hanldeRouteChangeStart);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", hanldeRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [isLoaded]);

  return null;
};
