import gql from "graphql-tag";
import { fragment_GroupResponse } from "../fragments/groupResponse.gql";
import { fragment_OrganizationResponse } from "../fragments/organizationResponse.gql";

export const query_GetGroupByIDWithInheritance = gql`
  ${fragment_GroupResponse}
  ${fragment_OrganizationResponse}
  query GetGroupByIDWithInheritance($input: GetGroupByIDWithInheritanceRequestInput) {
    GetGroupByIDWithInheritance(input: $input) {
      ...GetGroupByIDWithInheritanceResponse
    }
  }
  fragment GetGroupByIDWithInheritanceResponse on GetGroupByIDWithInheritanceResponse {
    group {
      ...fragment_GroupResponse
    }
    organization {
      ...fragment_OrganizationResponse
    }
  }
`;
