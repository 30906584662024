import styled, { css } from "styled-components";

const CSSFree = css`
  height: 24px;
`;

export const SPricingCardEmptyLabel = styled.div`
  height: 23px;
  ${({ isFree }) => isFree && CSSFree}
`;
