export const Z_INDEX = {
  CODEMIRROR_HSCROLLBAR: 3,
  LAYOUT_HEADER: 50,
  LAYOUT_HEADER_POPUP: 120,
  POPOVER_BEHIND_MODAL: 100,
  RICHTEXT_FLOATING_TOOLBAR: 120,
  IMAGE_UPLOAD_POPUP: 1500,
  LAYOUT_TOP_NAVBAR: 99,
  SNACKBAR: 40,
  PLAYGROUND_ROOT_CONTAINER: 9,
  LINK_SETUP_CARD: 99,
  ASSET_EDITOR_LOADING_CONTAINER: 2,
  ONBOARDING_TOUR_POPOVER: 1250,
  ONBOARDING_TOUR_CONFETTI: 1225,
  ONBOARDING_TOUR_BACKGROUND: 1200,
  ONBOARDING_TOUR_HIGHLIGHT: 1300,
};
