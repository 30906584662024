import { useEffect } from "react";
import { useRouter } from "next/router";
import { broadcastToAllPeers } from "../collaboration/helper";
import { encodeAnyJSONMessage } from "../collaboration/encoding";
import { PEER_MESSAGE_TYPE_ACTIVE_DOCUMENT_CHANGE, PEER_MESSAGE_TYPE_ACTIVE_URL } from "../collaboration/constants";

export const ListenerCurrentDocument = () => {
  const router = useRouter();
  const documentId = router.query?.document_id as string;

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!window.c) {
      window.c = {};
    }
    const v = documentId ? [documentId] : [];
    window.c["activeDocumentIds"] = v;

    if (window.c["collaboration"]?.peers && window.c["collaboration"].peers.length > 0) {
      broadcastToAllPeers(
        window.c["collaboration"].peers,
        encodeAnyJSONMessage({ messageType: PEER_MESSAGE_TYPE_ACTIVE_DOCUMENT_CHANGE, body: v }),
      );
      broadcastToAllPeers(
        window.c["collaboration"].peers,
        encodeAnyJSONMessage({ messageType: PEER_MESSAGE_TYPE_ACTIVE_URL, body: { asPath: router.asPath } }),
      );
    }
  }, [documentId]);

  return null;
};
