import { SList } from "@caisy/league/src/components/list/styles/SList";
import styled, { css } from "styled-components";
import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "../../../../constants/styles/mediaquerys";

const Default = css<{ menuWidth?: string }>`
  width: ${({ menuWidth }) => (menuWidth ? menuWidth : "100%")};
  min-width: ${({ menuWidth }) => (menuWidth ? menuWidth : "100%")};
  ${SList} {
    background: var(--ui-background);
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuSub = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
