import gql from "graphql-tag";

export const updateTag = gql`
  mutation CreateTag($input: UpdateTagRequest) {
    __typename
    UpdateTag(input: $input) {
      tag {
        color
        name
        projectId
        referenceType
        tagId
      }
    }
  }
`;
