import { useContext } from "react";
import { I18nProviderContext } from "src/provider/i18n/I18nProvider";
import { templateFormat } from "../../utils/stringTemplate";
import get from "lodash/get";
import { message } from "@caisy/league";
import { FCWithChildren } from "../../interfaces/FCWithChildren";
import { markdownTemplate } from "../../utils/markdownTemplate";

export const I18n: FCWithChildren<{ selector: string; fallback: string; params?: any }> = ({
  children,
  selector,
  fallback,
  params,
}) => {
  if (selector.split(".")?.length > 3) {
    throw new Error("Illegal selector: " + selector + ' Only two "." allowd');
  }

  let c = useContext(I18nProviderContext);
  if (c.hasData === false) {
    c = globalThis["i18n"];
  }

  const v = get(c, selector);
  // if (v === undefined) {
  //   console.info(`I18n: selector=${selector} not found in i18n data using fallback=${fallback}`);
  // }

  const translationString = params
    ? v
      ? templateFormat(v, params)
      : fallback
      ? templateFormat(fallback, params)
      : null
    : v || fallback;

  const translationFormatedJSX = markdownTemplate(translationString);

  return (
    <span
      className="i18n"
      data-selector={selector}
      onMouseEnter={
        process.env.NEXT_PUBLIC_I18N_DEBUG
          ? () => {
              message.info(`selector=${selector} fallback=${fallback}`);
            }
          : undefined
      }
    >
      {translationFormatedJSX}
      {children}
    </span>
  );
};
