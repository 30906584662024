import {
  Button,
  DeleteConfirmationPopup,
  DeleteConfirmationPopupTitle,
  FlatActionButton,
  IconCross,
  Popover,
  RichTextRenderer,
} from "@caisy/league";
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import {
  IGenUserOnboardingTourGuide,
  IGenUserOnboardingTourPointer,
  IGenUserOnboardingTourPost,
} from "../../../../interfaces/generated/i18n-types";
import { I18n } from "../../../../provider/i18n";
import { SOnboardingTourCard } from "./styles/SOnboardingTourCard";
import { SOnboardingTourCardCloseIcon } from "./styles/SOnboardingTourCardCloseIcon";
import { SOnboardingTourCardContent } from "./styles/SOnboardingTourCardContent";
import { SOnboardingTourCardFooter } from "./styles/SOnboardingTourCardFooter";
import { SOnboardingTourCardGuide } from "./styles/SOnboardingTourCardGuide";
import { SOnboardingTourCardProfilePic } from "./styles/SOnboardingTourCardProfilePic";
import confetti from "canvas-confetti";
import { SOnboardingTourCardTitle } from "./styles/SOnboardingTourCardTitle";
import { SOnboardingTourCardButtonWrapper } from "./styles/SOnboardingTourCardButtonWrapper";
import { Z_INDEX } from "../../../../constants/z-index-numbers";
import { SDeleteMemberModalHeader } from "../../../page/management/styles/SDeleteMemberModalHeader";
import { SOnboardingTourCardDescription } from "./styles/SOnboardingTourCardDescription";
import { SOnboardingTourCardPopupContent } from "./styles/SOnboardingTourCardPopupContent";
import { SOnboardingTourCardPopupButtons } from "./styles/SOnboardingTourCardPopupButtons";

const triggerMouseEvent = (node) => {
  const event = new Event("mouseup", { bubbles: true, cancelable: false });
  node.dispatchEvent(event);
};

export interface IOnboardingTourCardBase {
  guide: IGenUserOnboardingTourGuide;
  onClickNext: () => Promise<void>;
  currentStepNumber: number;
  stepsAmount: number;
  onSkipCurrent: () => void;
  onSkipAll: () => void;
  showSkipPopover: boolean;
  setShowSkipPopover: Dispatch<SetStateAction<boolean>>;
  skipButtonRef;
  confettiZIndex?: number;
}

interface IOnboardingTourCard extends IOnboardingTourCardBase {
  step: IGenUserOnboardingTourPointer | IGenUserOnboardingTourPost;
}

const COUNT = 500;

const defaults = { zIndex: Z_INDEX.ONBOARDING_TOUR_CONFETTI };

const fire = (particleRatio, opts) => {
  confetti(
    Object.assign({}, defaults, opts, {
      particleCount: Math.floor(COUNT * particleRatio),
    }),
  );
};

export const fireAll = (origin, zIndex) => {
  fire(0.25, {
    spread: 400,
    startVelocity: 55,
    origin,
    zIndex: zIndex || defaults.zIndex,
  });
  fire(0.2, {
    spread: 200,
    origin,
    zIndex: zIndex || defaults.zIndex,
  });
  fire(0.35, {
    spread: 500,
    decay: 0.91,
    scalar: 0.8,
    origin,
    zIndex: zIndex || defaults.zIndex,
  });
  fire(0.1, {
    spread: 300,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
    origin,
    zIndex: zIndex || defaults.zIndex,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
    origin,
    zIndex: zIndex || defaults.zIndex,
  });
};

export const OnboardingTourCard: FC<IOnboardingTourCard> = ({
  step,
  guide,
  onClickNext,
  currentStepNumber,
  stepsAmount,
  onSkipAll,
  onSkipCurrent,
  showSkipPopover,
  setShowSkipPopover,
  skipButtonRef,
  confettiZIndex,
}) => {
  const buttonRef = useRef<HTMLDivElement>();
  const ref = useRef();
  // some elements like iframe cause as scrollbar on the whole page, that is why we make sure they are shown later
  const [showDelayedElements, setShowDelayedElements] = useState(false);

  const handleClickDone = async () => {
    const buttonPosition = (buttonRef?.current as HTMLDivElement)?.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const buttonTop = buttonPosition.top;
    const buttonLeft = buttonPosition.left;
    const buttonWidth = buttonPosition.width;
    const buttonHeight = buttonPosition.height;

    const x = (buttonLeft + buttonWidth / 2) / windowWidth;
    const y = (buttonTop + buttonHeight / 2) / windowHeight;

    fireAll({ x, y }, confettiZIndex);
    setTimeout(async () => {
      onClickNext && (await onClickNext());
      setTimeout(() => {
        triggerMouseEvent(document.body);
        triggerMouseEvent(document.body);
      }, 300);
    }, 200);
  };

  useEffect(() => {
    if (!step?.text?.json || !JSON.stringify(step.text.json).includes(`"type":"iframe"`)) {
      return;
    }

    const timeout = setTimeout(() => {
      setShowDelayedElements(true);
    }, 480);

    return () => timeout && clearTimeout(timeout);
  }, [step.text.json]);

  if (!step?.text?.json) {
    return null;
  }

  return (
    <SOnboardingTourCard ref={ref} size={(step as IGenUserOnboardingTourPointer).size}>
      <SOnboardingTourCardGuide>
        <SOnboardingTourCardProfilePic>
          <img src={guide.avatar.src} />
        </SOnboardingTourCardProfilePic>
        <div>
          {guide.name} <I18n selector="userOnboardingTourShared.guideSuffix" fallback="from caisy" />
        </div>
        <SOnboardingTourCardCloseIcon onClick={onSkipCurrent}>
          <IconCross size={20} />
        </SOnboardingTourCardCloseIcon>
      </SOnboardingTourCardGuide>
      <SOnboardingTourCardContent showDelayedElements={showDelayedElements}>
        <RichTextRenderer node={step.text.json} />
      </SOnboardingTourCardContent>
      <SOnboardingTourCardFooter>
        <div>
          <SOnboardingTourCardButtonWrapper ref={skipButtonRef}>
            <FlatActionButton onClick={() => setShowSkipPopover((prev) => !prev)} type="default">
              <I18n selector="userOnboardingTourShared.skip" fallback="Skip" />
            </FlatActionButton>
          </SOnboardingTourCardButtonWrapper>
        </div>
        <Popover disableTriangle zIndex={1210} reference={ref} placement="left" display={showSkipPopover}>
          <div
            onClick={() => {
              setShowSkipPopover(true);
            }}
          >
            <DeleteConfirmationPopup>
              <SDeleteMemberModalHeader>
                <I18n selector="userOnboardingTourShared.skipTour" fallback="Skip tour" />
                <SOnboardingTourCardCloseIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSkipPopover(false);
                  }}
                  style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                >
                  <IconCross size={24} />
                </SOnboardingTourCardCloseIcon>
              </SDeleteMemberModalHeader>
              <SOnboardingTourCardPopupContent>
                <SOnboardingTourCardDescription>
                  <I18n
                    selector="userOnboardingTourShared.skipAllNote"
                    fallback="The tours can be all activated again in your profile settings."
                  />
                </SOnboardingTourCardDescription>
                <DeleteConfirmationPopupTitle>
                  <SOnboardingTourCardTitle style={{ display: "flex", alignItems: "flex-start" }}>
                    <I18n
                      selector="userOnboardingTourShared.skipAllTitle"
                      fallback="Do you want to skip all tours or only this one?"
                    />
                  </SOnboardingTourCardTitle>
                </DeleteConfirmationPopupTitle>
              </SOnboardingTourCardPopupContent>
              <SOnboardingTourCardPopupButtons>
                <Button onClick={onSkipCurrent} type="primary">
                  <I18n selector="userOnboardingTourShared.skipThisTour" fallback="Skip" />
                </Button>
                <Button onClick={onSkipAll} type="secondary">
                  <I18n selector="userOnboardingTourShared.skipAllTours" fallback="Skip all tours" />
                </Button>
              </SOnboardingTourCardPopupButtons>
            </DeleteConfirmationPopup>
          </div>
        </Popover>

        <p>
          {currentStepNumber} <I18n selector="userOnboardingTourShared.of" fallback="of" /> {stepsAmount}
        </p>
        {!(step as IGenUserOnboardingTourPointer).clickToContinue ? (
          <Button
            onClick={currentStepNumber === stepsAmount ? handleClickDone : onClickNext}
            type="primary"
            size="small"
          >
            <div ref={buttonRef}>
              {currentStepNumber === stepsAmount ? (
                <I18n selector="userOnboardingTourShared.done" fallback="Done" />
              ) : (
                <I18n selector="userOnboardingTourShared.nextButtonText" fallback="Next" />
              )}
            </div>
          </Button>
        ) : (
          <div />
        )}
      </SOnboardingTourCardFooter>
    </SOnboardingTourCard>
  );
};
