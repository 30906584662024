import produce from "immer";
import { getManyAuditEvents } from "../../graphql/queries/getManyAudtEvents.gql";
import { IAuditEventPayload, IGetManyAuditEventsResponse } from "../../interfaces/generated";
import { client } from "../../utils/client";
import { IUseAuditlogState } from "./types";

export const createAuditlogSlice = (
  set: (cb: (state: IUseAuditlogState) => IUseAuditlogState, replace: boolean, name: string) => void,
  get: () => IUseAuditlogState,
): IUseAuditlogState => ({
  auditlog: {
    auditlogs: {},
    loading: false,
    isNextPageLoading: false,
    hasNextPage: false,
    endCursor: null,
    loadManyAuditEvents: async (input) => {
      set(
        produce<IUseAuditlogState>(({ auditlog }) => {
          auditlog.loading = true;
          auditlog.isNextPageLoading = true;
        }),
        false,
        "auditlog/LoadManyAuditEvents/start",
      );
      const { data } = await client.query({
        query: getManyAuditEvents,
        variables: { input },
      });

      const nodes = (data.GetManyAuditEvents as IGetManyAuditEventsResponse).connection.edges.map(({ node }) => node);
      const { pageInfo } = (data.GetManyAuditEvents as IGetManyAuditEventsResponse).connection;

      set(
        produce<IUseAuditlogState>(({ auditlog }) => {
          auditlog.loading = false;
          auditlog.isNextPageLoading = false;
          auditlog.hasNextPage = pageInfo.hasNextPage;
          auditlog.endCursor = pageInfo.endCursor;
          auditlog.auditlogs =
            nodes.reduce(
              (prev, auditEvent: IAuditEventPayload) => ({
                ...{
                  ...prev,
                  [auditEvent.eventId]: auditEvent as IAuditEventPayload,
                },
              }),
              {},
            ) || {};
        }),
        false,
        "apikey/createdApiKey/done",
      );
    },
    loadNextPage: async ({ projectId }) => {
      if (!get().auditlog?.hasNextPage || get().auditlog?.isNextPageLoading || get().auditlog?.loading) return;

      set(
        produce<IUseAuditlogState>(({ auditlog }) => {
          auditlog.isNextPageLoading = true;
        }),
        false,
        "auditlog/loadNextPage/start",
      );
      const { data } = await client.query({
        query: getManyAuditEvents,
        variables: {
          input: {
            projectId,
            paginationArguments: {
              first: 50,
              after: get().auditlog.endCursor,
            },
          },
        },
      });

      const nodes = (data.GetManyAuditEvents as IGetManyAuditEventsResponse).connection.edges.map(({ node }) => node);
      const { pageInfo } = (data.GetManyAuditEvents as IGetManyAuditEventsResponse).connection;

      set(
        produce<IUseAuditlogState>(({ auditlog }) => {
          auditlog.isNextPageLoading = false;
          auditlog.hasNextPage = pageInfo.hasNextPage;
          auditlog.endCursor = pageInfo.endCursor;
          auditlog.auditlogs =
            nodes.reduce(
              (prev, auditEvent: IAuditEventPayload) => ({
                ...{
                  ...auditlog.auditlogs,
                  [auditEvent.eventId]: auditEvent as IAuditEventPayload,
                  ...prev,
                },
              }),
              {},
            ) || {};
        }),
        false,
        "auditlog/loadNextPage/done",
      );
    },
  },
});
