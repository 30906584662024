import React from "react";

function IconCards() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <rect
        width="10.667"
        height="12"
        x="2.667"
        y="2"
        stroke="#008CFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        rx="2"
      ></rect>
      <path
        stroke="#008CFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M5.333 11.333h5.334"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#008CFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M7.477 4.659a.583.583 0 011.046 0l.297.601c.085.172.25.292.44.32l.663.096a.583.583 0 01.323.995l-.48.469a.583.583 0 00-.168.516l.114.66a.583.583 0 01-.847.616l-.594-.312a.583.583 0 00-.542 0l-.594.312a.583.583 0 01-.847-.615l.114-.661a.583.583 0 00-.168-.516l-.48-.47a.583.583 0 01.323-.994l.664-.097a.583.583 0 00.44-.319l.296-.601z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IconCards;
