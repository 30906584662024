import styled from "styled-components";

export const SCreateProjectCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: var(--ui-supportive-06);
  color: var(--icon-02);
  border-radius: 8px;
  box-shadow: var(--box-shadow-strong-pop-elavation);
`;
