import styled from "styled-components";

export const SAddNewItemModalCloseIconWrapper = styled.div`
  color: var(--icon-01);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
`;
