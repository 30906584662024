import produce from "immer";
import { getOrganizationQuotaPlan } from "../../graphql/queries/getOrganizationQuotaPlan.gql";
import { IGetOrganizationQuotaPlanRequestInput } from "../../interfaces/generated";
import { client } from "../../utils/client";
import { IUseOrganizationQuotaSlice } from "./types";

const defaultPlanValue = {
  maxMembers: 0,
  availableSystemRoles: 110101,
};

export const createOrganizationQuotaSlice = (
  set: (cb: (state: IUseOrganizationQuotaSlice) => IUseOrganizationQuotaSlice, replace: boolean, name: string) => void,
) => ({
  organizationQuota: {
    planLoaded: false,
    plan: defaultPlanValue,
    getOrganizationQuotaPlan: async (input: IGetOrganizationQuotaPlanRequestInput) => {
      const { data } = await client.query({
        query: getOrganizationQuotaPlan,
        variables: { input },
      });

      if (!data.GetOrganizationQuotaPlan.quotaPlan) return;

      set(
        produce((state) => {
          state.organizationQuota.plan = data.GetOrganizationQuotaPlan.quotaPlan;
          state.organizationQuota.planLoaded = true;
        }),
        false,
        "organizationQuota/getOrganizationQuotaPlan",
      );
    },
    reset: () => {
      set(
        produce<IUseOrganizationQuotaSlice>((state) => {
          state.organizationQuota.plan = defaultPlanValue;
          state.organizationQuota.planLoaded = false;
        }),
        false,
        "organizationQuota/reset",
      );
    },
  },
});
