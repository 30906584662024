import gql from "graphql-tag";

export const CreateWebhook = gql`
  mutation CreateWebhook($input: CreateWebhookRequestInput) {
    CreateWebhook(input: $input) {
      ...CreateWebhookResponse
    }
  }
  fragment CreateWebhookResponse on CreateWebhookResponse {
    webhook {
      ...WebhookResponse
    }
  }
  fragment WebhookResponse on WebhookResponse {
    createdAt
    description
    headers {
      ...WebhookHeader
    }
    method
    name
    trigger
    updatedAt
    url
    webhookId
  }
  fragment WebhookHeader on WebhookHeader {
    name
    value
  }
`;