import gql from "graphql-tag";

export const getProjectPort = gql`
  query GetProjectPort($input: GetProjectPortRequestInput) {
    __typename
    GetProjectPort(input: $input) {
      selectionProgress {
        blueprintCompleted
        blueprintTotal
        documentFieldLocaleCompleted
        documentCompleted
        webhookTotal
        viewTotal
        webhookCompleted
        viewCompleted
        tagTotal
        tagCompleted
        previewCompleted
        previewTotal
        memberTotal
        memberCompleted
        documentTotal
        documentFieldLocaleTotal
      }
      createdAt
      expiresAt
      finished
      portErrors {
        errorMessage
        objectId
        objectType
      }
      portType
      projectId
    }
  }
`;
