import gql from "graphql-tag";

export const mutation_createUserAccessToken = gql`
  mutation CreateUserAccessToken($input: CreateUserAccessTokenRequestInput) {
    CreateUserAccessToken(input: $input) {
      id
      createdAt
      value
      name
    }
  }
`;
