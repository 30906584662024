import { gql } from "@apollo/client";

export const assignRole = gql`
  mutation AssignRole(
    $groupId: String
    $organizationId: String
    $projectId: String
    $roleId: String
    $userId: String
    $systemRole: String
    $emailAddress: String
  ) {
    AssignRole(
      input: {
        groupId: $groupId
        organizationId: $organizationId
        projectId: $projectId
        roleId: $roleId
        userId: $userId
        systemRole: $systemRole
        emailAddress: $emailAddress
      }
    ) {
      success
      userId
    }
  }
`;
