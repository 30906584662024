import styled from "styled-components";

export const SSettingsTopNav = styled.div`
  position: relative;
  height: 60px;
  box-sizing: border-box;
  background-color: var(--ui-01);
  box-shadow: var(--box-shadow-sticked-top-ui);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 12px;
  border-bottom: 1px solid var(--ui-03);
  z-index: 20;
  & > div:first-child {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
  }
`;
