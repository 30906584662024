import { CSSProgressiveHighLight } from "@caisy/league/src/constants/styles/fonts";
import styled from "styled-components";

export const SCreateProjectCardIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  background-color: var(--ui-supportive-12);
  color: var(--text-09);
  ${CSSProgressiveHighLight};
`;
