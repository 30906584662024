import React, { useEffect } from "react";
import { setImmediatePolyfill } from "src/utils/setImmediate";
import Head from "next/head";
import NoScript from "src/components/molecule/noscript/NoScript";
import { AuthProvider } from "src/components/molecule/AuthProvider";
import { I18nProvider } from "src/provider/i18n/I18nProvider";
import { I18nDayjsProvider } from "src/provider/i18n/I18nDayjsProvider";
import { ListenerCurrentProject } from "src/provider/current-project/listenerCurrentProject";
import { resetServerContext } from "react-beautiful-dnd";
import { ListenerEntrypoint } from "src/stores/entrypoint/useEntrypoint";
import "src/components/organism/playground/styles/graphiql.css";
import {
  Layout as LeagueLayout,
  LayoutTopNav,
  SLayoutContentWrapper,
  SLayoutWrapper,
  Theme as LeagueTheme,
} from "@caisy/league";
import MainNavigation from "src/components/organism/main-navigation/MainNavigation";
import { SecurityProvider } from "src/services/security";
import { Upload } from "src/services/upload/Upload";
import { ListenerCurrentGroup } from "src/provider/current-group/listenerCurrentGroup";
import { ListenerCurrentOrganization } from "src/provider/current-organization/listenerCurrentOrganization";
import { GSFonts } from "../constants/styles/fonts";
import GSGeneral from "../constants/styles/GSGeneral";
import { HistoryProvider } from "../provider/history-provider/HistoryProvider";
import { Analytics } from "@vercel/analytics/react";
import SettingsTopNav from "../components/organism/settings-top-nav/SettingsTopNav";
import { GroupMembersNavChildren } from "../components/page/management/group/members/GroupMembersNavChildren";
import { GroupProjectsNavChildren } from "../components/page/management/group/projects/GroupProjectsNavChildren";
import { OrganizationSidebar } from "../components/page/management/organization/organization-sidebar/OrganizationSidebar";
import { ListenerUserOnboardingTours } from "../provider/user-onboarding-tours/UserOnboardingTours";
import { PosthogWrapper } from "../services/posthog/wrapper";
import { IntercomWrapper } from "../services/intercom/wrapper";
import { ListenerProjectOnboarding } from "../provider/project-onboarding/ListenerProjectOnboarding";
import { AppsumoDowngradeModal } from "../services/appsumo/AppsumoDowngradeModal";
import { NavbarBehaviorProvider } from "../provider/navbar-behavior/NavbarBehavior";
import { CollaborationProvider } from "../provider/collaboration/CollaborationContext";
import { ListenerCurrentDocument } from "../provider/current-document/listenerCurrentDocument";
import { ListenerPeerMutation } from "../provider/collaboration/listenerPeerMutation";
import { MaintenanceWarningModal } from "../components/organism/maintenance-warning/maintenance-warning-modal/MaintenanceWarningModal";
import { ListenerPreviewEdit } from "../provider/collaboration/live-preview/listenerPreviewEdit";

resetServerContext();

const unprotected = [
  "/login",
  "/_error",
  "/404",
  "/reset-password-apply",
  "/reset-password-request",
  "/register-contact-info",
  "/register-sign-up",
  "/register-set-password",
  "/register-confirm-email",
  "/register-partner-email",
  "/signup",
];

const unprotectedWildcards = ["/signup/"];

const withoutAnalytics = ["/login", "/reset-password-apply", "/reset-password-request", "/register-sign-up", "/signup"];

const protectedWithoutNav = ["/onboarding", "/organization/onboarding"];

const withSettingsTopNav = ["/settings", "/settings", "/home"];

const getSettingsNavComponent = (path) => {
  if (path.includes("/group/settings/members")) {
    return <GroupMembersNavChildren />;
  }

  if (path.includes("group/settings/projects")) {
    return <GroupProjectsNavChildren />;
  }

  return null;
};

const getPageTitle = ({ path, nav }) => {
  if (!nav || !path) {
    return "";
  }

  if (nav?.nav_items_documents && path.includes("project/document")) {
    return nav.nav_items_documents ?? "Documents";
  }
  if (nav?.nav_items_assets && path.includes("project/asset")) {
    return nav.nav_items_assets ?? "Assets";
  }
  if (nav?.nav_items_releases && path.includes("project/releases")) {
    return nav.nav_items_releases ?? "Releases";
  }
  if (nav?.nav_items_blueprints && path.includes("project/blueprint")) {
    return nav.nav_items_blueprints ?? "Blueprints";
  }
  if (nav?.nav_items_playground && path.includes("project/playground")) {
    return nav.nav_items_playground ?? "Playground";
  }
  if (nav?.nav_items_setting && path.includes("project/setting")) {
    return nav.nav_items_setting ?? "Settings";
  }
  if (nav?.nav_items_home && path.includes("project/home")) {
    return nav.nav_items_home ?? "Home";
  }

  return "";
};

export default function App({ Component, pageProps, router, err }) {
  const pageTitle = getPageTitle({ path: router?.pathname, nav: pageProps?.i18n?.nav });

  const unprotectedPage =
    unprotected.includes(router.pathname) || unprotectedWildcards.some((path) => router.pathname.includes(path));

  useEffect(() => {
    setImmediatePolyfill();
  }, []);
  useEffect(() => {
    if (unprotectedPage) {
      return;
    }
    const w = window as any;
    w && typeof w.clarity == "function" && w.clarity("consent");
  }, [unprotectedPage]);

  const ComponentWithAuthLayout = unprotectedPage ? (
    <Component {...pageProps} pathname={router.pathname} query={router.query} asPath={router.asPath} err={err} />
  ) : (
    <AuthProvider>
      {/* <MaintenanceWarning /> */}
      <NavbarBehaviorProvider>
        <HistoryProvider>
          <SecurityProvider>
            <Upload>
              <CollaborationProvider>
                <LeagueLayout styleOverwrite={{ height: "100vh" }}>
                  <AppsumoDowngradeModal></AppsumoDowngradeModal>
                  <MaintenanceWarningModal />
                  {!protectedWithoutNav.includes(router.pathname) && <MainNavigation asPath={router.asPath} />}
                  {withSettingsTopNav.some((path) => router.pathname.includes(path)) ? (
                    <SLayoutWrapper>
                      {router.pathname.includes("/organization") ? (
                        <SLayoutContentWrapper>
                          <OrganizationSidebar />
                          <Component asPath={router.asPath} err={err} {...pageProps} />
                        </SLayoutContentWrapper>
                      ) : (
                        <>
                          <LayoutTopNav>
                            <SettingsTopNav>{getSettingsNavComponent(router.pathname)}</SettingsTopNav>
                          </LayoutTopNav>
                          <Component asPath={router.asPath} err={err} {...pageProps} />
                        </>
                      )}
                    </SLayoutWrapper>
                  ) : (
                    <Component asPath={router.asPath} err={err} {...pageProps} />
                  )}
                </LeagueLayout>
              </CollaborationProvider>
            </Upload>
            <ListenerEntrypoint />
            <ListenerCurrentProject />
            <ListenerCurrentDocument />
            <ListenerCurrentGroup />
            <ListenerCurrentOrganization />
            <ListenerUserOnboardingTours />
            <ListenerProjectOnboarding />
            <ListenerPeerMutation />
            <ListenerPreviewEdit />
          </SecurityProvider>
        </HistoryProvider>
      </NavbarBehaviorProvider>
    </AuthProvider>
  );

  return (
    <>
      <NoScript />
      <Head>
        <title key="title">{pageTitle == "" ? "caisy" : `${pageTitle} | caisy`}</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/app/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/app/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/app/favicon-16x16.png" />
        <link rel="manifest" href="/app/site.webmanifest"></link>
        <link rel="icon" href="/app/favicon.ico" />
        <meta name="description" content="Make Content easy with Caisy - Caisy the headless CMS" />
        <meta name="keywords" content="CMS, Headless, Headless CMS, Developer, GraphQL, API, Editor, Content" />
        <link rel="manifest" href="/app/site.webmanifest"></link>
        <link rel="icon" href="/app/favicon.ico" />
        <noscript>
          <link href="/app/noscript.css" rel="stylesheet" />
        </noscript>
      </Head>
      <GSFonts />
      <GSGeneral />
      {withoutAnalytics.includes(router.pathname) ? null : (
        <>
          <PosthogWrapper />
          <IntercomWrapper />
        </>
      )}
      <LeagueTheme>
        <I18nProvider i18n={pageProps?.i18n} unprotectedPage={unprotectedPage}>
          <I18nDayjsProvider>{ComponentWithAuthLayout}</I18nDayjsProvider>
        </I18nProvider>
      </LeagueTheme>
      <Analytics debug={false} />
    </>
  );
}
