import gql from "graphql-tag";

export const GetManyWebhookCalls = gql`
  query GetManyWebhookCalls($input: GetManyWebhookCallsRequestInput) {
    __typename
    GetManyWebhookCalls(input: $input) {
      connection {
        edges {
          cursor
          node {
            createdAt
            eventId
            responseStatusCode
            webhookCallId
            webhookId
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;
