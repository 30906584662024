import produce from "immer";
import { mutation_createUserAccessToken } from "../../graphql/mutations/createUserAccessToken.gql";
import { mutation_deleteUserAccessToken } from "../../graphql/mutations/deleteUserAccessToken.gql";
import { query_readUserAccessTokens } from "../../graphql/queries/readUserAccessTokens.gql";
import { client } from "../../utils/client";
import {
  ICreateUserAccessTokenResponse,
  IDeleteUserAccessTokenResponse,
  IReadUserAccessTokensResponse,
} from "src/interfaces/generated";
import { IUserAccessTokenState } from "./types";

export const createUserAccessTokenSlice = (
  set: (cb: (state: IUserAccessTokenState) => IUserAccessTokenState, replace: boolean, name: string) => void,
  get: () => IUserAccessTokenState,
): IUserAccessTokenState => ({
  userAccessToken: {
    userAccessTokens: {},
    loading: false,
    loadUserAccessTokens: async () => {
      set(
        produce<IUserAccessTokenState>((state) => {
          state.userAccessToken.loading = true;
        }),
        false,
        "useUserAccessToken/loadUserAccessTokens/start",
      );

      const { data } = await client.query({
        query: query_readUserAccessTokens,
        variables: { input: {} },
        fetchPolicy: "no-cache",
      });

      const { tokens } = data?.ReadUserAccessTokens as IReadUserAccessTokensResponse;

      if (tokens) {
        set(
          produce<IUserAccessTokenState>((state) => {
            tokens.forEach((token) => {
              state.userAccessToken.userAccessTokens[token.id] = token;
              state.userAccessToken.loading = false;
            });
          }),
          false,
          "useUserAccessToken/loadUserAccessTokens/end",
        );
      }
    },
    createUserAccessToken: async ({ name }) => {
      const { data } = await client.mutate({
        mutation: mutation_createUserAccessToken,
        variables: { input: { name } },
      });

      const newToken: ICreateUserAccessTokenResponse = data.CreateUserAccessToken;

      if (data.CreateUserAccessToken) {
        set(
          produce<IUserAccessTokenState>((state) => {
            state.userAccessToken.userAccessTokens[newToken.id] = { ...newToken, __typename: "AccessTokenResponse" };
          }),
          false,
          "useUserAccessToken/createUserAccessToken",
        );

        return newToken.value;
      }
    },
    deleteUserAccessToken: async ({ userAccessTokenId }) => {
      const { data } = await client.mutate({
        mutation: mutation_deleteUserAccessToken,
        variables: { input: { id: userAccessTokenId } },
      });

      const { deleted } = data.DeleteUserAccessToken as IDeleteUserAccessTokenResponse;

      if (deleted) {
        set(
          produce<IUserAccessTokenState>((state) => {
            delete state.userAccessToken.userAccessTokens[userAccessTokenId];
          }),
          false,
          "useUserAccessToken/deleteUserAccessToken",
        );
      }
    },

    getUserAccessTokens: () => Object.values(get().userAccessToken.userAccessTokens),

    deleteManyUserAccessTokens: async ({ userAccessTokensIds }) => {
      await Promise.all(
        userAccessTokensIds.map((userAccessTokenId) =>
          get().userAccessToken.deleteUserAccessToken({ userAccessTokenId }),
        ),
      );
    },
  },
});
