import { FC } from "react";
import { IGenProjectTemplate } from "../../../../../../interfaces/generated/i18n-types";
import { SelectFromTemplateStepTemplatesGrid } from "./SelectFromTemplateStepTemplatesGrid";
import { SSelectTemplateStep } from "./styles/SSelectTemplateStep";

interface ISelectTemplateStep {
  setSelectedTemplate: (template: IGenProjectTemplate) => void;
  advanceStep: () => void;
}

export const SelectTemplateStep: FC<ISelectTemplateStep> = ({ setSelectedTemplate, advanceStep }) => {
  const handleSelect = (template: IGenProjectTemplate) => {
    advanceStep();
    setSelectedTemplate(template);
  };

  return (
    <SSelectTemplateStep>
      <SelectFromTemplateStepTemplatesGrid onSelectTemplate={handleSelect} />
    </SSelectTemplateStep>
  );
};
