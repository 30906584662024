import gql from "graphql-tag";

export const getManyDocumentFieldsByDocumentId = gql`
  query GetManyDocumentFieldsByDocumentId($input: GetManyDocumentFieldsByDocumentIdRequestInput) {
    GetManyDocumentFieldsByDocumentId(input: $input) {
      ...GetManyDocumentFieldsByDocumentIdResponse
    }
  }
  fragment GetManyDocumentFieldsByDocumentIdResponse on GetManyDocumentFieldsByDocumentIdResponse {
    fields {
      ...DocumentFieldResponse
    }
  }
  fragment DocumentFieldResponse on DocumentFieldResponse {
    field {
      blueprintFieldId
      data
      documentFieldLocaleId
      updatedAt
      lastUpdatedByUserId
    }
    validation {
      ...Validation
    }
  }
  fragment Validation on Validation {
    errors {
      ...ValidationError
    }
    valid
  }
  fragment ValidationError on ValidationError {
    blueprintFieldId
    errorCodes
    errorDetails
    documentFieldLocaleId
  }
`;
