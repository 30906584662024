import gql from "graphql-tag";
import { fragment_GroupResponse } from "../fragments/groupResponse.gql";

export const updateGroup = gql`
  ${fragment_GroupResponse}
  mutation UpdateGroup($input: UpdateGroupRequestInput) {
    UpdateGroup(input: $input) {
      group {
        ...fragment_GroupResponse
      }
    }
  }
`;
