import { FC } from "react";
import { CreateProjectModalFirstStepItem } from "./CreateProjectModalFirstStepItem";
import { IconCopy, IconDocuments, IconGridViewSwitch } from "@caisy/league";
import { useI18nString } from "../../../../../provider/i18n/useI18nString";
import { ECreateProjectSteps } from "../CreateProjectModal";
import { SCreateProjectModalFirstStepWrapper } from "./styles/SCreateProjectModalFirstStepWrapper";

interface ICreateProjectModalFirstStep {
  onSelectStep: (step: ECreateProjectSteps) => void;
}

export const CreateProjectModalFirstStep: FC<ICreateProjectModalFirstStep> = ({ onSelectStep }) => {
  const emptyProjectTitle = useI18nString({
    selector: "nav.createProject.emptyProject",
    fallback: "New empty project",
  });

  const emptyProjectDescription = useI18nString({
    selector: "nav.createProject.emptyProjectDescription",
    fallback: "A new empty project will be created",
  });

  const newFromExistingTitle = useI18nString({
    selector: "nav.createProject.newFromExistingProject",
    fallback: "New from existing project",
  });

  const newFromExistingDescription = useI18nString({
    selector: "nav.createProject.newFromExistingProjectDescription",
    fallback: "A new copy of the existing project will be created",
  });

  const newFromTemplateTitle = useI18nString({
    selector: "nav.createProject.newFromTemplate",
    fallback: "New from template",
  });

  const newFromTemplateDescription = useI18nString({
    selector: "nav.createProject.newFromTemplateDescription",
    fallback: "A new project based on the template will be created",
  });

  return (
    <>
      <SCreateProjectModalFirstStepWrapper>
        <CreateProjectModalFirstStepItem
          iconColor="var(--ui-supportive-06)"
          onClick={() => onSelectStep(ECreateProjectSteps.create_new_project)}
          icon={<IconDocuments size={24} />}
          title={emptyProjectTitle}
          description={emptyProjectDescription}
        />

        <CreateProjectModalFirstStepItem
          iconColor="var(--icon-07)"
          onClick={() => onSelectStep(ECreateProjectSteps.create_new_from_existing)}
          icon={<IconCopy size={24} />}
          title={newFromExistingTitle}
          description={newFromExistingDescription}
        />

        <CreateProjectModalFirstStepItem
          iconColor="var(--icon-supportive-03)"
          onClick={() => onSelectStep(ECreateProjectSteps.create_new_from_template)}
          icon={<IconGridViewSwitch size={24} />}
          title={newFromTemplateTitle}
          description={newFromTemplateDescription}
        />
      </SCreateProjectModalFirstStepWrapper>
    </>
  );
};
