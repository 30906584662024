import { CSSProgressiveBody03Medium } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSLink = css`
  color: var(--active-text-04);

  a {
    color: var(--active-text-04);
    text-decoration: none;
  }
`;

export const SOnboardingTemplatesModalSiderItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${CSSProgressiveBody03Medium};
  color: var(--text-01);

  img {
    height: 16px;
    width: 16px;
  }

  ${({ isLink }) => isLink && CSSLink}
`;
