import { CSSProgressiveBody03, CSSProgressiveHeading02 } from "@caisy/league";
import styled from "styled-components";
import { CSSRichtext } from "../../../../../constants/styles/css-richtext";

export const SOnboardingTourCardContent = styled.div<{ showDelayedElements?: boolean }>`
  ${CSSRichtext};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${CSSProgressiveHeading02};
    color: var(--text-01);
    padding: 0 16px;
    margin-bottom: 0;
  }

  ol,
  ul,
  iframe {
    padding: 0 16px;
    padding-bottom: 16px;
  }

  iframe {
    width: calc(100% - 32px);
    max-width: calc(544px - 32px);
    overflow: hidden;
    ${({ showDelayedElements }) => (showDelayedElements ? "" : "display: none;")};
  }

  ol,
  ul {
    margin-top: 0;
    > li {
      padding: 0;
      &:not(:last-child) {
        padding-bottom: 8px;
      }
    }
  }
  p {
    strong,
    b {
      font-weight: 600;
    }
    ${CSSProgressiveBody03};
    color: var(--text-01);
    padding: 0 16px;
    padding-bottom: 16px;
  }
`;
