import gql from "graphql-tag";
import { fragment_blueprintResponse } from "../fragments/blueprintResponse.gql";

export const getManyBlueprints = gql`
  ${fragment_blueprintResponse}
  query GetManyBlueprints($input: GetManyBlueprintsRequest) {
    GetManyBlueprints(input: $input) {
      connection {
        edges {
          cursor
          node {
            ...fragment_blueprintResponse
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;
