import React from "react";
import { I18n } from "../provider/i18n";
import { useI18nString } from "../provider/i18n/useI18nString";

export type IMemberRole = "owner" | "admin" | "developer" | "editor" | "contributor" | "viewer";
export const memberRoles = ["owner", "admin", "developer", "editor", "contributor", "viewer"];
export const memberRolesDropDownDataSource = [
  {
    key: "owner",
    title: <I18n selector="memberRoles.owner" fallback="Owner" />,
  },
  {
    key: "admin",
    title: <I18n selector="memberRoles.admin" fallback="Admin" />,
  },
  {
    key: "developer",
    title: <I18n selector="memberRoles.developer" fallback="Developer" />,
  },
  {
    key: "editor",
    title: <I18n selector="memberRoles.editor" fallback="Editor" />,
  },
  {
    key: "contributor",
    title: <I18n selector="memberRoles.contributor" fallback="Contributor" />,
  },
  {
    key: "viewer",
    title: <I18n selector="memberRoles.viewer" fallback="Viewer" />,
  },
];

const getMemberRoleI18N = (role: IMemberRole) => {
  const memberRolesI18N = {
    editor: useI18nString({
      fallback: "Editor",
      selector: "memberRoles.editor",
    }),
    owner: useI18nString({
      fallback: "Owner",
      selector: "memberRoles.owner",
    }),
    contributor: useI18nString({
      fallback: "Contributor",
      selector: "memberRoles.contributor",
    }),
    viewer: useI18nString({
      fallback: "Viewer",
      selector: "memberRoles.viewer",
    }),
    admin: useI18nString({
      fallback: "Admin",
      selector: "memberRoles.admin",
    }),
    developer: useI18nString({
      fallback: "Editor",
      selector: "memberRoles.developer",
    }),
    guest: useI18nString({
      fallback: "Guest",
      selector: "memberRoles.guest",
    }),
  };

  return memberRolesI18N[role];
};

export default getMemberRoleI18N;
