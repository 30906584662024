import { PEER_MESSAGE_TYPE_PEER_MUTATION } from "../constants";
import { encodeAnyJSONMessage } from "../encoding";
import { broadcastToAllPeers } from "../helper";

export type IStoreEvent =
  | "document/updateField"
  | "document/updateDocument"
  | "document/validateField"
  | "document/deleteDocumentById"
  | "document/duplicateDocumentById"
  | "document/deleteManyDocuments";

export const broadcastMutation = ({
  storeEvent,
  request = null,
  response = null,
}: {
  storeEvent: IStoreEvent;
  request: any;
  response: any;
}) => {
  broadcastToAllPeers(
    window.c["collaboration"].peers,
    encodeAnyJSONMessage({ messageType: PEER_MESSAGE_TYPE_PEER_MUTATION, body: { storeEvent, request, response } }),
  );
};
