import styled from "styled-components";

export const SOnboardingTemplatesModalSider = styled.div`
  padding: 24px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  height: fit-content;
  border: 1px solid var(--ui-03);
  box-shadow: 0px 4px 8px rgba(78, 114, 144, 0.04);
  border-radius: 8px;
  position: sticky;
  top: 0;
`;
