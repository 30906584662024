import { Modal } from "@caisy/league";
import { FCWithChildren } from "../../../interfaces/FCWithChildren";
import { SInformationModal } from "./styles/SInformationModal";

interface IInformationModal {
  visible: boolean;
  onClose?: () => void;
}

export const InformationModal: FCWithChildren<IInformationModal> = ({ children, visible, onClose }) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <SInformationModal>{children}</SInformationModal>
    </Modal>
  );
};
