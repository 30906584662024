import styled, { css } from "styled-components";
import { Z_INDEX } from "../../../../../constants/z-index-numbers";

const CSSSmall = css`
  width: 272px;
`;

export const SOnboardingTourCard = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: var(--ui-01);
  width: 544px;
  z-index: ${Z_INDEX.ONBOARDING_TOUR_POPOVER};
  box-shadow: var(--box-shadow-strong-pop-elavation);
  ${({ size }) => size === "small" && CSSSmall};
`;
