import styled from "styled-components";

export const SOnboardingAssetFieldImageContainer = styled.div`
  padding: 32px 0;
  width: 100%;
  background-color: var(--ui-background);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 36px;
`;
