import gql from "graphql-tag";

export const getGroupQuotaPlan = gql`
  query GetGroupQuotaPlan($input: GetGroupQuotaPlanRequestInput) {
    GetGroupQuotaPlan(input: $input) {
      groupId
      quotaPlan {
        availableSystemRoles
        maxMembers
      }
    }
  }
`;
