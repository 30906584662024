import { CSSProgressiveCaption02 } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSCompleted = css`
  color: var(--text-01);
`;

const CSSCurrent = css`
  color: var(--text-01);
`;

export const SCreateProjectModalNavStepsStep = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  ${CSSProgressiveCaption02};
  color: var(--text-04);
  ${({ current }) => current && CSSCurrent};
  ${({ completed }) => completed && CSSCompleted};
`;
