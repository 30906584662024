import gql from "graphql-tag";

export const fragment_Document = gql`
  fragment Document on Document {
    archivedAt
    blueprintBranch
    blueprintId
    createdAt
    createdByUserId
    documentId
    environmentId
    previewImageUrl
    projectId
    publishedAt
    statusId
    title
    updatedAt
    lastUpdatedByUserId
    blueprintVariant
  }
`;
