import gql from "graphql-tag";
import { fragment_Document } from "../fragments/document.gql";

export const updateDocumentField = gql`
  ${fragment_Document}
  mutation UpdateDocumentField($input: UpdateDocumentFieldRequestInput) {
    UpdateDocumentField(input: $input) {
      ...UpdateDocumentFieldResponse
    }
  }
  fragment UpdateDocumentFieldResponse on UpdateDocumentFieldResponse {
    blueprintFieldId
    document {
      ...Document
    }
  }
`;
