import styled from "styled-components";

export const SInformationModal = styled.div`
  padding: 32px;
  background-color: var(--ui-01);
  box-shadow: var(--box-shadow-light-blue-pop-elavation);
  border-radius: 12px;
  width: 488px;
  box-sizing: border-box;
`;
