import { CSSProgressiveCaption01Semibold } from "@caisy/league";
import styled from "styled-components";

export const SSelectOrganizationStepListItemBadge = styled.div`
  margin-left: auto;
  background-color: var(--ui-03);
  border-radius: 24px;
  padding: 2px 6px;
  color: var(--text-04);
  ${CSSProgressiveCaption01Semibold};
`;
