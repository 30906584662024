import { IconCloudDoneUpload, IconRefresh } from "@caisy/league";
import { usePreview } from "src/stores/preview/usePreview";
import { I18n } from "../../../../../provider/i18n";
import { SPreviewSyncIndicator } from "./styles/SPreviewSyncIndicator";

const PreviewSyncIndicator = () => {
  const { syncingPreview } = usePreview();

  return (
    <SPreviewSyncIndicator syncing={syncingPreview}>
      {syncingPreview ? <IconRefresh size={16} /> : <IconCloudDoneUpload size={16} />}
      {syncingPreview ? (
        <I18n selector="projectManagementPreviews.details_previewIsSyncing" fallback="PREVIEW IS SYNCING" />
      ) : (
        <I18n selector="projectManagementPreviews.details_previewIsSynced" fallback="PREVIEW IS SYNCED" />
      )}
    </SPreviewSyncIndicator>
  );
};

export default PreviewSyncIndicator;
