import { Fragment, ReactElement } from "react";

export const formatStrikeThrough = (input: string): ReactElement | null | string | ReactElement[] => {
  if (!input) {
    return null;
  }

  const parts = input.split("~~");
  const formattedMessage = parts.map((part, index) => {
    if (index % 2 !== 0) {
      return <del key={index}>{part}</del>;
    } else {
      return <Fragment key={index}>{part}</Fragment>;
    }
  });

  return formattedMessage;
};

export const markdownTemplate = (input: string): ReactElement | null | string | ReactElement[] => {
  if (!input) {
    return null;
  }

  // we only support bold with **text**
  // and strike through with ~~text~~ format for now

  const parts = input.split("**");
  const formattedMessage = parts.map((part, index) => {
    if (index % 2 !== 0) {
      return <strong key={index}>{formatStrikeThrough(part)}</strong>;
    } else {
      return <Fragment key={index}>{formatStrikeThrough(part)}</Fragment>;
    }
  });

  return formattedMessage;
};
