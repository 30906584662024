import "isomorphic-fetch";
import React from "react";
import { FCWithChildren } from "../../interfaces/FCWithChildren";
import { FileUploadDropHandler } from "./UploadDropHandler";

const MemoDropBody = React.memo<{ children?: React.ReactNode }>(({ children }) => (
  <FileUploadDropHandler>{children}</FileUploadDropHandler>
));

export const Upload: FCWithChildren = ({ children }) => {
  return (
    <>
      <MemoDropBody>{children}</MemoDropBody>
      {/* <FileUploadMenu /> */}
    </>
  );
};
