import { FC, Fragment, useEffect, useMemo } from "react";
import { IProjectResponse } from "../../../../interfaces/generated";
import { I18n } from "../../../../provider/i18n";
import { AddProjectModalSectionItem } from "./AddProjectModalSectionItem";
import { SAddProjectModalSections } from "./styles/SAddProjectModalSections";
import { SAddProjectModalSectionsInfo } from "./styles/SAddProjectModalSectionsInfo";
import { SAddProjectModalSectionsInfoDescription } from "./styles/SAddProjectModalSectionsInfoDescription";
import { SAddProjectModalSectionsInfoMain } from "./styles/SAddProjectModalSectionsInfoMain";
import { useMemberShip } from "../../../../stores/membership/useMembership";
import { AddProjectModalSectionBreadcrumb } from "./AddProjectModalSectionBreadcrumb";
import { IconChevronRight } from "@caisy/league";

export interface IProjectSection {
  label: React.ReactNode;
  key: string;
  subsections?: IProjectSection[];
  hidden?: boolean;
}

export enum ALL_PROJECT_SECTION_KEYS {
  blueprint = `blueprint`,
  document = `document`,
  documentFieldLocale = `documentFieldLocale`,
  documentHistory = `documentHistory`,
  member = `member`,
  preview = `preview`,
  tag = `tag`,
  view = `view`,
  webhook = `webhook`,
}

export const PROJECT_SECTIONS: IProjectSection[] = [
  {
    label: <I18n selector="nav.createProject.blueprints" fallback="Blueprints" />,
    key: ALL_PROJECT_SECTION_KEYS.blueprint,
    subsections: [
      {
        label: <I18n selector="nav.createProject.content" fallback="Content (Documents, Components, Assets)" />,
        key: ALL_PROJECT_SECTION_KEYS.document,
      },
      {
        label: <I18n selector="nav.createProject.documentFieldLocales" fallback="Document Field Locales" />,
        key: ALL_PROJECT_SECTION_KEYS.documentFieldLocale,
        hidden: true,
      },
      {
        label: <I18n selector="nav.createProject.documentHistory" fallback="Documents History" />,
        key: ALL_PROJECT_SECTION_KEYS.documentHistory,
        hidden: true,
      },
      {
        label: <I18n selector="nav.createProject.previews" fallback="Previews" />,
        key: ALL_PROJECT_SECTION_KEYS.preview,
      },
      {
        label: <I18n selector="nav.createProject.tags" fallback="Tags" />,
        key: ALL_PROJECT_SECTION_KEYS.tag,
      },
      {
        label: <I18n selector="nav.createProject.views" fallback="Views" />,
        key: ALL_PROJECT_SECTION_KEYS.view,
      },
    ],
  },
  {
    label: <I18n selector="nav.createProject.members" fallback="Members" />,
    key: ALL_PROJECT_SECTION_KEYS.member,
  },
  {
    label: <I18n selector="nav.createProject.webhooks" fallback="Webhooks" />,
    key: ALL_PROJECT_SECTION_KEYS.webhook,
  },
];

const ALL_PROJECT_SECTION_KEYS_ARRAY = [
  ALL_PROJECT_SECTION_KEYS.blueprint,
  ALL_PROJECT_SECTION_KEYS.document,
  ALL_PROJECT_SECTION_KEYS.documentFieldLocale,
  ALL_PROJECT_SECTION_KEYS.documentHistory,
  ALL_PROJECT_SECTION_KEYS.preview,
  ALL_PROJECT_SECTION_KEYS.tag,
  ALL_PROJECT_SECTION_KEYS.view,
  ALL_PROJECT_SECTION_KEYS.webhook,
  ALL_PROJECT_SECTION_KEYS.member,
];

interface IAddProjectModalSections {
  project: IProjectResponse;
  selectedSections: string[];
  setSelectedSections: (newSelectedSections: string[]) => void;
}

export const AddProjectModalSections: FC<IAddProjectModalSections> = ({
  selectedSections,
  setSelectedSections,
  project,
}) => {
  const { groups, organizations } = useMemberShip();

  const handleChange = (newValue: boolean, key: string) => {
    if (newValue) {
      if (key === ALL_PROJECT_SECTION_KEYS.document) {
        setSelectedSections([
          ...selectedSections,
          key,
          ALL_PROJECT_SECTION_KEYS.documentFieldLocale,
          ALL_PROJECT_SECTION_KEYS.documentHistory,
        ]);
      } else {
        setSelectedSections([...selectedSections, key]);
      }
    } else {
      let newSelectedSections = selectedSections;
      if (key === ALL_PROJECT_SECTION_KEYS.document) {
        newSelectedSections = selectedSections.filter(
          (section) =>
            section !== key &&
            section !== ALL_PROJECT_SECTION_KEYS.documentFieldLocale &&
            section !== ALL_PROJECT_SECTION_KEYS.documentHistory,
        );
      } else {
        newSelectedSections = selectedSections.filter((section) => section !== key);
        const subSections = PROJECT_SECTIONS.find((section) => section.key === key)?.subsections;
        if (subSections) {
          newSelectedSections = newSelectedSections.filter(
            (section) => !subSections.includes(subSections.find((subSection) => subSection.key === section)),
          );
        }
      }
      setSelectedSections(newSelectedSections);
    }
  };

  const group = groups[project.groupId];
  const organization = organizations[project.organizationId];

  const areAllSelected = useMemo(() => {
    return selectedSections.length === 9;
  }, [selectedSections]);

  const handleToggleSelectAll = () => {
    if (areAllSelected) {
      setSelectedSections([]);
    } else {
      setSelectedSections(ALL_PROJECT_SECTION_KEYS_ARRAY);
    }
  };

  useEffect(() => {
    setSelectedSections(ALL_PROJECT_SECTION_KEYS_ARRAY);
  }, []);

  return (
    <SAddProjectModalSections>
      <SAddProjectModalSectionsInfo>
        <SAddProjectModalSectionsInfoMain>
          <I18n
            selector="nav.createProject.addProjectModalSectionsTitle"
            fallback="Your new project will be based on"
          />
        </SAddProjectModalSectionsInfoMain>
        <SAddProjectModalSectionsInfoDescription>
          <AddProjectModalSectionBreadcrumb
            header={<I18n fallback="organization" selector="nav.organization" />}
            name={organization.name}
            logoAssetUrl={organization.logoAssetUrl}
          />
          <IconChevronRight size={24} />
          {group && (
            <>
              <AddProjectModalSectionBreadcrumb
                header={<I18n fallback="group" selector="nav.group" />}
                name={group.name}
                logoAssetUrl={group.logoAssetUrl}
              />
              <IconChevronRight size={24} />
            </>
          )}
          <AddProjectModalSectionBreadcrumb
            header={<I18n fallback="project" selector="nav.project" />}
            name={project.name}
            logoAssetUrl={project.logoAssetUrl}
          />
        </SAddProjectModalSectionsInfoDescription>
      </SAddProjectModalSectionsInfo>
      <AddProjectModalSectionItem onChange={handleToggleSelectAll} active={areAllSelected}>
        <I18n selector="nav.createProject.all" fallback="All" />
      </AddProjectModalSectionItem>
      {PROJECT_SECTIONS.map((section) => (
        <Fragment key={section.key}>
          <AddProjectModalSectionItem
            onChange={(newValue) => handleChange(newValue, section.key)}
            active={selectedSections.includes(section.key)}
            key={section.key}
          >
            {section.label}
          </AddProjectModalSectionItem>
          {section.subsections?.length > 0 &&
            section.subsections.map(
              (subsection) =>
                !subsection.hidden && (
                  <AddProjectModalSectionItem
                    isSubSection
                    disabled={!selectedSections.includes(section.key)}
                    onChange={(newValue) => handleChange(newValue, subsection.key)}
                    active={selectedSections.includes(subsection.key)}
                    key={subsection.key}
                  >
                    {subsection.label}
                  </AddProjectModalSectionItem>
                ),
            )}
        </Fragment>
      ))}
    </SAddProjectModalSections>
  );
};
