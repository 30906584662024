import gql from "graphql-tag";

export const updateRelease = gql`
  mutation UpdateRelease($input: UpdateReleaseRequestInput) {
    __typename
    UpdateRelease(input: $input) {
      release {
        createdAt
        id
        name
        projectId
        scheduledAt
        status
        updatedAt
        type
        validationErrors {
          documentId
          errors {
            blueprintFieldId
            errorCodes
            documentFieldLocaleId
          }
        }
      }
    }
  }
`;
