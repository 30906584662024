import styled from "styled-components";

export const SCreateProjectModalFirstStepItem = styled.div`
  padding: 52px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  cursor: pointer;
  transition: background-color 150ms;
  background-color: var(--ui-01);
  width: 304px;
  height: 316px;
  border-radius: 8px;
  box-shadow: var(--box-shadow-light-blue-pop-elavation);
  box-sizing: border-box;

  &:hover {
    background-color: var(--hover-ui-01);
  }
`;
