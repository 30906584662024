import gql from "graphql-tag";

export const fragment_APIKeyInitalResponse = gql`
  fragment fragment_APIKeyInitalResponse on APIKeyInitalResponse {
    apiKeyId
    createdAt
    name
    value
    scope {
      write
      readDraft
    }
  }
`;
