import produce from "immer";
import { createTag } from "../../graphql/mutations/createTag.gql";
import { deleteTag } from "../../graphql/mutations/deleteTag.gql";
import { updateTag } from "../../graphql/mutations/updateTag.gql";
import { getManyTags } from "../../graphql/queries/getManyTags.gql";
import { client } from "../../utils/client";
import { ICreateTagInput, ITagResponse } from "../../interfaces/generated";
import { ITagState } from "./types";

export const createTagSlice = (
  set: (cb: (state: ITagState) => ITagState, replace: boolean, name: string) => void,
  get: () => ITagState,
) => ({
  tag: {
    tags: {} as { [tagId: string]: ITagResponse },
    loading: false,
    error: false,
    getTagsList: () => {
      const entries = Object.values(get().tag.tags);
      if (entries) {
        entries.sort((a, b) => String(a.name).localeCompare(String(b.name)));
      }
      return entries;
    },
    resetTag: () => {
      set(
        produce((state: ITagState) => {
          state.tag.loading = false;
          state.tag.error = false;
          state.tag.tags = {};
        }),
        true,
        "tag/reset",
      );
    },
    loadAllTags: async ({ projectId }) => {
      set(
        produce((state: ITagState) => {
          state.tag.loading = true;
        }),
        false,
        "tag/getMany/loading",
      );
      try {
        const { data } = await client.query({
          query: getManyTags,
          variables: {
            input: {
              projectId,
            },
          },
        });

        set(
          produce((state: ITagState) => {
            data.GetManyTags.connection.edges?.forEach(({ node }) => {
              state.tag.tags[node.tagId] = node;
            });
            state.tag.loading = false;
            state.tag.error = false;
          }),
          false,
          "tag/getMany/done/success",
        );
      } catch (err) {
        set(
          produce((state: ITagState) => {
            state.tag.loading = false;
            state.tag.error = true;
          }),
          false,
          "tag/getMany/done/eroor",
        );
        console.log(err);
      }
    },

    updateTag: async ({ input, tagId, projectId }) => {
      try {
        const { data } = await client.mutate({
          mutation: updateTag,
          variables: {
            input: {
              projectId,
              input,
              tagId,
            },
          },
        });

        if (data.UpdateTag) {
          const { tag } = data.UpdateTag;
          set(
            produce((state: ITagState) => {
              state.tag.tags[tag.tagId] = tag;
            }),
            false,
            "tag/update",
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    createTag: async ({ projectId, color, name, referenceType }: ICreateTagInput) => {
      const { data } = await client.mutate({
        mutation: createTag,
        variables: {
          input: {
            tag: {
              projectId,
              color,
              name,
              referenceType,
            },
          },
        },
      });

      if (data.CreateTag) {
        const { tag } = data.CreateTag;
        set(
          produce((state: ITagState) => {
            state.tag.tags[tag.tagId] = tag;
          }),
          false,
          "tag/create",
        );
        return tag;
      }
    },

    deleteTag: async ({ tagId, projectId }) => {
      try {
        const { data } = await client.mutate({
          mutation: deleteTag,
          variables: { input: { tagId, projectId } },
        });

        if (data.DeleteTag) {
          set(
            produce((state: ITagState) => {
              delete state.tag.tags[tagId];
            }),
            false,
            "tag/delete",
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
