import { gql } from "@apollo/client";
import { fragment_blueprintResponse } from "../fragments/blueprintResponse.gql";

export const getBlueprintById = gql`
  ${fragment_blueprintResponse}
  query GetBlueprintById($input: GetBlueprintByIDRequest) {
    GetBlueprintById(input: $input) {
      blueprint {
        ...fragment_blueprintResponse
      }
    }
  }
`;
