import styled from "styled-components";
import { SOnboardingTemplatesModalContent } from "../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalContent";

export const SCreateProjectModalContent = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  margin: auto;
  margin: 0;
  height: calc(
    100vh - 80px - 65px
  ); // 76px for the padding inner, 65px for the header above, 80px for the padding outside the modal
  overflow: auto;

  ${SOnboardingTemplatesModalContent} {
    height: calc(100% - 65px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
