import { SList } from "@caisy/league/src/components/list/styles/SList";
import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../constants/styles/mediaquerys";

const Default = css`
  position: absolute;
  top: 73px;
  left: 0;
  height: calc(100% - 73px);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 11;

  ${SList} {
    background: var(--ui-background);
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuSearch = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
