import { FC, useEffect, useRef, useState } from "react";
import {
  ActiveUserAvatar,
  Badge,
  ClickOutside,
  EBadgePosition,
  Flex,
  IconAvatarShared,
  IconChat,
  IconGearSettings,
  IconLogout,
  IconPersonalKeyToken,
  IconSettingsDetails,
  IconUserProfile,
  Img,
  MainMenuItem,
  Popover,
  Preview,
  SAssetListItemTitle,
} from "@caisy/league";
import { SIntercomButton } from "./styles/SIntercomButton";
import { SMainNavigationFooterNav } from "./styles/SMainNavigationFooterNav";
import { MainMenuUserPopup } from "../MainMenuUserPopup/MainMenuUserPopup";
import { UserListItem } from "../UserListItem/UserListItem";
import { SUserListItemTextWrapper } from "../UserListItem/styles/SUserListItemTextWrapper";
import { SUserListItemDescription } from "../UserListItem/styles/SUserListItemDescription";
import { MainMenuUserPopupListItem } from "../MainMenuUserPopup/MainMenuUserPopupListItem";
import { I18n } from "../../../../provider/i18n";
import { CLink } from "../../../molecule/c-link/CLink";
import {
  IntercomDomainsWithChat,
  loadIntercomWithContext,
  shouldLoadIntercom,
} from "../../../../services/intercom/config";
import { IconBug } from "../../../atom/icons/IconBug";
import { SMainMenuItemIconTextHolder } from "../MainMenuItems/styles/SMainMenuItemIconTextHolder";
import { SMainMenuItemText } from "../MainMenuItems/styles/SMainMenuItemText";
import useExpandableNavigation from "../../../../hooks/useExpandableNavigation";
import { useCollaboration } from "../../../../provider/collaboration/CollaborationContext";
import { useCollaborator } from "../../../../provider/collaboration/useCollaborator";
import Link from "next/link";
import { getCollaborationColor } from "../../../../provider/collaboration/getCollaborationColor";
import { generatePreviewPropsForUserAvatar } from "../../../../utils/generatePreviewPropsForUserAvatar";
import { SActiveUsersDropdownItem } from "../../../page/documents/detail/components/document-detail-top-nav/document-active-users/styles/SActiveUsersDropdownItem";
import { SActiveUsersDropdownItemContent } from "../../../page/documents/detail/components/document-detail-top-nav/document-active-users/styles/SActiveUsersDropdownItemContent";
import { SActiveUsersDropdownItemEmail } from "../../../page/documents/detail/components/document-detail-top-nav/document-active-users/styles/SActiveUsersDropdownItemEmail";
import { SActiveUsersDropdownItemName } from "../../../page/documents/detail/components/document-detail-top-nav/document-active-users/styles/SActiveUsersDropdownItemName";

const handlePreviewProps = (user: any) => {
  if (user?.photoURL) {
    return {
      image: user?.photoURL && <Img lazyload={false} src={user?.photoURL} resolution={48} />,
    };
  }
  return {
    text: user?.displayName[0] || user?.email.split("@")[0][0],
  };
};

const MainMenuFooterNav: FC<{ user: any; logout: () => Promise<void>; projectId: string }> = ({
  user,
  logout,
  projectId,
}) => {
  const { expanded } = useExpandableNavigation();
  const profilePopupRef = useRef();
  const [profilePopupActive, setProfilePopupActive] = useState(false);
  const [isOnCaisyDomainWithChat, setIsOnCaisyDomainWithChat] = useState(
    typeof window !== "undefined" && IntercomDomainsWithChat.some((d) => window.location.hostname.includes(d)),
  );

  const collaborationPopupRef = useRef();
  const [collaborationPopupActive, setCollaborationPopupActive] = useState(false);
  const { useActiveProjectCollaborator } = useCollaboration();
  const clientIds = useActiveProjectCollaborator();
  const activeCollaborator = useCollaborator({ clientIds });

  useEffect(() => {
    if (shouldLoadIntercom()) {
      setIsOnCaisyDomainWithChat(IntercomDomainsWithChat.some((d) => window.location.hostname.includes(d)));
      loadIntercomWithContext({ user, projectId });
    }
  }, []);

  useEffect(() => {
    if (
      isOnCaisyDomainWithChat &&
      user?.email &&
      (window as any)?.birdeatsbug?.isBrowserSupported &&
      (window as any).birdeatsbug.trigger
    ) {
      (window as any).birdeatsbug.setOptions({
        user: { email: user.email },
      });
    }
  }, [isOnCaisyDomainWithChat]);

  const handleBugreport = () => {
    if (isOnCaisyDomainWithChat) {
      if ((window as any)?.birdeatsbug?.isBrowserSupported && (window as any).birdeatsbug.trigger) {
        (window as any).birdeatsbug.trigger();
      }
    }
  };

  return (
    <>
      <SMainNavigationFooterNav>
        {/* <MainMenuItem onClick={() => handleFooterButtonsClick("Search")}>
        <IconSearch size={20} />
      </MainMenuItem>
      <MainMenuItem onClick={() => handleFooterButtonsClick("Notification")}>
        <IconNotificationBell size={20} />
         </MainMenuItem>  */}
        {activeCollaborator?.length > 1 && (
          <>
            <SMainMenuItemIconTextHolder expanded={expanded}>
              <div ref={collaborationPopupRef}>
                <Badge
                  value={`${activeCollaborator?.length || 0}`}
                  type="dark"
                  position={EBadgePosition.TopRight}
                  size="small"
                >
                  <MainMenuItem
                    onClick={() => {
                      setCollaborationPopupActive(true);
                    }}
                  >
                    <IconAvatarShared></IconAvatarShared>{" "}
                  </MainMenuItem>
                </Badge>
              </div>
              <SMainMenuItemText
                expanded={expanded}
                onClick={() => {
                  setCollaborationPopupActive(true);
                }}
              >
                Collaborator
              </SMainMenuItemText>
            </SMainMenuItemIconTextHolder>
            <Popover placement="topRight" reference={collaborationPopupRef} display={collaborationPopupActive}>
              {() => (
                <ClickOutside
                  onClickOutside={(e) => {
                    !e.target.closest(".collaboration-popup") && setCollaborationPopupActive(false);
                  }}
                >
                  <MainMenuUserPopup opened={true}>
                    <div className="collaboration-popup">
                      {activeCollaborator.map((user) => (
                        <SActiveUsersDropdownItem key={user.clientId}>
                          {user.location ? (
                            <Link href={`${user.location.asPath}`} onClick={() => setCollaborationPopupActive(false)}>
                              <ActiveUserAvatar color={getCollaborationColor(user.clientId)} key={user.id}>
                                <Preview {...generatePreviewPropsForUserAvatar({ user, size: 28 })} />
                              </ActiveUserAvatar>
                            </Link>
                          ) : (
                            <ActiveUserAvatar color={getCollaborationColor(user.clientId)} key={user.id}>
                              <Preview {...generatePreviewPropsForUserAvatar({ user, size: 28 })} />
                            </ActiveUserAvatar>
                          )}

                          <SActiveUsersDropdownItemContent>
                            <SActiveUsersDropdownItemName>{user.displayName}</SActiveUsersDropdownItemName>
                            <SActiveUsersDropdownItemEmail>{user.email}</SActiveUsersDropdownItemEmail>
                          </SActiveUsersDropdownItemContent>
                        </SActiveUsersDropdownItem>
                      ))}
                    </div>
                  </MainMenuUserPopup>
                </ClickOutside>
              )}
            </Popover>
          </>
        )}
        {isOnCaisyDomainWithChat && (
          <>
            <div onClick={handleBugreport}>
              <SMainMenuItemIconTextHolder>
                <MainMenuItem>
                  <IconBug />
                </MainMenuItem>
                <SMainMenuItemText expanded={expanded}>
                  <I18n selector="nav.nav_items_bug_report" fallback="Bug Report" />
                </SMainMenuItemText>
              </SMainMenuItemIconTextHolder>
            </div>
            <SIntercomButton id="intercom_btn" active={isOnCaisyDomainWithChat}>
              <SMainMenuItemIconTextHolder active={isOnCaisyDomainWithChat} expanded={expanded}>
                <MainMenuItem>
                  <IconChat size={20} />
                </MainMenuItem>
                <SMainMenuItemText expanded={expanded}>
                  <I18n selector="nav.nav_items_support" fallback="Support" />
                </SMainMenuItemText>
              </SMainMenuItemIconTextHolder>
            </SIntercomButton>
          </>
        )}

        <SMainMenuItemIconTextHolder expanded={expanded}>
          <MainMenuItem
            onClick={() => {
              setProfilePopupActive(true);
            }}
          >
            <div ref={profilePopupRef}>
              <Preview size={20} {...handlePreviewProps(user)} />
            </div>
          </MainMenuItem>
          <SMainMenuItemText
            expanded={expanded}
            onClick={() => {
              setProfilePopupActive(true);
            }}
          >
            <I18n selector="nav.nav_items_profile" fallback="Profile" />
          </SMainMenuItemText>
        </SMainMenuItemIconTextHolder>
        <Popover placement="topRight" reference={profilePopupRef} display={profilePopupActive}>
          {() => (
            <ClickOutside
              onClickOutside={(e) => {
                !e.target.closest(".main-user-popup") && setProfilePopupActive(false);
              }}
            >
              <MainMenuUserPopup opened={true}>
                <div className="main-user-popup">
                  <UserListItem>
                    <Flex gap={"16px"}>
                      <Preview size={32} {...handlePreviewProps(user)} />
                      <SUserListItemTextWrapper progressValue={null}>
                        <SAssetListItemTitle>{user?.displayName ?? null}</SAssetListItemTitle>
                        <SUserListItemDescription>{user?.email ?? null}</SUserListItemDescription>
                      </SUserListItemTextWrapper>
                    </Flex>
                  </UserListItem>
                </div>
                <div className="main-user-popup">
                  <CLink path="/profile/membership" params={{ projectId }}>
                    <MainMenuUserPopupListItem
                      onClick={() => {
                        setProfilePopupActive(false);
                      }}
                    >
                      <IconUserProfile size={20} />
                      <I18n selector="nav.profile_yourAccount" fallback="Your account" />
                    </MainMenuUserPopupListItem>
                  </CLink>
                </div>
                <div className="main-user-popup">
                  <CLink path="/profile/information" params={{ projectId }}>
                    <MainMenuUserPopupListItem
                      onClick={() => {
                        setProfilePopupActive(false);
                      }}
                    >
                      <IconSettingsDetails size={20} />
                      <I18n selector="nav.profile_profileDetails" fallback="Profile details" />
                    </MainMenuUserPopupListItem>
                  </CLink>
                </div>
                <div className="main-user-popup">
                  <CLink path="/profile/user-access-token" params={{ projectId }}>
                    <MainMenuUserPopupListItem
                      onClick={() => {
                        setProfilePopupActive(false);
                      }}
                    >
                      <IconPersonalKeyToken size={20} />
                      <I18n selector="nav.profile_personalTokens" fallback="Personal tokens" />
                    </MainMenuUserPopupListItem>
                  </CLink>
                </div>
                <div className="main-user-popup">
                  <CLink path="/profile/settings" params={{ projectId }}>
                    <MainMenuUserPopupListItem
                      onClick={() => {
                        setProfilePopupActive(false);
                      }}
                    >
                      <IconGearSettings size={20} />
                      <I18n selector="profile.common_settings" fallback="Settings" />
                    </MainMenuUserPopupListItem>
                  </CLink>
                </div>

                <div className="main-user-popup">
                  <MainMenuUserPopupListItem
                    onClick={() => {
                      setProfilePopupActive(false);
                      logout();
                    }}
                  >
                    <IconLogout size={20} />
                    <I18n selector="nav.profile_logOut" fallback="Log out" />
                  </MainMenuUserPopupListItem>
                </div>
              </MainMenuUserPopup>
            </ClickOutside>
          )}
        </Popover>
      </SMainNavigationFooterNav>
    </>
  );
};

export default MainMenuFooterNav;
