import produce from "immer";
import { EFileStatus, IHandleDrop, IHandleFile, IImageMetadata, IUpload, IUploadSlice, IUploadState } from "./types";
import { Upload as TusUpload } from "tus-js-client";
import { getAuthToken } from "../../utils/client";
import { dataURLtoFile, downloadCORS, steppedResize } from "../../services/upload/utils/createThumbnailFromUrl";
import * as Sentry from "@sentry/browser";
import { useStore } from "..";
import { message } from "@caisy/league";
import { I18n } from "../../provider/i18n";
import { debounce } from "lodash";

const baseUpload = process.env.ASSET_UPLOAD_URL || process.env.CORE_URL;
const baseServe = process.env.ASSET_SERVE_URL || process.env.CORE_URL;

const IGNORED_META_MODES = ["avatar", "blueprint", "reupload"];

const debounceMessage = debounce((errorMessage) => {
  message.error(errorMessage);
}, 50);

export const createUploadSlice: IUploadSlice = (set, get) => {
  const _handleFile = async (
    { file, meta = {}, extraHeaders, onAfterResponse, onProgress, onError, onSuccess }: IHandleFile,
    cb: () => void,
  ) => {
    if (!file && !file.size) {
      return;
    }
    const maxFileSize = useStore.getState().projectQuota.plan.maxAssetUploadTraffic;
    const fileSize = file.size / (1024 * 1024);

    if (fileSize > maxFileSize && maxFileSize !== 0) {
      debounceMessage(
        <I18n
          params={{ maxUploadFileSize: maxFileSize }}
          selector="nav.upload_maxSizeError"
          fallback="The upload failed because the file is to larger (max {{maxUploadFileSize}}) for your current plan."
        />,
      );
      return;
    }

    const id = get().upload.uploadCount + 1;
    get().upload.setUploadCount(id);

    const headers = {
      "x-caisy-token": await getAuthToken(),
      "x-caisy-upload-mode": meta.mode || "document",
      ...extraHeaders,
    };

    const uploadFile = async (file, metadata = {} as IImageMetadata, dataURL = null) => {
      if (!file || !file.name) {
        onError && onError("Missing file or file has no name");
        cb();
        return;
      }
      const upload = new TusUpload(file, {
        headers,
        // httpStack: (CustomXHRHttpStack as unknown as tus.HttpStack),
        onAfterResponse: onAfterResponse,
        onProgress: function (bytesUploaded, bytesTotal) {
          if (!IGNORED_META_MODES.includes(meta?.mode)) {
            const uploadIndex = get().upload.uploads.findIndex(({ ID }) => ID === upload.ID);
            const uploadIndexInCurrentUploads = get().upload.currentUploads.findIndex(({ ID }) => ID === upload.ID);
            const newUploads = get().upload.uploads.slice();
            const newCurrentUploads = get().upload.currentUploads.slice();
            const progress = bytesUploaded && bytesTotal ? (bytesUploaded / bytesTotal) * 100 : 0;
            newUploads[uploadIndex].progress = progress;
            newCurrentUploads[uploadIndexInCurrentUploads].progress = progress;

            set(
              produce<IUploadState>((state) => {
                state.upload.uploads = newUploads;
                state.upload.currentUploads = newCurrentUploads;
              }),
              false,
              "useUpload/_handleFile/uploadFile/onSuccess",
            );
          }
          onProgress && onProgress(bytesUploaded, bytesTotal);
        },
        // uploadUrl: base + "/upload/",
        endpoint: baseUpload + "/upload/",
        onError: function (error) {
          console.error(error);
          if (!IGNORED_META_MODES.includes(meta?.mode)) {
            const uploadIndex = get().upload.uploads.findIndex(({ ID }) => ID === upload.ID);
            const newUploads = get().upload.uploads.slice();
            newUploads[uploadIndex].status = EFileStatus.ERROR;

            set(
              produce<IUploadState>((state) => {
                state.upload.uploads = newUploads;
              }),
              false,
              "useUpload/_handleFile/uploadFile/onSuccess",
            );
          }
          onError && onError(error);
          cb();
        },
        metadata: {
          filename: file.name.replace(".icloud", "").replace(".drive", ""),
          filetype: file.type,
          ...metadata,
          mode: meta.mode || "default",
          ...meta,
        },
        parallelUploads: 1,
        chunkSize: 3 * Math.pow(10, 6),
        onSuccess: function () {
          if (!IGNORED_META_MODES.includes(meta?.mode)) {
            const uploadIndex = get().upload.uploads.findIndex(({ url }) => upload.url === url);
            const newUploads = get().upload.uploads.slice();
            newUploads[uploadIndex].status = EFileStatus.SUCCESS;
            newUploads[uploadIndex].url = newUploads[uploadIndex].url = upload.url
              .replace(baseUpload, baseServe)
              .replace("/upload/", "/assets/");

            set(
              produce<IUploadState>((state) => {
                state.upload.uploads = newUploads;
                state.upload.successfulUploads.unshift(upload);
              }),
              false,
              "useUpload/_handleFile/uploadFile/onSuccess",
            );
          }
          onSuccess && onSuccess(upload);
          cb();
        },
      }) as IUpload;
      upload.progress = 0;
      upload.name = file.name;
      upload.size = file.size;
      upload.status = EFileStatus.UPLOADING;
      if (dataURL) {
        upload.dataURL = dataURL;
      }
      // Start the upload by default
      upload.start();
      get().upload.setUploadCount(get().upload.uploadCount + 1);

      upload.ID = id;
      if (!IGNORED_META_MODES.includes(meta?.mode)) {
        set(
          produce<IUploadState>((state) => {
            state.upload.currentUploads.push(upload);
            state.upload.uploads.unshift(upload);
          }),
          false,
          "useUpload/_handleFile/uploadFile/startUpload",
        );
      }
      cb();
    };

    if (file?.type && file.type.includes("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        steppedResize(reader.result, 100, 0.5, (dataURL, metadata) => {
          uploadFile(file, metadata, dataURL);
        });
      };
      reader.readAsDataURL(file);
    } else {
      uploadFile(file);
    }

    return id;
  };

  const _handleDrop = async ({ ...props }: IHandleDrop, cb: () => void) => {
    let id = 0;
    const e = props.event;
    const fileUrl = e.dataTransfer.getData("url");
    if (fileUrl) {
      try {
        const u = new URL("/corsproxy", baseUpload);
        u.searchParams.append("u", fileUrl);
        let name = `${fileUrl}`;

        if (name.lastIndexOf("/") != -1) {
          name = name.substr(name.lastIndexOf("/") + 1);
        }

        if (name.lastIndexOf("?") != -1) {
          name = name.substr(0, name.lastIndexOf("?"));
        }
        id = await new Promise((resolve) => {
          downloadCORS(u.toString(), async (data) => {
            const file = dataURLtoFile(data, name);
            resolve(await _handleFile({ ...props, file }, cb));
          });
        });
      } catch (err) {
        Sentry.setContext("baseUpload", {
          url: baseUpload,
        });
        console.error(` err in handleDrop`, err);
        console.error(` baseUpload`, baseUpload);
        throw err;
      }
    }

    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === "file") {
          const file = e.dataTransfer.items[i].getAsFile();
          id = await _handleFile({ ...props, file }, cb);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        id = await _handleFile({ ...props, file: e.dataTransfer.files[i] }, cb);
      }
    }

    return id;
  };

  const uploadSlice: IUploadState = {
    upload: {
      currentDroppingFilesCount: 0,
      setCurrentDroppingFilesCount: (newCount) => {
        set(
          produce<IUploadState>((state) => {
            state.upload.currentDroppingFilesCount = newCount;
          }),
          false,
          "useUpload/setCurrentDroppingFilesCount",
        );
      },
      uploads: [],
      successfulUploads: [],
      setUploads: (newUploads) => {
        set(
          produce<IUploadState>((state) => {
            state.upload.uploads = newUploads;
          }),
          false,
          "useUpload/setUploads",
        );
      },
      uploadCount: 0,
      setUploadCount: (newUploadCount) => {
        set(
          produce<IUploadState>((state) => {
            state.upload.uploadCount = newUploadCount;
          }),
          false,
          "useUpload/setUploadCount",
        );
      },
      handleFile: async (props, cb) => {
        const wrappedCb = () => {
          cb && cb();
        };
        const res = await _handleFile({ ...props }, wrappedCb);
        return res;
      },

      handleDrop: async (props, cb) => {
        const wrappedCb = () => {
          cb && cb();
        };
        const res = await _handleDrop({ ...props }, wrappedCb);
        return res;
      },
      clearSuccessfulUploads: () => {
        set(
          produce<IUploadState>((state) => {
            state.upload.successfulUploads = [];
          }),
          false,
          "useUpload/clearSuccessfulUploads",
        );
      },
      currentUploads: [],
      setCurrentUploads: (newUploads) => {
        set(
          produce<IUploadState>((state) => {
            state.upload.currentUploads = newUploads;
          }),
          false,
          "useUpload/setCurrentUploads",
        );
      },
    },
  };

  return uploadSlice;
};
