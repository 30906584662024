import gql from "graphql-tag";

export const validateDocumentField = gql`
  mutation ValidateDocumentField($input: ValidateDocumentFieldRequestInput) {
    ValidateDocumentField(input: $input) {
      ...ValidateDocumentFieldResponse
    }
  }
  fragment ValidateDocumentFieldResponse on ValidateDocumentFieldResponse {
    validation {
      ...Validation
    }
  }
  fragment Validation on Validation {
    errors {
      ...ValidationError
    }
    valid
  }
  fragment ValidationError on ValidationError {
    blueprintFieldId
    errorCodes
    errorDetails
    documentFieldLocaleId
  }
`;
