import gql from "graphql-tag";

export const query_GetWebhookCall = gql`
  query GetWebhookCall($input: GetWebhookCallRequestInput) {
    GetWebhookCall(input: $input) {
      webhookCall {
        createdAt
        eventId
        requestBody
        requestHeaders {
          name
          value
        }
        responseBody
        responseHeaders {
          value
          name
        }
        responseStatusCode
        webhookCallId
        webhookId
      }
    }
  }
`;
