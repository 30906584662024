import styled from 'styled-components';

export const SUserListItem = styled.div`
    width: 100%;
    background-color: var(--ui-01);
    border-bottom: 1px solid var(--ui-03);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    >svg{
    color: var(--icon-08);
    height: 1rem;
    width: 1rem;
    }
    &: hover {
    background-color: var(--hover-ui-01);
    }
    &: active {
    background-color: var(--$active-ui-01-1);
    }

    img {
        height: 32px;
        width: 32px;
        object-fit: cover;
    }
`;
