import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

const Bronze = css`
  td,
  th {
    text-align: left;
  }
  tr > td {
    padding: 4px 0;
  }
  td:first-child {
    font-weight: 300;
    opacity: 0.9;
    padding-right: 1rem;
  }
  td:not(:first-child) {
    font-weight: 500;
  }

  tr:first-child > td {
    padding-top: 0;
  }
  tr:last-child > td {
    padding-bottom: 0;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationSelectMenuToolTipWrapper = styled.div<any>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
