import {
  IDocumentFieldFilterInput,
  IDocumentField as IDocumentFieldGen,
  IDocumentMetaFilterInput,
  IDocument as IGenDocument,
  IDocumentSortInput,
  IGetManyDocumentsRequestInput,
  IValidationError,
  IGetUnpublishedLinkedDocumentsRequestInput,
  IGetAllDocumentSnapshotRequestInput,
  IDocumentSnapshotListFragment,
  IGetDocumentSnapshotRequestInput,
  IDocumentWithFieldsResponse,
  IGetDocumentReferencesRequestInput,
  IUpdateDocumentRequestInput,
} from "../../interfaces/generated";
export interface IManyDocumentsState {
  document: IUseDocument;
}

/*
 at IDocumentLoadSource here is that indirect
 comes from searches on for example the overview and
 direct usage comes when a document is opened
 and loaded on the detail page. This information is useful
 when garbage collecting old documents, while rerunning the search
*/

export enum IDocumentUsage {
  direct = 0,
  indirect = 1,
}

export type IDocument = IGenDocument & {
  usage: IDocumentUsage;
};
export type IDocumentField = IDocumentFieldGen & {
  usage: IDocumentUsage;
  name?: string;
};

export type IDocumentMetaFilterInputExtended = IDocumentMetaFilterInput & { tagFilter?: { tagId?: string } };
export type IDocumentSearchFilter = {
  blueprintBranch?: string;
  environmentId?: string;
  fieldFilter?: IDocumentFieldFilterInput;
  fulltextFilter?: string;
  metaFilter?: IDocumentMetaFilterInputExtended;
  projectId?: string;
  sort?: IDocumentSortInput[];
};

export interface IDocumentFieldState {
  data?: any;
  error?: string;
  isLoadingFields?: boolean;
}

export interface IUseDocument {
  // we only store documents here and use reference ids everywhere else
  documents: { [documentId: string]: IDocument };
  // we only store documents fields here and use reference ids everywhere else
  // here the id is blueprintFieldId + documentId + documentFieldLocaleId
  documentFields: { [id: string]: IDocumentFieldState };
  // keeps the reference to last document loaded by id (see loadDocumentById)
  // keeps the list of unpublished linked documents in relation to current document
  unpublishedDocuments: { [parentDocumentId: string]: IGenDocument[] };
  isLoadingLinked: boolean;
  currentPageRootDocumentId: string | null;
  currentSearchResultIds: string[];
  currentSearchTotalCount: number;
  currentSearchFilter: IDocumentSearchFilter;
  initialLoading: boolean;
  isNextPageLoading: boolean;
  hasNextPage: boolean;
  endCursor: string;
  isUpdating: boolean;
  loadedPages: number;
  error: any;
  documentValidities: { [documentId: string]: boolean };
  googleMapsScriptLoaded: boolean;
  documentReferences: { [documentId: string]: { [referencedDocumentId: string]: IDocumentWithFieldsResponse } };
  updatingDocumentField: boolean;
  setCurrentPageRootDocumentId: (currentPageRootDocumentId: string | null) => void;
  getManyDocuments: (
    variables: IGetManyDocumentsRequestInput,
    options?: { withFields?: boolean; pageSize?: number },
  ) => Promise<void>;
  loadDocumentById: (variables: {
    projectId: string;
    documentId: string;
    fromLinkedDocument?: boolean;
  }) => Promise<IDocument>;
  loadManyDocumentByIds: (
    projectId: string,
    documentIds: string[],
    fromLinkedDocument?: boolean,
  ) => Promise<IDocument[]>;
  loadNextPage: (
    variables: IGetManyDocumentsRequestInput,
    options?: { withFields?: boolean; pageSize?: number },
  ) => Promise<void>;
  resetDocument: () => void;
  createDocument: (input: { projectId: string; blueprintId: string }) => Promise<IGenDocument | undefined>;
  updateFieldValue: ({
    projectId,
    input,
    documentFieldLocaleId,
    documentId,
  }: {
    projectId: string;
    input: IDocumentField;
    documentFieldLocaleId: string;
    documentId: string;
  }) => Promise<any>;
  getFieldValue: (input: IDocumentField, localeId: string, documentId: string) => any;
  getFieldState: (input: IDocumentField, localeId: string, documentId: string) => { data?: any; error?: any[] } | null;
  updateFieldValidation: (
    input: IDocumentField,
    documentFieldLocaleId: string,
    error: IValidationError[],
    documentId: string,
  ) => void;
  getFieldValueAllLocales: (input: IDocumentField, documentId: string) => any;
  getDocumentErrors: (documentId: string) => IValidationError[] | null;
  getDocumentFieldLoadingStatus: (documentId: string) => boolean | void;
  // setFieldValidation: (input: IDocumentField, localeId: string, error: string | null) => any;
  publishDocumentById: ({ projectId, documentId }: { projectId: string; documentId: string }) => Promise<void>;
  unpublishDocumentById: ({ projectId, documentId }: { projectId: string; documentId: string }) => Promise<void>;
  publishManyDocuments: ({ documentIds, projectId }: { documentIds: string[]; projectId }) => Promise<void>;
  unpublishManyDocuments: ({ documentIds, projectId }: { documentIds: string[]; projectId }) => Promise<void>;
  deleteDocumentById: ({
    documentId,
    projectId,
    isBulkOperation,
  }: {
    documentId: string;
    projectId: string;
    isBulkOperation?: boolean;
  }) => Promise<void>;
  deleteManyDocuments: ({ documentIds, projectId }: { documentIds: string[]; projectId: string }) => Promise<void>;
  duplicateDocumentById: ({
    documentId,
    blueprintId,
    projectId,
  }: {
    blueprintId: string;
    documentId: string;
    projectId: string;
  }) => Promise<IGenDocument>;
  duplicateManyDocuments: ({
    documents,
    projectId,
  }: {
    documents: { documentId: string; blueprintId: string }[];
    projectId: string;
  }) => Promise<void>;
  publishDocumentList: (documents: IGenDocument[]) => Promise<IGenDocument[]>;
  getUnpublishedLinkedDocuments: (input: IGetUnpublishedLinkedDocumentsRequestInput) => Promise<IGenDocument[]>;
  clearUnpublishedLinkedDocuments: (documentId: string) => void;
  addIdToSearchResults: (documentId: string, projectId: string) => Promise<void>;
  checkManyDocumentsValidity: ({
    documentIds,
    projectId,
  }: {
    documentIds: string[];
    projectId: string;
  }) => Promise<{ validity: boolean; documentId: string; documentTitle: string; errors: [IValidationError] }[]>;
  checkDocumentValidity: ({
    documentId,
    projectId,
  }: {
    documentId: string;
    projectId: string;
  }) => Promise<{ validity: boolean; documentId: string; documentTitle: string; errors: [IValidationError] }>;
  validateDocumentAndUpdateFieldsValidity: ({
    documentId,
    projectId,
  }: {
    documentId: string;
    projectId: string;
  }) => Promise<void>;
  updateLocalDocument: (document: IDocument) => void;
  documentSnapshots: IDocumentSnapshotListFragment[];
  getAllDocumentSnapshots: (input: IGetAllDocumentSnapshotRequestInput) => Promise<void>;
  getDocumentSnapshot: (input: IGetDocumentSnapshotRequestInput) => Promise<IDocumentWithFieldsResponse>;
  applyVersionChanges: ({
    fields,
    documentId,
    projectId,
  }: {
    fields: {
      [blueprintFieldId: string]: {
        [documentFieldLocaleId: string]: IDocumentField;
      };
    };
    documentId: string;
    projectId: string;
  }) => Promise<void>;

  isDocumentWithinSearchQuery: (input: {
    projectId: string;
    documentId: string;
    variant: "document" | "component" | "asset";
  }) => Promise<boolean>;

  setGoogleMapsScriptLoaded: (value: boolean) => void;
  openedInlineDocuments: { [name: string]: boolean };
  toggleOpenedInlineDocument: (input: { documentId: string; parentDocumentId: string }) => void;

  getDocumentReferences: (
    input: IGetDocumentReferencesRequestInput,
  ) => Promise<{ hasNextPage: boolean; endCursor: string; totalCount: number }>;

  richtextFieldEditors: { [field: string]: any };
  addRichtextFieldEditor: (input: { key: string; editor: any }) => void;
  applyPeerUpdateDocument: (document: IDocument) => void;
  applyPeerDocumentFieldUpdate: (update: any) => void;
  applyPeerFieldValidation: (update: any) => void;
  applyPeerDeleteDocument: (update: any) => void;
  applyPeerDuplicateDocument: (update: any) => void;
  updateDocumentTitle: (input: IUpdateDocumentRequestInput) => Promise<void>;
}
