import styled from "styled-components";

export const SSelectProjectStepList = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--ui-03);

  :last-of-type {
    border: none;
  }
`;
