import React from "react";

function IconGithub() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M3.6 3.933c-.933 1-1.6 2.4-1.6 4.2 0 1.334.467 2.6 1.2 3.6.867 1.134 2.133 1.8 2.8 2.067v-.467s0-1.066.6-2c-1.333-.066-2.4-1.333-2.4-2.8 0-.533.2-1 .533-1.4L4.8 5.867c0-.334.333-.534.667-.467l1.4.533c.4-.066.8-.133 1.266-.133.467 0 .867.067 1.267.133l1.2-.6c.333-.133.667.134.667.467l.066 1.267c.334.4.534.933.534 1.4 0 1.466-1.067 2.666-2.4 2.8.6.933.6 2 .6 2v.466c.666-.266 1.933-.933 2.8-2.066.733-.934 1.2-2.267 1.2-3.6 0-1.8-.667-3.2-1.6-4.2C11.2 2.6 9.467 2 8 2c-1.467 0-3.2.6-4.4 1.933v0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IconGithub;
