import PreviewDetailsNavComponent from "./PreviewDetailsNavComponent";
import WebhookCallNavComponent from "./WebhookCallNavComponent";
import WebhookEditNavComponent from "./WebhookEditNavComponent";
import WebhookCreateNavComponent from "./WebhookCreateNavComponent";

const pageSpecificComponent = {
  previewDetails: <PreviewDetailsNavComponent />,
  webhookEdit: <WebhookEditNavComponent />,
  webhookCall: <WebhookCallNavComponent />,
  webhookCreate: <WebhookCreateNavComponent />,
};

export default pageSpecificComponent;
