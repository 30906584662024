import { useRouter } from "next/router";
import { FC, useState } from "react";
import { I18n } from "../../../../../provider/i18n";
import { ManagementSidebarNavigation } from "../../sidebar-navigation/ManagementSidebarNavigation";
import {
  IconCreditCard,
  IconGearSettings,
  IconItemsGroup,
  IconProjectsFolder,
  IconUsersProfileGroup,
  Img,
  ListItem,
  NotificationsGroupSelectListItem,
} from "@caisy/league";
import { SComponentListItemContent } from "@caisy/league/src/components/component-list-item/styles/SComponentListItemContent";
import { useCurrentOrganizationId } from "../../../../../hooks/current-organization-id/useCurrentOrganizationId";
import { getPlaceholderLettersByName } from "../../../../organism/organization-menu/getPlaceholderLettersByName";
import { CLink } from "../../../../molecule/c-link/CLink";
import { useMemberShip } from "../../../../../stores/membership/useMembership";
import { PERMISSION, PERMISSION_ACTION } from "../../../../../services/security/constant";
import { Secured } from "../../../../../services/security";

interface IOrganizationManagement {}

export const OrganizationSidebar: FC<IOrganizationManagement> = () => {
  const [activated, setActivated] = useState(false);
  const organizationId = useCurrentOrganizationId();
  const { organizations } = useMemberShip();
  const router = useRouter();

  const organization = organizations[organizationId];

  return (
    <ManagementSidebarNavigation>
      <NotificationsGroupSelectListItem
        title={organization?.name}
        previewProps={{
          image: organization?.logoAssetUrl && <Img resolution={1440} src={organization?.logoAssetUrl} />,
          text: !organization?.logoAssetUrl && getPlaceholderLettersByName(organization?.name || ""),
        }}
        activated={activated}
        onClick={() => setActivated(!activated)}
        disableArrow={true}
      />
      <CLink path={`/organization/settings/general`} params={{ organizationId }}>
        <ListItem onClick={function noRefCheck() {}} activated={router.asPath?.includes("/settings/general")}>
          <IconGearSettings size={16} />
          <SComponentListItemContent>
            <I18n selector="organizationManagementNavigation.general" fallback="General" />
          </SComponentListItemContent>
        </ListItem>
      </CLink>
      <Secured action={PERMISSION_ACTION.READ} entity={PERMISSION.MEMBER}>
        <CLink path={`/organization/settings/members`} params={{ organizationId }}>
          <ListItem onClick={function noRefCheck() {}} activated={router.asPath?.includes("/settings/members")}>
            <IconUsersProfileGroup size={16} />
            <SComponentListItemContent>
              <I18n selector="organizationManagementNavigation.members" fallback="Members" />
            </SComponentListItemContent>
          </ListItem>
        </CLink>
      </Secured>
      <CLink path={`/organization/settings/groups`} params={{ organizationId }}>
        <ListItem onClick={function noRefCheck() {}} activated={router.asPath?.includes("/settings/groups")}>
          <IconItemsGroup size={16} />
          <SComponentListItemContent>
            <I18n selector="organizationManagementNavigation.groups" fallback="Groups" />
          </SComponentListItemContent>
        </ListItem>
      </CLink>
      <CLink path={`/organization/settings/projects`} params={{ organizationId }} shallow={true}>
        <ListItem onClick={function noRefCheck() {}} activated={router.asPath?.includes("/settings/projects")}>
          <IconProjectsFolder size={16} />
          <SComponentListItemContent>
            <I18n selector="organizationManagementNavigation.projects" fallback="Projects" />
          </SComponentListItemContent>
        </ListItem>
      </CLink>
      <Secured action={PERMISSION_ACTION.READ} entity={PERMISSION.BILLING}>
        <CLink path={`/organization/settings/billing`} params={{ organizationId }} shallow={true}>
          <ListItem onClick={function noRefCheck() {}} activated={router.asPath?.includes("/settings/billing")}>
            <IconCreditCard size={16} />
            <SComponentListItemContent>
              <I18n selector="organizationManagementNavigation.billing" fallback="Billing" />
            </SComponentListItemContent>
          </ListItem>
        </CLink>
      </Secured>
    </ManagementSidebarNavigation>
  );
};
