import { MenuListItem } from "@caisy/league";
import { FCWithChildren } from "../../../../interfaces/FCWithChildren";
import { SMainMenuUserPopupListItemContent } from "./styles/SMainMenuUserPopupListItemContent";
import { SMainMenuUserPopupListItemWrapper } from "./styles/SMainMenuUserPopupListItemWrapper";

interface IMainMenuUserPopupListItem {
  onClick?: () => void;
}

export const MainMenuUserPopupListItem: FCWithChildren<IMainMenuUserPopupListItem> = ({ children, onClick }) => {
  return (
    <SMainMenuUserPopupListItemWrapper onClick={onClick}>
      <MenuListItem>
        <SMainMenuUserPopupListItemContent>{children}</SMainMenuUserPopupListItemContent>
      </MenuListItem>
    </SMainMenuUserPopupListItemWrapper>
  );
};
