import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createAuthenticationSlice } from "./authentication/createAuthenticationSlice";
import { createBlueprintSlice } from "./blueprint/createBlueprintSlice";
// import { createDocumentSlice } from "./document/createDocumentSlice";
import { createEntrypointSlice } from "./entrypoint/createEntrypointSlice";
import { createGroupMembershipSlice } from "./membership/createGroupMembershipSlice";
import { createOrganizationMembershipSlice } from "./membership/createOrganizationMembershipSlice";
import { createProjectMembershipSlice } from "./membership/createProjectMembershipSlice";
import { createPermissionSlice } from "./permission/createPermissionSlice";
import { createPlaygroundSlice } from "./playground/createPlaygroundSlice";
import { createPreviewSlice } from "./preview/createPreviewSlice";
import { createTagSlice } from "./tag/createTagSlice";
import { createWebhookSlice } from "./webhook/createWebhookSlice";
import { createViewSlice } from "./view/createViewSlice";
import { createProfileSlice } from "./profile/createProfileSlice";
import { createDocumentLocaleSlice } from "./document-locale/createDocumentLocaleSlice";
import { createApiKeySlice } from "./apikey/createApiKeySlice";
import { createGroupMemberSlice } from "./membership/createGroupMemberSlice";
import { createOrganizationMemberSlice } from "./membership/createOrganizationMemberSlice";
import { createProjectMemberSlice } from "./membership/createProjectMemberSlice";
import { createSchedulingSlice } from "./scheduling/createSchedulingSlice";
import { createDocumentStatusSlice } from "./document-status/createDocumentStatusSlice";
import { createUserAccessTokenSlice } from "./user-access-token/createUserAccessTokenSlice";
import { createUploadSlice } from "./upload/createUploadSlice";
import { createDocumentNavigationSlice } from "./document-navigation/createDocumentNavigationSlice";
import { createAuditlogSlice } from "./auditlog/createAuditlogSlice";
import { createProjectQuotaSlice } from "./project-quota/createProjectQuotaSlice";
import { createProjectOnboardingSlice } from "./project-onbarding/createProjectOnboardingSlice";
import { createUserOnboardingSlice } from "./user-onboarding/createUserOnboardingSlice";
import { createSignUpSlice } from "./sign-up/createSignUpSlice";
import { createBillingSlice } from "./billing/createBillingSlice";
import { createOrganizationQuotaSlice } from "./organization-quota/createGroupQuotaSlice";
import { createGroupQuotaSlice } from "./group-quota/createGroupQuotaSlice";
// // To enable Immer to operate on the native Map and Set collections
// enableMapSet();

type StateFromFunctions<T extends [...any]> = T extends [infer F, ...infer R]
  ? F extends (...args: any) => object
    ? StateFromFunctions<R> & ReturnType<F>
    : unknown
  : unknown;

type State = StateFromFunctions<
  [
    typeof createAuthenticationSlice,
    typeof createPermissionSlice,
    typeof createBlueprintSlice,
    // typeof createDocumentSlice,
    typeof createEntrypointSlice,
    typeof createPlaygroundSlice,
    typeof createPreviewSlice,
    typeof createOrganizationMembershipSlice,
    typeof createGroupMembershipSlice,
    typeof createProjectMembershipSlice,
    typeof createTagSlice,
    typeof createWebhookSlice,
    typeof createViewSlice,
    typeof createProfileSlice,
    typeof createDocumentLocaleSlice,
    typeof createApiKeySlice,
    typeof createOrganizationMemberSlice,
    typeof createGroupMemberSlice,
    typeof createProjectMemberSlice,
    typeof createSchedulingSlice,
    typeof createDocumentStatusSlice,
    typeof createUserAccessTokenSlice,
    typeof createUploadSlice,
    typeof createDocumentNavigationSlice,
    typeof createAuditlogSlice,
    typeof createProjectQuotaSlice,
    typeof createGroupQuotaSlice,
    typeof createOrganizationQuotaSlice,
    typeof createProjectOnboardingSlice,
    typeof createUserOnboardingSlice,
    typeof createSignUpSlice,
    typeof createBillingSlice,
  ]
>;

export const useStore = create<State>()(
  devtools(
    (set, get) => ({
      ...createAuthenticationSlice(set, get),
      ...createPermissionSlice(set, get),
      ...createBlueprintSlice(set, get),
      // ...createDocumentSlice(set, get),
      ...createEntrypointSlice(set, get),
      ...createPlaygroundSlice(set, get),
      ...createPreviewSlice(set, get),
      ...createOrganizationMembershipSlice(set),
      ...createGroupMembershipSlice(set),
      ...createProjectMembershipSlice(set, get),
      ...createTagSlice(set, get),
      ...createWebhookSlice(set, get),
      ...createViewSlice(set, get),
      ...createProfileSlice(set, get),
      ...createDocumentLocaleSlice(set, get),
      ...createApiKeySlice(set),
      ...createOrganizationMemberSlice(set, get),
      ...createGroupMemberSlice(set, get),
      ...createProjectMemberSlice(set, get),
      ...createSchedulingSlice(set, get),
      ...createDocumentStatusSlice(),
      ...createUserAccessTokenSlice(set, get),
      ...createUploadSlice(set, get),
      ...createDocumentNavigationSlice(set, get),
      ...createAuditlogSlice(set, get),
      ...createProjectQuotaSlice(set),
      ...createGroupQuotaSlice(set),
      ...createOrganizationQuotaSlice(set),
      ...createProjectOnboardingSlice(set, get),
      ...createUserOnboardingSlice(set, get),
      ...createSignUpSlice(set, get),
      ...createBillingSlice(set, get),
    }),
    { name: "caisy", enabled: process.env.NODE_ENV !== "production" },
  ),
);
