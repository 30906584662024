import SLogoFull from "./Styles/SLogoFull";
import React from "react";
import { Img } from "@caisy/league";
import { useEntrypoint } from "../../../stores/entrypoint/useEntrypoint";

const LogoFull = () => {
  const { logoFull } = useEntrypoint();
  return (
    <SLogoFull>
      {logoFull && (
        <Img resolution={logoFull && logoFull.endsWith(".svg") ? 0 : 800} src={logoFull} contain lazyload={false} />
      )}
    </SLogoFull>
  );
};

export default LogoFull;
