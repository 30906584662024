import { IconCheckmarkCircle } from "@caisy/league";
import { FCWithChildren } from "../../../../../../../interfaces/FCWithChildren";
import { SPricingCardFeature } from "./styles/SPricingCardFeature";
import { SPricingCardFeatureCheckmarkWrapper } from "./styles/SPricingCardFeatureCheckmarkWrapper";

interface IPricingCardFeature {
  checkmarkType: 1 | 2 | 3;
}

export const PricingCardFeature: FCWithChildren<IPricingCardFeature> = ({ children, checkmarkType }) => {
  return (
    <SPricingCardFeature>
      <SPricingCardFeatureCheckmarkWrapper type={checkmarkType}>
        <IconCheckmarkCircle solid={checkmarkType === 3} size={16} />
      </SPricingCardFeatureCheckmarkWrapper>
      {children}
    </SPricingCardFeature>
  );
};
