import React from 'react';
import { SUserListItem } from './styles/SUserListItem';

export interface IAssetListItemProps {
    children?
}

export const UserListItem: React.FC<IAssetListItemProps> = ({ children }) => {
    return (
        <SUserListItem >
            {children}
        </SUserListItem>
    )
}
