import { Button, Flex } from "@caisy/league";
import { useMemberShip } from "../../../../stores/membership/useMembership";
import { InformationModal } from "../../information-modal/InformationModal";
import { SInformationModalTitle } from "../../information-modal/styles/SInformationModalTitle";
import { SInformationModalText } from "../../information-modal/styles/SInformationModalText";
import { I18n } from "../../../../provider/i18n";
import { SInformationModalButtons } from "../../information-modal/styles/SInformationModalButtons";

export const OrganizationLimitModal = () => {
  const { setShowOrganizationLimitModal, showOrganizationLimitModal } = useMemberShip();

  return (
    <InformationModal visible={showOrganizationLimitModal}>
      <Flex direction="column" gap="24px">
        <Flex direction="column" gap="12px">
          <SInformationModalTitle>
            <I18n
              selector="nav.organization_switcher_organizationCreationLimitReached"
              fallback="Organization Creation Limit Reached"
            />
          </SInformationModalTitle>
          <SInformationModalText>
            <I18n
              selector="nav.organization_switcher_organizationCreationLimitReachedDescription"
              fallback="You've reached the maximum limit of 3 organisations per day. You'll be able to create more after 24 hours. However, if you'd like to create a paid organisation immediately, please reach out to our support via chat."
            />
          </SInformationModalText>
        </Flex>
        <SInformationModalButtons>
          <Button onClick={() => setShowOrganizationLimitModal(null)} type="primary" style={{ width: "100%" }}>
            <I18n selector="nav.createProject.close" fallback="close" />
          </Button>
        </SInformationModalButtons>
      </Flex>
    </InformationModal>
  );
};
