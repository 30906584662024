import gql from "graphql-tag";

export const UpdateProject = gql`
  mutation UpdateProject($input: UpdateProjectRequestInput) {
    UpdateProject(input: $input) {
      ...UpdateProjectResponse
    }
  }
  fragment UpdateProjectResponse on UpdateProjectResponse {
    project {
      ...ProjectResponse
    }
  }
  fragment ProjectResponse on ProjectResponse {
    alias
    createdAt
    description
    groupId
    logoAssetUrl
    name
    organizationId
    projectId
    updatedAt
  }
`;
