import { FC } from "react";
import { SCreateProjectModalFirstStepItemContent } from "./styles/SCreateProjectModalFirstStepItemContent";
import { SCreateProjectModalFirstStepItemDescription } from "./styles/SCreateProjectModalFirstStepItemDescription";
import { SCreateProjectModalFirstStepItemIcon } from "./styles/SCreateProjectModalFirstStepItemIcon";
import { SCreateProjectModalFirstStepItemTitle } from "./styles/SCreateProjectModalFirstStepItemTitle";
import { SCreateProjectModalFirstStepItem } from "./styles/SCreateProjectModalFirstStepItem";

interface ICreateProjectModalFirstStepItem {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick: () => void;
  iconColor: string;
}

export const CreateProjectModalFirstStepItem: FC<ICreateProjectModalFirstStepItem> = ({
  title,
  description,
  icon,
  onClick,
  iconColor,
}) => {
  return (
    <SCreateProjectModalFirstStepItem onClick={onClick}>
      <SCreateProjectModalFirstStepItemIcon color={iconColor}>{icon}</SCreateProjectModalFirstStepItemIcon>
      <SCreateProjectModalFirstStepItemContent>
        <SCreateProjectModalFirstStepItemTitle>{title}</SCreateProjectModalFirstStepItemTitle>
        <SCreateProjectModalFirstStepItemDescription>{description}</SCreateProjectModalFirstStepItemDescription>
      </SCreateProjectModalFirstStepItemContent>
    </SCreateProjectModalFirstStepItem>
  );
};
