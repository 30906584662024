import { CSSProgressiveBody03Medium } from "@caisy/league";
import styled from "styled-components";

export const SMaintenanceWarningModalDescription = styled.p`
  ${CSSProgressiveBody03Medium};
  color: var(--text-04);
  text-align: center;

  strong {
    font-weight: bold;
  }
`;
