import { Button, Flex, Modal } from "@caisy/league";
import { FC } from "react";
import { SUpgradeProjectWarningModal } from "./styles/SUpgradeProjectWarningModal";
import { SUpgradeProjectWarningModalTitle } from "./styles/SUpgradeProjectWarningModalTitle";
import { SUpgradeProjectWarningModalDescription } from "./styles/SUpgradeProjectWarningModalDescription";
import { useRouter } from "next/router";
import { I18n } from "../../../../../../../provider/i18n";
import { useMemberShip } from "../../../../../../../stores/membership/useMembership";

interface IUpradeProjectWarningModal {
  visible: boolean;
  organizationId: string;
  projectId: string;
  onCancel: () => void;
}

export const UpgradeProjectWarningModal: FC<IUpradeProjectWarningModal> = ({
  visible,
  organizationId,
  projectId,
  onCancel,
}) => {
  const router = useRouter();
  const { setShowProjectLimitModal } = useMemberShip();

  const redirectToOrganization = () => {
    router.push(
      `/organization/settings/billing?organization_id=${organizationId}&fromProject=${projectId}`,
      undefined,
      { shallow: true },
    );
    setShowProjectLimitModal(null);
    onCancel();
  };

  return (
    <Modal visible={visible}>
      <SUpgradeProjectWarningModal>
        <Flex direction="column" gap="24px">
          <Flex direction="column" gap="12px">
            <SUpgradeProjectWarningModalTitle>
              <I18n
                selector="nav.createProject.upgradeWarningTitle"
                fallback="To upgrade project plan you need to add your first payment method"
              />
            </SUpgradeProjectWarningModalTitle>
            <SUpgradeProjectWarningModalDescription>
              <I18n
                selector="nav.createProject.upgradeWarningDescription"
                fallback="Add payment method to your organization"
              />
            </SUpgradeProjectWarningModalDescription>
          </Flex>
          <div style={{ width: 240, minWidth: "fit-content" }}>
            <Flex direction="column" gap="8px">
              <Button onClick={redirectToOrganization} style={{ width: "100%" }} type="primary">
                <I18n selector="nav.createProject.goToYourOrganization" fallback="go to your organizaiton" />
              </Button>
              <Button onClick={onCancel} style={{ width: "100%" }} type="tertiary">
                <I18n selector="nav.createProject.cancel" fallback="cancel" />
              </Button>
            </Flex>
          </div>
        </Flex>
      </SUpgradeProjectWarningModal>
    </Modal>
  );
};
