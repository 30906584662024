import gql from "graphql-tag";

export const createPreviewItem = gql`
  mutation CreatePreviewItem($input: CreatePreviewItemRequestInput) {
    __typename
    CreatePreviewItem(input: $input) {
      previewItem {
        blueprintId
        enableLivePreview
        previewItemId
        previewId
        previewUrl
      }
    }
  }
`;
