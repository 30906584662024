import { CSSProgressiveBody02Semibold } from "@caisy/league";
import styled, { css } from "styled-components";
import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "../../../../constants/styles/mediaquerys";

const Default = css<{ secondary?: boolean }>`
  ${CSSProgressiveBody02Semibold};
  color: ${({ secondary }) => (secondary ? "var(--text-04)" : "var(--text-01)")};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuHeaderTitle = styled.p`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
