import gql from "graphql-tag";

export const getUserOnboarding = gql`
  query GetUserOnboarding($input: GetUserOnboardingRequestInput) {
    GetUserOnboarding(input: $input) {
      onboarding {
        status
        completedEvents
      }
    }
  }
`;
