import styled from "styled-components";

export const SOnboardingTourCardProfilePic = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 32px;
    width: 32px;
    object-fit: cover;
  }
`;
