import { useEffect, useRef, useState } from "react";

const useControlKeyList = ({ items, onEnter }) => {
  const listRef = useRef<any>();
  const [selection, setSelection] = useState(null);
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (selection === null) {
        setSelection(0);
      } else {
        setSelection(Math.min(listRef?.current?.props?.itemCount - 1, selection + 1));
      }
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      if (selection === null) {
        setSelection(0);
      } else {
        setSelection(Math.max(0, selection - 1));
      }
    }

    if (e.key === "Enter") {
      e.preventDefault();
      if (selection !== null) {
        onEnter?.(items[selection]);
      }
    }
  };

  const handleBlur = () => {
    setSelection(null);
  };

  useEffect(() => {
    const list = listRef?.current?._listRef;
    if (!list) return;
    if (selection === null) return;
    list.scrollToItem(selection);
  }, [selection]);

  useEffect(() => {
    if (items?.length === 1) setSelection(0);
  }, [items]);

  return { listRef, selection, handleBlur, handleKeyDown };
};

export { useControlKeyList };
