import gql from "graphql-tag";

export const deleteRelease = gql`
  mutation DeleteManyReleases($input: DeleteManyReleasesRequestInput) {
    __typename
    DeleteManyReleases(input: $input) {
      deleted
    }
  }
`;
