import styled from "styled-components";

export const SOnboardingAssetFieldImageWrapper = styled.div`
  height: 112px;
  width: 112px;
  box-shadow: var(--box-shadow-snackbar);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: var(--ui-04);
  overflow: hidden;

  img {
    height: 112px;
    width: 112px;
    object-fit: cover;
  }
`;
