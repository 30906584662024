import styled, { css } from "styled-components";

const CSSHover = css`
  background-color: var(--hover-ui-01);
`;

export const SSelectOrganizationStepListItem = styled.div`
  height: 80px;
  padding-right: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--ui-03);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 150ms;
  :hover,
  :active {
    ${CSSHover}
  }
`;
