import styled from "styled-components";

export const SOnboardingTemplatesModalContent = styled.div`
  padding: 40px 120px 0;
  margin: auto;
  height: calc(100vh - 80px - 65px - 40px);
  overflow: auto;
  display: flex;
  gap: 80px;
`;
