import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Observable } from "lib0/observable";
import * as awarenessProtocol from "y-protocols/awareness";
import { useCurrentProjectId } from "../../hooks/current-project-id/useCurrentProjectId";
import { useAuthentication } from "../../stores/authentication/useAuthentication";
import { getAuthToken } from "../../utils/client";
import { FCWithChildren } from "../../interfaces/FCWithChildren";
import { ICollaborationLocation, ICollaborationState } from "./types";
import { base64ToUint8Array, encodeFieldMessage } from "./encoding";
import { getDocumentKey, getFieldKey } from "./keys";
import { CaisyYjsProvider } from "./CaisyYjsProvider";
import {
  PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON,
  PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON,
  PEER_MESSAGE_TYPE_MESSAGE_SYNC_UPDATE,
  PEER_MESSAGE_TYPE_FIELD_UNSUBSCRIBE_ON,
  PEER_MESSAGE_TYPE_FIELD_INACTIVE_ON,
  PEER_MESSAGE_TYPE_AWARENESS,
  PUBSUB_KEY_CONNECTION,
  PUBSUB_KEY_DOCUMENT_ACTIVE_CHANGE,
  PUBSUB_KEY_ACTIVE_URL_CHANGE,
  INCOMING_SOCKET_MESSAGE_TYPE,
  OUTGOING_SOCKET_MESSAGE_TYPE,
} from "./constants";
import { broadcastToAllPeers, clientIsEditorRole } from "./helper";
import { handlePeerChange } from "./handlePeerChange";
import { onlyUnique } from "../../utils/onlyUnique";
import { Mutex } from "async-mutex";
import { onLocalBroadcastMessage } from "./onLocalBroadcastMessage";

const getSocket = ({ projectId, token, onMessage }) => {
  const baseUrl = `${process.env.CORE_URL}`.replace("http", "ws") + `/api/i/v1/collaboration`;
  const socketUrl = `${baseUrl}/ws?token=${token}&project_id=${projectId}`;

  const socket = new WebSocket(socketUrl);

  socket.onmessage = onMessage;

  socket.onopen = () => {
    console.log("WebSocket connection opened!");
  };

  socket.onerror = (error) => {
    console.error(`WebSocket error: `, error);
  };

  return { socket };
};

export const useCollaboration = () => {
  return useContext(CollaborationProviderContext);
};

export const hexDump = (buf) => buf.map((byte) => byte.toString(16).padStart(2, "0")).join(" ");

export const CollaborationProviderContext = createContext<ICollaborationProvider>({} as any);

interface ICollaborationProvider {
  addActiveField: ({ blueprintFieldId, documentId }: { blueprintFieldId: string; documentId: string }) => void;
  removeActiveField: ({ blueprintFieldId, documentId }: { blueprintFieldId: string; documentId: string }) => void;
  listenOnFieldYDoc({ projectId, documentId, blueprintFieldId, documentFieldLocaleId, ydoc, provider }): {
    destroy: () => void;
  };
  useConnection(): ICollaborationState["connection"];
  useActiveFieldCollaborator({
    documentId,
    blueprintFieldId,
  }: {
    documentId: string;
    blueprintFieldId: string;
  }): string[];
  useActiveProjectCollaborator(): string[];
  useOwnClientId(): string;
  useActiveDocumentCollaborator({ documentId }: { documentId: string }): string[];
  useCollaboratorLocation(): { [clientId: string]: ICollaborationLocation };
}

export const CollaborationProvider: FCWithChildren = ({ children }) => {
  const projectId = useCurrentProjectId();
  const { userId } = useAuthentication();

  const unreactiveLocalStateRef = useRef<ICollaborationState>({
    peers: [],
    localBroadcastChannel: null,
    clientIds: [],
    ownListeningFields: [],
    fieldCollaborator: {},
    documentCollaborator: {},
    peerLocations: {},
    projectCollaborator: [],
    fieldListener: {},
    socket: null,
    pubsub: new Observable(),
    mutex: new Mutex(),
    lastOwnActiveFieldMessage: null,
    connection: {
      wsAssignment: false,
      wsChange: false,
      p2p: false,
      isAlone: true,
    },
  });

  const state = unreactiveLocalStateRef.current;

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!window.c) {
      window.c = {};
    }
    window.c["collaboration"] = state;
  }, []);

  const addActiveFieldCollaborator = ({ blueprintFieldId, documentId, clientId }) => {
    const fieldKey = getFieldKey({ type: PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON, blueprintFieldId, documentId });

    Object.keys(state.fieldCollaborator).forEach((key) => {
      if (fieldKey != key && state.fieldCollaborator[key].includes(clientId)) {
        state.fieldCollaborator[key] = state.fieldCollaborator[key].filter((c) => c !== clientId);
        state.pubsub.emit(key, [state.fieldCollaborator[key]]);
      }
    });

    if (state.fieldCollaborator[fieldKey]) {
      state.fieldCollaborator[fieldKey] = [
        ...state.fieldCollaborator[fieldKey].filter((c) => c != clientId).filter(onlyUnique),
        clientId,
      ];
    } else {
      state.fieldCollaborator[fieldKey] = [clientId];
    }
    state.pubsub.emit(fieldKey, [state.fieldCollaborator[fieldKey]]);
  };

  const removeActiveFieldCollaborator = ({ blueprintFieldId, documentId, clientId }) => {
    const fieldKey = getFieldKey({ type: PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON, blueprintFieldId, documentId });
    if (state.fieldCollaborator[fieldKey]) {
      state.fieldCollaborator[fieldKey] = state.fieldCollaborator[fieldKey].filter((u) => u !== clientId);
      if (state.fieldCollaborator[fieldKey].length === 0) {
        delete state.fieldCollaborator[fieldKey];
      }
    }
    state.pubsub.emit(fieldKey, [state.fieldCollaborator[fieldKey]]);
  };

  const onMessage = (raw) => {
    if (!raw.data) return;
    if (raw.data == "") return;
    const payload = JSON.parse(raw.data);

    switch (payload.t) {
      case INCOMING_SOCKET_MESSAGE_TYPE.CHANGE:
        const clientIdsBefore = state.clientIds.slice();
        state.clientIds = payload.clientIds;

        state.connection.wsChange = true;
        // filter out preview users
        state.connection.isAlone = state.clientIds.filter((c) => clientIsEditorRole(c)).length === 1;
        handlePeerChange(clientIdsBefore, state);
        state.pubsub.emit(PUBSUB_KEY_CONNECTION, [state.connection]);
        break;
      case INCOMING_SOCKET_MESSAGE_TYPE.ASSIGNMENT:
        state.connection.wsAssignment = true;
        // filter out preview users
        state.connection.isAlone = state.clientIds.filter((c) => clientIsEditorRole(c)).length === 1;
        state.pubsub.emit(PUBSUB_KEY_CONNECTION, [state.connection]);

        state.ownClientId = payload.clientId;
        break;
      case INCOMING_SOCKET_MESSAGE_TYPE.SIGNAL:
        {
          const peer = state.peers.find((peer) => peer.clientId === payload.from);
          if (!peer) return;

          const signalObject = JSON.parse(atob(payload.data));
          peer.signal(signalObject);
        }
        break;
      case INCOMING_SOCKET_MESSAGE_TYPE.PEER_MESSAGE:
        {
          const peer = state.peers.find((peer) => peer.clientId === payload.from);
          if (!peer) return;
          if (!payload.data) {
            console.error("no payload data", raw.data, payload);
            return;
          }
          peer.handleSocketMessage(base64ToUint8Array(payload.data));
        }
        break;
      default:
        console.error("unknown ws message type", payload.t);
    }
  };

  useEffect(() => {
    const onFreeze = () => {
      console.log(` onFreeze`);
      if (state.socket !== null) {
        state.socket.onclose = () => {
          console.log("WebSocket is closed");
        };
        state.socket.close();
        state.socket = null;
      }
    };
    const onResume = () => {
      console.log(` onResume getting new token`);
      getAuthToken().then((token) => {
        // on init server will auto join the project provided in the query params
        const { socket } = getSocket({ token, projectId, onMessage });
        state.socket = socket;
      });
    };
    document.addEventListener("freeze", onFreeze);

    document.addEventListener("resume", onResume);

    return () => {
      window.removeEventListener("freeze", onFreeze);
      window.removeEventListener("resume", onResume);
    };
  }, []);

  useEffect(() => {
    if (!userId || !projectId) return;
    if (state.socket) return;

    getAuthToken().then((token) => {
      // on init server will auto join the project provided in the query params
      const { socket } = getSocket({ token, projectId, onMessage });
      state.socket = socket;
    });
  }, [userId, projectId]);

  useEffect(() => {
    const listener = () => {
      if (state.socket !== null) {
        state.socket.onclose = () => {
          console.log("WebSocket is closed");
        };
        state.socket.close();
        state.socket = null;
      }
    };

    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener);
      if (state.socket !== null) {
        state.socket.onclose = () => {
          console.log("WebSocket is closed");
        };
        state.socket.close();
        state.socket = null;
      }
    };
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const collaboration_debug = localStorage.getItem("collaboration_debug");
    if (`${collaboration_debug}` == "true") {
      window.c.debug = true;
    }
  }, [projectId]);

  useEffect(() => {
    if (!projectId) return;

    state.localBroadcastChannel = new BroadcastChannel(`collaboration-${projectId}`);
    const listener = (event) => {
      onLocalBroadcastMessage(state, event.data);
    };

    state.localBroadcastChannel.addEventListener("message", listener);

    return () => {
      state.localBroadcastChannel.removeEventListener("message", listener);
      state.localBroadcastChannel.close();
    };
  }, [projectId]);

  useEffect(() => {
    if (!projectId) return;
    if (!state.socket) return;

    state.mutex.runExclusive(() => {
      state.peers = [];
      state.clientIds = [];
      state.ownListeningFields = [];
      state.fieldCollaborator = {};
      state.documentCollaborator = {};
      state.fieldListener = {};
      state.lastOwnActiveFieldMessage = null;
      state.connection = {
        wsAssignment: true,
        wsChange: false,
        p2p: false,
        isAlone: true,
      };

      if (state.socket.readyState === WebSocket.OPEN) {
        state.socket?.send(JSON.stringify({ t: OUTGOING_SOCKET_MESSAGE_TYPE.SWITCH, projectId }));
      }
    });
  }, [projectId]);

  const broadcastToAllCurrentEditorPeers = (msg) => {
    return broadcastToAllPeers(
      state.peers.filter((p) => clientIsEditorRole(p.clientId)),
      msg,
    );
  };

  const listenOnFieldYDoc = ({
    documentId,
    blueprintFieldId,
    documentFieldLocaleId,
    ydoc,
    provider,
  }: {
    documentId: string;
    blueprintFieldId: string;
    documentFieldLocaleId: string;
    ydoc: any;
    provider: CaisyYjsProvider;
  }) => {
    const { isAlone, wsAssignment, wsChange } = state.connection;
    console.log(` isAlone, wsAssignment, wsChange`, isAlone, wsAssignment, wsChange);
    if (isAlone && wsAssignment && wsChange) {
      provider.setLeaderClientId(state.ownClientId, true);
      console.log(` alone, we are leader, emit syuncted`);
      provider.setSynced(true);
      provider.emit("synced", [{ synced: true }]);
    }

    // in case you need to orgin for debug it should be either peer, local or timeout
    // provider.awareness.on("change", ({ added, updated, removed }, origin) => {
    provider.awareness.on("change", ({ added, updated, removed }) => {
      const ownListeningField = state.ownListeningFields.find(
        (f) =>
          f.documentId === documentId &&
          f.documentFieldLocaleId === documentFieldLocaleId &&
          f.blueprintFieldId === blueprintFieldId,
      );

      if (!ownListeningField) return;

      const changedClients = added.concat(updated).concat(removed);

      Object.keys(ownListeningField.peersSynced).forEach((clientId) => {
        if (ownListeningField.peersSynced[clientId]) {
          const p = state.peers.find((peer) => peer.clientId === clientId);
          if (p) {
            // console.log(
            //   `awareness update origin=${origin} state=${JSON.stringify(provider.awareness.getLocalState())}`,
            //   changedClients,
            // );
            const xBinary = encodeFieldMessage({
              ...ownListeningField,
              messageType: PEER_MESSAGE_TYPE_AWARENESS,
              body: awarenessProtocol.encodeAwarenessUpdate(provider.awareness, changedClients),
            });
            p.send(xBinary);
          }
        }
      });
    });

    ydoc.on("update", (update, origin) => {
      if (origin && origin.key && origin.key.startsWith("peer")) {
        return;
      }
      const ownListeningField = state.ownListeningFields.find(
        (f) =>
          f.documentId === documentId &&
          f.documentFieldLocaleId === documentFieldLocaleId &&
          f.blueprintFieldId === blueprintFieldId,
      );
      if (!ownListeningField) {
        return;
      }
      // get all peers that are listening on this field
      // if the peer is synced, send the update
      if (!ownListeningField?.provider?.doc) {
        return;
      }

      if (!ownListeningField?.provider?.synced) {
        console.log(`! ownListeningField?.provider?.synced skipping update`);
        return;
      }

      // console.log(` update ydoc synced=${ownListeningField?.provider.synced}`, update);
      if (!ownListeningField.peersSynced) {
        ownListeningField.peersSynced = {};
      }

      if (ownListeningField.peersSynced) {
        Object.keys(ownListeningField.peersSynced).forEach((clientId) => {
          if (ownListeningField.peersSynced[clientId]) {
            const peer = state.peers.find((peer) => peer.clientId === clientId);
            if (peer) {
              const xBinary = encodeFieldMessage({
                ...ownListeningField,
                messageType: PEER_MESSAGE_TYPE_MESSAGE_SYNC_UPDATE,
                syncChecksum: ownListeningField.peersSynced[clientId] ? "2" : "1",
                body: update,
              });
              peer.send(xBinary);
            }
          }
        });
      }
    });

    broadcastToAllCurrentEditorPeers(
      encodeFieldMessage({
        messageType: PEER_MESSAGE_TYPE_FIELD_SUBSCRIBE_ON,
        documentId,
        blueprintFieldId,
        documentFieldLocaleId,
      }),
    );

    state.ownListeningFields = [
      ...state.ownListeningFields,
      { documentId, blueprintFieldId, documentFieldLocaleId, ydoc, provider, peersSynced: {}, peersDismissed: {} },
    ];

    return {
      destroy: () => {
        broadcastToAllCurrentEditorPeers(
          encodeFieldMessage({
            messageType: PEER_MESSAGE_TYPE_FIELD_UNSUBSCRIBE_ON,
            documentId,
            blueprintFieldId,
            documentFieldLocaleId,
          }),
        );

        state.ownListeningFields = state.ownListeningFields.filter((f) => {
          return (
            f.documentId !== documentId &&
            f.blueprintFieldId !== blueprintFieldId &&
            f.documentFieldLocaleId !== documentFieldLocaleId
          );
        });
      },
    };
  };

  const addActiveField = ({ documentId, blueprintFieldId }: { documentId: string; blueprintFieldId: string }) => {
    addActiveFieldCollaborator({ clientId: state.ownClientId, documentId, blueprintFieldId });
    state.lastOwnActiveFieldMessage = encodeFieldMessage({
      messageType: PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON,
      documentId,
      blueprintFieldId,
      documentFieldLocaleId: "00000000-0000-0000-0000-000000000000",
    });

    broadcastToAllCurrentEditorPeers(state.lastOwnActiveFieldMessage);
  };

  const removeActiveField = ({ documentId, blueprintFieldId }: { documentId: string; blueprintFieldId: string }) => {
    removeActiveFieldCollaborator({ clientId: state.ownClientId, documentId, blueprintFieldId });
    broadcastToAllCurrentEditorPeers(
      encodeFieldMessage({
        messageType: PEER_MESSAGE_TYPE_FIELD_INACTIVE_ON,
        documentId,
        blueprintFieldId,
        documentFieldLocaleId: "00000000-0000-0000-0000-000000000000",
      }),
    );
  };

  function useActiveFieldCollaborator({
    documentId,
    blueprintFieldId,
  }: {
    documentId: string;
    blueprintFieldId: string;
  }) {
    const [activeFieldCollaborator, setActiveFieldCollaborator] = useState(
      state.fieldCollaborator[getFieldKey({ type: PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON, documentId, blueprintFieldId })] ||
        [],
    );

    useEffect(() => {
      const changeHandler = (newValue) => {
        setActiveFieldCollaborator(newValue);
      };

      state.pubsub.on(
        getFieldKey({ type: PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON, documentId, blueprintFieldId }),
        changeHandler,
      );
      return () => {
        state.pubsub.off(
          getFieldKey({ type: PEER_MESSAGE_TYPE_FIELD_ACTIVE_ON, documentId, blueprintFieldId }),
          changeHandler,
        );
      };
    }, []);

    return activeFieldCollaborator;
  }

  function useActiveDocumentCollaborator({ documentId }: { documentId: string }) {
    const getActiveDocumentCollaborator = () => {
      const collectedClientIds = state.ownClientId ? [state.ownClientId] : [];

      Object.keys(state.documentCollaborator).forEach((clientId) => {
        if (state.documentCollaborator[clientId].includes(documentId)) {
          collectedClientIds.push(clientId);
        }
      });

      return collectedClientIds;
    };

    const [clientIds, setClientIds] = useState(getActiveDocumentCollaborator());

    useEffect(() => {
      const changeHandler = () => {
        setClientIds(getActiveDocumentCollaborator());
      };

      state.pubsub.on(getDocumentKey({ type: PUBSUB_KEY_DOCUMENT_ACTIVE_CHANGE, documentId }), changeHandler);
      state.pubsub.on(PUBSUB_KEY_CONNECTION, changeHandler);
      return () => {
        state.pubsub.off(PUBSUB_KEY_CONNECTION, changeHandler);
        state.pubsub.off(getDocumentKey({ type: PUBSUB_KEY_DOCUMENT_ACTIVE_CHANGE, documentId }), changeHandler);
      };
    }, []);

    return clientIds;
  }

  function useActiveProjectCollaborator() {
    const getClientIdsFilterd = () => {
      return [
        ...(state.ownClientId ? [state.ownClientId] : []),
        // there if the client is coming from the live preview he will have a "-"" in his client id otherwise not
        ...(state.clientIds?.filter((cid) => cid != state.ownClientId && cid[40] != "-") || []),
      ];
    };
    const [clientIds, setClientIds] = useState(getClientIdsFilterd());

    useEffect(() => {
      const changeHandler = () => {
        setClientIds(getClientIdsFilterd());
      };

      state.pubsub.on(PUBSUB_KEY_CONNECTION, changeHandler);
      return () => {
        state.pubsub.off(PUBSUB_KEY_CONNECTION, changeHandler);
      };
    }, []);

    return clientIds;
  }

  function useCollaboratorLocation() {
    const [locations, setLocations] = useState(state.peerLocations);

    useEffect(() => {
      const changeHandler = () => {
        setLocations(state.peerLocations);
      };

      state.pubsub.on(PUBSUB_KEY_ACTIVE_URL_CHANGE, changeHandler);
      return () => {
        state.pubsub.off(PUBSUB_KEY_ACTIVE_URL_CHANGE, changeHandler);
      };
    }, []);

    return locations;
  }

  function useOwnClientId() {
    const [ownClientId, setOwnClientId] = useState(state.ownClientId);

    useEffect(() => {
      const changeHandler = () => {
        setOwnClientId(state.ownClientId);
      };

      state.pubsub.on(PUBSUB_KEY_CONNECTION, changeHandler);
      return () => {
        state.pubsub.off(PUBSUB_KEY_CONNECTION, changeHandler);
      };
    }, []);

    return ownClientId;
  }

  function useConnection() {
    const [connection, setConnection] = useState(state.connection);

    useEffect(() => {
      const changeHandler = (newValue) => {
        setConnection(newValue);
      };

      state.pubsub.on(PUBSUB_KEY_CONNECTION, changeHandler);
      return () => {
        state.pubsub.off(PUBSUB_KEY_CONNECTION, changeHandler);
      };
    }, []);

    return connection;
  }

  return (
    <CollaborationProviderContext.Provider
      value={{
        useActiveFieldCollaborator,
        addActiveField,
        removeActiveField,
        listenOnFieldYDoc,
        useConnection,
        useActiveProjectCollaborator,
        useActiveDocumentCollaborator,
        useCollaboratorLocation,
        useOwnClientId,
      }}
    >
      {children}
    </CollaborationProviderContext.Provider>
  );
};
