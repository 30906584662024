import gql from "graphql-tag";

export const getDocumentCountsByBlueprints = gql`
  query GetDocumentCountsByBlueprints($input: GetDocumentCountsByBlueprintsRequestInput) {
    GetDocumentCountsByBlueprints(input: $input) {
      counts {
        blueprintId
        count
      }
    }
  }
`;
