import {
  Button,
  Form,
  FormFieldWrapper,
  IconCheckmarkOutlined,
  IconCross,
  Modal,
  PrimaryModalHeader,
  SimpleInput,
  useForm,
} from "@caisy/league";
import { FC, ReactNode, useEffect, useState } from "react";
import { useI18nString } from "../../../../provider/i18n/useI18nString";
import { SAddNewItemModal } from "./styles/SAddNewItemModal";
import { SAddNewItemModalButton } from "./styles/SAddNewItemModalButton";
import { SAddNewItemModalCloseIconWrapper } from "./styles/SAddNewItemModalCloseIconWrapper";
import { SAddNewItemModalContent } from "./styles/SAddNewItemModalContent";
import { SAddNewItemModalFooter } from "./styles/SAddNewItemModalFooter";
import { SAddNewItemModalSection } from "./styles/SAddNewItemModalSection";
import { SAddMemberModalSectionTitlte } from "./styles/SAddNewItemModalSectionTitle";
import { SInputContainer } from "./styles/SInputContainer";

export interface IAddGroupModal {
  visible: boolean;

  onCancel(): void;

  label: string | ReactNode;

  placeholder: { fallback: string; selector: string };

  inputTitle: string | ReactNode;

  onSubmit(data: any): void;

  title: any;

  btnText: any;

  icon?: JSX.Element;
}

export const AddNewItemModal: FC<IAddGroupModal> = ({
  visible,
  label,
  placeholder,
  inputTitle,
  onSubmit,
  onCancel,
  title,
  icon,
  btnText,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { control, handleSubmit, validate, getFieldErrors } = useForm();

  const onFormSubmit = async (formData) => {
    if (await validate()) {
      setSubmitting(true);
      return onSubmit(formData);
    }
    return null;
  };

  const headerBreadcrumbs = [{ title, icon }];

  const i18nNamePlaceholder = useI18nString(placeholder);

  const i18nRequired = useI18nString({
    fallback: "Required",
    selector: "nav.organization_switcher_required",
  });

  useEffect(() => {
    if (visible) return;
    setSubmitting(false);
  }, [visible]);

  return (
    <Modal onClose={onCancel} visible={visible}>
      <SAddNewItemModal>
        <PrimaryModalHeader breadcrumbs={headerBreadcrumbs}>
          <SAddNewItemModalCloseIconWrapper onClick={onCancel}>
            <IconCross size={24} />
          </SAddNewItemModalCloseIconWrapper>
        </PrimaryModalHeader>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <SAddNewItemModalContent>
            <SAddNewItemModalSection>
              <SAddMemberModalSectionTitlte>{inputTitle}</SAddMemberModalSectionTitlte>
              <SInputContainer>
                <FormFieldWrapper
                  name="name"
                  control={control}
                  rules={[
                    {
                      name: "required",
                      condition: (value) => value && value !== "",
                      message: i18nRequired,
                    },
                  ]}
                >
                  <SimpleInput
                    label={label}
                    errors={getFieldErrors("name")?.map((e) => e.message) ?? []}
                    autoFocus
                    autoComplete="off"
                    placeholder={i18nNamePlaceholder}
                    required
                  />
                </FormFieldWrapper>
              </SInputContainer>
            </SAddNewItemModalSection>
          </SAddNewItemModalContent>
          <SAddNewItemModalFooter>
            <Button disabled={submitting} style={{ width: "calc(100% - 32px)" }} type="primary" htmlType="submit">
              <SAddNewItemModalButton>
                <IconCheckmarkOutlined size={16} />
                {btnText}
              </SAddNewItemModalButton>
            </Button>
          </SAddNewItemModalFooter>
        </Form>
      </SAddNewItemModal>
    </Modal>
  );
};
