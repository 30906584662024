import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "@caisy/league";

const Bronze = css`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 98;
  background-color: var(--ui-01);
  border-right: 1px solid var(--ui-03);
  box-sizing: border-box;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SManagementSidebarWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
