// import produce from "immer";
// import { client } from "../../utils/client";
import produce from "immer";
import { IUseDocumentNavigationState, IDocumentNavigationItem } from "./types";

export const createDocumentNavigationSlice = (
  set: (
    cb: (state: IUseDocumentNavigationState) => IUseDocumentNavigationState,
    replace: boolean,
    name: string,
  ) => void,
  get: () => IUseDocumentNavigationState,
) => ({
  documentNavigation: {
    history: [] as IDocumentNavigationItem[],
    push: (item: IDocumentNavigationItem) => {
      // if we go to the same document we are already in, do nothing.
      if (!get().documentNavigation.head()) {
        const currentDocumentId = window.location.search
          .replace("?", "")
          .split("&")
          .find((query) => query.includes("document_id"))
          ?.split("document_id=")[1];

        if (currentDocumentId === item.documentId) {
          return;
        }
      }

      if (get().documentNavigation?.tail()?.documentId === item.documentId) {
        return;
      }

      // if the document is already in the history, we go to that index instead of creating an aditional history item
      const index = get().documentNavigation.history.findIndex(({ documentId }) => documentId === item.documentId);
      if (index !== -1) {
        get().documentNavigation.goTo(index);
        return;
      }

      // if we go the the topItem document, we go there instead of creating new history item
      let topItem = null;
      const head = get().documentNavigation.head();
      if (!head) {
        topItem = item.parentId;
      } else {
        topItem = head.parentId;
      }
      if (topItem === item.documentId) {
        get().documentNavigation.reset();
        return;
      }

      // if the document is different from base and it's not in history, we create a new history item
      set(
        produce((state) => {
          state.documentNavigation.history.push(item);
        }),
        false,
        `documentNavigation/push/${item.documentId}`,
      );
    },

    pop: () => {
      let document: IDocumentNavigationItem;
      set(
        produce((state) => {
          const { history } = state.documentNavigation;
          document = history.length ? { ...history.pop() } : null;
        }),
        false,
        `documentNavigation/pop`,
      );
      return document;
    },

    goTo: (index: number) => {
      let document: IDocumentNavigationItem;
      set(
        produce((state) => {
          const { history } = state.documentNavigation;
          // for (const hIndex in history) {
          //   const h = history[hIndex];
          //   console.log(` h`, {
          //     index: hIndex,
          //     parentId: h.parentId,
          //     documentId: h.documentId,
          //     projectId: h.projectId,
          //     type: h.type,
          //     title: h.title,
          //   });
          // }
          document = { ...history[index] };
          state.documentNavigation.history = history.slice(0, index + 1);
        }),
        false,
        `documentNavigation/goTo/${index}`,
      );
      return document;
    },

    head: () => get().documentNavigation.history[0],
    tail: () => get().documentNavigation.history[get().documentNavigation.history.length - 1],
    hasManyLevels: () => get().documentNavigation.history.length > 1,

    reset: () => {
      set(
        produce((state) => {
          state.documentNavigation.history = [];
        }),
        false,
        `documentNavigation/reset`,
      );
    },
  },
});
