import { Upload as TusUpload } from "tus-js-client";

export enum EFileStatus {
  ADDED = "added",
  QUEUED = "queued",
  PAUSED = "paused",
  UPLOADING = "uploading",
  CANCELED = "canceled",
  ERROR = "error",
  SUCCESS = "success",
}

export interface IUpload extends TusUpload {
  status: EFileStatus;
  name: string;
  progress: number;
  size: number;
  ID: number;
  dataURL: any;
}

export interface IHandleFile {
  file: any;
  meta?: any;
  extraHeaders: { [key: string]: string };
  onAfterResponse?: (req, res) => void;
  onProgress?: (bytesUploaded, bytesTotal) => void;
  onError?: (error) => void;
  onSuccess?: (upload: IUpload) => void;
}

export interface IHandleDrop extends IHandleFile {
  event?: any;
}

export type IUploadSlice = (
  set: (cb: (state: IUploadState) => IUploadState, replace: boolean, name: string) => void,
  get: () => IUploadState,
) => IUploadState;

export interface IUploadState {
  upload: IUseUpload;
}
export type IImageMetadata = { dominantColor?: string; blurHash?: string; width?: number; height?: number };

export interface IUseUpload {
  uploadCount: number;
  currentDroppingFilesCount: number;
  setCurrentDroppingFilesCount: (newCount: number) => void;
  setUploadCount: (newCount: number) => void;
  uploads: IUpload[];
  successfulUploads: any[];
  clearSuccessfulUploads: () => void;
  setUploads: (newUploads: any[]) => void;
  handleFile: (file: any, meta?: any) => Promise<number>;
  handleDrop: (e: any, meta: any) => Promise<number>;
  currentUploads: IUpload[];
  setCurrentUploads: (newUploads: IUpload[]) => void;
}
