import React from "react";

function IconImageUpload() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <path
        stroke="#F7F9FA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M28.005 13.332v9.337a5.336 5.336 0 01-5.336 5.336H9.331a5.336 5.336 0 01-5.336-5.336V9.33a5.336 5.336 0 015.336-5.335h8.003"
      ></path>
      <path
        stroke="#F7F9FA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        d="M3.995 17.334l1.728-1.728a3.216 3.216 0 014.548 0L16 21.336M9.335 28.005l8.397-8.397a3.216 3.216 0 014.548 0l5.254 5.254M24.003 3.995L20.67 7.329M27.338 7.33l-3.335-3.335M24.003 3.995v6.67"
      ></path>
    </svg>
  );
}

export default IconImageUpload;
