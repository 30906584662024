import styled, { css } from "styled-components";

const CSSFinished = css`
  color: var(--active-icon-01);
`;

export const SDuplicateProjectProgressModalItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-03);

  ${({ finished, allFinished }) => (allFinished || finished) && CSSFinished}
`;
