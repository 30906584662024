import { gql } from "@apollo/client/core";

export const getManyOrganizationsByUserID = gql`
  query GetManyOrganizationsByUserID(
    $userId: String!
    $filter: GetManyOrganizationsByUserIDRequestFilterInput
    $paginationArguments: PaginationArgumentsInput
  ) {
    GetManyOrganizationsByUserID(
      input: { filter: $filter, paginationArguments: $paginationArguments, userId: $userId }
    ) {
      connection {
        edges {
          node {
            alias
            createdAt
            description
            logoAssetUrl
            name
            updatedAt
            organizationId
            roleByUser {
              title
            }
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;
