import { Img } from "@caisy/league";

export const generatePreviewPropsForUserAvatar = ({
  user,
  size,
}: {
  user: any;
  size: 28 | 24 | 20 | 16 | 12 | 32 | 36 | 40 | 48;
}) => {
  if (user?.photoURL) {
    return {
      image: <Img lazyload={false} src={user.photoURL} resolution={size} />,
      size,
    };
  }
  return {
    text: user?.displayName[0] || user?.email.split("@")[0][0],
    size,
  };
};
