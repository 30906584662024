import { assignRole } from "../../graphql/mutations/assignRole.gql";
import { deAssignRole } from "../../graphql/mutations/deAssignRole.gql";
import { client } from "../../utils/client";
import {
  IAssignRoleRequestInput,
  IAssignRoleResponse,
  IDeAssignRoleRequestInput,
  IDeAssignRoleResponse,
} from "../../interfaces/generated";
import { message } from "@caisy/league";
import { I18n } from "src/provider/i18n";

interface ICreateMemberInputBasic {
  roleId?: string;
  systemRole?: string;
}

interface ICreateMemberInputWithUserId extends ICreateMemberInputBasic {
  emailAddress?: string;
  userId?: string;
}

interface ICreateMemberInputWithEmail extends ICreateMemberInputBasic {
  userId?: string;
  emailAddress?: string;
}

export type ICreateMemberInput = ICreateMemberInputWithEmail | ICreateMemberInputWithUserId;

interface IDeleteMemberInputWithUserId {
  emailAddress?: never;
  userId: string;
}

interface IDeleteMemberInputWithEmail {
  userId?: never;
  emailAddress: string;
}

export type IDeleteMemberInput = IDeleteMemberInputWithEmail | IDeleteMemberInputWithUserId;

export const createMember = async ({
  projectId,
  organizationId,
  groupId,
  roleId,
  userId,
  emailAddress,
  systemRole,
}: IAssignRoleRequestInput): Promise<IAssignRoleResponse | undefined> => {
  try {
    const { data } = await client.mutate({
      mutation: assignRole,
      variables: {
        projectId,
        organizationId,
        groupId,
        roleId,
        userId,
        systemRole,
        emailAddress,
      },
    });

    return data.AssignRole;
  } catch (err) {
    if (err.toString().includes("ResourceExhausted")) {
      const selector =
        (projectId && "projectMemberQuota") || (groupId && "groupMemberQuota") || "organizationMemberQuota";
      const fallback =
        (projectId && "Quota breached: Max project members reached") ||
        (groupId && "Quota breached: Max group members reached") ||
        "Quota breached: Max organization members reached";

      message.error(<I18n selector={`memberErrorMessages.${selector}`} fallback={fallback} />, {
        duration: 5000,
      });

      return;
    }
    if (err.toString().includes("AlreadyExists")) {
      message.error(<I18n selector="memberErrorMessages.userAlreadyExists" fallback="User already exists." />, {
        duration: 3000,
      });
      return;
    }
    console.error(err);
  }
};

export const updateMember = async ({
  projectId,
  organizationId,
  groupId,
  roleId,
  userId,
  systemRole,
}: IAssignRoleRequestInput): Promise<IAssignRoleResponse> => {
  const { data } = await client.mutate({
    mutation: assignRole,
    variables: {
      projectId,
      organizationId,
      groupId,
      roleId,
      userId,
      systemRole,
    },
  });

  return data.AssignRole;
};

export const deleteMember = async ({
  groupId,
  projectId,
  organizationId,
  userId,
  emailAddress,
}: IDeAssignRoleRequestInput): Promise<IDeAssignRoleResponse> => {
  const { data } = await client.mutate({
    mutation: deAssignRole,
    variables: {
      projectId,
      groupId,
      organizationId,
      userId,
      emailAddress,
    },
  });

  return data.DeAssignRole;
};

export const getUserIdWithEmailAddress = (
  emailAddress: string,
  members: { [userId: string]: IAssignRoleRequestInput },
): string | undefined => {
  let id;
  for (const member in members) {
    if (members[member].emailAddress === emailAddress) {
      id = member;
      break;
    }
  }
  return id;
};

export const addOrUpdateMemberFromState = (
  key: string,
  member: IAssignRoleRequestInput,
  members: { [projectId: string]: { [userId: string]: IAssignRoleRequestInput } },
  stateKey: string,
) => {
  if (!members[stateKey]) {
    members[stateKey] = {};
  }
  members[stateKey][key] = member;
};
