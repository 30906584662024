import produce from "immer";
import { client } from "../../utils/client";
import { deleteLocale } from "../../graphql/mutations/deleteLocale.gql";
import { getManyLocales } from "../../graphql/queries/getManyLocales.gql";
import { updateLocale } from "../../graphql/mutations/updateLocale.gql";
import { createLocale } from "../../graphql/mutations/createLocale.gql";
import { ILocale, IUseDocumentLocaleState } from "./types";
import {
  ICreateDocumentFieldLocaleRequestInput,
  IDeleteDocumentFieldLocaleRequestInput,
  IDocumentFieldLocaleResponse,
  IUpdateDocumentFieldLocaleRequestInput,
} from "../../interfaces/generated";
import { defaultLocale } from "./fixtures";
import { message } from "@caisy/league";
import { I18n } from "../../provider/i18n";
/*
  this is name DocumentLocale to not be cunfused with the locales of a users UI
*/
export const createDocumentLocaleSlice = (
  set: (cb: (state: IUseDocumentLocaleState) => IUseDocumentLocaleState, replace: boolean, name: string) => void,
  get: () => IUseDocumentLocaleState,
) => ({
  documentLocale: {
    locales: {} as { [localeId: string]: ILocale },
    loadingLocales: false,
    syncingLocale: false,
    defaultDocumentFieldLocaleId: null,
    inactiveLocaleIds: [] as string[],
    localesOrder: [] as string[],
    resetDocumentLocale: () => {
      set(
        produce((state) => {
          state.documentLocale.locales = {};
          state.documentLocale.loadingLocales = false;
          state.documentLocale.syncingLocale = false;
          state.documentLocale.inactiveLocaleIds = [];
          state.documentLocale.localesOrder = [];
        }),
        false,
        "documentLocale/reset",
      );
    },
    loadLocales: async ({ projectId }, noCache = false) => {
      try {
        set(
          produce((state) => {
            state.documentLocale.loadingLocales = true;
          }),
          false,
          "documentLocale/loadLocales/start",
        );

        const { data } = await client.query({
          query: getManyLocales,
          ...(noCache
            ? { fetchPolicy: "no-cache", variables: { input: { projectId } } }
            : { variables: { input: { projectId } } }),
        });

        set(
          produce((state: IUseDocumentLocaleState) => {
            state.documentLocale.loadingLocales = false;
            if (state.documentLocale.localesOrder.length == 0) {
              state.documentLocale.localesOrder = [
                data.GetAllDocumentFieldLocale?.documentFieldLocales.find((l) => l.default)?.id,
              ];
            }
            state.documentLocale.locales =
              data.GetAllDocumentFieldLocale?.documentFieldLocales?.reduce(
                (prev, locale: IDocumentFieldLocaleResponse) => {
                  if (locale.default) {
                    state.documentLocale.defaultDocumentFieldLocaleId = locale.id;
                  }

                  return {
                    ...{
                      [locale.id]: {
                        apiName: locale.apiName,
                        documentFieldLocaleId: locale.id,
                        default: locale.default,
                        title: locale.title,
                        allowEmptyRequired: locale.allowEmptyRequired,
                        disableEditing: locale.disableEditing,
                        disableInResponse: locale.disableInResponse,
                        fallbackLocaleId: locale.fallbackLocaleId,
                      } as ILocale,
                      ...prev,
                    },
                  };
                },
                {},
              ) || {};
          }),
          false,
          "documentLocale/getManyLocales/done",
        );
      } catch (error) {
        console.log(error);
      }
    },

    getMasterLocale: () => Object.values(get().documentLocale.locales).find((l) => l.default === true) || defaultLocale,
    getAllLocalesList: () => {
      const s = get().documentLocale;
      return Object.values(get().documentLocale.locales).sort((a, b) => {
        return s.localesOrder.indexOf(a.documentFieldLocaleId) > s.localesOrder.indexOf(b.documentFieldLocaleId)
          ? -1
          : 1;
      });
    },
    createLocale: async (input: ICreateDocumentFieldLocaleRequestInput) => {
      try {
        const { data } = await client.mutate({
          mutation: createLocale,
          variables: { input },
        });
        const localeRes: IDocumentFieldLocaleResponse = data?.CreateDocumentFieldLocale?.documentFieldLocale;

        if (localeRes) {
          const locale = {
            apiName: localeRes.apiName,
            documentFieldLocaleId: localeRes.id,
            default: localeRes.default,
            title: localeRes.title,
            allowEmptyRequired: localeRes.allowEmptyRequired,
            disableEditing: localeRes.disableEditing,
            disableInResponse: localeRes.disableInResponse,
            fallbackLocaleId: localeRes.fallbackLocaleId,
          } as ILocale;

          set(
            produce((state) => {
              state.documentLocale.locales[locale.documentFieldLocaleId] = locale;
            }),
            false,
            "documentLocale/createLocale",
          );
          return locale;
        }

        return undefined;
      } catch (err) {
        if (err.toString().includes("ResourceExhausted")) {
          message.error(
            <I18n
              selector="documentLocaleErrorMessages.documentLocaleQuota"
              fallback="Quota breached: max locales the are allowed in your plan exhauseted."
            />,
            {
              duration: 5000,
            },
          );

          return;
        }
        console.log(err);
      }
    },

    updateLocale: async (input: IUpdateDocumentFieldLocaleRequestInput) => {
      try {
        set(
          produce((state) => {
            state.documentLocale.syncingLocale = true;
          }),
          false,
          "documentLocale/updateLocale/start",
        );

        const { data } = await client.mutate({
          mutation: updateLocale,
          variables: {
            input,
          },
        });

        const localeRes: IDocumentFieldLocaleResponse = data?.UpdateDocumentFieldLocale?.documentFieldLocale;

        if (localeRes) {
          const locale = {
            apiName: localeRes.apiName,
            documentFieldLocaleId: localeRes.id,
            default: localeRes.default,
            title: localeRes.title,
            allowEmptyRequired: localeRes.allowEmptyRequired,
            disableEditing: localeRes.disableEditing,
            disableInResponse: localeRes.disableInResponse,
            fallbackLocaleId: localeRes.fallbackLocaleId,
          } as ILocale;

          set(
            produce((state) => {
              if (locale.default) {
                state.documentLocale.defaultDocumentFieldLocaleId = locale.documentFieldLocaleId;
                // check if there was another locale default before so there can only be one default
                const oldDefaultLocaleId = (
                  Object.values(state.documentLocale.locales).find((l) => l.default === true) as any
                )?.id;

                if (oldDefaultLocaleId) {
                  state.documentLocale.locales[oldDefaultLocaleId].default = false;
                }
              }

              state.documentLocale.syncingLocale = false;
              state.documentLocale.locales[locale.documentFieldLocaleId] = locale;
            }),
            false,
            "documentLocale/updateLocale/done",
          );
          return locale;
        }

        return undefined;
      } catch (error) {
        console.log(error);
      }
    },

    deleteLocale: async (input: IDeleteDocumentFieldLocaleRequestInput) => {
      try {
        const { data } = await client.query({
          query: deleteLocale,
          fetchPolicy: "no-cache",
          variables: { input },
        });

        if (data.DeleteDocumentFieldLocale) {
          set(
            produce((state) => {
              delete state.documentLocale.locales[input.documentFieldLocaleId];
            }),
            false,
            "documentLocale/deleteLocale",
          );
        }
      } catch (error) {
        console.error(` error deleteLocale`, error);
      }
    },
    setInactiveLocaleIds: (inactiveLocaleIds: string[]) => {
      set(
        produce((state) => {
          state.documentLocale.inactiveLocaleIds = inactiveLocaleIds;
        }),
        false,
        "documentLocale/setInactiveLocaleIds",
      );
    },
    getActiveLocales: () => {
      const s = get().documentLocale;
      return Object.values(s.locales)
        .sort((a, b) => {
          return s.localesOrder.indexOf(a.documentFieldLocaleId) > s.localesOrder.indexOf(b.documentFieldLocaleId)
            ? -1
            : 1;
        })
        .filter((l) => !s.inactiveLocaleIds.includes(l.documentFieldLocaleId));
    },
    setLocalesOrder: (localeIds: string[]) => {
      set(
        produce((state) => {
          state.documentLocale.localesOrder = localeIds;
        }),
        false,
        "documentLocale/setLocalesOrder",
      );
    },
  },
});
