import { FC } from "react";
import { SOnboardingTemplatesModalArticle } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalArticle";
import { SOnboardingTemplatesModalName } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalName";
import { SOnboardingTemplatesModalImage } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalImage";
import { SOnboardingTemplateModalRichtext } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplateModalRichtext";
import { SOnboardingTemplatesModalSider } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalSider";
import { SOnboardingTemplatesModalSiderSection } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalSiderSection";
import { SOnboardingTemplatesModalSiderTitle } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalSiderTitle";
import { SOnboardingTemplatesModalSiderItem } from "../../../../../page/onboarding/components/OnboardingTemplates/styles/SOnboardingTemplatesModalSiderItem";
import { IGenProjectTemplate } from "../../../../../../interfaces/generated/i18n-types";
import { Button, IconExternalLink, IconGithub, RichTextRenderer } from "@caisy/league";
import { I18n } from "../../../../../../provider/i18n";
import LogoFull from "../../../../../atom/logoFull/LogoFull";
import { SSelectFromTemplateStepTemplateDetails } from "./styles/SSelectFromTemplateStepTemplateDetails";

interface ISelectFromTemplateStepTemplateDetails {
  template: IGenProjectTemplate;
  onAddToProject: () => void;
}

export const SelectFromTemplateStepTemplateDetails: FC<ISelectFromTemplateStepTemplateDetails> = ({
  template,
  onAddToProject,
}) => {
  return (
    <SSelectFromTemplateStepTemplateDetails>
      {(template?.name || template?.previewImage?.src || template?.fullDescription?.json) && (
        <SOnboardingTemplatesModalArticle>
          {template?.name && <SOnboardingTemplatesModalName>{template.name}</SOnboardingTemplatesModalName>}
          {template?.previewImage?.src && <SOnboardingTemplatesModalImage src={template.previewImage.src} />}
          {template?.fullDescription?.json && (
            <SOnboardingTemplateModalRichtext>
              <RichTextRenderer node={template.fullDescription.json} />
            </SOnboardingTemplateModalRichtext>
          )}
        </SOnboardingTemplatesModalArticle>
      )}
      <SOnboardingTemplatesModalSider>
        <Button onClick={onAddToProject} style={{ display: "block" }} type="primary">
          <I18n selector="nav.createProject.addToProject" fallback="Add to project" />
        </Button>
        {(template?.demoLink || template?.githubLink) && (
          <SOnboardingTemplatesModalSiderSection>
            <SOnboardingTemplatesModalSiderTitle>Resources</SOnboardingTemplatesModalSiderTitle>
            {template?.demoLink && (
              <SOnboardingTemplatesModalSiderItem isLink>
                <IconExternalLink size={16} />
                <a rel="noreferrer" href={template.demoLink} target="_blank">
                  <I18n selector="nav.createProject.liveDemo" fallback="Live demo" />
                </a>
              </SOnboardingTemplatesModalSiderItem>
            )}
            {template?.githubLink && (
              <SOnboardingTemplatesModalSiderItem isLink>
                <IconGithub />
                <a rel="noreferrer" href={template.githubLink} target="_blank">
                  <I18n selector="nav.createProject.githubRepo" fallback="Github repo" />
                </a>
              </SOnboardingTemplatesModalSiderItem>
            )}
          </SOnboardingTemplatesModalSiderSection>
        )}
        {template?.stack && (
          <SOnboardingTemplatesModalSiderSection>
            <SOnboardingTemplatesModalSiderTitle>
              <I18n selector="nav.createProject.stack" fallback="Stack" />
            </SOnboardingTemplatesModalSiderTitle>
            {template.stack.map(({ id, icon, label }) => (
              <SOnboardingTemplatesModalSiderItem key={id}>
                <img src={icon.src} /> {label}
              </SOnboardingTemplatesModalSiderItem>
            ))}
          </SOnboardingTemplatesModalSiderSection>
        )}
        <SOnboardingTemplatesModalSiderSection>
          <SOnboardingTemplatesModalSiderTitle>
            <I18n selector="nav.createProject.publisher" fallback="Publisher" />
          </SOnboardingTemplatesModalSiderTitle>
          <div style={{ width: "fit-content" }}>
            <LogoFull />
          </div>
        </SOnboardingTemplatesModalSiderSection>
      </SOnboardingTemplatesModalSider>
    </SSelectFromTemplateStepTemplateDetails>
  );
};
