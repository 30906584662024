import gql from "graphql-tag";

export const getUserInformation = gql`
  query GetUserInformation {
    GetUserInformation {
      preferredUITheme
      preferredUILanguage
      activeProjectId
      marketingProfile {
        complete
      }
    }
  }
`;
