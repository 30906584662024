import gql from "graphql-tag";
import { fragment_OrganizationResponse } from "../fragments/organizationResponse.gql";

export const updateOrganization = gql`
  ${fragment_OrganizationResponse}
  mutation UpdateOrganization($input: UpdateOrganizationRequestInput) {
    UpdateOrganization(input: $input) {
      organization {
        ...fragment_OrganizationResponse
      }
    }
  }
`;
