import { IconChevronRight } from "@caisy/league";
import { useMemo } from "react";
import { usePreview } from "../../../stores/preview/usePreview";
import PreviewSyncIndicator from "../../page/management/project/previews/PreviewSyncIndicator";
import { SSettingsTopNavBreadcrumb } from "./styles/SSettingsTopNavBreadcrumb";
import { SSettingsTopNavChevronRight } from "./styles/SSettingsTopNavChevronRight";

const PreviewDetailsNavComponent = () => {
  const { currentPreviewId, getCurrentPreview } = usePreview();

  const preview = useMemo(() => {
    if (!currentPreviewId) return;
    return getCurrentPreview();
  }, [currentPreviewId]);

  return (
    <>
      {preview?.name && (
        <>
          <SSettingsTopNavChevronRight>
            <IconChevronRight size={20} />
          </SSettingsTopNavChevronRight>

          <SSettingsTopNavBreadcrumb>{preview?.name}</SSettingsTopNavBreadcrumb>
        </>
      )}
      <PreviewSyncIndicator />
    </>
  );
};

export default PreviewDetailsNavComponent;
