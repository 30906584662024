import gql from "graphql-tag";

export const unlinkManyReleaseDocument = gql`
  mutation UnlinkManyReleaseDocument($input: UnlinkManyReleaseDocumentRequestInput) {
    __typename
    UnlinkManyReleaseDocument(input: $input) {
      successful
    }
  }
`;
