import { gql } from "@apollo/client/core";
import { fragment_ViewResponse } from "../fragments/viewResponse.gql";

export const getManyViews = gql`
  ${fragment_ViewResponse}
  fragment GetManyViewsResponse on GetManyViewsResponse {
    views {
      ...fragment_ViewResponse
    }
  }
  query GetManyViews($input: GetManyViewsRequestInput) {
    GetManyViews(input: $input) {
      ...GetManyViewsResponse
    }
  }
`;
