import gql from "graphql-tag";

export const query_readUserAccessTokens = gql`
  query ReadUserAccessTokens($input: ReadUserAccessTokensRequestInput) {
    ReadUserAccessTokens(input: $input) {
      tokens {
        createdAt
        id
        name
      }
    }
  }
`;
