import { useContext, useEffect } from "react";
import useWindowSize from "./useWindowSize";
import { NavbarBehaviorContext } from "../provider/navbar-behavior/NavbarBehavior";

// Hook
export default function useExpandableNavigation() {
  const { expanded, setExpanded, allowExpand, setAllowExpand } = useContext(NavbarBehaviorContext);
  const { width: windowSize } = useWindowSize();
  const expandMainNav = () => {
    setExpanded(!expanded);
    localStorage.setItem("expandedMainNav", JSON.stringify(!expanded));
  };
  useEffect(() => {
    if (windowSize >= 1440 && !localStorage.getItem("expandedMainNav")) {
      setExpanded(true);
      localStorage.setItem("expandedMainNav", "true");
    }

    if (windowSize <= 1300) {
      setAllowExpand(false);
      setExpanded(false);
      localStorage.setItem("expandedMainNav", "false");
    } else {
      setAllowExpand(true);
    }
  }, [windowSize]);
  return { expanded, setExpanded, allowExpand, setAllowExpand, expandMainNav };
}
