import { IStatus } from "./types";
// import { query_GetAllDocumentStatus } from "../../graphql/queries/getAllDocumentStatus.gql";
// import { IGetAllDocumentStatusRequestInput } from "../../interfaces/generated";

const defaultValue = {
  "0": { statusId: "0", name: "DRAFT" },
  "1": { statusId: "1", name: "CHANGED" },
  "2": { statusId: "2", name: "PUBLISHED" },
  "3": { statusId: "3", name: "ARCHIVED" },
};

export const createDocumentStatusSlice = (
  // set: (cb: (state: IUseDocumentStatusState) => void, replace: boolean, name: string) => void,
  // get: () => IUseDocumentStatusState,
) => ({
  documentStatus: {
    statuses: defaultValue as { [statusId: string]: IStatus },
    isLoading: false,

    resetStatus: () => {},
    // loadStatuses: async (input: IGetAllDocumentStatusRequestInput) => {
      // comment out since this feature is not implemented yet
      // try {
      //   set(
      //     produce((state) => {
      //       state.documentStatus.loading = true;
      //     }),
      //     false,
      //     "documentStatus/loadStatuss/start",
      //   );
      //   const { data } = await client.query({
      //     query: query_GetAllDocumentStatus,
      //     fetchPolicy: "no-cache",
      //     variables: { input },
      //   });
      //   console.log("data", data);
      //   set(
      //     produce((state: IUseDocumentStatusState) => {
      //       state.documentStatus.loading = false;
      //     }),
      //     false,
      //     "documentStatus/loadStatuses/done",
      //   );
      // } catch (error) {
      //   console.log(error);
      // }
    // },
  },
});
