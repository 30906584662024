import gql from "graphql-tag";

export const getProjectTemplateUsage = gql`
  query getProjectTemplateUsage($input: GetProjectTemplateUsageRequestInput) {
    GetProjectTemplateUsage(input: $input) {
      templates {
        templateId
        documentationId
      }
    }
  }
`;
