import produce from "immer";
import { queryWithStaleCache } from "../../graphql/middleware/query-with-stale-cache";
import { createRelease as createReleaseGql } from "src/graphql/mutations/createRelease.gql";
import { deleteRelease as deleteReleaseGql } from "src/graphql/mutations/deleteRelease.gql";
import { updateRelease as updateReleaseGql } from "src/graphql/mutations/updateRelease.gql";
import { validateRelease as validateReleaseGql } from "src/graphql/mutations/validateRelease.gql";
import { client } from "../../utils/client";
import { IUseSchedulingState } from "./types";
import {
  ICreateReleaseRequestInput,
  IDeleteManyReleasesRequestInput,
  IUpdateReleaseRequestInput,
  IValidateReleaseRequestInput,
  ILinkManyReleaseDocumentRequestInput,
  IUnlinkManyReleaseDocumentRequestInput,
} from "../../interfaces/generated";
import { getManyReleasesByProjectId } from "../../graphql/queries/getManyReleasesByProjectId.gql";
import { linkManyReleaseDocument } from "../../graphql/mutations/linkManyReleaseDocument.gql";
import { unlinkManyReleaseDocument } from "../../graphql/mutations/unlinkManyReleaseDocument.gql";

export const createSchedulingSlice = (
  set: (cb: (state: IUseSchedulingState) => IUseSchedulingState, replace: boolean, name: string) => void,
  get: () => IUseSchedulingState,
) => ({
  scheduling: {
    dataHash: undefined,
    isLoading: false,
    releases: undefined,
    syncing: false,
    getAllReleases: async ({ projectId }) => {
      await queryWithStaleCache({
        uniquePrefix: "getAllReleases",
        query: getManyReleasesByProjectId,
        variables: {
          input: {
            projectId,
          },
        },
        onCacheMiss: () => {
          set(
            produce((state: IUseSchedulingState) => {
              state.scheduling.isLoading = true;
            }),
            false,
            "scheduling/getAllReleases/start",
          );
        },
        onUpdate: ({ data, dataHash }) => {
          if (get().scheduling.dataHash != dataHash) {
            set(
              produce((state: IUseSchedulingState) => {
                state.scheduling.dataHash = dataHash;
                state.scheduling.releases = [...(data?.GetManyReleasesByProjectId?.node ?? [])].sort((a, b) =>
                  a.scheduling < b.scheduling ? -1 : a.scheduling > b.scheduling ? 1 : 0,
                );
              }),
              false,
              "scheduling/getAllReleases/onUpdate",
            );
          }
        },
      });
      set(
        produce((state: IUseSchedulingState) => {
          state.scheduling.isLoading = false;
        }),
        false,
        "scheduling/getAllReleases/done",
      );
    },
    createRelease: async (input: ICreateReleaseRequestInput) => {
      try {
        const res = await client.mutate({
          mutation: createReleaseGql,
          variables: { input },
        });
        // console.log("res?.data?.createRelease", res?.data?.createRelease);
        if (res?.data?.CreateRelease) {
          set(
            produce((state: IUseSchedulingState) => {
              state.scheduling.releases = [
                { __typename: "Release", ...res?.data?.CreateRelease.release },
                ...state.scheduling.releases,
              ].sort((a, b) => (a.scheduling < b.scheduling ? -1 : a.scheduling > b.scheduling ? 1 : 0));
              state.scheduling.isLoading = false;
            }),
            false,
            "scheduling/createRelease",
          );
          // console.log("created");
          return res?.data?.CreateRelease;
        }
      } catch (err) {
        return err;
      }
      return null;
    },
    updateRelease: async (input: IUpdateReleaseRequestInput) => {
      set(
        produce((state: IUseSchedulingState) => {
          state.scheduling.syncing = true;
        }),
        false,
        "scheduling/updateRelease",
      );
      try {
        const res = await client.mutate({
          mutation: updateReleaseGql,
          variables: { input },
        });
        if (res?.data?.UpdateRelease) {
          set(
            produce((state: IUseSchedulingState) => {
              state.scheduling.releases = state.scheduling.releases
                .map((release) => {
                  if (release.id !== input.releaseId || release.projectId !== input.projectId) {
                    return release;
                  } else {
                    const documentIds =
                      get().scheduling.releases.find((release) => release.id === input.releaseId)?.documentIds || [];

                    return { ...res?.data?.UpdateRelease.release, documentIds };
                  }
                })
                .sort((a, b) => (a.scheduling < b.scheduling ? -1 : a.scheduling > b.scheduling ? 1 : 0));
              state.scheduling.isLoading = false;
              state.scheduling.syncing = false;
            }),
            false,
            "scheduling/UpdateRelease",
          );

          return res?.data?.UpdateRelease;
        }
      } catch (err) {
        set(
          produce((state: IUseSchedulingState) => {
            state.scheduling.syncing = false;
          }),
          false,
          "scheduling/updateRelease",
        );
        return err;
      }
      return null;
    },
    deleteRelease: async (input: IDeleteManyReleasesRequestInput) => {
      try {
        const res = await client.mutate({
          mutation: deleteReleaseGql,
          variables: { input },
          fetchPolicy: "no-cache", // dont cache writes
        });
        if (res?.data?.DeleteManyReleases.deleted) {
          set(
            produce((state: IUseSchedulingState) => {
              state.scheduling.releases = state.scheduling.releases.filter(
                (release) => !input.id.includes(release.id) || release.projectId !== input.projectId,
              );
              state.scheduling.isLoading = false;
            }),
            false,
            "scheduling/deleteRelease",
          );
          return true;
        }
      } catch (err) {
        return err;
      }
      return false;
    },
    validateRelease: async (input: IValidateReleaseRequestInput) => {
      try {
        const res = await client.mutate({
          mutation: validateReleaseGql,
          variables: { input },
          fetchPolicy: "no-cache", // dont cache writes
        });
        if (res?.data?.ValidateRelease) {
          const releaseIndex = get().scheduling.releases.findIndex(
            (r) => r.id === input.releaseId && r.projectId === input.projectId,
          );
          const release = { ...get().scheduling.releases[releaseIndex], validation: res?.data?.ValidateRelease };

          set(
            produce((state: IUseSchedulingState) => {
              state.scheduling.releases[releaseIndex] = release;
              state.scheduling.isLoading = false;
            }),
            false,
            "scheduling/validateRelease",
          );
          return res?.data?.ValidateRelease;
        }
      } catch (err) {
        return err;
      }
      return null;
    },
    // TODO update set like in createRelease
    createReleaseDocument: async (input: ILinkManyReleaseDocumentRequestInput) => {
      set(
        produce((state: IUseSchedulingState) => {
          state.scheduling.syncing = true;
        }),
        false,
        "scheduling/createReleaseDocument/start",
      );
      try {
        const res = await client.mutate({
          mutation: linkManyReleaseDocument,
          variables: { input },
        });
        if (res?.data?.LinkManyReleaseDocument.successful) {
          set(
            produce((state: IUseSchedulingState) => {
              const relaseIndex = get().scheduling.releases.findIndex((release) => release.id === input.releaseId);
              state.scheduling.releases[relaseIndex].documentIds = [
                ...state.scheduling.releases[relaseIndex].documentIds,
                ...input.documentId,
              ];
              state.scheduling.isLoading = false;
              state.scheduling.syncing = false;
            }),
            false,
            "scheduling/createReleaseDocument",
          );
        }
        return res?.data?.LinkManyReleaseDocument.successful;
      } catch (err) {
        set(
          produce((state: IUseSchedulingState) => {
            state.scheduling.syncing = false;
          }),
          false,
          "scheduling/createReleaseDocument/error",
        );
        return err;
      }
    },
    deleteReleaseDocument: async (input: IUnlinkManyReleaseDocumentRequestInput) => {
      set(
        produce((state: IUseSchedulingState) => {
          state.scheduling.syncing = true;
        }),
        false,
        "scheduling/createReleaseDocument/start",
      );
      try {
        const res = await client.mutate({
          mutation: unlinkManyReleaseDocument,
          variables: { input },
        });
        if (res?.data?.UnlinkManyReleaseDocument) {
          set(
            produce((state: IUseSchedulingState) => {
              state.scheduling.releases = state.scheduling.releases.map((release) =>
                release.id === input.releaseId && release.projectId === input.projectId
                  ? { ...release, documentIds: release.documentIds?.filter((id) => !input.documentId.includes(id)) }
                  : release,
              );
              state.scheduling.isLoading = false;
              state.scheduling.syncing = false;
            }),
            false,
            "scheduling/deleteReleaseDocument",
          );
        }
        return res?.data?.UnlinkManyReleaseDocument.successful;
      } catch (err) {
        set(
          produce((state: IUseSchedulingState) => {
            state.scheduling.syncing = false;
          }),
          false,
          "scheduling/deleteReleaseDocument/error",
        );
        return err;
      }
    },
  },
});
