import styled, { css } from "styled-components";

const CSSFinished = css`
  // background-color: var(--interactional-primary-03);
  background-color: #41c300;
`;

export const SDuplicateProjectProgressModalProgressBar = styled.div`
  position: relative;

  width: 240px;
  height: 6px;
  margin-bottom: 24px;

  background-color: var(--ui-03);
  border-radius: 4px;
  overflow: hidden;

  &::after {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    background-color: var(--ui-supportive-06);
    border-radius: 4px 0 0 4px;

    width: ${(props) => (props.progress ? `${props.progress}%` : "0")};
    height: inherit;

    transition: width 0.3s ease-in-out;

    ${({ finished }) => finished && CSSFinished}
  }
`;
