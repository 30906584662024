import { PEER_MESSAGE_TYPE_FIELD_DISSMISS, PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT } from "../constants";
import { decodeFieldMessage, encodeFieldMessage } from "../encoding";
import { ICollaborationState, IPeer } from "../types";
import { findOwnListeningField } from "./helper";
import * as Y from "yjs";

export const handleSyncRequest = (state: ICollaborationState, peer: IPeer, data: Uint8Array) => {
  console.log("START SYNC_REQUEST PEER_MESSAGE_TYPE_MESSAGE_SYNC_REQUEST");
  state.mutex.runExclusive(async () => {
    const field = decodeFieldMessage(data);
    const ownListeningField = findOwnListeningField(state, field);

    if (!ownListeningField) {
      console.log("START SYNC_REQUEST !ownListeningField");
      peer.send(
        encodeFieldMessage({
          messageType: PEER_MESSAGE_TYPE_FIELD_DISSMISS,
          ...field,
        }),
      );
      return;
    }

    const { isAlone, wsAssignment, wsChange } = state.connection;
    if (!isAlone && wsAssignment && wsChange) {
      const twoClientsAreBothRequestingSyncAtTheSameTime = ownListeningField.peersSynced[peer.clientId] === false;
      // we have own sync process going on - both peers are trying to request sync from each other
      // in this case we use the one with the lower client_id as the one that backs off
      if (twoClientsAreBothRequestingSyncAtTheSameTime && peer.clientId > state.ownClientId) {
        console.log(
          ` ownListeningField.peersSynced[peer.clientId] === false && peer.clientId > state.ownClientId , skipping sync request from ${peer.clientId}`,
        );
        return;
      }

      // since the follower already retuned here we are now declared ourself as the leader
      const declareLeader = twoClientsAreBothRequestingSyncAtTheSameTime;

      declareLeader && console.log(`PEER_MESSAGE_TYPE_MESSAGE_SYNC_REQUEST i am thinking i am the leader`);
      if (declareLeader) {
        ownListeningField.provider.setLeaderClientId(state.ownClientId, true);
      } else {
        ownListeningField.provider.setLeaderClientId(peer.clientId, false);
      }

      const ydoc2 = ownListeningField.provider.doc;
      const stateVector2 = Y.encodeStateAsUpdate(ydoc2);
      const stateVector1 = field.body;

      Y.applyUpdate(ydoc2, stateVector1);

      ownListeningField.provider.setSynced(true);
      ownListeningField.provider.emit("synced", [{ synced: true }]);
      ownListeningField.peersSynced[peer.clientId] = true;

      if (ownListeningField.peersDismissed[peer.clientId]) {
        console.log(`  ownListeningField.peersDismissed[p._cid]`, ownListeningField.peersDismissed[peer.clientId]);
        return;
      }

      peer.send(
        encodeFieldMessage({
          messageType: PEER_MESSAGE_TYPE_MESSAGE_SYNC_ACKNOWLEDGEMENT,
          ...field,
          syncChecksum: declareLeader ? "2" : "1",
          body: stateVector2,
        }),
      );
    }
  });
};
