import { useEffect } from "react";
import { updateIntercom } from ".";
import { useCurrentProjectId } from "../../hooks/current-project-id/useCurrentProjectId";
import { FCWithChildren } from "../../interfaces/FCWithChildren";
import { useAuthentication } from "../../stores/authentication/useAuthentication";
import { shouldLoadIntercom, getIntercomUserData, loadIntercomWithContext } from "./config";

export const IntercomWrapper: FCWithChildren = ({ children }) => {
  const { user } = useAuthentication();
  const projectId = useCurrentProjectId();

  useEffect(() => {
    if (projectId && user) {
      shouldLoadIntercom() && updateIntercom(undefined, getIntercomUserData(user, projectId));
    }
  }, [user, projectId]);

  useEffect(() => {
    if (shouldLoadIntercom()) {
      if (user && user.id) {
        loadIntercomWithContext({ user, projectId });
      }
    }
  }, [user && user.id]);

  return <>{children}</>;
};
