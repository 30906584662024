import produce from "immer";
import { createCaisyUser } from "../../graphql/mutations/createCaisyUser.gql";
import { setupPasswordWithActionCode } from "../../graphql/mutations/setupPasswordWithActionCode.gql";
import { mutation_setUserMarketingProfile } from "../../graphql/mutations/setUserMarketingProfile.gql";
import { verifyActionCode } from "../../graphql/mutations/verifyActionCode.gql";
import { client } from "../../utils/client";
import { ISignUpState } from "./types";

export const createSignUpSlice: (
  set: (cb: (state: ISignUpState) => ISignUpState, replace: boolean, name: string) => void,
  get: () => ISignUpState,
) => ISignUpState = (set, get) => ({
  signUp: {
    currentEmail: null,
    verificationCode: null,
    createdCaisyUserId: null,
    createCaisyUser: async ({ email }) => {
      try {
        const { data } = await client.mutate({
          mutation: createCaisyUser,
          variables: { email: email.trim() },
        });
        if (data?.createCaisyUser?.userId) {
          set(
            produce<ISignUpState>((state) => {
              state.signUp.currentEmail = email;
              state.signUp.createdCaisyUserId = data?.createCaisyUser?.userId;
            }),
            false,
            "signUp/createCaisyUser",
          );
          return {
            success: true,
            userId: data?.createCaisyUser?.userId,
            email,
          };
        }
        return {
          success: false,
          error: data?.createCaisyUser?.error,
        };
      } catch (err) {
        console.error(`createCaisyUser err`, err);
        return {
          success: false,
          error: err.message,
        };
      }
    },
    validateVerificationCode: async ({ code }) => {
      try {
        const { data } = await client.mutate({
          mutation: verifyActionCode,
          variables: { email: get().signUp.currentEmail, code },
        });
        if (data?.VerifyActionCode?.success) {
          if (typeof window !== "undefined") {
            sessionStorage.setItem("caisy-signup-email", get().signUp.currentEmail);
            sessionStorage.setItem("caisy-signup-code", code);
          }
          set(
            produce<ISignUpState>((state) => {
              state.signUp.verificationCode = code;
            }),
            false,
            "signUp/ValidateVerificationCode",
          );
        }
        return data?.VerifyActionCode?.success;
      } catch (err) {
        return false;
      }
    },
    setUserMarketingProfile: async ({ profile }) => {
      try {
        const { data } = await client.mutate({
          mutation: mutation_setUserMarketingProfile,
          variables: { profile },
        });
        return data?.SetUserMarketingProfile?.success;
      } catch (err) {
        console.error(` setUserMarketingProfile err`, err);
        return false;
      }
    },
    loadSession: () => {
      let currentEmail = get().signUp.currentEmail;
      let verificationCode = get().signUp.verificationCode;

      if (currentEmail == null || verificationCode === null) {
        if (typeof window !== "undefined") {
          if (currentEmail == null) {
            currentEmail = sessionStorage.getItem("caisy-signup-email");
          }
          if (verificationCode === null) {
            verificationCode = sessionStorage.getItem("caisy-signup-code");
          }
        }
      }

      set(
        produce<ISignUpState>((state) => {
          state.signUp.verificationCode = verificationCode;
          state.signUp.currentEmail = currentEmail;
        }),
        false,
        "signUp/loadSession",
      );

      return !!(verificationCode && currentEmail);
    },
    setPassword: async ({ password }) => {
      let currentEmail = get().signUp.currentEmail;
      let verificationCode = get().signUp.verificationCode;

      if (currentEmail == null || verificationCode === null) {
        if (typeof window !== "undefined") {
          if (currentEmail == null) {
            currentEmail = sessionStorage.getItem("caisy-signup-email");
          }
          if (verificationCode === null) {
            verificationCode = sessionStorage.getItem("caisy-signup-code");
          }
        }
      }

      const { data } = await client.mutate({
        mutation: setupPasswordWithActionCode,
        variables: { email: currentEmail, code: verificationCode, password },
      });

      return data?.SetupPasswordWithActionCode?.success;
    },
    cancelEmailVerification: () => {
      set(
        produce<ISignUpState>((state) => {
          state.signUp.currentEmail = null;
          state.signUp.verificationCode = null;
          state.signUp.createdCaisyUserId = null;
        }),
        false,
        "signUp/cancelEmailVerification",
      );
    },
  },
});
