import { CSSProgressiveCaption01, SButton } from "@caisy/league";
import styled from "styled-components";

export const SOnboardingTourCardFooter = styled.div`
  ${CSSProgressiveCaption01};
  color: var(--text-03);
  padding: 8px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--ui-03);
  ${SButton},
  div,
  p {
    flex: 1;
  }
  p {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
`;
