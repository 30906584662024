import { SSearchInputWrapper } from "@caisy/league";
import styled, { css } from "styled-components";
import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "../../../../constants/styles/mediaquerys";

const Default = css`
  width: 100%;
  background: var(-ui-01);
  padding: ${({ searchOpen }) => (searchOpen ? "10px 16px" : "16px")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid var(--ui-03);

  ${SSearchInputWrapper} {
    height: 52px;
    box-sizing: border-box;
    flex: 1 1 auto;
  }
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuHeader = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
