import produce from "immer";
import { getAllAmounts } from "../../graphql/queries/getAllAmounts.gql";
import { getAmount } from "../../graphql/queries/getAmount.gql";
import { getCounts } from "../../graphql/queries/getCounts.gql";
import { getProjectQuotaPlan } from "../../graphql/queries/getProjectQuotaPlan.gql";
import {
  IGetAllAmountsRequestInput,
  IGetAllAmountsResponse,
  IGetAmountRequestInput,
  IGetCountsRequestInput,
  IGetProjectQuotaPlanRequestInput,
} from "../../interfaces/generated";
import { client } from "../../utils/client";
import { IUseProjectQuotaSlice } from "./types";

const defaultPlanValue = {
  maxDocumentBlueprints: 0,
  maxComponentBlueprints: 0,
  maxAssetBlueprints: 0,
  maxFieldsPerBlueprint: 0,
  maxMembers: 0,
  maxDocuments: 0,
  maxWebhooks: 0,
  maxPreviews: 0,
  maxViews: 0,
  maxLocales: 0,
  maxStoredDocumentVersions: 0,
  maxReleases: 0,
  maxAssetDownloadTraffic: 0,
  maxAssetUploadTraffic: 0,
  maxAssetStorage: 0,
  maxApiCallsExternal: 0,
  maxApiCallsInteral: 0,
  maxDaysAuditlog: 0,
  availableSystemRoles: 110101,
  name: "free_plan",
};

export const createProjectQuotaSlice = (
  set: (cb: (state: IUseProjectQuotaSlice) => IUseProjectQuotaSlice, replace: boolean, name: string) => void,
) => ({
  projectQuota: {
    planLoaded: false,
    plan: defaultPlanValue,
    operations: {},
    countsPerYearAndMonth: {},
    getProjectQuotaPlan: async (input: IGetProjectQuotaPlanRequestInput) => {
      const { data } = await client.query({
        query: getProjectQuotaPlan,
        variables: { input },
        fetchPolicy: "no-cache",
      });

      if (!data.GetProjectQuotaPlan.quotaPlan) return;

      set(
        produce((state) => {
          state.projectQuota.plan = data.GetProjectQuotaPlan.quotaPlan;
          state.projectQuota.planLoaded = true;
        }),
        false,
        "projectQuota/getProjectQuotaPlan",
      );
    },

    getAmount: async (input: IGetAmountRequestInput) => {
      const { data } = await client.query({
        query: getAmount,
        variables: { input },
      });

      return data.GetAmount.value;
    },

    getAllAmounts: async (input: IGetAllAmountsRequestInput) => {
      const { data } = await client.query({
        query: getAllAmounts,
        variables: { input },
        fetchPolicy: "no-cache",
      });

      if (!data?.GetAllAmounts) return;

      const { amounts } = data.GetAllAmounts as IGetAllAmountsResponse;

      set(
        produce<IUseProjectQuotaSlice>((state) => {
          amounts.forEach((amount) => {
            state.projectQuota.operations[amount.operation] = amount.value;
          });
        }),
        false,
        "projectQuota/getAllAmounts",
      );

      return data.GetAllAmounts;
    },

    getCounts: async (input: IGetCountsRequestInput) => {
      const { data } = await client.query({
        query: getCounts,
        variables: { input },
      });

      if (!data.GetCounts) return;

      set(
        produce<IUseProjectQuotaSlice>((state) => {
          if (!state.projectQuota.countsPerYearAndMonth[input.filter.operation]) {
            state.projectQuota.countsPerYearAndMonth[input.filter.operation] = {};
          }
          if (!state.projectQuota.countsPerYearAndMonth[input.filter.operation][input.pagination.endDate.year]) {
            state.projectQuota.countsPerYearAndMonth[input.filter.operation][input.pagination.endDate.year] = {};
          }
          state.projectQuota.countsPerYearAndMonth[input.filter.operation][input.pagination.endDate.year][
            input.pagination.endDate.month
          ] = data.GetCounts.counts;
        }),
        false,
        "projectQuota/getCounts",
      );
    },
    reset: () => {
      set(
        produce<IUseProjectQuotaSlice>((state) => {
          state.projectQuota.plan = defaultPlanValue;
          state.projectQuota.operations = {};
          state.projectQuota.planLoaded = false;
          state.projectQuota.countsPerYearAndMonth = {};
        }),
        false,
        "quota/reset",
      );
    },
  },
});
