import { CSSProgressiveBody02Semibold } from "@caisy/league";
import styled from "styled-components";

export const SSelectProjectStepListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 56px;
  padding: 0 16px;
  color: var(--text-01);
  ${CSSProgressiveBody02Semibold};
  border-bottom: 1px solid var(--ui-03);
  box-sizing: border-box;
`;
