import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

interface IMainMenuLabel {
  activated?: boolean;
  expanded?: boolean;
}

const CSSClosed = css`
  opacity: 0;
  width: 0px;
`;

const CSSExpanded = css`
  opacity: 1;
  width: auto;
`;

const Bronze = css<IMainMenuLabel>`
  font-family: "Inter";
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  ${(props) => (!props.expanded ? CSSClosed : CSSExpanded)};
  white-space: nowrap;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SMainMenuItemText = styled.div<IMainMenuLabel>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
