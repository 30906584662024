import gql from "graphql-tag";

export const deletePreview = gql`
  mutation DeletePreview($input: DeletePreviewRequestInput) {
    __typename
    DeletePreview(input: $input) {
      deleted
    }
  }
`;
