const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const paramsToUrlString = (params: Record<string, string>) => {
  let url = "";
  Object.entries(params).forEach(([key, value]) => {
    if (value !== null && typeof value !== "undefined") {
      url += url.includes("?") ? "&" : "?";
      url += `${camelToSnakeCase(key)}=${encodeURIComponent(value)}`;
    }
  });
  return url;
};

export const getHrefWithParams = ({ path, params }: { path: string; params: Record<string, string> }) => {
  return path + paramsToUrlString(params);
};
