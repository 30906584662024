import { CSSProgressiveBody02Semibold } from "@caisy/league";
import styled, { css } from "styled-components";
import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "../../../../constants/styles/mediaquerys";

const Default = css`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  ${CSSProgressiveBody02Semibold};
  color: var(--text-01);
  padding: 16px 16px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--ui-03);
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuSubHeader = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
