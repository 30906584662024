import { message } from "@caisy/league";
import produce from "immer";
import { CreateWebhook } from "../../graphql/mutations/createWebhook.gql";
import { DeleteWebhook } from "../../graphql/mutations/DeleteWebhook.gql";
import { UpdateWebhook } from "../../graphql/mutations/updateWebhook.gql";
import { GetAllWebhooks } from "../../graphql/queries/GetAllWebhooks";
import { GetManyWebhookCalls } from "../../graphql/queries/getManyWebhookCalls.gql";
import { query_GetWebhookCall } from "../../graphql/queries/getWebhookCall.gql";
import {
  IDeleteWebhookRequestInput,
  IGetManyWebhookCallsResponse,
  IGetWebhookCallRequestInput,
  IWebhookCallResponse,
  IWebhookResponse,
} from "../../interfaces/generated";
import { I18n } from "../../provider/i18n";
import { client } from "../../utils/client";
import { IUseWebState } from "./types";

type ICreateWebhookSlice = (
  set: (cb: (state: IUseWebState) => IUseWebState, replace: boolean, name: string) => void,
  get: () => IUseWebState,
) => IUseWebState;

export const createWebhookSlice: ICreateWebhookSlice = (set, get) => ({
  webhook: {
    webhooks: {} as { [webhookId: string]: IWebhookResponse },
    currentWebhook: {} as IWebhookResponse,
    loadingWebhooks: false,
    loadedWebhooks: false,
    getAllWebhooks: async ({ projectId }: { projectId: string }) => {
      set(
        produce((state: IUseWebState) => {
          state.webhook.loadingWebhooks = true;
        }),
        false,
        "webhook/getAllWebhooks/start",
      );

      try {
        const { data } = await client.query({
          query: GetAllWebhooks,
          variables: { input: { projectId } },
          fetchPolicy: "no-cache",
        });

        set(
          produce((state: IUseWebState) => {
            state.webhook.webhooks =
              data?.GetAllWebhooks?.webhooks?.reduce(
                (prev, webhook) => ({ ...{ [webhook.webhookId]: webhook, ...prev } }),
                {},
              ) || {};
            state.webhook.loadingWebhooks = false;
          }),
          false,
          "webhook/getAllWebhooks",
        );
      } catch (err) {
        console.log(` err at getAllWebhooks`, err);
        message.error(err.message);
        set(
          produce((state: IUseWebState) => {
            state.webhook.loadingWebhooks = false;
          }),
          false,
          "webhook/getAllWebhooks/error",
        );
      }
    },
    updateCurrentWebhook: ({ input, merge = true }) => {
      set(
        produce((state) => {
          if (merge) {
            state.webhook.currentWebhook = { ...state.webhook.currentWebhook, ...(input as any) };
          } else {
            state.webhook.currentWebhook = input as any;
          }
        }),
        false,
        "webhook/updateCurrentWebhook",
      );
    },

    saveCurrentWebhook: async ({ projectId }: { projectId: string }) => {
      const currentWebhook = get().webhook.currentWebhook;

      if (
        currentWebhook.name === "" ||
        currentWebhook.url === "" ||
        !currentWebhook.method ||
        !currentWebhook.trigger ||
        currentWebhook.trigger.length === 0
      ) {
        console.log(
          ` (currentWebhook.trigger && currentWebhook.trigger.length === 0)`,
          currentWebhook.trigger && currentWebhook.trigger.length === 0,
          { currentWebhook },
        );
        message.error("Please fill all the required fields");
        return;
      }

      const currentWebhookWithoutTypename = {
        description: currentWebhook.description,
        headers:
          currentWebhook?.headers
            ?.map((header) => ({ name: header.name, value: header.value }))
            .filter((header) => header.name && header.name !== "" && header.value && header.value != "") ?? [],
        method: currentWebhook.method,
        name: currentWebhook.name,
        trigger: currentWebhook.trigger,
        url: currentWebhook.url,
      };

      const { data } = await client.mutate({
        mutation: UpdateWebhook,
        variables: {
          input: { projectId, input: currentWebhookWithoutTypename, id: currentWebhook.webhookId },
        },
      });
      const updatedWebhook: IWebhookResponse = data?.UpdateWebhook?.webhook;

      if (updatedWebhook) {
        const item = {
          ...updatedWebhook,
          calls: null,
          count: 0,
          success: 0,
        };

        set(
          produce((state: IUseWebState) => {
            state.webhook.webhooks[item.webhookId] = item;
            state.webhook.currentWebhook = {
              ...item,
              headers:
                !item.headers || item.headers.length === 0
                  ? [{ __typename: "WebhookHeader", value: "", name: "" }]
                  : item.headers,
            };
          }),
          false,
          "webhook/saveCurrentWebhook",
        );
        message.success("Webhook saved");
      } else {
        message.error("There was an error trying to save the webhook");
      }
    },

    createWebhook: async ({ projectId, webhook }) => {
      try {
        if (
          webhook.name === "" ||
          webhook.url === "" ||
          !webhook.method ||
          !webhook.trigger ||
          webhook.trigger.length === 0
        ) {
          message.error("Please fill all the required fields");
          return;
        }
        const webhookWithoutTypename = {
          description: webhook.description,
          headers:
            webhook?.headers
              ?.map((header) => ({ name: header.name, value: header.value }))
              .filter((header) => header.name && header.name !== "" && header.value && header.value != "") ?? [],
          method: webhook.method,
          name: webhook.name,
          trigger: webhook.trigger,
          url: webhook.url,
        };

        const { data } = await client.mutate({
          mutation: CreateWebhook,
          variables: { input: { projectId, webhook: webhookWithoutTypename } },
        });
        const createdWebhook: IWebhookResponse = data?.CreateWebhook?.webhook;
        if (createdWebhook) {
          const item = {
            ...createdWebhook,
            calls: null,
            count: 0,
            success: 0,
          };

          set(
            produce((state: IUseWebState) => {
              state.webhook.webhooks[item.webhookId] = item;
              state.webhook.currentWebhook = item;
            }),
            false,
            "webhook/createWebhook",
          );

          message.success("Webhook created");

          return item;
        }
      } catch (err) {
        if (err.toString().includes("ResourceExhausted")) {
          message.error(
            <I18n
              selector="webhookErrorMessages.webhookQuota"
              fallback="Quota breached: max webhooks the are allowed in your plan exhauseted."
            />,
            {
              duration: 5000,
            },
          );

          return;
        }

        console.log(err);
      }
    },

    deleteWebhookHeaderByIndex: async (index) => {
      set(
        produce((state) => {
          state.webhook.currentWebhook.headers.splice(index, 1);
        }),
        false,
        "webhook/deleteWebhookHeaderByName",
      );
    },

    deleteWebhook: async ({ id, projectId }: IDeleteWebhookRequestInput) => {
      const { data } = await client.mutate({
        mutation: DeleteWebhook,
        variables: { input: { id: id, projectId: projectId } },
      });
      if (data) {
        set(
          produce((state: IUseWebState) => {
            delete state.webhook.webhooks[id];
          }),
          false,
          "webhook/deleteWebhook",
        );
      }
    },
    resetWebhooks: () => {
      set(
        produce((state: IUseWebState) => {
          state.webhook.webhooks = {};
        }),
        false,
        "webhook/resetWebhooks",
      );
    },

    loadWebhookById: ({ webhookId }) => {
      if (!webhookId) {
        set(
          produce<IUseWebState>((state) => {
            state.webhook.currentWebhook = {
              __typename: "WebhookResponse",
              trigger: [],
              headers: [{ name: "", value: "", __typename: "WebhookHeader" }],
            };
          }),
          false,
          "webhook/loadWebhookById/storedWebhook",
        );
        return;
      }

      const storedWebhook = get().webhook.webhooks[webhookId];
      if (storedWebhook) {
        set(
          produce<IUseWebState>((state) => {
            state.webhook.currentWebhook = {
              ...storedWebhook,
              headers:
                storedWebhook.headers.length === 0
                  ? [{ name: "", value: "", __typename: "WebhookHeader" }]
                  : storedWebhook.headers,
            };
          }),
          false,
          "webhook/loadWebhookById/storedWebhook",
        );
        return;
      }
    },
    getManyWebhookCalls: async (input) => {
      const { data } = await client.query({
        query: GetManyWebhookCalls,
        variables: { input },
        fetchPolicy: "no-cache",
      });

      const edges = (data?.GetManyWebhookCalls as IGetManyWebhookCallsResponse)?.connection?.edges;

      if (edges?.length > 0) {
        set(
          produce<IUseWebState>((state) => {
            if (!state.webhook.webhookCalls[input.filter.webhookId]) {
              state.webhook.webhookCalls[input.filter.webhookId] = {};
            }
            edges.forEach(({ node }) => {
              if (node.webhookId === input.filter.webhookId)
                state.webhook.webhookCalls[input.filter.webhookId][node.webhookCallId] = node;
            });
          }),
          false,
          "webhook/getManyWebhookCalls",
        );
      }
    },

    webhookCalls: {},

    getCurrentWebhookCalls: () => {
      if (
        !get().webhook.currentWebhook?.webhookId ||
        !get().webhook.webhookCalls[get().webhook.currentWebhook.webhookId]
      )
        return [];
      const webhookCalls = Object.values(get().webhook.webhookCalls[get().webhook.currentWebhook.webhookId]);

      return webhookCalls;
    },
    getWebhookCall: async (input: IGetWebhookCallRequestInput) => {
      try {
        const { data } = await client.query({
          query: query_GetWebhookCall,
          variables: { input },
          fetchPolicy: "no-cache",
        });

        if (data.GetWebhookCall?.webhookCall?.webhookId) {
          set(
            produce<IUseWebState>((state) => {
              if (!state.webhook.webhookCalls[data.GetWebhookCall.webhookCall.webhookId]) {
                state.webhook.webhookCalls[data.GetWebhookCall.webhookCall.webhookId] = {};
              }
              state.webhook.webhookCalls[data.GetWebhookCall.webhookCall.webhookId][input.webhookCallId] =
                data.GetWebhookCall.webhookCall;
            }),
            false,
            "webhook/getWebhookCall",
          );
        }
        return data.GetWebhookCall as IWebhookCallResponse;
      } catch (err) {
        console.log(`getWebhookCall err: `, err);
      }
      return null;
    },
  },
});
