import gql from "graphql-tag";

export const validateRelease = gql`
  mutation ValidateRelease($input: ValidateReleaseRequestInput) {
    ValidateRelease(input: $input) {
      releaseId
      valid
      validationErrors {
        documentId
        errors {
          blueprintFieldId
          errorCodes
          documentFieldLocaleId
        }
      }
    }
  }
`;
