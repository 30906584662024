import styled, { css } from "styled-components";
import { MIN_DIAMOND, MIN_GOLD, MIN_PLATINUM, MIN_SILVER } from "../../../../constants/styles/mediaquerys";
const CSSOpened = css`
  svg {
    transform: rotate(-180deg);
  }
`;

const Default = css<{ organizationOpen: boolean }>`
  margin-left: auto;
  transition: transform 400ms;
  ${({ organizationOpen }) => organizationOpen && CSSOpened};
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SOrganizationMenuHeaderSwitchButton = styled.div`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
