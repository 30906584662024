import { FC, useMemo } from "react";
import { SSelectProjectStepHeader } from "./styles/SSelectProjectStepHeader";
import {
  Button,
  FlatActionButton,
  IconDocuments,
  IconProjectsFolder,
  IconSwitchArrows,
  Preview,
  Spacer,
} from "@caisy/league";
import { useMemberShip } from "../../../../../../stores/membership/useMembership";
import { getPlaceholderLettersByName } from "../../../getPlaceholderLettersByName";
import { SSelectProjectStepHeaderSwitch } from "./styles/SSelectProjectStepHeaderSwitch";
import { SSelectProjectStepHeaderName } from "./styles/SSelectProjectStepHeaderName";
import { SSelectProjectStepListsContainer } from "./styles/SSelectProjectStepListsContainer";
import { SelectProjectStepList } from "./SelectProjectStepList";
import { useI18nString } from "../../../../../../provider/i18n/useI18nString";
import { I18n } from "../../../../../../provider/i18n";
import { SSelectProjectStepListsWrapper } from "./styles/SSelectProjectStepListsWrapper";
import { SCreateProjectModalContentContainer } from "../../styles/SCreateProjectModalContentContainer";
import { SCreateProjectModalContentTitle } from "../../styles/SCreateProjectModalContentTitle";

interface ISelectProjectStep {
  selectedOrganization: string;
  selectedGroup: string;
  selectedProject: string;
  onSelectGroup: (groupId: string) => void;
  onSelectProject: (projectId: string) => void;
  onContinue: () => void;
  onChange: () => void;
}

export const SelectProjectStep: FC<ISelectProjectStep> = ({
  selectedGroup,
  selectedOrganization,
  selectedProject,
  onSelectGroup,
  onSelectProject,
  onContinue,
  onChange,
}) => {
  const { organizations, groups, projects } = useMemberShip();

  console.log(` selectedOrganization`, selectedOrganization);
  const organization = organizations[selectedOrganization];
  console.log(` organization`, organization);
  const allProjects = useMemo(() => {
    return Object.keys(projects).filter((projectId) => projects[projectId].organizationId === selectedOrganization);
  }, [projects, selectedOrganization]);

  const hasUngroupedProjects = useMemo(() => {
    return allProjects.some((projectId) => !projects[projectId].groupId);
  }, [allProjects]);

  const ungroupedProjects = useMemo(() => {
    if (!hasUngroupedProjects) return null;
    return allProjects.filter((projectId) => !projects[projectId].groupId);
  }, [allProjects, hasUngroupedProjects]);

  const groupsList = useMemo(() => {
    const groupsList = Object.keys(groups).filter((groupId) => groups[groupId].organizationId === selectedOrganization);
    return hasUngroupedProjects ? ["ungrouped", ...groupsList] : groupsList;
  }, [groups, selectedOrganization, hasUngroupedProjects]);

  const projectsList = useMemo(() => {
    if (selectedGroup === "ungrouped") return ungroupedProjects;
    return allProjects.filter((projectId) => projects[projectId].groupId === selectedGroup);
  }, [allProjects, selectedGroup]);

  const groupsI18n = useI18nString({
    selector: "nav.createProject.groups",
    fallback: "Groups",
  });

  const projectsI18n = useI18nString({
    selector: "nav.createProject.projects",
    fallback: "Projects",
  });

  const searchGroupsI18n = useI18nString({
    selector: "nav.createProject.searchGroups",
    fallback: "Search groups",
  });

  const ungroupedI18n = useI18nString({
    selector: "nav.ungrouped",
    fallback: "Ungrouped",
  });

  const searchProjectsI18n = useI18nString({
    selector: "nav.createProject.searchProjects",
    fallback: "Search projects",
  });

  return (
    <SCreateProjectModalContentContainer>
      <div>
        <SCreateProjectModalContentTitle>
          <I18n fallback="Select Project" selector="nav.createProject.selectProject" />
        </SCreateProjectModalContentTitle>
        <SSelectProjectStepListsContainer>
          <SSelectProjectStepHeader>
            {organization && (
              <Preview
                size={24}
                {...(organization.logoAssetUrl
                  ? {
                      image: <img src={organization.logoAssetUrl!} style={{ objectFit: "cover" }} />,
                    }
                  : {
                      text: getPlaceholderLettersByName(`${organization.name ? organization.name : ""}`),
                    })}
              />
            )}
            <SSelectProjectStepHeaderName>{organization?.name ?? ""}</SSelectProjectStepHeaderName>
            <SSelectProjectStepHeaderSwitch>
              <FlatActionButton onClick={onChange} type="default">
                <IconSwitchArrows size={16} />
                <I18n fallback="Change" selector="nav.createProject.change" />
              </FlatActionButton>
            </SSelectProjectStepHeaderSwitch>
          </SSelectProjectStepHeader>
          <SSelectProjectStepListsWrapper>
            <SelectProjectStepList
              onSelect={onSelectGroup}
              header={groupsI18n}
              icon={<IconProjectsFolder size={20} />}
              searchPlaceholder={searchGroupsI18n}
              width={277}
              dataSource={groupsList}
              items={
                hasUngroupedProjects ? { ungrouped: { name: ungroupedI18n, groupId: "ungrouped" }, ...groups } : groups
              }
              selectedItem={selectedGroup}
            />
            <SelectProjectStepList
              onSelect={onSelectProject}
              header={projectsI18n}
              icon={<IconDocuments size={20} />}
              searchPlaceholder={searchProjectsI18n}
              width={507}
              dataSource={projectsList}
              items={projects}
              selectedItem={selectedProject}
            />
          </SSelectProjectStepListsWrapper>
        </SSelectProjectStepListsContainer>
        <Spacer bronze={24} />
        <Button onClick={onContinue} disabled={!selectedProject} type="primary" style={{ width: "100%" }}>
          <I18n selector="nav.createProject.continue" fallback="Continue" />
        </Button>
      </div>
    </SCreateProjectModalContentContainer>
  );
};
