import { CSSProgressivePrimaryButtons } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSSyncing = css`
  color: var(--active-text-04);
`;

export const SPreviewSyncIndicator = styled.div`
  ${CSSProgressivePrimaryButtons};
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-04);
  margin-left: auto;
  border: 1px solid var(--interactional-secondary-03);
  border-radius: 6px;
  padding: 8px 12px;
  transition: color 150ms;
  width: fit-content;

  ${(props) => props.syncing && CSSSyncing};
`;
