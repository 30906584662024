import gql from "graphql-tag";

export const getManyApiKeys = gql`
  query GetManyApiKey($input: GetManyAPIKeyRequestInput) {
    GetManyApiKey(input: $input) {
      apiKeys {
        apiKeyId
        createdAt
        name
        scope {
          write
          readDraft
        }
      }
      projectId
    }
  }
`;
