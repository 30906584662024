import { FC } from "react";
import { SFileUploadProgressBar } from "./Styles/SFileUploadProgressBar";
import { SFileUploadProgressBarContainer } from "./Styles/SFileUploadProgressBarContainer";

interface IFileUploadProgressBar {
  progress: number;
}

export const FileUploadProgressBar: FC<IFileUploadProgressBar> = ({ progress }) => {
  return (
    <SFileUploadProgressBarContainer>
      {Math.floor(progress)}%
      <SFileUploadProgressBar progress={progress} />
    </SFileUploadProgressBarContainer>
  );
};
