import produce from "immer";
import { getPermissionSet } from "src/graphql/queries/getPermissionSet.gql";
import { queryWithStaleCache } from "../../graphql/middleware/query-with-stale-cache";
import { IPermissionSet, IRole } from "../../interfaces/generated";
import { client } from "../../utils/client";

export interface IUsePermission {
  dataHash: string;
  isLoading: boolean;
  initializedPermission: boolean;
  permissions: IPermissionSet[];
  role: IRole;

  loadPermissions: ({
    projectId,
    organizationId,
    groupId,
    userId,
  }: {
    projectId?: string;
    organizationId?: string;
    groupId?: string;
    userId: string;
  }) => Promise<void>;

  getPermissions: ({
    projectId,
    organizationId,
    groupId,
    userId,
  }: {
    projectId?: string;
    organizationId?: string;
    groupId?: string;
    userId: string;
  }) => Promise<IPermissionSet[]>;
}

export interface IPermisionState {
  permission: IUsePermission;
}

export const createPermissionSlice = (
  set: (cb: (state: IPermisionState) => IPermisionState, boolean, string) => void,
  get: () => IPermisionState,
) => ({
  permission: {
    dataHash: undefined,
    role: {} as IRole,
    isLoading: true,
    permissions: undefined,
    initializedPermission: false,
    async loadPermissions({
      projectId,
      organizationId,
      groupId,
      userId,
    }: {
      userId: string;
      organizationId?: string;
      projectId?: string;
      groupId?: string;
    }) {
      try {
        await queryWithStaleCache({
          uniquePrefix: "permission",
          query: getPermissionSet,
          variables: { ...(projectId ? { projectId } : groupId ? { groupId } : { organizationId }), userId },
          onCacheMiss: () => {
            set(
              produce((state) => {
                state.permission.isLoading = true;
              }),
              false,
              "permissions/loadPermissions/cacheMiss",
            );
          },
          onUpdate: ({ data, dataHash }) => {
            if (get().permission.dataHash != dataHash) {
              set(
                produce((state) => {
                  state.permission.dataHash = dataHash;
                  state.permission.permissions = data.GetPermissionSet.permissionSet;
                  state.permission.role = data.GetPermissionSet.appliedRole;
                  state.permission.initializedPermission = true;
                }),
                false,
                "permissions/loadPermissions/update",
              );
            }
          },
        });
      } catch (err) {
        if (`${err}`.includes("not found") && typeof window !== "undefined") {
          window.location.replace("/app/profile/membership");
        } else {
          console.error(` loadPermissionserr`, err);
        }
      }

      set(
        produce((state) => {
          state.permission.isLoading = false;
        }),
        false,
        "permissions/loadPermissions",
      );
    },

    getPermissions: async ({
      userId,
      projectId,
      groupId,
      organizationId,
    }: {
      userId: string;
      organizationId?: string;
      projectId?: string;
      groupId?: string;
    }) => {
      const { data } = await client.query({
        query: getPermissionSet,
        variables: { ...(projectId ? { projectId } : groupId ? { groupId } : { organizationId }), userId },
        fetchPolicy: "no-cache",
      });

      if (data?.GetPermissionSet.permissionSet) {
        return data?.GetPermissionSet.permissionSet;
      }
    },
  },
});
