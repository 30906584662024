import produce from "immer";
import {
  addOrUpdateMemberFromState,
  createMember,
  deleteMember,
  getUserIdWithEmailAddress,
  updateMember,
} from "./common";
import { IAssignRoleResponse } from "../../interfaces/generated";
import { getGroupMembers } from "../../graphql/queries/getGroupMembers";
import { queryWithStaleCache } from "../../graphql/middleware/query-with-stale-cache";
import {
  ICreateGroupMemberInput,
  IDeleteGroupMemberInput,
  IUpdateGroupMemberInput,
  IUseGroupMemberState,
} from "./types";

export const createGroupMemberSlice = (
  set: (cb: (state: IUseGroupMemberState) => IUseGroupMemberState, boolean, string) => void,
  get: () => IUseGroupMemberState,
) => ({
  groupMember: {
    isLoading: false,
    dataHash: undefined,
    members: {},
    loadGroupMembers: async ({ groupId }) => {
      await queryWithStaleCache({
        uniquePrefix: "group-members",
        query: getGroupMembers,
        variables: { groupId },
        onCacheMiss: () => {
          set(
            produce((state) => {
              state.groupMember.isLoading = true;
            }),
            false,
            "groupMember/getGroupMembers/start",
          );
        },
        onUpdate: ({ data, dataHash }) => {
          if (get().groupMember.dataHash != dataHash && data.GetGroupMembers) {
            set(
              produce((state: IUseGroupMemberState) => {
                state.groupMember.isLoading = false;
                state.groupMember.dataHash = dataHash;
                data.GetGroupMembers.connection.edges.forEach((member) => {
                  const newMember = {
                    groupId,
                    roleId: member.node.role.roleId,
                    systemRole: member.node.role.systemRole,
                    emailAddress: member.node.user.email,
                    userId: member.node.user.userId,
                  };
                  addOrUpdateMemberFromState(member.node.user.userId, newMember, state.groupMember.members, groupId);
                });
              }),
              false,
              "groupMember/getGroupMembers/done",
            );
          }
        },
      });
    },
    createGroupMember: async ({
      groupId,
      roleId,
      userId,
      systemRole,
      emailAddress,
    }: ICreateGroupMemberInput): Promise<IAssignRoleResponse> => {
      const data = await createMember({ groupId, roleId, userId, systemRole, emailAddress });

      const key = data?.userId;

      if (key) {
        const newMember = {
          roleId,
          systemRole,
          emailAddress,
          userId: userId || data.userId,
          groupId,
        };
        const stateKey = groupId;

        set(
          produce((state: IUseGroupMemberState) => {
            addOrUpdateMemberFromState(key, newMember, state.groupMember.members, stateKey);
          }),
          false,
          "groupMember/createGroupMember",
        );
      }

      return data;
    },
    deleteGroupMember: async ({ groupId, userId, emailAddress }: IDeleteGroupMemberInput) => {
      const data = await deleteMember({ groupId, userId, emailAddress });

      const key = userId || getUserIdWithEmailAddress(emailAddress, get().groupMember.members[groupId]);

      set(
        produce((state: IUseGroupMemberState) => {
          delete state.groupMember.members[groupId][key];
        }),
        false,
        "groupMember/createGroupMember",
      );

      return data;
    },
    updateGroupMember: async ({ groupId, userId, roleId, systemRole }: IUpdateGroupMemberInput) => {
      const data = await updateMember({ groupId, userId, roleId, systemRole });

      const key = data.userId;

      if (key) {
        const id = userId || data.userId;
        const email = get().groupMember.members[groupId][key].emailAddress;
        const updatedMember = {
          roleId,
          systemRole,
          emailAddress: email,
          userId: id,
          groupId,
        };
        const stateKey = groupId;
        set(
          produce((state: IUseGroupMemberState) => {
            addOrUpdateMemberFromState(key, updatedMember, state.groupMember.members, stateKey);
          }),
          false,
          "groupMember/createGroupMember",
        );
      }

      return data;
    },
    getGroupMembers: ({ groupId }) => {
      return get().groupMember.members[groupId];
    },
  },
});
