import { FC } from "react";
import { IUseBlueprint } from "./types";
export type { IUseBlueprint } from "./types";
import { useStore } from "../index";
import { IBlueprintFieldType } from "../../interfaces/generated";

export const FIELD_TYPES = {
  [IBlueprintFieldType.BlueprintFieldTypeBoolean]: "boolean",
  [IBlueprintFieldType.BlueprintFieldTypeDatetime]: "datetime",
  [IBlueprintFieldType.BlueprintFieldTypeGeopoint]: "geopoint",
  [IBlueprintFieldType.BlueprintFieldTypeExtension]: "extension",
  [IBlueprintFieldType.BlueprintFieldTypeInt]: "number",
  [IBlueprintFieldType.BlueprintFieldTypeFloat]: "number",
  [IBlueprintFieldType.BlueprintFieldTypeSelect]: "select",
  [IBlueprintFieldType.BlueprintFieldTypeString]: "string",
  [IBlueprintFieldType.BlueprintFieldTypeRichtext]: "richtext",
  [IBlueprintFieldType.BlueprintFieldTypeTag]: "tag",
  [IBlueprintFieldType.BlueprintFieldTypeVideo]: "video",
  [IBlueprintFieldType.BlueprintFieldTypeUnspecified]: "unspecified",
  [IBlueprintFieldType.BlueprintFieldTypeCode]: "code",
  [IBlueprintFieldType.BlueprintFieldTypeFile]: "file",
  [IBlueprintFieldType.BlueprintFieldTypeColor]: "color",
  [IBlueprintFieldType.BlueprintFieldTypeConnection]: "connection",
};

export const useBlueprint = () => useStore(({ blueprint }) => blueprint) as IUseBlueprint;

export const withManyBlueprint = <P extends IUseBlueprint>(Wrapped: any) => {
  const withManyBlueprint: FC<P & IUseBlueprint> = (props: any) => {
    const manyBlueprint = useBlueprint();

    return <Wrapped {...(props as P)} manyBlueprint={manyBlueprint} />;
  };

  return withManyBlueprint;
};
