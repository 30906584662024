import gql from "graphql-tag";

export const getManyAuditEvents = gql`
  query GetManyAuditEvents($input: GetManyAuditEventsRequestInput) {
    GetManyAuditEvents(input: $input) {
      ...GetManyAuditEventsResponse
    }
  }
  fragment GetManyAuditEventsResponse on GetManyAuditEventsResponse {
    connection {
      ...AuditEventConnection
    }
  }
  fragment AuditEventConnection on AuditEventConnection {
    edges {
      ...AuditEventConnectionEdge
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
  fragment AuditEventConnectionEdge on AuditEventConnectionEdge {
    cursor
    node {
      ...AuditEventPayload
    }
  }
  fragment AuditEventPayload on AuditEventPayload {
    body
    eventId
    metaData {
      ...AuditEventMeta
      createdAt
      userRole
      documentStatusId
    }
    scope {
      ...AuditEventScope
    }
    trigger
  }
  fragment AuditEventMeta on AuditEventMeta {
    blueprintFieldId
    blueprintId
    documentId
    documentLocaleId
    effectedUserId
    triggeredUserId
  }
  fragment AuditEventScope on AuditEventScope {
    projectId
  }
  fragment PageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;
