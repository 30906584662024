import firebase from "firebase/app";
import "firebase/auth";

const config = {
  authDomain: process.env.NEXT_PUBLIC_FIRE_AUTHDOMAIN,
  apiKey: process.env.NEXT_PUBLIC_FIRE_APIKEY,
};

if (!firebase?.apps?.length) {
  firebase.initializeApp(config);
}

export const refreshFirebaseToken = async () => {
  if (typeof window !== "undefined") {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }
  const newToken = await firebase.auth().currentUser?.getIdToken();
  if (newToken) {
    localStorage.setItem("accounts:accessToken", newToken);
    localStorage.setItem("accounts:time", `${~~(Date.now() / 1000)}`);
  }
  return newToken;
};

export const checkFirebaseIfUserIsThere = () => {
  if (typeof window !== "undefined") {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    return !!firebase.auth().currentUser;
  }
};

export const signWithEmailAndPassword = async (email: string, password: string, router: any) => {
  await checkFirebaseLogin({ router });
  if (typeof window !== undefined) {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }
  const user = await firebase.auth().signInWithEmailAndPassword(email, password);
  return user;
};

export async function getEmailFromAuthCode({ actionCode }) {
  const auth = firebase.auth();
  const email = await auth.verifyPasswordResetCode(actionCode);
  return email;
}

export async function resetPasswordWithCode({ actionCode, password }) {
  const auth = firebase.auth();
  const resp = await auth.confirmPasswordReset(actionCode, password);
  return resp;
}

export const checkFirebaseLogin = async ({ router }) => {
  if (typeof window !== undefined) {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then(
          (token) => {
            localStorage.setItem("accounts:accessToken", token);
            localStorage.setItem("accounts:time", `${~~(Date.now() / 1000)}`);
            if (window.location.href.includes("login")) {
              router.push("/profile/membership");
            }
          },
          (error) => {
            console.log(` error `, error);
          },
        );
      }
    });
  }
};
export const fetchSignInMethodsForEmail = async ({ email }) => {
  if (typeof window !== undefined) {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const methods = await firebase.auth().fetchSignInMethodsForEmail(email);
    return methods;
  }
};
