import { message } from "@caisy/league";

const baseUpload = process.env.ASSET_UPLOAD_URL || process.env.CORE_URL;
const baseServe = process.env.ASSET_SERVE_URL || process.env.CORE_URL;

export const processImage = (file, id: string, handleFile) => {
  const headerName = "x-caisy-project-id";

  return new Promise((resolve) => {
    handleFile({
      file: file,
      extraHeaders: {
        [headerName]: id,
      },
      meta: {
        mode: "avatar",
      },
      onSuccess: (upload) => {
        const url = upload.url.replace(baseUpload, baseServe).replace("/upload/", "/assets/") as string;
        resolve(url);
      },
      onError: function (error) {
        message.error(`${error}`);
      },
    });
  });
};
