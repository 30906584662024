import gql from "graphql-tag";

export const getManyLocales = gql`
    query GetAllDocumentFieldLocale($input: GetAllDocumentFieldLocaleRequestInput) {  
        GetAllDocumentFieldLocale(input: $input) {    
            ...GetAllDocumentFieldLocaleResponse  
        }
    }
    fragment GetAllDocumentFieldLocaleResponse on GetAllDocumentFieldLocaleResponse {  
        documentFieldLocales {    
            ...DocumentFieldLocaleResponse  
        }  
        projectId
    }
    fragment DocumentFieldLocaleResponse on DocumentFieldLocaleResponse {  
        allowEmptyRequired  
        apiName  
        default  
        disableEditing  
        disableInResponse  
        fallbackLocaleId  
        flag  
        id  
        title
    }
`;