import { CSSProgressiveBody02Semibold } from "@caisy/league";
import styled from "styled-components";

export const SProjectLimitModalHeader = styled.div`
  height: 64px;
  border-bottom: 1px solid var(--ui-03);
  box-shadow: var(--box-shadow-sticked-top-ui);
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${CSSProgressiveBody02Semibold};
  color: var(--text-01);
  padding: 0 16px;
  background-color: var(--ui-01);
`;
