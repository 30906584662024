import { IconCheckmarkSolid, IconCircle } from "@caisy/league";
import { FC, Fragment, useEffect, useState } from "react";
import { I18n } from "../../../../provider/i18n";
import { useMemberShip } from "../../../../stores/membership/useMembership";
import { PROJECT_SECTIONS } from "../add-project-modal-sections/AddProjectModalSections";
import { SDuplicateProjectProgressModal } from "./styles/SDuplicateProjectProgressModal";
import { SDuplicateProjectProgressModalItem } from "./styles/SDuplicateProjectProgressModalItem";
import { SDuplicateProjectProgressModalItemIcon } from "./styles/SDuplicateProjectProgressModalItemIcon";
import { SDuplicateProjectProgressModalProgress } from "./styles/SDuplicateProjectProgressModalProgress";
import { SDuplicateProjectProgressModalProgressBar } from "./styles/SDuplicateProjectProgressModalProgressBar";
import { SDuplicateProjectProgressModalProgressBarContainer } from "./styles/SDuplicateProjectProgressModalProgressBarContainer";

interface IDuplicateProjectProgressModal {
  selectedSections: string[];
  portId: string;
}

export const DuplicateProjectProgressModal: FC<IDuplicateProjectProgressModal> = ({ selectedSections, portId }) => {
  const { duplicationProgress, getProjectPort, resetDuplicationProgress } = useMemberShip();
  const [progressInterval, setProgressInterval] = useState(null);

  const ProgressTitleDuplicating = (
    <I18n selector="nav.createProject.duplicatingTitle" fallback="Duplicating in progress..." />
  );
  const ProgressTitleFinished = (
    <I18n selector="nav.createProject.duplicatingFinishedTitle" fallback="Project has been successfully duplicated" />
  );

  useEffect(() => {
    getProjectPort({ portId });
    const interval = setInterval(() => {
      if (duplicationProgress.finished) return;
      getProjectPort({ portId });
    }, 1000);

    setProgressInterval(interval);

    return () => {
      if (interval) clearInterval(interval);
      resetDuplicationProgress();
    };
  }, []);

  useEffect(() => {
    if (duplicationProgress.finished) {
      const interval = clearInterval(progressInterval);
      // resetDuplicationProgress();
      setProgressInterval(interval);
    }
  }, [duplicationProgress?.finished]);

  return (
    <>
      <SDuplicateProjectProgressModal>
        <SDuplicateProjectProgressModalProgress finished={duplicationProgress.finished}>
          {duplicationProgress.finished ? ProgressTitleFinished : ProgressTitleDuplicating}
          <SDuplicateProjectProgressModalProgressBarContainer finished={duplicationProgress.finished}>
            {duplicationProgress.finished ? "100" : duplicationProgress.progress || "0"} %
            <SDuplicateProjectProgressModalProgressBar
              progress={duplicationProgress.finished ? 100 : duplicationProgress.progress}
              finished={duplicationProgress.finished}
            />
          </SDuplicateProjectProgressModalProgressBarContainer>
        </SDuplicateProjectProgressModalProgress>
        {PROJECT_SECTIONS.map((section) => (
          <Fragment key={section.key}>
            {selectedSections.includes(section.key) && (
              <SDuplicateProjectProgressModalItem>
                <SDuplicateProjectProgressModalItemIcon
                  allFinished={duplicationProgress.finished}
                  finished={duplicationProgress[section.key] || duplicationProgress.finished}
                >
                  {duplicationProgress[section.key] || duplicationProgress.finished ? (
                    <IconCheckmarkSolid size={20} />
                  ) : (
                    <IconCircle size={20} />
                  )}
                </SDuplicateProjectProgressModalItemIcon>
                {section.label}
              </SDuplicateProjectProgressModalItem>
            )}
            {section.subsections &&
              section.subsections.map((subSection) => (
                <Fragment key={subSection.key}>
                  {selectedSections.includes(subSection.key) && (
                    <SDuplicateProjectProgressModalItem>
                      <SDuplicateProjectProgressModalItemIcon
                        allFinished={duplicationProgress.finished}
                        finished={duplicationProgress[subSection.key] || duplicationProgress.finished}
                      >
                        {duplicationProgress[subSection.key] || duplicationProgress.finished ? (
                          <IconCheckmarkSolid size={20} />
                        ) : (
                          <IconCircle size={20} />
                        )}
                      </SDuplicateProjectProgressModalItemIcon>
                      {subSection.label}
                    </SDuplicateProjectProgressModalItem>
                  )}
                </Fragment>
              ))}
          </Fragment>
        ))}
      </SDuplicateProjectProgressModal>
    </>
  );
};
