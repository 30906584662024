import gql from "graphql-tag";
// import { fragment_session } from "./session.gql";
import { fragment_caisyUserProfile } from "./userProfile.gql";

export const fragment_userResponse = gql`
  ${fragment_caisyUserProfile}
  fragment fragment_userResponse on GetUserResponse {
    profile {
      ...fragment_caisyUserProfile
    }
    # session {
    #   ...fragment_session
    # }
    # settings {
    #   preferredUILanguage
    #   preferredUITheme
    # }
  }
`;
