import styled from "styled-components";

export const SFileUploadEmptyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ui-background);
  text-align: center;
  flex-direction: column;
  gap: 16px;
`;
