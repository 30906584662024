import produce from "immer";
import { getManyApiKeys } from "../../graphql/queries/getManyApiKeys.gql";
import { createApiKey as createApiKeyGQL } from "../../graphql/mutations/createApiKey.gql";
import { deleteApiKey as deleteApiKeyGQL } from "../../graphql/mutations/deleteApiKey.gql";
import {
  IApiKeyResponse,
  ICreateApiKeyRequestInput,
  IDeleteApiKeyRequestInput,
  IGetManyApiKeyRequestInput,
} from "../../interfaces/generated";
import { client } from "../../utils/client";
import { IApiKey, IUseApiKeyState } from "./types";
import { message } from "@caisy/league";

export const createApiKeySlice = (
  set: (cb: (state: IUseApiKeyState) => IUseApiKeyState, replace: boolean, name: string) => void,
  // get: () => IUseApiKeyState,
) => ({
  apikey: {
    loading: false,
    apiKeys: {} as { [apiKeyId: string]: IApiKey },
    loadApiKeys: async (input: IGetManyApiKeyRequestInput) => {
      set(
        produce((state) => {
          state.apikey.loading = true;
        }),
        false,
        "apikey/loadApiKeys/start",
      );

      const { data } = await client.query({
        query: getManyApiKeys,
        variables: { input },
        fetchPolicy: "no-cache",
      });

      set(
        produce((state: IUseApiKeyState) => {
          state.apikey.loading = false;
          state.apikey.apiKeys =
            data?.GetManyApiKey?.apiKeys?.reduce(
              (prev, apikey: IApiKeyResponse) => ({
                ...{
                  [apikey.apiKeyId]: {
                    apiKeyId: apikey.apiKeyId,
                    createdAt: apikey.createdAt,
                    name: apikey.name,
                    scope: apikey.scope,
                  } as IApiKey,
                  ...prev,
                },
              }),
              {},
            ) || {};
        }),
        false,
        "apikey/createdApiKey/done",
      );
    },
    createApiKey: async (input: ICreateApiKeyRequestInput) => {
      try {
        const { data } = await client.mutate({
          mutation: createApiKeyGQL,
          variables: { input },
        });

        const createdApiKey: IApiKey | undefined = data?.CreateApiKey?.apikey as IApiKey;
        set(
          produce((state: IUseApiKeyState) => {
            if (createdApiKey) {
              state.apikey.apiKeys[createdApiKey.apiKeyId] = createdApiKey;
            }
          }),
          false,
          "apikey/createdApiKey",
        );

        return createdApiKey?.value;
      } catch (e) {
        message.error(e.message);
      }
    },

    deleteApiKey: async (input: IDeleteApiKeyRequestInput) => {
      await client.mutate({
        mutation: deleteApiKeyGQL,
        variables: { input },
      });

      set(
        produce((state: IUseApiKeyState) => {
          if (input.apiKeyId) {
            delete state.apikey.apiKeys[input.apiKeyId];
          }
        }),
        false,
        "apikey/deleteApiKey",
      );
    },
  },
});
