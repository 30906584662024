import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  COMPLETED_CODE_SUM,
  INDEX_NUMBER_MULTIPLIER,
  OnboardingTour,
} from "../../components/organism/onboarding-tour/OnboardingTour";
import { IGenUserOnboardingTour } from "../../interfaces/generated/i18n-types";
import { useUserOnboarding } from "../../stores/user-onboarding/useUserOnboarding";

export const ListenerUserOnboardingTours = () => {
  const { getUserOnboardingTour, userOnboardingTours, getUserOnboarding, status, completedEvents } =
    useUserOnboarding();
  const router = useRouter();
  const locale = router.locale || "en";
  const [loading, setLoading] = useState(true);
  const [currentPageTour, setCurrentPageTour] = useState<IGenUserOnboardingTour>(null);

  useEffect(() => {
    if (!status || status === "COMPLETED") return;

    getUserOnboardingTour({ locale }).then(() => setLoading(false));
  }, [status, locale]);

  useEffect(() => {
    getUserOnboarding();
  }, []);

  useEffect(() => {
    setCurrentPageTour(null);

    const currentPageTour = userOnboardingTours.find(({ activePattern }) => {
      const regex = new RegExp(activePattern);
      const path = `app${router.asPath}`;
      return regex.test(path);
    });

    if (
      !userOnboardingTours ||
      completedEvents?.includes(currentPageTour?.indexNumber * INDEX_NUMBER_MULTIPLIER + COMPLETED_CODE_SUM)
    )
      return setCurrentPageTour(null);

    if (currentPageTour) {
      setCurrentPageTour(currentPageTour);
    }
  }, [router?.asPath, userOnboardingTours]);

  // console.log(` userOnboardingTours`, { userOnboardingTours, currentPageTour, status, completedEvents });
  if (!currentPageTour || loading) return null;

  return <OnboardingTour key={currentPageTour.activePattern} tour={currentPageTour} />;
};
