import { FCWithChildren } from "../../../../interfaces/FCWithChildren";
import {
  SOnboardingTourCardPointerBackgroundBottom,
  SOnboardingTourCardPointerBackgroundLeft,
  SOnboardingTourCardPointerBackgroundRight,
  SOnboardingTourCardPointerBackgroundTop,
} from "./styles/SOnboardingTourCardPointerBackground";
import { SOnboardingTourCardPointerWithClick } from "./styles/SOnboardingTourCardPointerWithClick";

export const OnboardingTourCardPointerWithClick: FCWithChildren<{ elementPosition?: DOMRect }> = (props) => {
  const { height, top, left, right, bottom } = props.elementPosition;

  return (
    <SOnboardingTourCardPointerWithClick>
      <SOnboardingTourCardPointerBackgroundTop height={`${top}px`} />
      <SOnboardingTourCardPointerBackgroundRight
        top={`${top}px`}
        height={`${height}px`}
        width={`calc(100vw - ${right}px)`}
      />
      <SOnboardingTourCardPointerBackgroundBottom height={`calc(100vh - ${bottom}px)`} />
      <SOnboardingTourCardPointerBackgroundLeft top={`${top}px`} height={`${height}px`} width={`${left}px`} />
    </SOnboardingTourCardPointerWithClick>
  );
};
