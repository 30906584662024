import { gql } from "@apollo/client";

export const query_GetManyDocuments = gql`
  query GetManyDocuments($input: GetManyDocumentsRequestInput) {
    GetManyDocuments(input: $input) {
      connection {
        edges {
          cursor
          node {
            blueprintBranch
            archivedAt
            blueprintId
            createdAt
            createdByUserId
            documentId
            environmentId
            previewImageUrl
            projectId
            publishedAt
            statusId
            title
            updatedAt
            lastUpdatedByUserId
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;
