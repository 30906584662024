import { CSSScrollbarStyles } from "@caisy/league";
import { createGlobalStyle } from "styled-components";

const GSGeneral = createGlobalStyle`
    *, * * {
        ${CSSScrollbarStyles}
    }

    #birdeatsbug-sdk{
        position: fixed;
        left: 80px;
        bottom: 20px;
    }
`;

export default GSGeneral;
