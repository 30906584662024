import { CSSProgressiveBody03Semibold } from "@caisy/league";
import styled, { css } from "styled-components";

const CSSFinished = css`
  color: var(--active-text-07);
`;

export const SDuplicateProjectProgressModalProgressBarContainer = styled.div`
  ${CSSProgressiveBody03Semibold};
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  color: var(--text-01);

  ${({ finished }) => finished && CSSFinished}
`;
