import gql from "graphql-tag";

export const fragment_ProjectResponse = gql`
  fragment fragment_ProjectResponse on ProjectResponse {
    alias
    createdAt
    description
    groupId
    logoAssetUrl
    name
    organizationId
    projectId
    updatedAt
    roleByUser {
      title
    }
  }
`;
