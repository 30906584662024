import { IPermissionSet } from "../interfaces/generated";

export const hasPermissions = ({
  permissionSet,
  permission,
}: {
  permissionSet: IPermissionSet[];
  permission: { action?: string; object?: string };
}) => {
  if (!permission) return true;

  const permissionToCompare = permissionSet.find(
    ({ object, action }) => object === permission.object && (action === permission.action || !action),
  );

  return permissionToCompare?.allow;
};
