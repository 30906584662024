import { CSSProgressiveCaption01 } from "@caisy/league";
import styled from "styled-components";

export const SOnboardingTourCardGuide = styled.div`
  ${CSSProgressiveCaption01};
  color: var(--text-03);
  padding: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
`;
