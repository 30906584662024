import styled, { css } from "styled-components";

const CSS1 = css`
  color: var(--icon-08);
`;

const CSS2 = css`
  color: var(--icon-09);
`;

const CSS3 = css`
  color: var(--icon-09);
`;

const types = {
  1: CSS1,
  2: CSS2,
  3: CSS3,
};

export const SPricingCardFeatureCheckmarkWrapper = styled.div`
  height: 16px;
  width: 16px;
  ${({ type }) => types[type]};
`;
