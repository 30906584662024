import { ClickOutside, IconItemsGroup, OrganizationSelectMenu, Popover } from "@caisy/league";
import { FC, useCallback, useRef, useState } from "react";
import { IGroupResponse, IOrganizationResponse, IProjectResponse } from "../../../../interfaces/generated";
import { I18n } from "../../../../provider/i18n";
import { AddNewItemModal, IAddGroupModal } from "../../../page/management/add-new-modal/AddNewItemModal";
import { OrganizationMenu } from "../../organization-menu/OrganizationMenu";
import { SOrganizationMenuRefHolder } from "./styles/SOrganizationMenuRefHolder";
import { SOrganizationSelectMenuToolTipWrapper } from "./styles/SOrganizationSelectMenuToolTipWrapper";
import { SOrganizationSelectMenuWrapper } from "./styles/SOrganizationSelectMenuWrapper";
import useExpandableNavigation from "../../../../hooks/useExpandableNavigation";

const ORGANISATION_TOOLTIP = (
  <I18n selector="nav.organization_switcher_switchOrganization" fallback="Switch Organization" />
);

const GROUP_TOOLTIP = <I18n selector="nav.organization_switcher_switchGroup" fallback="Switch Group" />;

const PROJECT_TOOLTIP = <I18n selector="nav.organization_switcher_switchProject" fallback="Switch Project" />;

const MainMenuOrganizationSelect: FC<{
  organization: IOrganizationResponse;
  group: IGroupResponse;
  project: IProjectResponse;
}> = ({ organization, group, project }) => {
  const [organizationMenuOpened, setOrganizationMenuOpened] = useState(false);
  const organizationSelectMenuRef = useRef();
  const { expanded } = useExpandableNavigation();
  const handleOrganizationMenuClick = useCallback(() => {
    setOrganizationMenuOpened((prev) => !prev);
  }, [setOrganizationMenuOpened]);

  const [addNewModalProps, setAddNewModalProps] = useState<IAddGroupModal>({
    label: "Name",
    visible: false,
    onCancel: () => {},
    title: "",
    placeholder: { fallback: "", selector: "" },
    btnText: <I18n selector="settings.add_new" fallback="Add New" />,
    inputTitle: "",
    onSubmit: () => {},
    icon: <IconItemsGroup />,
  });

  const CurrentProjectTooltip = (
    <SOrganizationSelectMenuToolTipWrapper>
      <table>
        <tbody>
          {organization?.name && (
            <tr>
              <td>
                <I18n selector="nav.organization_switcher_Organization" fallback="Organization" />:
              </td>
              <td>{organization.name}</td>
            </tr>
          )}
          {group?.name && (
            <tr>
              <td>
                <I18n selector="nav.organization_switcher_Group" fallback="Group" />:
              </td>
              <td>{group?.name}</td>
            </tr>
          )}
          {project?.name && (
            <tr>
              <td>
                <I18n selector="nav.organization_switcher_Project" fallback="Project" />:
              </td>
              <td>{project.name}</td>
            </tr>
          )}
        </tbody>
      </table>
    </SOrganizationSelectMenuToolTipWrapper>
  );
  return (
    <>
      <SOrganizationMenuRefHolder ref={organizationSelectMenuRef} />
      <ClickOutside onClickOutside={() => setOrganizationMenuOpened(false)}>
        <div className="ob-19c">
          <SOrganizationSelectMenuWrapper expanded={expanded} onClick={handleOrganizationMenuClick}>
            <OrganizationSelectMenu
              organization={organization}
              group={group}
              project={project}
              organizationTooltip={organization?.name && project?.name ? CurrentProjectTooltip : ORGANISATION_TOOLTIP}
              groupTooltip={organization?.name && project?.name ? CurrentProjectTooltip : GROUP_TOOLTIP}
              projectTooltip={organization?.name && project?.name ? CurrentProjectTooltip : PROJECT_TOOLTIP}
              disableTooltips={organizationMenuOpened}
              expanded={expanded}
              ungroupedI18n={<I18n selector="nav.organization_switcher_ungrouped" fallback="Ungrouped" />}
            />
          </SOrganizationSelectMenuWrapper>
          <Popover
            placement="rightBottom"
            reference={organizationSelectMenuRef}
            disableTriangle
            display={organizationMenuOpened}
          >
            <OrganizationMenu
              addNewModalProps={addNewModalProps}
              setAddNewModalProps={setAddNewModalProps}
              onClose={() => {
                setOrganizationMenuOpened(false);
              }}
            />
          </Popover>
        </div>
      </ClickOutside>

      {addNewModalProps?.visible && <AddNewItemModal {...addNewModalProps} />}
    </>
  );
};

export default MainMenuOrganizationSelect;
