import React from "react";

function IconHome() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.333 7.167V17.5h13.334V7.167"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.667 8.333L10 2.5l8.333 5.833M12.5 17.5v-5c0-.92-.746-1.667-1.667-1.667H9.167c-.921 0-1.667.746-1.667 1.667v5"
      ></path>
    </svg>
  );
}

export default IconHome;
