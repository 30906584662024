import { CSSProgressiveBody02Semibold } from "@caisy/league";
import styled from "styled-components";

export const SCreateProjectModalHeader = styled.div`
  padding: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  ${CSSProgressiveBody02Semibold};
  color: var(--text-01);
  border-bottom: 1px solid var(--ui-03);
  box-shadow: var(--box-shadow-sticked-top-ui);
  background-color: var(--ui-01);
`;
