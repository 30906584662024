import gql from "graphql-tag";

export const linkManyReleaseDocument = gql`
  mutation LinkManyReleaseDocument($input: LinkManyReleaseDocumentRequestInput) {
    __typename
    LinkManyReleaseDocument(input: $input) {
      successful
    }
  }
`;
