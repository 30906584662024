import styled from "styled-components";

export const SMainMenuSkeleton = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  border-radius: 0.25rem;
  background-color: var(--ui-03);
  background: #e8eff3;
  position: relative;
  overflow: hidden;
  @keyframes shimmer {
    0% {
      background-position: -75px 0;
    }
    100% {
      background-position: 75px 0;
    }
  }
  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: linear-gradient(90deg, var(--ui-03) 0%, var(--ui-02) 60%, var(--ui-03) 100%);
    background-repeat: no-repeat;
    background-size: 75px 1200px;
    animation: shimmer 1.2s linear infinite;
  }
`;
