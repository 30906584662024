/* eslint-disable prefer-rest-params, prefer-spread */
// @ts-nocheck
export const setImmediatePolyfill = () => {
  (function (global) {
    "use strict";

    if (global.setImmediate) {
      return;
    }

    let nextHandle = 1; // Spec says greater than zero
    const tasksByHandle = {};
    let currentlyRunningATask = false;
    let registerImmediate;

    function setImmediate(callback) {
      // Callback can either be a function or a string
      if (typeof callback !== "function") {
        callback = new Function("" + callback);
      }
      // Copy function arguments
      const args = new Array(arguments.length - 1);
      for (let i = 0; i < args.length; i++) {
        args[i] = arguments[i + 1];
      }
      // Store and register the task
      const task = { callback: callback, args: args };
      tasksByHandle[nextHandle] = task;
      registerImmediate(nextHandle);
      return nextHandle++;
    }

    function clearImmediate(handle) {
      delete tasksByHandle[handle];
    }

    function run(task) {
      const callback = task.callback;
      const args = task.args;
      switch (args.length) {
        case 0:
          callback();
          break;
        default:
          callback(...args);
          break;
      }
    }

    function runIfPresent(handle) {
      // From the spec: "Wait until any invocations of this algorithm started before this one have completed."
      // So if we're currently running a task, we'll need to delay this invocation.
      if (currentlyRunningATask) {
        // Delay by doing a setTimeout. setImmediate was tried instead, but in Firefox 7 it generated a
        // "too much recursion" error.
        setTimeout(runIfPresent, 0, handle);
      } else {
        const task = tasksByHandle[handle];
        if (task) {
          currentlyRunningATask = true;
          try {
            run(task);
          } finally {
            clearImmediate(handle);
            currentlyRunningATask = false;
          }
        }
      }
    }

    function canUsePostMessage() {
      // The test against `importScripts` prevents this implementation from being installed inside a web worker,
      // where `global.postMessage` means something completely different and can't be used for this purpose.
      if (global.postMessage && !global.importScripts) {
        let postMessageIsAsynchronous = true;
        const oldOnMessage = global.onmessage;
        global.onmessage = function () {
          postMessageIsAsynchronous = false;
        };
        global.postMessage("", "*");
        global.onmessage = oldOnMessage;
        return postMessageIsAsynchronous;
      }
    }

    function installPostMessageImplementation() {
      // Installs an event handler on `global` for the `message` event: see
      // * https://developer.mozilla.org/en/DOM/window.postMessage
      // * http://www.whatwg.org/specs/web-apps/current-work/multipage/comms.html#crossDocumentMessages

      const messagePrefix = "setImmediate$" + Math.random() + "$";
      const onGlobalMessage = function (event) {
        if (event.source === global && typeof event.data === "string" && event.data.indexOf(messagePrefix) === 0) {
          runIfPresent(+event.data.slice(messagePrefix.length));
        }
      };

      if (global.addEventListener) {
        global.addEventListener("message", onGlobalMessage, false);
      } else {
        global.attachEvent("onmessage", onGlobalMessage);
      }

      registerImmediate = function (handle) {
        global.postMessage(messagePrefix + handle, "*");
      };
    }

    function installMessageChannelImplementation() {
      const channel = new MessageChannel();
      channel.port1.onmessage = function (event) {
        const handle = event.data;
        runIfPresent(handle);
      };

      registerImmediate = function (handle) {
        channel.port2.postMessage(handle);
      };
    }

    function installSetTimeoutImplementation() {
      registerImmediate = function (handle) {
        setTimeout(runIfPresent, 0, handle);
      };
    }

    // If supported, we should attach to the prototype of global, since that is where setTimeout et al. live.
    let attachTo = Object.getPrototypeOf && Object.getPrototypeOf(global);
    attachTo = attachTo && attachTo.setTimeout ? attachTo : global;

    // Don't get fooled by e.g. browserify environments.
    if (canUsePostMessage()) {
      // For non-IE10 modern browsers
      installPostMessageImplementation();
    } else if (global.MessageChannel) {
      // For web workers, where supported
      installMessageChannelImplementation();
    } else {
      // For older browsers
      installSetTimeoutImplementation();
    }

    attachTo.setImmediate = setImmediate;
    attachTo.clearImmediate = clearImmediate;
  })(typeof self === "undefined" ? (typeof global === "undefined" ? this : global) : self);
};
