import gql from "graphql-tag";

export const createProject = gql`
  mutation Mutation(
    $organizationId: String
    $name: String!
    $alias: String
    $description: String
    $groupId: String
    $logoAssetUrl: String
  ) {
    CreateProject(
      input: {
        organizationId: $organizationId
        project: {
          name: $name
          alias: $alias
          description: $description
          groupId: $groupId
          logoAssetUrl: $logoAssetUrl
        }
      }
    ) {
      project {
        alias
        createdAt
        description
        groupId
        logoAssetUrl
        name
        organizationId
        projectId
        updatedAt
      }
    }
  }
`;
