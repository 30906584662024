import gql from "graphql-tag";

export const getManyPreviews = gql`
  query GetManyPreviews($input: GetManyPreviewsRequestInput) {
    __typename
    GetManyPreviews(input: $input) {
      connection {
        edges {
          cursor
          node {
            name
            previewId
            previewItems {
              blueprintId
              previewId
              enableLivePreview
              previewItemId
              previewUrl
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;
