import styled, { css } from "styled-components";
import { Z_INDEX } from "../../../../../constants/z-index-numbers";

const CSSTopLeft = css`
  --left: 50px;
  --top: 50px;
`;

const CSSTopRight = css`
  --left: -50px;
  --top: 50px;
`;

const CSSBottomRight = css`
  --left: -50px;
  --top: -50px;
`;

const CSSBottomLeft = css`
  --left: 50px;
  --top: -50px;
`;

const POSITIONS = {
  topLeft: CSSTopLeft,
  topRight: CSSTopRight,
  bottomLeft: CSSBottomLeft,
  bottomRight: CSSBottomRight,
};

export const SOnboardingTourCardPointerClickIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 41px;
  width: 41px;
  z-index: ${Z_INDEX.ONBOARDING_TOUR_HIGHLIGHT};
  opacity: 0;
  pointer-events: none;
  --top: 50px;
  --left: -50px;

  animation: animateIn 2000ms infinite;
  animation-delay: 500ms;
  transition: all 300ms;

  ${({ from }) => POSITIONS[from]}

  @keyframes animateIn {
    0% {
      opacity: 0;
    }

    25% {
      opacity: 1;
    }

    25% {
      top: calc(50% + var(--top));
      left: calc(50% + var(--left));
      transform: translate(-50%, -50%);

      opacity: 0;
    }

    45% {
      opacity: 1;
    }

    50% {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
    }

    60% {
      transform: translate(-50%, -50%) scale(0.8);
    }
    70% {
      transform: translate(-50%, -50%) scale(0.8);
    }

    85% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;
