import styled from "styled-components";

export const SOnboardingImportingTemplateSections = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 24px;
  background-color: var(--ui-01);
  border: 1px solid var(--ui-03);
  border-radius: 12px;
`;
