import { FC } from "react";
import { CreateProjectForm } from "../create-project-form/CreateProjectForm";
import { SCreateProjectModalContentContainer } from "../styles/SCreateProjectModalContentContainer";
import { SCreateProjectModalContentTitle } from "../styles/SCreateProjectModalContentTitle";
import { useMemberShip } from "../../../../../stores/membership/useMembership";
import { useAuthentication } from "../../../../../stores/authentication/useAuthentication";
import { useUserOnboarding } from "../../../../../stores/user-onboarding/useUserOnboarding";
import { useUpload } from "../../../../../stores/upload/useUpload";
import { paramsToUrlString } from "../../../../../utils/getHrefWithParams";
import { useRouter } from "next/router";
import { I18n } from "../../../../../provider/i18n";
import { processImage } from "../utils";

interface ICreateBlankProject {
  onCancel: () => void;
  organizationId: string;
  groupId: string;
  onClose: () => void;
}

export const CreateBlankProject: FC<ICreateBlankProject> = ({ onCancel, organizationId, groupId, onClose }) => {
  const { createProject, updateProject } = useMemberShip();
  const { userId } = useAuthentication();
  const { logoBlob } = useUserOnboarding();
  const { handleFile } = useUpload();
  const router = useRouter();

  const onCreateProject = async (data: any) => {
    const project: any = { name: data.name };

    if (groupId && groupId !== "ungrouped") {
      project.groupId = groupId;
    }

    const createdProject = await createProject({ userId, project, organizationId });
    if (!createdProject) return;

    if (data.logo) {
      const projectId = createdProject.projectId;
      const logoAssetUrl = await processImage(logoBlob, projectId, handleFile);
      await updateProject({
        projectId,
        input: { logoAssetUrl: logoAssetUrl as string, name: data.name },
      });
    }

    await sleep(600);
    await router.push(
      `/project/documents${paramsToUrlString({
        projectId: createdProject.projectId,
      })}`,
      undefined,
      { shallow: false },
    );

    onClose();
  };

  return (
    <SCreateProjectModalContentContainer>
      <div>
        <SCreateProjectModalContentTitle>
          <I18n selector="nav.createProject.newProjectDetails" fallback="New Project details" />
        </SCreateProjectModalContentTitle>
        <CreateProjectForm onCreateProject={onCreateProject} onCancel={onCancel} />
      </div>
    </SCreateProjectModalContentContainer>
  );
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
