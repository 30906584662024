import React from "react";
import styled from "styled-components";

const SSpinnerWrapperFullWidthCenter = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  // padding: 20% 50%;
`;

export const SpinnerWrapperFullWidthCenter: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <SSpinnerWrapperFullWidthCenter>{children}</SSpinnerWrapperFullWidthCenter>;
};
