import styled from "styled-components";

export const SActiveUsersDropdownItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  border-bottom: 1px solid var(--ui-03);
  box-sizing: border-box;

  &:last-of-type {
    border-bottom: none;
  }
`;
