import gql from "graphql-tag";

export const getAllDocumentSnapshot = gql`
  query getAllDocumentSnapshot($input: GetAllDocumentSnapshotRequestInput) {
    __typename
    GetAllDocumentSnapshot(input: $input) {
      documentSnapshots {
        publishedAt
        snapshotId
      }
    }
  }
`;
