import { FC, useState } from "react";
import { SelectTemplateStep } from "./select-template-step/SelectTemplateStep";
import { IGenProjectTemplate } from "../../../../../interfaces/generated/i18n-types";
import { CreateProjectForm } from "../create-project-form/CreateProjectForm";
import { useMemberShip } from "../../../../../stores/membership/useMembership";
import { useUserOnboarding } from "../../../../../stores/user-onboarding/useUserOnboarding";
import { useAuthentication } from "../../../../../stores/authentication/useAuthentication";
import { useProjectOnboarding } from "../../../../../stores/project-onbarding/useProjectOnboarding";
import { useUpload } from "../../../../../stores/upload/useUpload";
import { ImportingTemplate } from "./importing-template/ImportingTemplate";
import { SCreateProjectModalContentContainer } from "../styles/SCreateProjectModalContentContainer";
import { SCreateProjectModalContentTitle } from "../styles/SCreateProjectModalContentTitle";
import { I18n } from "../../../../../provider/i18n";
import { SelectFromTemplateStepTemplateDetails } from "./select-template-step/SelectFromTemplateStepTemplateDetails";
import { processImage } from "../utils";

interface ICreateFromExisting {
  onCancel: () => void;
  advanceStep: () => void;
  backStep?: () => void;
  currentStep: number;
  onClose: () => void;
  organizationId: string;
  groupId: string;
  setSelectedTemplate: (template: IGenProjectTemplate) => void;
  selectedTemplate: IGenProjectTemplate;
}

export const CreateFromTemplate: FC<ICreateFromExisting> = ({
  currentStep,
  onCancel,
  advanceStep,
  backStep,
  onClose,
  organizationId,
  groupId,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const { createProject, updateProject } = useMemberShip();
  const { handleFile } = useUpload();
  const { userId } = useAuthentication();
  const { logoBlob } = useUserOnboarding();
  const { importFromTemplate } = useProjectOnboarding();
  const [newProjectId, setNewProjectId] = useState<string>(null);

  const [importing, setImporting] = useState<boolean>(false);

  const handleCreateProject = async (data: any) => {
    const project = { name: data.name };

    if (groupId && groupId !== "ungrouped") {
      project["groupId"] = groupId;
    }

    const createdProject = await createProject({ userId, project, organizationId });

    if (!createdProject) return;

    setNewProjectId(createdProject.projectId);
    if (data.logo) {
      const projectId = createdProject.projectId;
      const logoAssetUrl = await processImage(logoBlob, projectId, handleFile);
      await updateProject({
        projectId,
        input: { logoAssetUrl: logoAssetUrl as string, name: data.name },
      });
    }

    setImporting(true);
    await importFromTemplate({
      source: { templateId: selectedTemplate.templateId },
      projectId: createdProject.projectId,
      documentationId: selectedTemplate.id,
    });
  };

  return (
    <>
      {currentStep === 2 && !importing ? (
        <SelectTemplateStep setSelectedTemplate={setSelectedTemplate} advanceStep={advanceStep} />
      ) : null}
      {currentStep === 3 && !importing ? (
        <SelectFromTemplateStepTemplateDetails template={selectedTemplate} onAddToProject={advanceStep} />
      ) : null}
      {currentStep === 4 && !importing ? (
        <SCreateProjectModalContentContainer>
          <div>
            <SCreateProjectModalContentTitle>
              <I18n selector="nav.createProject.newProjectDetails" fallback="New Project details" />
            </SCreateProjectModalContentTitle>
            <CreateProjectForm
              selectedTemplate={selectedTemplate}
              onCreateProject={handleCreateProject}
              onCancel={onCancel}
              onChangeTemplate={() => {
                setSelectedTemplate(null);
                backStep();
              }}
            />
          </div>
        </SCreateProjectModalContentContainer>
      ) : null}
      {importing ? (
        <SCreateProjectModalContentContainer>
          <div>
            <SCreateProjectModalContentTitle>
              <I18n fallback="Importing template..." selector="nav.createProject.templateImport" />
            </SCreateProjectModalContentTitle>
            <ImportingTemplate newProjectId={newProjectId} onFinish={onClose} />
          </div>
        </SCreateProjectModalContentContainer>
      ) : null}
    </>
  );
};
