import gql from "graphql-tag";

export const createPinView = gql`
  mutation updatePinView($input: CreateViewPinRequestInput!) {
    CreateViewPin(input: $input) {
      success
    }
  }
`;
export const deletePinView = gql`
  mutation deletePinView($input: DeleteViewPinRequestInput!) {
    DeleteViewPin(input: $input) {
      deleted
    }
  }
`;
