import gql from "graphql-tag";

export const deleteTag = gql`
  mutation CreateTag($input: DeleteTagRequest) {
    __typename
    DeleteTag(input: $input) {
      success
    }
  }
`;
