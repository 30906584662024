import gql from "graphql-tag";

export const createRelease = gql`
  mutation CreateRelease($input: CreateReleaseRequestInput) {
    __typename
    CreateRelease(input: $input) {
      release {
        createdAt
        documentIds
        id
        name
        projectId
        scheduledAt
        status
        type
        updatedAt
        validationErrors {
          documentId
          errors {
            blueprintFieldId
            errorCodes
            documentFieldLocaleId
          }
        }
      }
    }
  }
`;
