import { FC } from "react";
import { IGenUserOnboardingTourPost } from "../../../../interfaces/generated/i18n-types";
import { IOnboardingTourCardBase, OnboardingTourCard } from "./OnboardingTourCard";
import { Modal } from "@caisy/league";

interface IOnboardingTourCardPost extends IOnboardingTourCardBase {
  step: IGenUserOnboardingTourPost;
}

export const OnboardingTourCardPost: FC<IOnboardingTourCardPost> = (props) => {
  return (
    <Modal visible>
      <OnboardingTourCard confettiZIndex={1000} {...props} />
    </Modal>
  );
};
