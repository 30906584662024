import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "../../../../../constants/styles/mediaquerys";

interface IMainMenuLabel {
  activated?: boolean;
  disableHover?: boolean;
  uploadIcon?: boolean;
}

const CSSHoverEffect = css`
  &:hover {
    color: var(--active-text-04);
    svg,
    button {
      background-color: var(--hover-ui-01);
      color: var(--hover-icon-04);
      padding: 8px;
      border-radius: 8px;
    }
  }
`;
const CSSUploadIcon = css`
  &:hover {
    color: var(--hover-icon-04);
    button {
      background-color: var(--hover-ui-01);
      color: var(--hover-icon-04);
    }
  }
`;

const Bronze = css<IMainMenuLabel>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--text-04);
  ${(props) => props.uploadIcon && CSSUploadIcon}
  ${(props) => props.activated && "color: var(--text-01);"}
  ${(props) => !props.disableHover && CSSHoverEffect}
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css``;

export const SMainMenuItemIconTextHolder = styled.div<IMainMenuLabel>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
