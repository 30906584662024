import gql from "graphql-tag";
import { fragment_ViewResponse } from "../fragments/viewResponse.gql";

export const createView = gql`
  ${fragment_ViewResponse}
  fragment CreateViewResponse on CreateViewResponse {
    view {
      ...fragment_ViewResponse
    }
  }

  mutation CreateView($input: CreateViewRequestInput) {
    CreateView(input: $input) {
      ...CreateViewResponse
    }
  }
`;
