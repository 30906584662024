import { loadIntercom } from ".";
export const IntercomIgnorlist = ["localhost", "vercel.app"];
// export const IntercomIgnorlist = ["asdf", "vercel.app"];
export const IntercomDomainsWithChat = ["localhost", "caisy.io", "app.caisy.io", "app.staging.caisy.io"];

export const shouldLoadIntercom = () => {
  const shouldLoadIntercom =
    typeof window !== "undefined" && !IntercomIgnorlist.some((domain) => window.location.host.includes(domain));
  return shouldLoadIntercom;
};

export const defaultIntercomConfig = {
  custom_launcher_selector: "#intercom_btn",
  appId: process.env.NEXT_PUBLIC_INTERCOM_ID,
  alignment: "left",
  horizontal_padding: 84,
  vertical_padding: 12,
  hide_default_launcher: true,
};

export const getIntercomUserData = (user: any, projectId?: string) => {
  return {
    email: user.email,
    name: user.displayName,
    user_id: user.id,
    photoURL: user.photoURL,
    preferredUILanguage: user.preferredUILanguage,
    ...(projectId ? { last_active_project_id: projectId } : {}),
    ...(typeof window !== "undefined" ? { last_active_host: window.location.host } : {}),
  };
};

export const loadIntercomWithContext = ({ user, projectId }) => {
  loadIntercom({
    appId: process.env.NEXT_PUBLIC_INTERCOM_ID, // default : ''
    ...getIntercomUserData(user, projectId),
    preferredUILanguage: user.preferredUILanguage,
    ssr: false, // default: false
    initWindow: true, // default: true
    delay: 0, // default: 0  - usefull for mobile devices to prevent blocking the main thread
    ...defaultIntercomConfig,
  });
};
