import gql from "graphql-tag";

export const GetAllWebhooks = gql`
  query GetAllWebhooks($input: GetAllWebhooksRequestInput) {
    GetAllWebhooks(input: $input) {
      ...GetAllWebhooksResponse
    }
  }
  fragment GetAllWebhooksResponse on GetAllWebhooksResponse {
    webhooks {
      ...WebhookResponse
    }
  }
  fragment WebhookResponse on WebhookResponse {
    createdAt
    description
    headers {
      ...WebhookHeader
    }
    method
    name
    trigger
    updatedAt
    url
    webhookId
  }
  fragment WebhookHeader on WebhookHeader {
    name
    value
  }
`;
