import gql from "graphql-tag";
import { fragment_userResponse } from "../fragments/userResponse.gql";

export const getUser = gql`
  ${fragment_userResponse}
  query getUser {
    getUser {
      ...fragment_userResponse
    }
  }
`;
