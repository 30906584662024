import gql from "graphql-tag";

export const deleteDocument = gql`
  mutation DeleteDocument($input: DeleteDocumentRequestInput) {
    DeleteDocument(input: $input) {
      ...DeleteDocumentResponse
    }
  }
  fragment DeleteDocumentResponse on DeleteDocumentResponse {
    deleted
  }
`;
