import { FC, Fragment, useEffect, useState } from "react";
import { SPricingCardsContainer } from "./styles/SPricingCardsContainer";
import { PricingCard } from "../pricing-card/PricingCard";
import { SPricingCardsContainerSeparator } from "./styles/SPricingCardsContainerSeparator";
import { useBilling } from "../../../../../../../stores/billing/useBilling";
import { useCurrentProjectId } from "../../../../../../../hooks/current-project-id/useCurrentProjectId";
import { UpgradeProjectWarningModal } from "../upgrade-project-warning-modal/UpgradeProjectWarningModal";
import { IBillingPlansResponse } from "../../../../../../../services/billing-plans/getBillingPlans";
import { useCurrentBillingPlan } from "../../../../../../../hooks/current-plan/useCurrentBillingPlan";

interface IPricingCards {
  billingContent?: any;
  selectedCard?: string;
  setSelectedCard?: (card: string) => void;
  currentTab: string;
  submitOnSelect?: boolean;
  onSubmit?: () => void;
  organizationId: string;
}

export const PricingCards: FC<IPricingCards> = ({
  billingContent,
  selectedCard,
  setSelectedCard,
  currentTab,
  submitOnSelect,
  onSubmit,
  organizationId,
}) => {
  const projectId = useCurrentProjectId();
  const {
    organizationBillingStatus,
    loadOrganizationBillingStatus,
    setProjectBillingPlan,
    paymentInfo: paymentInfoByOrg,
  } = useBilling();
  const [billing, setBilling] = useState<IBillingPlansResponse>(billingContent);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { projectBillingStatus, nextPlanName, currentPlanName, currentPlanPeriod } = useCurrentBillingPlan();

  const paymentInfo = paymentInfoByOrg?.[organizationId];

  useEffect(() => {
    if (!!billingContent) return;

    fetch(`/app/api/v1/billing-plans/en`).then((res) =>
      res.json().then((data) => {
        setBilling(data);
      }),
    );
  }, []);

  useEffect(() => {
    if (organizationId && !organizationBillingStatus) {
      loadOrganizationBillingStatus({ organizationId });
    }
  }, [organizationId]);

  const changePlan = async (planName: string) => {
    if (!paymentInfo?.valid) {
      setShowWarningModal(true);
    } else {
      setSubmitting(true);
      const sufix = currentTab === "yearly" ? "_yearly" : "_monthly";
      const billingPlanName = planName === "v1_free_plan" ? `${planName}_monthly` : planName + sufix;
      const r = await setProjectBillingPlan({ projectId, organizationId, billingPlanName });
      onSubmit();
      setSubmitting(false);

      console.log(` r`, r);
    }
  };

  const isCardActive = (planName: string) => {
    // check if the card and next/current plan are free
    const areBothFree =
      (planName.includes("free") && currentPlanName?.includes("free")) ||
      (planName.includes("free") && nextPlanName?.includes("free"));
    if (areBothFree) return true;

    // check of card is different from current and next plan
    const bothAreDifferent = planName !== currentPlanName && planName !== nextPlanName;
    if (bothAreDifferent) return false;

    // check if the card is the same as the scheduled plan
    const hasPlanScheduled = !!nextPlanName;
    const isScheduledPlan = planName === nextPlanName;
    if (!isScheduledPlan && hasPlanScheduled) return false;

    // check if the card is the same as the current plan
    const isSameAsCurrent = planName === currentPlanName && currentTab === currentPlanPeriod;
    if (isSameAsCurrent) return true;
  };

  return (
    <>
      <SPricingCardsContainer>
        {billing?.pricingCards?.map((pricingCard, index) => (
          <Fragment key={pricingCard.planName}>
            <PricingCard
              disableButton={!organizationBillingStatus?.valid || submitting}
              active={isCardActive(pricingCard.planName)}
              onChangePlan={changePlan}
              checkmarkType={pricingCard.planName === projectBillingStatus?.systemName ? 3 : 2}
              isFree={index === 0}
              yearly={currentTab === "yearly"}
              pricingCard={pricingCard}
              selected={selectedCard === pricingCard.planName}
              onSelectCard={() => {
                if (!paymentInfo?.valid) {
                  setShowWarningModal(true);
                  return;
                }
                submitOnSelect ? changePlan(pricingCard.planName) : setSelectedCard(pricingCard.planName);
              }}
            />
            {index !== billing.pricingCards.length - 1 && <SPricingCardsContainerSeparator />}
          </Fragment>
        ))}
      </SPricingCardsContainer>

      <UpgradeProjectWarningModal
        visible={showWarningModal}
        onCancel={() => setShowWarningModal(false)}
        organizationId={organizationId}
        projectId={projectId}
      />
    </>
  );
};
