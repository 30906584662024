import gql from "graphql-tag";

export const deleteManyDocument = gql`
  mutation DeleteManyDocument($input: DeleteManyDocumentsRequestInput) {
    DeleteManyDocuments(input: $input) {
      deletedDocumentIds
      batchErrors {
        documentId
        errorMessage
      }
    }
  }
`;
