import { FC, useMemo, useState } from "react";
import { SSelectOrganizationStep } from "./styles/SSelectOrganizationStep";
import { SCreateProjectModalContentContainer } from "../../styles/SCreateProjectModalContentContainer";
import { SCreateProjectModalContentTitle } from "../../styles/SCreateProjectModalContentTitle";
import { useMemberShip } from "../../../../../../stores/membership/useMembership";
import { Button, List, SearchInput } from "@caisy/league";
import { SSelectOrganizationStepList } from "./styles/SSelectOrganizationStepList";
import { SelectOrganizationStepListItem } from "./SelectOrganizationStepListItem";
import fuzzysearch from "fuzzysearch";
import { I18n } from "../../../../../../provider/i18n";
import { useI18nString } from "../../../../../../provider/i18n/useI18nString";

interface ISelectOrganizationStep {
  onContinue: () => void;
  onSelect: (organizationId: string) => void;
  selectedOrganization: string;
}

export const SelectOrganizationStep: FC<ISelectOrganizationStep> = ({ onContinue, onSelect, selectedOrganization }) => {
  const { organizations } = useMemberShip();
  const [searchTerm, setSearchTerm] = useState("");

  const organizationIds = useMemo(() => {
    return Object.keys(organizations).filter(
      (organizationId) =>
        fuzzysearch(searchTerm.toLowerCase(), organizations[organizationId]?.name?.toLowerCase()) ||
        fuzzysearch(searchTerm.toLowerCase(), organizations?.[organizationId]?.alias?.toLowerCase() || ""),
    );
  }, [organizations, searchTerm]);

  const searchOrganizationsI18n = useI18nString({
    selector: "nav.createProject.searchOrganizations",
    fallback: "Search organizations",
  });

  const handleSelect = (organizationId) => {
    if (selectedOrganization === organizationId) return;

    onSelect(organizationId);
  };

  return (
    <SCreateProjectModalContentContainer>
      <div>
        <SCreateProjectModalContentTitle>
          <I18n selector="nav.createProject.selectOrganization" fallback="Select Organization" />
        </SCreateProjectModalContentTitle>
        <SSelectOrganizationStep>
          <SSelectOrganizationStepList>
            <SearchInput
              onClose={() => setSearchTerm("")}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={searchOrganizationsI18n}
            />
            <List
              renderLoadingItem={() => <></>}
              dataSource={organizationIds}
              itemSize={80}
              height={400}
              renderItem={(organizationId) => (
                <SelectOrganizationStepListItem
                  selected={selectedOrganization === organizationId}
                  onSelect={() => handleSelect(organizationId)}
                  organization={organizations[organizationId]}
                />
              )}
            />
          </SSelectOrganizationStepList>

          <Button
            onClick={onContinue}
            type="primary"
            style={{ display: "block", width: "100%" }}
            disabled={!selectedOrganization}
          >
            <I18n selector="nav.createProject.continue" fallback="Continue" />
          </Button>
        </SSelectOrganizationStep>
      </div>
    </SCreateProjectModalContentContainer>
  );
};
