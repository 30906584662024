import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createDocumentSlice } from "./createDocumentSlice";
import { IManyDocumentsState } from "./types";

export const useStore = create<IManyDocumentsState>()(
  devtools(
    (set, get) => ({
      ...createDocumentSlice(set, get),
    }),
    { name: "caisy-document", enabled: process.env.NODE_ENV !== "production" },
  ),
);

export const useDocument = () => useStore(({ document }) => document);
