import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FCWithChildren } from "../../interfaces/FCWithChildren";
import { DocumentStatusEnum } from "../../stores/document-status/types";
import { IDocument, IDocumentUsage } from "../../stores/document/types";
import { useDocument } from "../../stores/document/useDocument";
import { useUpload } from "../../stores/upload/useUpload";

const isFileDrop = (e) => {
  if (e?.dataTransfer?.getData("url")) {
    return true;
  }

  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (e.dataTransfer.items[i].kind === "file") {
        if (e.dataTransfer.items[i].getAsFile()) {
          return true;
        }
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      return true;
    }
  }
  return false;
};

export const FileUploadDropHandler: FCWithChildren = ({ children }) => {
  const [initiated, setInitiated] = useState(false);
  // const [, forceRerender] = useState(0);
  const router = useRouter();
  const projectId = router.query?.project_id as string;

  const prevent = (e) => {
    // if (t !== "dragover") {
    // console.log(` e`, { e: { e } });
    // console.log(` isFileDrop`, t, isFileDrop(e),);
    // }
    e.preventDefault();
    // e.stopPropagation();
  };

  const { updateLocalDocument } = useDocument();
  const uploadCtx = useUpload();
  const onChange = (e) => {
    // console.log(`onChange isFileDrop`, isFileDrop(e));
    uploadCtx.setCurrentDroppingFilesCount(0);
    if (isFileDrop(e)) {
      uploadCtx.handleDrop(
        {
          event: e,
          extraHeaders: {
            "x-caisy-project-id": globalThis._caisyClientState?.projectId || projectId,
          },
          projectId: globalThis._caisyClientState?.projectId || projectId,
          meta: {
            mode: "document",
          },
          onSuccess: function (upload) {
            const splitUrl = upload.url.split("/");
            const documentId = splitUrl[splitUrl.length - 2];

            const document: IDocument = {
              __typename: "Document",
              previewImageUrl: upload.url,
              statusId: DocumentStatusEnum.PUBLISHED,
              title: upload.file.name,
              usage: IDocumentUsage.direct,
              documentId,
            };

            updateLocalDocument(document);
          },
        },
        () => {},
      );
    }
  };

  useEffect(() => {
    if (!initiated) {
      setInitiated(true);
      const ele = document.body;
      if (ele) {
        ele.addEventListener("drop", onChange, false);
        ele.addEventListener(
          "dragenter",
          (e) => {
            // console.log(` e dragenter`, e);
            prevent(e);
            // prevent(e, "dragenter");
          },
          false,
        );
        ele.addEventListener(
          "dragend",
          (e) => {
            // console.log(` e dragend`, e);
            prevent(e);
            // prevent(e, "dragend");
          },
          false,
        );
        ele.addEventListener(
          "dragleave",
          (e) => {
            // console.log(` e dragleave`, e);
            if (e.screenX === 0 && e.screenY === 0) {
              uploadCtx.setCurrentDroppingFilesCount(0);
            }
            prevent(e);
            // prevent(e, "dragleave");
          },
          false,
        );
        ele.addEventListener(
          "dragexit",
          (e) => {
            // console.log(` e dragexit`, e);
            prevent(e);
            // prevent(e, "dragexit");
          },
          false,
        );
        ele.addEventListener(
          "dragover",
          (e) => {
            const dt = e.dataTransfer;
            if (
              dt.types &&
              (dt.types.indexOf ? dt.types.indexOf("Files") != -1 : (dt.types as any).contains("Files"))
            ) {
              prevent(e);
              // prevent(e, "dragover");

              if (uploadCtx.currentDroppingFilesCount != e.dataTransfer.items.length) {
                uploadCtx.setCurrentDroppingFilesCount(e.dataTransfer.items.length);
              }
            }
            // console.log(
            //   ` e.dataTransfer.items`,
            //   e.dataTransfer.items,
            //   e.dataTransfer.items.length,
            //   uploadCtx.currentDroppingFilesCount,
            // );
            // console.log(
            //   ` e.dataTransfer.items[0].length`,
            //   typeof e.dataTransfer?.items?.length,
            //   Array.isArray(e.dataTransfer?.items),
            // );
            // console.log(
            //   ` e.dataTransfer`,
            //   JSON.stringify(e),
            //   { e },
            //   e.dataTransfer.items.length,
            //   e.dataTransfer.files.length,
            // );

            // console.log(
            //   ` dragover e`,
            //   `${e.dataTransfer.types.length} ${e.dataTransfer?.files?.length} ${e.dataTransfer.items.length} files`,
            //   e,
            // );
          },
          false,
        );
      }
    }
  }, []);

  return (
    <>
      <div>{children}</div>
    </>
  );
};
