import { FC } from "react";
import { SAddProjectModalSectionBreadcrumb } from "./styles/SAddProjectModalSectionsBreadcrumb";
import { Preview } from "@caisy/league";
import { getPlaceholderLettersByName } from "../../../organism/organization-menu/getPlaceholderLettersByName";
import { SAddProjectModalSectionBreadcrumbBody } from "./styles/SAddProjectModalSectionBreadcrumbBody";
import { SAddProjectModalSectionBreadcrumbHeader } from "./styles/SAddProjectModalSectionBreadcrumbHeader";
import { SAddProjectModalSectionBreadcrumbName } from "./styles/SAddProjectModalSectionBreadcrumbName";

interface IAddProjectModalSectionBreadcrumb {
  header: React.ReactNode;
  name: string;
  logoAssetUrl: string;
}

export const AddProjectModalSectionBreadcrumb: FC<IAddProjectModalSectionBreadcrumb> = ({
  header,
  name,
  logoAssetUrl,
}) => {
  return (
    <SAddProjectModalSectionBreadcrumb>
      <Preview
        size={20}
        {...(logoAssetUrl
          ? {
              image: <img src={logoAssetUrl!} style={{ objectFit: "cover" }} />,
            }
          : {
              text: getPlaceholderLettersByName(`${name ? name : ""}`),
            })}
      />
      <SAddProjectModalSectionBreadcrumbBody>
        <SAddProjectModalSectionBreadcrumbHeader>{header}</SAddProjectModalSectionBreadcrumbHeader>
        <SAddProjectModalSectionBreadcrumbName>{name}</SAddProjectModalSectionBreadcrumbName>
      </SAddProjectModalSectionBreadcrumbBody>
    </SAddProjectModalSectionBreadcrumb>
  );
};
