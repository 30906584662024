import gql from "graphql-tag";
import { fragment_Document } from "../fragments/document.gql";

export const createDocument = gql`
  ${fragment_Document}
  mutation CreateDocument($input: CreateDocumentRequestInput) {
    CreateDocument(input: $input) {
      ...CreateDocumentResponse
    }
  }
  fragment CreateDocumentResponse on CreateDocumentResponse {
    document {
      ...Document
    }
  }
`;
