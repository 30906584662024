import { gql } from "@apollo/client";

export const deAssignRole = gql`
  mutation DeAssignRole(
    $groupId: String
    $organizationId: String
    $projectId: String
    $userId: String
    $emailAddress: String
  ) {
    DeAssignRole(
      input: {
        groupId: $groupId
        organizationId: $organizationId
        projectId: $projectId
        userId: $userId
        emailAddress: $emailAddress
      }
    ) {
      success
    }
  }
`;
