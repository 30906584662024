import gql from "graphql-tag";
import { fragment_GetCountsResponse } from "../fragments/countsResponse.glq";

export const getCounts = gql`
  ${fragment_GetCountsResponse}
  query GetCounts($input: GetCountsRequestInput) {
    GetCounts(input: $input) {
      ...GetCountsResponse
    }
  }
`;
