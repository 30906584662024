import produce from "immer";
import {
  addOrUpdateMemberFromState,
  createMember,
  deleteMember,
  getUserIdWithEmailAddress,
  updateMember,
} from "./common";
import { IAssignRoleResponse } from "../../interfaces/generated";
import { getOrganizationMembers } from "../../graphql/queries/getOrganizationMembers";
import { queryWithStaleCache } from "../../graphql/middleware/query-with-stale-cache";
import {
  ICreateOrganizationMemberInput,
  IDeleteOrganizationMemberInput,
  IUpdateOrganizationMemberInput,
  IUseOrganizationMemberState,
} from "./types";

export const createOrganizationMemberSlice = (
  set: (cb: (state: IUseOrganizationMemberState) => IUseOrganizationMemberState, boolean, string) => void,
  get: () => IUseOrganizationMemberState,
) => ({
  organizationMember: {
    isLoading: false,
    dataHash: undefined,
    members: {},
    loadOrganizationMembers: async ({ organizationId }) => {
      await queryWithStaleCache({
        uniquePrefix: "organization-members",
        query: getOrganizationMembers,
        variables: { organizationId },
        onCacheMiss: () => {
          set(
            produce((state) => {
              state.organizationMember.isLoading = true;
            }),
            false,
            "organizationMember/getOrganizationMembers/start",
          );
        },
        onUpdate: ({ data, dataHash }) => {
          if (data.GetOrganizationMembers) {
            set(
              produce((state: IUseOrganizationMemberState) => {
                state.organizationMember.isLoading = false;
                if (get().organizationMember.dataHash != dataHash) {
                  state.organizationMember.dataHash = dataHash;
                  data.GetOrganizationMembers.connection.edges.forEach((member) => {
                    const newMember = {
                      organizationId,
                      roleId: member.node.role.roleId,
                      systemRole: member.node.role.systemRole,
                      emailAddress: member.node.user.email,
                      userId: member.node.user.userId,
                    };
                    addOrUpdateMemberFromState(
                      member.node.user.userId,
                      newMember,
                      state.organizationMember.members,
                      organizationId,
                    );
                  });
                }
              }),
              false,
              "organizationMember/getOrganizationMembers/done",
            );
          }
        },
      });
    },
    createOrganizationMember: async ({
      organizationId,
      roleId,
      userId,
      systemRole,
      emailAddress,
    }: ICreateOrganizationMemberInput): Promise<IAssignRoleResponse | undefined> => {
      const data = await createMember({ organizationId, roleId, userId, systemRole, emailAddress });

      const key = data?.userId;

      if (key) {
        const newMember = {
          roleId,
          systemRole,
          emailAddress,
          userId: userId || data.userId,
          organizationId,
        };
        const stateKey = organizationId;

        set(
          produce((state: IUseOrganizationMemberState) => {
            addOrUpdateMemberFromState(key, newMember, state.organizationMember.members, stateKey);
          }),
          false,
          "organizationMember/createOrganizationMember",
        );
      }

      return data;
    },
    deleteOrganizationMember: async ({ organizationId, userId, emailAddress }: IDeleteOrganizationMemberInput) => {
      const data = await deleteMember({ organizationId, userId, emailAddress });

      const key = userId || getUserIdWithEmailAddress(emailAddress, get().organizationMember.members[organizationId]);

      set(
        produce((state: IUseOrganizationMemberState) => {
          delete state.organizationMember.members[organizationId][key];
        }),
        false,
        "organizationMember/createOrganizationMember",
      );

      return data;
    },
    updateOrganizationMember: async ({
      organizationId,
      userId,
      roleId,
      systemRole,
    }: IUpdateOrganizationMemberInput) => {
      const data = await updateMember({ organizationId, userId, roleId, systemRole });

      const key = data.userId;

      if (key) {
        const id = userId || data.userId;
        const email = get().organizationMember.members[organizationId][key].emailAddress;
        const updatedMember = {
          roleId,
          systemRole,
          emailAddress: email,
          userId: id,
          organizationId,
        };
        const stateKey = organizationId;
        set(
          produce((state: IUseOrganizationMemberState) => {
            addOrUpdateMemberFromState(key, updatedMember, state.organizationMember.members, stateKey);
          }),
          false,
          "organizationMember/createOrganizationMember",
        );
      }

      return data;
    },
    getOrganizationMembers: ({ organizationId }) => {
      return get().organizationMember.members[organizationId];
    },
  },
});
