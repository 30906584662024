import gql from "graphql-tag";

export const fragment_ViewResponse = gql`
  fragment fragment_ViewResponse on ViewResponse {
    iconUrl
    id
    parentId
    pinned
    position
    privat
    query
    target
    title
  }
`;
