import { CSSScrollbarStyles } from "@caisy/league";
import styled from "styled-components";

export const SCreateProjectModal = styled.div`
  width: calc(100vw - 80px);
  max-width: 1200px;
  background-color: var(--ui-background);
  display: flex;
  flex-direction: column;
  ${CSSScrollbarStyles};
`;
