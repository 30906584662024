import { Button, SearchInput, List, IconProjectsFoldersUngrouped, Popover } from "@caisy/league";
import { useRouter } from "next/router";
import React, { FC, forwardRef, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { I18n } from "../../../provider/i18n";
import { useI18nString } from "../../../provider/i18n/useI18nString";
import getMemberRoleI18N from "../../../utils/getMemberRoleI18N";
import { getPlaceholderLettersByName } from "./getPlaceholderLettersByName";
import { IOrganizationMenuEmpty, OrganizationMenuEmpty } from "./OrganizationMenuEmpty";
import { SOrganizationMenuSub } from "./styles/SOrganizationMenuSub";
import { SOrganizationMenuSubButtonsContainer } from "./styles/SOrganizationMenuSubButtonsContainer";
import fuzzysearch from "fuzzysearch";
import { SOrganizationMenuSubHeader } from "./styles/SOrganizationMenuSubHeader";
import { useControlKeyList } from "../../../hooks/useControlKeyList";
import { SOrganizationMenuSubTitleTooltip } from "./styles/SOrganizationmenuSubTitleTooltip";
import { STitleWithTooltip } from "./styles/STitleWithTooltip";
import { STitleWithTooltipTitle } from "./styles/STitleWithTooltipTitle";
import { OrganizationSelectListItemWrapper } from "./OrganizationSelectListItemWrapper";
import { paramsToUrlString } from "../../../utils/getHrefWithParams";

interface IOrganizationMenuSubItem {
  name: string;
  description?: string;
  roleByUser?: { title: string };
  logoAssetUrl?: string;
  projectId?: string;
  groupId?: string;
  organizationId?: string;
  labelText?: string;
}

interface IOrganizationMenuSub {
  title: string | ReactNode;
  items: IOrganizationMenuSubItem[];
  canCreate?: boolean;
  ButtonContent?: ReactNode | string;
  onButtonClick?: () => void;
  headerIcon?: ReactNode;
  currentlyOpen?: boolean;
  ref?: any;
  menuWidth?: string;
  onItemClick?: (item) => void;
  noHeader?: boolean;
  selectedItem?: string;
  itemHeight: number;
  onClickSwitchButton?: (item) => void;
  onClose: () => void;
  emptyProps?: IOrganizationMenuEmpty;
  hideSearch?: boolean;
  listHeight: number;
  selection?: number;
  searchPlaceholder?: {
    fallback: string;
    selector: string;
  };
  loading?: boolean;
}

const TitleWithTooltip: FC<{ title: string }> = ({ title }) => {
  const [ref, setRef] = useState<HTMLParagraphElement>();
  const [showTooltip, setShowTooltip] = useState(false);
  const reference = useRef();

  const needsTooltip = useMemo(() => {
    if (!ref) return;
    return ref.offsetWidth < ref.scrollWidth;
  }, [ref, showTooltip]);

  return (
    <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      <Popover
        display={needsTooltip && showTooltip}
        trianglecolor="var(--ui-overlay-02)"
        placement="top"
        reference={reference}
      >
        {() => <SOrganizationMenuSubTitleTooltip>{title}</SOrganizationMenuSubTitleTooltip>}
      </Popover>
      <STitleWithTooltip ref={reference}>
        <STitleWithTooltipTitle ref={setRef}>{title}</STitleWithTooltipTitle>
      </STitleWithTooltip>
    </div>
  );
};

export const OrganizationMenuSub: FC<IOrganizationMenuSub> = forwardRef(
  (
    {
      title,
      items,
      ButtonContent,
      onButtonClick,
      menuWidth,
      headerIcon,
      onItemClick,
      noHeader,
      selectedItem,
      itemHeight,
      onClickSwitchButton,
      onClose,
      emptyProps,
      hideSearch,
      listHeight,
      searchPlaceholder,
      loading,
    },
    ref,
  ) => {
    const router = useRouter();
    const [searchValue, setSearchValue] = useState("");
    const [filteredItems, setFilteredItems] = useState<(IOrganizationMenuSubItem & { selected?: boolean })[]>([]);

    useEffect(() => {
      setFilteredItems(
        searchValue
          ? items.filter(
              (item) =>
                fuzzysearch(searchValue.toLowerCase(), item?.name?.toLowerCase?.() || "") ||
                (item.description &&
                  item.description != "" &&
                  fuzzysearch(searchValue.toLowerCase(), item.description.toLowerCase())) ||
                searchValue == item.projectId ||
                searchValue == item.groupId ||
                searchValue == item.organizationId,
            )
          : items,
      );
    }, [searchValue, items]);

    const { listRef, handleBlur, handleKeyDown } = useControlKeyList({
      onEnter: onClickSwitchButton,
      items: filteredItems,
    });

    const onSettingsClick = ({
      projectId,
      organizationId,
      groupId,
    }: {
      projectId?: string;
      organizationId?: string;
      groupId?: string;
    }) => {
      if (projectId) {
        router.push(`/project/settings/members${paramsToUrlString({ projectId })}`, undefined, {
          shallow: true,
        });
      } else if (groupId) {
        router.push(`/group/settings/general${paramsToUrlString({ groupId })}`, undefined, {
          shallow: true,
        });
      } else if (organizationId) {
        router.push(`/organization/settings/general${paramsToUrlString({ organizationId })}`, undefined, {
          shallow: true,
        });
      }
    };

    const switchButton = (item) =>
      onClickSwitchButton && {
        onClickSwitchButton: () => {
          onClickSwitchButton(item);
        },
      };

    const handleOrganizationSelectListItemProps = (item) => {
      if (item?.groupId === "ungrouped") {
        return {
          previewProps: {
            icon: <IconProjectsFoldersUngrouped size={20} />,
          },
          badgeText: null,
          onClickSettingButton: null,
        };
      }

      return {};
    };

    const i18nSearchPlaceholder = useI18nString(
      searchPlaceholder || {
        fallback: "",
        selector: "",
      },
    );

    const dataSource = useMemo(() => {
      if (!filteredItems) return [];

      if (!selectedItem) return filteredItems;
      return filteredItems.map((item) => ({
        ...item,
        selected:
          item.projectId === selectedItem || item.groupId === selectedItem || item.organizationId === selectedItem,
      }));
    }, [selectedItem, filteredItems]);

    const selectedIndex = useMemo(() => {
      if (selectedItem === null || selectedItem === undefined) return undefined;
      return filteredItems?.findIndex((item) => item.projectId === selectedItem || item.groupId === selectedItem);
    }, [selectedItem, filteredItems]);

    return (
      <SOrganizationMenuSub menuWidth={menuWidth}>
        {!noHeader && (
          <SOrganizationMenuSubHeader>
            {headerIcon && headerIcon}
            <p>{title}</p>
          </SOrganizationMenuSubHeader>
        )}
        {items.length ? (
          <>
            <div tabIndex={0} onKeyDown={handleKeyDown} onBlur={handleBlur}>
              {!hideSearch && (
                <SearchInput
                  onClose={() => setSearchValue("")}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={i18nSearchPlaceholder}
                />
              )}
              <List
                ref={ref || listRef}
                height={listHeight}
                dataSource={dataSource}
                hasNextPage={false}
                isNextPageLoading={false}
                loadNextPage={async () => {}}
                renderLoadingItem={() => <div>{"loading ..."}</div>}
                itemSize={itemHeight}
                scrollToIndex={selectedIndex}
                renderItem={(item) => {
                  return (
                    <OrganizationSelectListItemWrapper
                      onClick={() => onItemClick(item)}
                      settingTooltip={<I18n selector="nav.nav_items_setting" fallback="Settings" />}
                      previewProps={
                        item.logoAssetUrl
                          ? {
                              image: <img src={item.logoAssetUrl!} style={{ objectFit: "cover" }} />,
                            }
                          : {
                              text: getPlaceholderLettersByName(`${item.name ? item.name : ""}`),
                            }
                      }
                      switchText={<I18n selector="nav.organization_switcher_switch" fallback="Switch" />}
                      label={item.labelText}
                      title={<TitleWithTooltip title={item.name} />}
                      itemSize={noHeader ? "large" : "medium"}
                      badgeText={getMemberRoleI18N(item?.roleByUser?.title.toLowerCase() || "viewer")}
                      active={item.selected}
                      disabledSwitchButton={item.selected}
                      onClickSettingButton={() => {
                        if (item.projectId) {
                          onSettingsClick({ projectId: item.projectId });
                        } else if (item.groupId) {
                          onSettingsClick({ groupId: item.groupId });
                        } else if (item.organizationId) {
                          onSettingsClick({ organizationId: item.organizationId });
                        }
                        onClose();
                      }}
                      {...switchButton(item)}
                      {...handleOrganizationSelectListItemProps(item)}
                    />
                  );
                }}
              />
            </div>
            {ButtonContent && (
              <SOrganizationMenuSubButtonsContainer>
                <Button onClick={onButtonClick}>{ButtonContent}</Button>
              </SOrganizationMenuSubButtonsContainer>
            )}
          </>
        ) : loading ? null : (
          <OrganizationMenuEmpty emptyHeight={noHeader ? "447px" : "393px"} {...emptyProps} />
        )}
      </SOrganizationMenuSub>
    );
  },
);
