import Link from "next/link";
import { FCWithChildren } from "../../../interfaces/FCWithChildren";
import { getHrefWithParams } from "../../../utils/getHrefWithParams";
import { SCLink } from "./styles/SCLink";

export interface ICLink {
  path: string;
  params: Record<string, string>;
  shallow?: boolean;
  passHref?: boolean;
  replace?: boolean;
  onClick?: any;
}

export const CLink: FCWithChildren<ICLink> = ({
  shallow = true,
  passHref = true,
  path,
  params,
  onClick,
  children,
  replace,
}) => {
  return (
    <Link
      replace={replace}
      shallow={shallow}
      passHref={passHref}
      href={getHrefWithParams({
        path,
        params,
      })}
      legacyBehavior
    >
      <SCLink
        href={getHrefWithParams({
          path,
          params,
        })}
        onClick={onClick}
      >
        {children}
      </SCLink>
    </Link>
  );
};
