import { IconCross, Modal } from "@caisy/league";
import { FC, useState } from "react";
import { useI18nString } from "../../../../provider/i18n/useI18nString";
import { SCreateProjectModal } from "./styles/SCreateProjectModal";
import { SCreateProjectModalCloseIconWrapper } from "./styles/SCreateProjectModalCloseIconWrapper";
import { SCreateProjectModalHeader } from "./styles/SCreateProjectModalHeader";
import { SCreateProjectModalContent } from "./styles/SCreateProjectModalContent";
import { CreateProjectModalFirstStep } from "./create-project-modal-first-step/CreateProjectModalFirstStep";
import { CreateBlankProject } from "./create-blank-project/CreateBlankProject";
import { CreateFromExisting } from "./create-from-existing/CreateFromExisting";
import {
  CreateProjectModalNavSteps,
  ICreateProjectModalNavStepsStep,
} from "./create-project-modal-nav-steps/CreateProjectModalNavSteps";
import { CreateFromTemplate } from "./create-from-template/CreateFromTemplate";
import { IGenProjectTemplate } from "../../../../interfaces/generated/i18n-types";

export enum ECreateProjectSteps {
  first_step = "first step",
  create_new_project = "create new project",
  create_new_from_existing = "create new from existing",
  create_new_from_template = "create new from template",
}

export const NAV_STEPS: { [projectStep: string]: ICreateProjectModalNavStepsStep[] } = {
  [ECreateProjectSteps.first_step]: [],
  [ECreateProjectSteps.create_new_project]: [
    { i18n: { selector: "nav.createProject.emptyProject", fallback: "Empty project" }, stepNumber: 1 },
    { i18n: { selector: "nav.createProject.projectDetails", fallback: "Project Details" }, stepNumber: 2 },
  ],
  [ECreateProjectSteps.create_new_from_existing]: [
    {
      i18n: { selector: "nav.createProject.newFromExistingProject", fallback: "New from existing project" },
      stepNumber: 1,
    },
    { i18n: { selector: "nav.createProject.selectOrganization", fallback: "Select Organization" }, stepNumber: 2 },
    {
      i18n: { selector: "nav.createProject.selectExistingProject", fallback: "Select existing project" },
      stepNumber: 3,
    },
    {
      i18n: { selector: "nav.createProject.duplicateSettings", fallback: "Duplicate settings" },
      stepNumber: 4,
    },
    { i18n: { selector: "nav.createProject.projectDetails", fallback: "Project Details" }, stepNumber: 5 },
  ],
  [ECreateProjectSteps.create_new_from_template]: [
    { i18n: { selector: "nav.createProject.newFromTemplate", fallback: "New from template" }, stepNumber: 1 },
    { i18n: { selector: "nav.createProject.selectTemplate", fallback: "Select template" }, stepNumber: 2 },
    {
      i18n: { selector: "nav.createProject.templateDetails", fallback: "Template details" },
      stepNumber: 3,
    },
    { i18n: { selector: "nav.createProject.projectDetails", fallback: "Project Details" }, stepNumber: 4 },
  ],
};

interface ICreateProjectModal {
  onClose: () => void;
  visible: boolean;
  organizationId: string;
  groupId: string;
}

const CREATE_PROJECT_STRING_I18N = {
  [ECreateProjectSteps.first_step]: {
    selector: "nav.createProject.createNewProject",
    fallback: "New project",
  },
  [ECreateProjectSteps.create_new_project]: {
    selector: "nav.createProject.createNewProject",
    fallback: "New project",
  },
  [ECreateProjectSteps.create_new_from_existing]: {
    selector: "nav.createProject.copyProject",
    fallback: "Copy project",
  },
  [ECreateProjectSteps.create_new_from_template]: {
    selector: "nav.createProject.template",
    fallback: "Template",
  },
};

export const CreateProjectModal: FC<ICreateProjectModal> = ({ visible, onClose, organizationId, groupId }) => {
  const [currentStep, setCurrentStep] = useState<ECreateProjectSteps>(ECreateProjectSteps.first_step);
  const [currentNavStep, setCurrentNavStep] = useState<number>(2);
  const [selectedTemplate, setSelectedTemplate] = useState<IGenProjectTemplate>(null);

  const createProjectString = useI18nString(CREATE_PROJECT_STRING_I18N[currentStep]);

  const reset = () => {
    setCurrentStep(ECreateProjectSteps.first_step);
    setCurrentNavStep(2);
    setSelectedTemplate(null);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleAdvanceStep = () => {
    setCurrentNavStep((prev) => prev + 1);
  };

  const handleBackStep = () => {
    setCurrentNavStep((prev) => prev - 1);
  };

  const handleCancel = () => {
    reset();
  };

  const handleClickStep = (stepNumber: number) => {
    if (stepNumber >= currentNavStep) return;

    if (stepNumber === 1) {
      handleCancel();
    } else {
      setCurrentNavStep(stepNumber);
    }
  };

  const handleChangeBaseProject = () => {
    setCurrentStep(ECreateProjectSteps.create_new_from_existing);
    setCurrentNavStep(2);
  };

  return (
    <Modal onClose={handleClose} visible={visible}>
      <SCreateProjectModal>
        <SCreateProjectModalHeader>
          {createProjectString}
          <CreateProjectModalNavSteps
            onClickStep={handleClickStep}
            currentStep={currentNavStep}
            steps={NAV_STEPS[currentStep]}
          />

          <SCreateProjectModalCloseIconWrapper onClick={handleClose}>
            <IconCross size={24} />
          </SCreateProjectModalCloseIconWrapper>
        </SCreateProjectModalHeader>
        <SCreateProjectModalContent>
          {currentStep === ECreateProjectSteps.first_step ? (
            <CreateProjectModalFirstStep onSelectStep={setCurrentStep} />
          ) : null}
          {currentStep === ECreateProjectSteps.create_new_project ? (
            <CreateBlankProject
              onClose={handleClose}
              organizationId={organizationId}
              groupId={groupId}
              onCancel={handleCancel}
            />
          ) : null}
          {currentStep === ECreateProjectSteps.create_new_from_existing ? (
            <CreateFromExisting
              organizationId={organizationId}
              groupId={groupId}
              currentStep={currentNavStep}
              advanceStep={handleAdvanceStep}
              backStep={handleBackStep}
              onClose={handleClose}
              onCancel={handleChangeBaseProject}
            />
          ) : null}
          {currentStep === ECreateProjectSteps.create_new_from_template ? (
            <CreateFromTemplate
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              currentStep={currentNavStep}
              advanceStep={handleAdvanceStep}
              backStep={handleBackStep}
              onCancel={handleCancel}
              onClose={handleClose}
              organizationId={organizationId}
              groupId={groupId}
            />
          ) : null}
        </SCreateProjectModalContent>
      </SCreateProjectModal>
    </Modal>
  );
};
