import { SLineTab } from "@caisy/league/src/components/line-tab/styles/SLineTab";
import styled from "styled-components";

export const SOnboardingTemplatesNav = styled.div`
  border-bottom: 1px solid var(--ui-03);
  padding-left: -16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 24px;
  width: 960px;
  img {
    height: 16px;
    width: 16px;
  }

  ${SLineTab} {
    margin-left: 0;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
